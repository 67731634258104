import { Bagde } from 'components/bagde';
import { useStores } from 'hooks';
import { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { ChevronDoubleLeftIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

type Props = {
  title?: string;
  titleComponent?: JSX.Element;
  subTitle?: string;
  children?: ReactNode;
  badges?: {
    title: string;
    className?: string;
  }[];
  className?: string;
  isShowCollapsedButton?: boolean;
};

export const Header = observer(
  ({
    title,
    titleComponent,
    subTitle,
    children,
    badges,
    className = 'flex flex-col lg:flex-row',
    isShowCollapsedButton = true
  }: Props) => {
    const { uiStore } = useStores();
    const { isNavbarCollapsed } = uiStore;

    const handleCollapse = () => {
      uiStore.toggleNav();
    };

    return (
      <div
        className={clsx(
          'w-full py-2.5 pr-4 md:pr-8 border-b border-gray-200 lg:items-center lg:justify-between sticky top-0 bg-white z-30 gap-4',
          className,
          !isShowCollapsedButton ? 'pl-4 md:pl-8' : 'pl-10 sm:pl-8'
        )}
      >
        <div className="flex items-start flex-wrap basis-3/5">
          {isNavbarCollapsed && isShowCollapsedButton && (
            <div
              role="button"
              className="flex items-center justify-center w-6 h-6 rounded-lg bg-primary-200 text-primary-500 cursor-pointer sm:hidden mr-3 -ml-9 sm:ml-0 mt-3 sm:mt-0"
              onClick={handleCollapse}
            >
              <ChevronDoubleLeftIcon className="duration-300 h-4 w-4 rotate-180" />
            </div>
          )}
          {title && (
            <div className="flex-1">
              <h2 className="text-xl sm:text-2xl font-semibold flex items-center flex-wrap">
                <span className="mr-2 text-gray-900">{title}</span>
                <div className="flex py-1">
                  {badges &&
                    badges?.map((badge, index) => (
                      <Bagde key={index} title={badge.title} className={`mr-2 ${badge.className}`} />
                    ))}
                </div>
              </h2>
              {subTitle && <p className="text-gray-700 font-medium text-sm">{subTitle}</p>}
            </div>
          )}
          {titleComponent && <> {titleComponent} </>}
        </div>
        {children}
      </div>
    );
  }
);
