import SingleUnitImg from '../../assets/svg/single-unit.svg';
import { ReactSVG } from 'react-svg';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';

export const SingleUnit = observer(() => {
  const { subscriptionStore } = useStores();

  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center mt-[74px]">
        <ReactSVG src={SingleUnitImg} className="mb-4" />
        <div className="flex flex-col w-full text-gray-900 content-center text-center">
          <p className="font-medium text-sm">User management is not available for SINGLE UNIT plan.</p>
          <p className="font-medium text-sm md:flex justify-center block">
            Please&nbsp;
            <span className="text-primary-500 cursor-pointer" onClick={() => subscriptionStore.showPlanOptionsModal()}>
              upgrade your plan
            </span>
            &nbsp;to add more units or users.
          </p>
        </div>
      </div>
    </div>
  );
});
