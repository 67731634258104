import React from 'react';
import './style.scss';
import clsx from 'clsx';
import { FontAndTableStyles } from 'types';

type Props = {
  className?: string;
  fontAndTableStyles?: FontAndTableStyles;
};
export const MapTablePreview = ({ className, fontAndTableStyles }: Props) => {
  const { borderColor, headerBgColor, headerTextColor, subHeaderBgColor, subHeaderTextColor } =
    fontAndTableStyles || {};

  const subHeaderStyle = {
    backgroundColor: subHeaderBgColor,
    color: subHeaderTextColor,
    borderColor: subHeaderBgColor
  };

  return (
    <div className={clsx('overflow-hidden', className)}>
      <table
        style={{ '--table-border-color': borderColor, borderColor } as any}
        className={clsx(`border-[--table-border-color]`)}
      >
        <thead>
          <tr>
            <th style={{ backgroundColor: headerBgColor, color: headerTextColor, borderColor }} colSpan={5}>
              COMPETENCY EVIDENCE REQUIRED
            </th>
          </tr>
        </thead>
        <tbody
          style={{ '--table-border-color': borderColor } as any}
          className={clsx(`[&_td]:border-[--table-border-color]`)}
        >
          <tr className="header" style={subHeaderStyle}>
            <td>Unit Code</td>
            <td>Element (from training.gov.au)</td>
            <td>Performance Criteria (from training.gov.au)</td>
            <td>Assessments</td>
            <td>Observations</td>
          </tr>
          <tr>
            <td>BSBAUD412</td>
            <td>1. Identify compliance requirements </td>
            <td>
              1.1 Identify and document statutory, legislative and regulatory requirements relevant to job role and
              industry{' '}
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr className="header" style={subHeaderStyle}>
            <td>Unit Code</td>
            <td colSpan={2}>Performance Evidence or Required Skills (from training.gov.au)</td>
            <td>Assessments</td>
            <td>Observations</td>
          </tr>
          <tr>
            <td>BSBAUD412</td>
            <td>Reading </td>
            <td>
              Identifies and analyses statutory, legislative and regulatory requirements to determine key information,
              specific requirements and responsibilities
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr className="header" style={subHeaderStyle}>
            <td>Unit Code</td>
            <td colSpan={2}>Knowledge Evidence or Require Knowledge (from training.gov.au)</td>
            <td>Assessments</td>
            <td>Observations</td>
          </tr>
          <tr>
            <td>BSBAUD412</td>
            <td colSpan={2}>
              The candidate must be able to demonstrate knowledge to complete the tasks outlined in the elements,
              performance criteria and foundation skills of this unit, including knowledge of
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr className="header" style={subHeaderStyle}>
            <td>Unit Code</td>
            <td colSpan={2}>Foundation Skills (Australian Core Skills Framework)</td>
            <td>Assessments</td>
            <td>Observations</td>
          </tr>
          <tr>
            <td>BSBAUD412</td>
            <td colSpan={2}>
              The candidate must be able to demonstrate knowledge to complete the tasks outlined in the elements,
              performance criteria and foundation skills of this unit, including knowledge of
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr className="header" style={subHeaderStyle}>
            <td>Unit Code</td>
            <td colSpan={2}>Assessment Conditions (from training.gov.au) or Critical Aspects of Evidence</td>
            <td>Assessments</td>
            <td>Observations</td>
          </tr>
          <tr>
            <td>BSBAUD412</td>
            <td colSpan={2}>This includes access to:</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
