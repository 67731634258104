import clsx from 'clsx';
import { observer } from 'mobx-react';
import { getInitials } from 'utils/helpers';

export type AvatarProps = {
  src?: string;
  alt?: string;
  label?: string;
  size?: number;
  className?: string;
  bgColor?: string;
  txtColor?: string;
  txtSize?: string;
  onClick?: Function;
};

export const Avatar = observer(
  ({
    src,
    alt = 'avatar',
    label,
    size = 32,
    className,
    bgColor = 'bg-primary-100',
    txtColor = 'text-primary-600',
    txtSize = 'text-xs',
    onClick
  }: AvatarProps) => {
    const shortLabel = getInitials(label);

    return (
      <div
        className={clsx(
          'flex items-center justify-center rounded-full relative cursor-pointer min-w-[32px]',
          bgColor,
          className
        )}
        style={{ width: size, height: size }}
        onClick={() => {
          onClick && onClick();
        }}
      >
        {!!src ? (
          <img className="w-full h-full rounded-full object-cover" src={src} alt={alt} />
        ) : (
          <span className={clsx('font-medium', txtColor, txtSize)}>{shortLabel}</span>
        )}
      </div>
    );
  }
);
