import { XMarkIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { fetchMapDetail, fetchUnitOptions } from 'api';
import clsx from 'clsx';
import { Button } from 'components/buttons';
import { reactSelectCustomStyle } from 'constant';
import { Modal, ModalProps } from 'flowbite-react';
import { useStores } from 'hooks';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { ErrorResponse, UnitOption, Mapping } from 'types';
import * as yup from 'yup';

const schema = yup
  .object({
    name: yup.string().required("Name can't be blank."),
    unitId: yup.string().required("Unit can't be blank.")
  })
  .required();

export type Option = {
  value: string;
  label: string;
  displayLabelOption: string;
};

type NewMapFormInputs = {
  unitId?: string;
  name?: string;
};

type Props = ModalProps;

export const DuplicateNewMapModal = (props: Props) => {
  const { mapUIStore } = useStores();
  const { currentMap } = mapUIStore;
  const {
    control,
    register,
    handleSubmit,
    reset,
    setFocus,
    setValue,
    formState: { errors }
  } = useForm<NewMapFormInputs>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (props.show) {
      setFocus('name');
      setValue('name', `Copy of ${currentMap?.name ?? 'Untitled'}`);
    }
  }, [props.show, currentMap, setFocus, setValue]);

  const navigate = useNavigate();

  const handleClose = () => {
    reset();
    mapUIStore.closeModal();
  };

  const { data: listUnitOptions, isLoading } = useQuery<UnitOption[], ErrorResponse>(
    ['unitsOptions'],
    () => fetchUnitOptions(''),
    {
      staleTime: 300000 // Slate = Cache = 5 minutes
    }
  );

  const { data: mapData } = useQuery<Mapping>(['maps', currentMap?.id], () =>
    fetchMapDetail(parseInt(currentMap!.id.toString()))
  );

  const { code: unitCode } = mapData || {};

  const unitOptions: Option[] = useMemo(() => {
    if (!listUnitOptions) return [];
    return listUnitOptions
      .filter((option) => option.code !== unitCode)
      ?.map((option) => {
        return {
          value: `${option.code}`,
          label: `${option.code} - ${option.title}`,
          displayLabelOption: `${option.code} - ${option.title}`
        } as Option;
      });
  }, [listUnitOptions, unitCode]);

  const Option = (props: any) => {
    const { data, ...optionProps } = props;
    return (
      <components.Option {...optionProps}>
        <p>{data?.displayLabelOption}</p>
      </components.Option>
    );
  };

  return (
    <Modal {...props} size="md" position={'center'}>
      <div className="p-4 flex justify-between title">
        <h4 className="text-sm font-medium text-gray-900">Duplicate Map to New Unit</h4>
        <Button
          disabled={isLoading}
          className={`form-control !text-sm !text-gray-900 ${errors.name ? 'invalid' : ''}`}
          onClick={() => handleClose()}
        >
          <XMarkIcon
            className={clsx(
              !isLoading && 'hover:rotate-90 duration-300 hover:text-primary-500',
              'text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none'
            )}
          />
        </Button>
      </div>
      <div className="p-4 pt-0">
        <form
          className="text-left"
          onSubmit={handleSubmit((value) => {
            if (!value?.name || !value?.unitId) {
              toast('Name or Unit cannot be blank', {
                type: 'info'
              });
              return;
            }
            mapUIStore.closeModal();
            navigate({
              pathname: `/maps/${currentMap?.id}/migrate?unitId=${value.unitId}&name=${value.name}`
            });
          })}
        >
          <div className="form-group mb-4">
            <label className="text-sm font-normal text-gray-700">Map Name:*</label>
            <input
              {...register('name')}
              type="text"
              placeholder="Map name"
              className={`form-control !text-sm !text-gray-900 ${errors.name ? 'invalid' : ''}`}
              data-testid="mapNameInput"
            />
            <p className="feedback-invalid text-sm">{errors.name?.message}</p>
          </div>
          <div className="form-group mb-4">
            <label className="text-sm font-normal text-gray-700">Select unit in library:*</label>
            <Controller
              name="unitId"
              control={control}
              render={({ field }) => (
                <Select
                  className={`${errors.unitId?.message ? 'react-select-invalid' : ''}`}
                  isMulti={false}
                  onBlur={field.onBlur}
                  onChange={(option: any) => {
                    const value = option?.value;
                    field.onChange(value);
                    setValue('unitId', value || '');
                  }}
                  options={unitOptions}
                  placeholder={`Choose unit`}
                  components={{ Option }}
                  styles={reactSelectCustomStyle}
                  classNamePrefix="react-select"
                  isClearable={true}
                  value={unitOptions?.find((option) => option.value === field.value) || null}
                />
              )}
            />
            <p className="feedback-invalid text-sm">{errors.unitId?.message}</p>
          </div>
          <div className="flex justify-end">
            <Button
              isDisabled={isLoading}
              className="btn-primary text-sm font-medium bg-white text-gray-900 hover:bg-gray-200 disabled:bg-white disabled:hover:bg-white mr-2"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={isLoading}
              className="btn-primary font-normal disabled:hover:bg-purple-100"
              data-testid="viewChangesBtn"
            >
              View Changes
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
