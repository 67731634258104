import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { HeaderContext } from '@tanstack/react-table';
import clsx from 'clsx';
import { useMediaQuery } from 'components/MediaQueryProvider';

export const DefaultHeader = ({
  table: { getState },
  header,
  column: { columnDef, getCanResize }
}: HeaderContext<any, any>) => {
  const { isTabletAndDown } = useMediaQuery();
  const meta = (columnDef.meta as any) || {};
  const { width = 0, pinning, headerTitle, headerClass } = meta;

  return (
    <th
      style={{
        left: `${width}px`,
        minWidth: `${header.getSize()}px`,
        maxWidth: `${header.getSize()}px`,
        width: `${header.getSize()}px`
      }}
      className={clsx('h-th', pinning ? 'header-sticky top-0' : '', headerClass)}
      key={header.id}
    >
      {headerTitle}
      {!isTabletAndDown && getCanResize() && (
        <div
          {...{
            onMouseDown: header.getResizeHandler(),
            onTouchStart: header.getResizeHandler(),
            className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
            style: {
              transform: header.column.getIsResizing() ? `translateX(${getState().columnSizingInfo.deltaOffset}px)` : ''
            }
          }}
        >
          <div className="absolute bottom-[-10px] left-[-8px] bg-gray-500 border border-white rounded-full h-[20px] w-[20px] flex">
            <ChevronLeftIcon className="text-white" />
            <ChevronRightIcon className="text-white" />
          </div>
        </div>
      )}
    </th>
  );
};
