import { PagedList, Template } from 'types';
import { FileType } from 'types/enums';
import axios from '../lib/axios';

export const fetchTemplates = async (page: number = 1, perPage: number = 10): Promise<PagedList<Template>> => {
  return await axios.get(`templates?page=${page}&per_page=${perPage}`).then((res) => {
    return res.data;
  });
};

export const deleteTemplate = async (id: string) => {
  return await axios.delete(`templates/${id}`);
};

export const createTemplate = async (request: { name: string; columns: string[] }) => {
  return await axios.post(`templates`, {
    template: {
      name: request.name,
      columns: request.columns
    }
  });
};

export const updateTemplate = async (request: { id: string; name: string; columns: string[] }) => {
  return await axios.put(`templates/${request.id}`, {
    template: {
      name: request.name,
      columns: request.columns
    }
  });
};

export const details = async (id: string) => {
  return await axios.get(`templates/${id}`).then((res) => {
    return res.data;
  });
};

export const generateDownloadUnitTemplateUrl = async (request: {
  templateId: string;
  unitIds: number[];
  fileType: FileType;
}) => {
  return await axios
    .post(`templates/${request.templateId}/generate_download_url`, {
      fileType: request.fileType,
      unitIds: request.unitIds
    })
    .then((res) => {
      return Promise.resolve(res.data);
    });
};
