import { XMarkIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { changePassword } from 'api/user';
import clsx from 'clsx';
import { PasswordField } from 'components';
import { Button } from 'components/buttons';
import { Modal, ModalProps } from 'flowbite-react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import 'styles/modal.scss';

interface IFormInputs {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

type Props = {} & ModalProps;

const schema = yup
  .object({
    currentPassword: yup
      .string()
      .required("Current password can't be blank.")
      .min(6, 'Password must contain at least 6 characters.'),
    password: yup
      .string()
      .required("New password can't be blank.")
      .min(6, 'Password must contain at least 6 characters.'),
    passwordConfirmation: yup
      .string()
      .required("Confirm new password can't be blank.")
      .oneOf([yup.ref('password')], "Confirm new password doesn't match Password.")
  })
  .required();

export const ChangePasswordModal = ({ onClose, ...modalProps }: Props) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  const { mutate, isLoading } = useMutation((variables: IFormInputs) => changePassword(variables), {
    onSuccess: (data) => {
      const msgSuccess = 'Your password have been changed.';
      toast(msgSuccess, {
        type: 'success'
      });
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  const handleClose = () => {
    reset();
    onClose?.();
  };

  return (
    <Modal {...modalProps} size="lg" position={`top-center`} id="change-password-modal">
      <div className="p-4 flex justify-between title">
        <h4 className="text-sm font-medium text-gray-900">Change Password</h4>
        <Button disabled={isLoading} className={`form-control !text-sm !text-gray-900`} onClick={() => handleClose()}>
          <XMarkIcon
            className={clsx(
              !isLoading && 'hover:rotate-90 duration-300 hover:text-primary-500',
              'text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none'
            )}
          />
        </Button>
      </div>
      <div className="p-4 pt-0">
        <form
          className="text-left"
          onSubmit={handleSubmit((values) => {
            mutate(values);
          })}
        >
          <div className="form-group">
            <label className="text-sm font-normal text-gray-700">Current Password: *</label>
            <Controller
              name="currentPassword"
              control={control}
              render={({ field, fieldState }) => (
                <PasswordField
                  className={`form-control ${fieldState.error ? 'invalid' : ''}`}
                  value={field.value || ''}
                  onChange={field.onChange}
                  placeholder="Input password"
                  name={field.name}
                />
              )}
            />
            <p className="feedback-invalid">{errors.currentPassword?.message}</p>
          </div>
          <div className="form-group">
            <label className="text-sm font-normal text-gray-700">New Password: *</label>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => (
                <PasswordField
                  className={`form-control ${fieldState.error ? 'invalid' : ''}`}
                  value={field.value || ''}
                  onChange={field.onChange}
                  placeholder="Input new password"
                  name={field.name}
                />
              )}
            />
            <p className="feedback-invalid">{errors.password?.message}</p>
          </div>
          <div className="form-group">
            <label className="text-sm font-normal text-gray-700">Confirm New Password: *</label>
            <Controller
              name="passwordConfirmation"
              control={control}
              render={({ field, fieldState }) => (
                <PasswordField
                  className={`form-control ${fieldState.error ? 'invalid' : ''}`}
                  value={field.value || ''}
                  onChange={field.onChange}
                  placeholder="Confirm new password"
                  name={field.name}
                />
              )}
            />
            <p className="feedback-invalid">{errors.passwordConfirmation?.message}</p>
          </div>
          <div className="flex justify-end">
            <Button
              isDisabled={isLoading}
              className="btn-primary text-sm font-medium bg-white text-gray-900 hover:bg-gray-200 disabled:bg-white disabled:hover:bg-white mr-2"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={isLoading}
              className="btn-primary font-normal disabled:hover:bg-purple-100"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
