import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import { Routers } from './routers';
import { ToastContainer } from 'react-toastify';

const basename = document.querySelector('base')?.getAttribute('href') ?? '/';

function App() {
  return (
    <BrowserRouter basename={basename}>
      <Routers />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
