import { CheckIcon, ChevronDoubleLeftIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getBrandSettings, updateBrandSettings } from 'api/brand-settings';
import { Header, Loading } from 'components';
import { BrandSettingForm, BrandSettingPreview, BSFConfirmModal } from 'components/brand-settings';
import { Button } from 'components/buttons';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';
import { BrandSettingsForm, BrandSettingsPayload, FontAndTableStyles } from 'types/brand-settings';
import edjsHTML from 'lib/editorjs-html';
import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { NotAvailable } from 'components/icons';
import { useStores } from 'hooks';
const edjsParser = edjsHTML();

export const BrandSettings = observer(() => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [error, setError] = useState<any>();
  const [readOnly, setReadOnly] = useState<{ [key: string]: boolean }>({
    unitDesc: true,
    text: true,
    footer: true
  });

  const { uiStore } = useStores();
  const { isNavbarCollapsed } = uiStore;

  const { isLoading, data } = useQuery<BrandSettingsForm, ErrorResponse>(['brand-settings'], getBrandSettings);

  const methods = useForm<BrandSettingsForm>({
    defaultValues: data,
    mode: 'onChange'
  });

  useEffect(() => {
    if (data) {
      methods.reset(data);
    }
  }, [data, methods]);

  const notReadOnly = useMemo(() => {
    return Object.values(readOnly).some((value) => !value);
  }, [readOnly]);

  const { mutate, isLoading: isMutating } = useMutation({
    mutationFn: updateBrandSettings,
    onSuccess: () => {
      toast.success('Your changes has been saved');
    }
  });

  const parseHtml = (data: any, styles?: FontAndTableStyles) => {
    const blocks = (data?.blocks || []).map((block: any) => {
      return {
        ...block,
        data: {
          ...block.data,
          styles: styles
        }
      };
    });
    return edjsParser.parse({ ...data, blocks })?.join('');
  };

  const onSubmit: SubmitHandler<FieldValues> = (formValue: FieldValues) => {
    const { fontAndTableStyles, unitDescriptionTemplateJson, textAfterMapDetailsTemplateJson, footerTemplateJson } =
      formValue;
    const unitDescriptionTemplate = parseHtml(unitDescriptionTemplateJson, fontAndTableStyles);
    const textAfterMapDetailsTemplate = parseHtml(textAfterMapDetailsTemplateJson, fontAndTableStyles);
    const footerTemplate = parseHtml(footerTemplateJson, fontAndTableStyles);

    const payload = {
      ...formValue,
      logo: formValue.logoUrl,
      unitDescriptionTemplate,
      textAfterMapDetailsTemplate,
      footerTemplate
    } as BrandSettingsPayload;

    // Turn off the modal if it's in edit mode
    if (notReadOnly) {
      setConfirmModal(false);
      setReadOnly({ unitDesc: true, text: true, footer: error ? false : true });
    }

    mutate(payload);
  };

  const beforeSubmit: SubmitHandler<FieldValues> = (formValue: FieldValues) => {
    if (notReadOnly) {
      setConfirmModal(true);
      return;
    }
    onSubmit(formValue);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="mapping flex flex-col h-full sm:hidden">
        <Header title="Brand Settings" className="flex justify-between text-gray-900" />
        <div className="flex flex-col items-center pt-24">
          <NotAvailable className="mb-8" />
          <div className="font-medium text-sm text-gray-900">Brand settings are not available on mobile devices.</div>
        </div>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(beforeSubmit)} className="hidden sm:block">
          <Header title="Brand Settings" className="flex justify-between text-gray-900">
            <Button
              type="submit"
              isLoading={isLoading || isMutating}
              className="btn-primary font-normal disabled:hover:bg-purple-100"
            >
              <CheckIcon className="h-5 w-auto mr-2" />
              Save
            </Button>
          </Header>
          <div className="flex items-start">
            <div className="flex-initial basis-[340px] max-w-[340px]">
              <BrandSettingForm />
            </div>
            <div className="flex-1">
              <BrandSettingPreview readOnly={readOnly} setReadOnly={setReadOnly} onError={(error) => setError(error)} />
            </div>
          </div>
        </form>
        <BSFConfirmModal
          show={confirmModal}
          onConfirm={() => onSubmit(methods.getValues())}
          onClose={() => setConfirmModal(false)}
        />
      </FormProvider>
    </>
  );
});
