import { ErrorResponse, Unit } from 'types';
import { FileType } from 'types/enums';
import axios from '../lib/axios';

export const fetchUnitDetail = async (code: string): Promise<Unit> => {
  return await axios.get(`units/${code}`).then((res) => {
    return res.data;
  });
};

export const generateDownloadUrl = async (request: {
  id: string;
  fileType: FileType;
}): Promise<string | ErrorResponse> => {
  return await axios
    .post(`units/${request.id}/generate_download_url`, {
      file_type: request.fileType
    })
    .then((res) => {
      return Promise.resolve(res.data);
    });
};

export const addToLibrary = async (unitCode: string): Promise<any | ErrorResponse> => {
  return await axios.post(`units/${unitCode}/add_to_library`).then((res) => {
    return res.data;
  });
};
