import {
  AcademicCapIcon,
  FolderIcon,
  BuildingOfficeIcon,
  PresentationChartBarIcon,
  MapIcon
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { NodeType } from 'types';

type props = {
  icon?: any;
  className?: string;
  nodeType?: NodeType;
};

export const TreeNodeIcon = ({ icon: IconComponent, className, nodeType }: props) => {
  if (IconComponent) {
    return <IconComponent className={clsx('h-5 w-5', className)} />;
  }

  switch (nodeType) {
    case NodeType.REGULAR:
      return <FolderIcon className={clsx('h-5 w-5 flex', className)} />;
    case NodeType.UNIT:
      return <PresentationChartBarIcon className={clsx('h-5 w-5', className)} />;
    case NodeType.COURSE:
      return <AcademicCapIcon className={clsx('h-5 w-5', className)} />;
    case NodeType.RTO:
      return <BuildingOfficeIcon className={clsx('h-5 w-5', className)} />;
    case NodeType.MAP:
      return <MapIcon className={clsx('h-5 w-5', className)} />;

    default:
      return <FolderIcon className={clsx('h-5 w-5', className)} />;
  }
};
