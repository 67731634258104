import { Element, FoundationSkill } from 'types';
import { PerformanceCriterionLevel } from './PerformanceCriterionLevel';
type Props = {
  className?: string;
  elements: Element[];
  foundationSkills: FoundationSkill[];
};

export const PerformanceCriteriaTable = ({ elements, foundationSkills, className }: Props) => {
  const findSkills = (level?: string) => {
    if (!level) return [];
    return foundationSkills.filter((x) => x.performanceCriteria.includes(level)).map((item) => item.skill);
  };

  const ElementRow = ({ element }: { element: Element }) => {
    const { performanceCriterions: rawPerformanceCriterions } = element;
    const performanceCriterions = [...rawPerformanceCriterions!];
    const firstPerformanceCriterion = performanceCriterions?.shift();
    const firstSkills = findSkills(firstPerformanceCriterion?.level);
    return (
      <>
        <tr>
          <th className="text-left font-medium border-t" rowSpan={element.performanceCriterions?.length!}>
            {element.level}. {element.content}
          </th>
          <td className="text-left border-t border-l foundation-skill">
            <PerformanceCriterionLevel performanceCriterion={firstPerformanceCriterion} skills={firstSkills} />;
          </td>
        </tr>
        {element.performanceCriterions?.length! > 0 &&
          performanceCriterions?.map((performanceCriterion, index) => {
            const skills = findSkills(performanceCriterion.level);

            return (
              <tr key={index}>
                <td className="text-left border-t border-l foundation-skill">
                  <PerformanceCriterionLevel performanceCriterion={performanceCriterion} skills={skills} />
                </td>
              </tr>
            );
          })}
      </>
    );
  };

  return (
    <div className={className}>
      <h3 className="uppercase mb-4 text-primary-500 text-xs font-semibold leading-5">
        Elements and Performance Criteria
      </h3>
      <table className="table w-full">
        <thead className="bg-primary-100">
          <tr>
            <th className="text-left font-medium text-sm rounded-tl-lg">Element</th>
            <th className="text-left font-medium text-sm rounded-tr-lg">Performance Criteria</th>
          </tr>
        </thead>
        <tbody>
          {elements.map((element, index) => (
            <ElementRow key={index} element={element} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
