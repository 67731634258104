import { Navigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';

export const CheckAuth = observer(({ children }: { children: JSX.Element }) => {
  let { authStore } = useStores();
  let location = useLocation();

  if (authStore.authenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return <>{children}</>;
});
