import { Link } from 'react-router-dom';

export const NotFound = () => {
  return (
    <main className="h-screen w-full flex flex-col justify-center items-center bg-gray-800">
      <h1 className="text-9xl font-extrabold text-white tracking-widest">404</h1>
      <div className="bg-primary-500 px-2 text-sm rounded rotate-12 absolute">Page Not Found</div>
      {/* <p className="text-white">Please check the URL in the address bar and try again.</p> */}
      <button className="mt-5">
        <Link
          to="/"
          className="relative inline-block text-sm font-medium text-primary-500 group active:text-orange-500 focus:outline-none focus:ring"
        >
          <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-primary-500 group-hover:translate-y-0 group-hover:translate-x-0"></span>

          <span className="relative block px-8 py-3 bg-gray-800 border border-current">Go Home</span>
        </Link>
      </button>
    </main>
  );
};
