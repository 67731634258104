import { useOutletContext } from 'react-router-dom';
import { UnitTable } from './UnitTable';
import { CourseNoUnit } from './CourseNoUnit';

export const AllUnits = () => {
  const { courseType, allUnits, onAddAddUnit } = useOutletContext() as any;

  if (!allUnits?.length) return <CourseNoUnit />;

  return (
    <div>
      <UnitTable units={allUnits} tableType="all" courseType={courseType} onAddUnit={onAddAddUnit} />
    </div>
  );
};
