import { BrandSettingsForm } from 'types';

export const BRAND_SETTINGS_DEFAULT: BrandSettingsForm = {
  logoDisplay: true,
  logoUrl: '',
  logoAlignment: 'left',
  unitDescriptionDisplay: true,
  unitDescriptionTemplate: '',
  unitDescriptionTemplateJson: {
    blocks: [
      {
        type: 'table',
        data: {
          withHeadings: false,
          content: [
            ['Unit Code:', '{{unit_code}}'],
            ['Unit Name:', '{{unit_name}}'],
            ['Unit Release:', '{{unit_release}}'],
            ['Map Name:', '{{map_name}}'],
            ['Map Version:', '{{map_version}}']
          ]
        }
      }
    ]
  },
  fontAndTableStyles: {
    fontFamily: 'Inter',
    headerBgColor: '#E346D1',
    headerTextColor: '#FFFFFF',
    subHeaderBgColor: '#E7EBEF',
    subHeaderTextColor: '#00000',
    borderColor: '#E7EBEF'
  },
  textAfterMapDetailsDisplay: true,
  textAfterMapDetailsTemplate: '',
  textAfterMapDetailsTemplateJson: {
    time: new Date().getTime(),
    blocks: [
      {
        type: 'header',
        data: {
          text: 'TEXT HEADING',
          level: 1
        }
      },
      {
        type: 'paragraph',
        data: {
          text: 'Lorem ipsum dolor sit amet consectetur. Egestas netus magna velit eu mi sollicitudin consectetur in hendrerit. Placerat suspendisse sit mattis nunc consectetur ut. Tristique nunc in leo sagittis sit. Dictumst amet lacus vulputate ipsum.'
        }
      }
    ]
  },
  footerDisplay: true,
  footerTemplate: '',
  footerTemplateJson: {
    time: new Date().getTime(),
    blocks: [
      {
        type: 'paragraph',
        data: {
          text: `© $${new Date().getFullYear} All rights reserved.`,
          alignment: 'center'
        }
      }
    ]
  }
};
