import { SidebarTreeNode } from 'types';
import axios from 'lib/axios';

export const fetchSidebarRegularFolders = async (): Promise<SidebarTreeNode[]> => {
  return await axios.get(`library/regulars/sidebar`).then((res) => {
    return res.data;
  });
};

export const fetchSidebarCourseFolders = async (): Promise<SidebarTreeNode[]> => {
  return await axios.get(`library/courses/sidebar`).then((res) => {
    return res.data;
  });
};

export const fetchSidebarRtoFolders = async (): Promise<SidebarTreeNode[]> => {
  return await axios.get(`library/rtos/sidebar`).then((res) => {
    return res.data;
  });
};

export const fetchSidebarUnitFolders = async (): Promise<SidebarTreeNode[]> => {
  return await axios.get(`library/units/sidebar`).then((res) => {
    return res.data;
  });
};

export const fetchSidebar = async (): Promise<SidebarTreeNode[][]> => {
  const units = await fetchSidebarUnitFolders();
  const rtos = await fetchSidebarRtoFolders();
  const courses = await fetchSidebarCourseFolders();
  const regulars = await fetchSidebarRegularFolders();

  return [regulars, courses, rtos, units];
};
