import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { fetchWorkspaceDetails } from 'api';
import { useStores } from 'hooks';
import { FormManagePayment } from 'pages';
import { Loading } from 'components/loading';
import { ErrorResponse, WorkspaceBilling } from 'types';

interface EmailAddressProps {
  readOnly?: boolean;
  email?: string;
}

export const EmailAddress: React.FC<EmailAddressProps> = observer(({ readOnly, email }) => {
  const { uiStore } = useStores();

  let classes = clsx('form-control', '!px-3', '!py-2.5', '!text-sm ', '!leading-5', ' w-full', {
    '!text-gray-500': readOnly,
    '!text-black': !readOnly
  });

  if (email) {
    return <input className={classes} defaultValue={email} readOnly={readOnly} disabled={readOnly} />;
  }

  const { currentWorkspaceId } = uiStore;

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery<WorkspaceBilling, ErrorResponse>(['workspaces', currentWorkspaceId], () =>
    fetchWorkspaceDetails(currentWorkspaceId)
  );

  const onBlurEmailInput = (e: ChangeEvent<HTMLInputElement>) => {
    queryClient.setQueryData<WorkspaceBilling>(['workspaces', 'current'], (oldData: any) => {
      return {
        ...oldData,
        email: e.target.value
      };
    });
  };

  const {
    register,
    formState: { errors }
  } = useFormContext<FormManagePayment>();

  classes = clsx('form-control', '!px-3', '!py-2.5', '!text-sm ', '!leading-5', ' w-full', {
    '!text-gray-500': readOnly,
    '!text-black': !readOnly,
    invalid: errors.email
  });

  if (isLoading) return <Loading />;

  return (
    <>
      <input
        {...register('email')}
        className={classes}
        defaultValue={data?.email}
        readOnly={readOnly}
        onBlur={onBlurEmailInput}
      />

      {errors.email && <p className="feedback-invalid">{errors.email?.message}</p>}
    </>
  );
});
