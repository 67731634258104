import { Helmet } from 'react-helmet-async';
import { HomeIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchLibraryRTO } from 'api';
import { Breadcrumb, BreadcrumbItem, Loading } from 'components';
import { observer } from 'mobx-react';
import { ErrorResponse, Library, NodeType } from 'types';
import { LibraryContainer } from 'components/library/LibraryContainer';

import { Link } from 'react-router-dom';
import { useStores } from 'hooks/useStores';
import { useEffect } from 'react';

export const RTOFolder = observer(() => {
  const queryClient = useQueryClient();
  const { sidebarTreeStore, uiStore } = useStores();
  const { currentWorkspaceId } = uiStore;
  const { data: listData, isLoading } = useQuery<Library[], ErrorResponse>(
    ['rto_folder_list', currentWorkspaceId],
    () => fetchLibraryRTO()
  );

  useEffect(() => {
    sidebarTreeStore.setCurrentFolderId('rto');
  }, [sidebarTreeStore]);

  const onOpenModalModifyFolder = () => {
    uiStore.openSearchBar();
  };

  const onActionCallback = async () => {
    await sidebarTreeStore.loadRtoFolders();
    queryClient.invalidateQueries(['rto_folder_list', currentWorkspaceId]);
  };

  if (isLoading) {
    return <Loading />;
  }

  const ActionButton = () => {
    return (
      <>
        <button className="btn-primary text-sm ml-3 hidden md:block" onClick={onOpenModalModifyFolder}>
          <BuildingOfficeIcon className="w-5 h-5 inline-block mr-2" />
          Add New RTO
        </button>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>RTOs</title>
      </Helmet>

      <LibraryContainer
        data={listData || []}
        typeFolder={NodeType.RTO}
        buttonComponent={<ActionButton />}
        onActionCallback={onActionCallback}
        titleComponent={
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">
                <HomeIcon className="w-5 text-gray-600" />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>RTOs</BreadcrumbItem>
          </Breadcrumb>
        }
      />
    </>
  );
});
