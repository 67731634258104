import { TemplateDetails } from 'components';
import { CompentecyEvidenceRequired, UnitDetails, UnitMaps } from 'components/unit';
import { AllUnits, CoreUnits, ElectiveUnits } from 'components/course';
import { AllCourses, CurrentCourses, SupersededCourses } from 'components/rto';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ForgotPasswordForm, ResetPasswordForm, SignupForm } from '../components/auth';
import {
  UnAuthLayout,
  AuthLayout,
  NotFound,
  Unit,
  Mapping,
  ManageUsers,
  AcceptInvite,
  MyTemplates,
  ManagePayment,
  Course,
  RTO,
  CourseConfiguration,
  Folder,
  FolderDetail,
  RTOFolder,
  UnitFolder,
  CourseFolder,
  RTOConfiguration,
  MyLibrary,
  Profile,
  Expired,
  UnitMigration,
  Signin,
  BrandSettings
} from '../pages';
import { CheckAuth } from './checkAuth';
import { DefaultUnitRoute } from './defaultUnitRoute';
import { ProtectedRoute } from './protectedRoute';
import { observer } from 'mobx-react';
import { useStores } from 'hooks';
import { MapMigration } from 'pages/MapMigration';
import { SubscriptionProtectedRoute } from './subscriptionProtectedRoute';

export const Routers = observer(() => {
  const { authStore } = useStores();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute isAllowed={authStore.authenticated} redirectPath="/auth/login">
            <SubscriptionProtectedRoute>
              <AuthLayout />
            </SubscriptionProtectedRoute>
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to="/library" />} />
        <Route path="templates">
          <Route path="" element={<MyTemplates />} />
          <Route path="new" element={<TemplateDetails />} />
          <Route path=":id" element={<TemplateDetails />} />
        </Route>

        <Route path="units/:id" element={<Unit />}>
          <Route index element={<DefaultUnitRoute />} />
          <Route path="maps" element={<UnitMaps />} />
          <Route path="details" element={<UnitDetails />} />
          <Route path="competency-evidence-required" element={<CompentecyEvidenceRequired />} />
        </Route>
        <Route path="units/:id/migrate" element={<UnitMigration />} />
        <Route path="courses/:id" element={<Course />}>
          <Route index element={<AllUnits />} />
          <Route path="core-units" element={<CoreUnits />} />
          <Route path="elective-units" element={<ElectiveUnits />} />
        </Route>
        <Route path="rtos/:code" element={<RTO />}>
          <Route index element={<AllCourses />} />
          <Route path="current-courses" element={<CurrentCourses />} />
          <Route path="superseded-courses" element={<SupersededCourses />} />
        </Route>
        <Route path="maps/:id" element={<Mapping />} />
        <Route path="maps/:id/migrate" element={<MapMigration />} />
        <Route path="library">
          <Route index element={<MyLibrary />} />
          <Route path="regulars" element={<Folder />} />
          <Route path="folders/:id" element={<FolderDetail />} />
          <Route path="rtos" element={<RTOFolder />} />
          <Route path="units" element={<UnitFolder />} />
          <Route path="courses" element={<CourseFolder />} />
          <Route path="courses/:code/:id" element={<CourseConfiguration />}></Route>
          <Route path="rtos/:code/:id" element={<RTOConfiguration />}></Route>
        </Route>

        <Route path="mapping" element={<Mapping />}></Route>
        <Route path="manage-users" element={<ManageUsers />}></Route>
        <Route path="brand-settings" element={<BrandSettings />}></Route>
        <Route path="profile" element={<Profile />}></Route>
        <Route path="subscriptions" element={<ManagePayment />}></Route>
        <Route path="subscriptions/expired" element={<Expired />}></Route>
        <Route path="brand-settings" element={<BrandSettings />}></Route>
      </Route>
      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="login" element={<Signin />} />
        <Route path="register" element={<SignupForm />} />
        <Route path="forgot-password" element={<ForgotPasswordForm />} />
        <Route path="reset-password" element={<ResetPasswordForm />} />
        <Route path="accept-invite" element={<AcceptInvite />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
});
