import { useParams, useOutletContext, Navigate } from 'react-router-dom';
import { UnitTable } from './UnitTable';
import { CourseNoUnit } from './CourseNoUnit';

export const ElectiveUnits = () => {
  const { courseType, electiveUnits, onAddAddUnit } = useOutletContext() as any;
  const { id } = useParams();

  if (courseType === 'skill') {
    return <Navigate to={`/courses/${id}`} />;
  }

  if (!electiveUnits?.length) return <CourseNoUnit />;

  return (
    <div>
      <UnitTable units={electiveUnits} tableType="elective" courseType={courseType} onAddUnit={onAddAddUnit} />
    </div>
  );
};
