import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useDebounce } from 'hooks';
import { useEffect, useRef, useState } from 'react';

export const SearchBox = ({
  onChange,
  placeholder,
  id,
  superseded = false
}: {
  onChange: (value: string, superseded?: boolean) => void;
  placeholder: string;
  id: string;
  superseded?: boolean;
}) => {
  const [showClearButton, setShowClearButton] = useState(false);
  const [keyword, setKeyword] = useState<string>('');
  const checkboxRef = useRef<HTMLInputElement>(null);

  const debouncedSearch = useDebounce(keyword, 500);
  useEffect(() => {
    onChange(debouncedSearch, checkboxRef.current?.checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <form className="mb-4">
      <div className="form-group relative !mb-2">
        <div className="absolute left-4 top-0 bottom-0 flex items-center">
          <button className="w-[18px] h-[18px]" type="button">
            <MagnifyingGlassIcon />
          </button>
        </div>
        <div>
          <input
            value={keyword}
            className={clsx('form-control text-sm !pl-10 !pr-14 !leading-5 placeholder:text-gray-300')}
            autoFocus={true}
            placeholder={placeholder}
            type={'text'}
            onChange={(event) => {
              const keyword = event.target.value;
              setShowClearButton(!!keyword);
              setKeyword(keyword);
            }}
            onKeyDown={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          />
        </div>
        {showClearButton && (
          <div className="absolute right-4 top-0 bottom-0 flex items-center py-4">
            <button
              className="text-xs text-gray-700"
              title="Clear"
              type="reset"
              onClick={() => {
                setKeyword('');
                setShowClearButton(false);
              }}
            >
              Clear
            </button>
          </div>
        )}
      </div>
      {superseded && (
        <div className="flex items-center">
          <input
            id={id}
            ref={checkboxRef}
            type="checkbox"
            value="true"
            onChange={(event) => {
              onChange(debouncedSearch, event.target.checked);
            }}
            className="w-4 h-4 text-primary-500 bg-white rounded border-gray-200 focus:ring-0 ring-0 "
          />
          <label htmlFor={id} className="ml-2 text-sm font-normal text-gray-500 cursor-pointer">
            Include superseded items
          </label>
        </div>
      )}
    </form>
  );
};
