import { useMediaQuery } from 'components/MediaQueryProvider';
import { useStores } from 'hooks';
import React from 'react';
import SimpleBar from 'simplebar-react';
import SubscriptionActions from './SubscriptionActions';
import SubscriptionPlanCard from './SubscriptionPlanCard';

interface Props {
  isExpired: boolean;
}

const SubscriptionListPlan: React.FC<Props> = ({ isExpired }) => {
  const { subscriptionStore } = useStores();

  const { isTabletAndDown } = useMediaQuery();

  return (
    <div className="relative w-full mt-12 xs:order-2 xs:mt-0 sm:order-2 sm:mt-0 lg:w-7/12 lg:mt-0 xl:w-1/2" role="list">
      <SimpleBar autoHide={true} className="!mt-0 px-3 hover:cursor-pointer sm:p-0 sm:mt-4 max-h-[30rem]">
        {subscriptionStore.plans.map((plan) => (
          <SubscriptionPlanCard key={plan.name} plan={plan} />
        ))}
        {isTabletAndDown && isExpired && (
          <div className="sm:order-3">
            <SubscriptionActions />
          </div>
        )}
      </SimpleBar>
    </div>
  );
};

export default SubscriptionListPlan;
