import { useStores } from 'hooks';
import SubscriptionExpiredSelect from './SubscriptionSelect';

const SubscriptionActions = () => {
  const store = useStores();

  const logout = () => {
    store.logout();
  };

  return (
    <div className="flex flex-col gap-4 xs:mt-6 xs:pb-14 md:!pb-0">
      <div className="flex flex-col gap-x-1.5">
        <p className="mb-1 text-sm font-medium text-gray-500">Switch workspace</p>
        <SubscriptionExpiredSelect />
      </div>

      <button
        type="button"
        className="w-full flex items-center justify-center outline-0 text-center relative h-10 disabled:opacity-50 btn-secondary px-4 py-2.5 bg-[#FFEAFD]"
        onClick={logout}
      >
        Sign out now
      </button>
    </div>
  );
};

export default SubscriptionActions;
