import clsx from 'clsx';
import { Bagde } from 'components/bagde';
import { Loading } from 'components/loading';
import { Progress } from 'flowbite-react';
import { useStores } from 'hooks';
import { observer } from 'mobx-react';
import { pluralize } from 'utils/helpers';

type Props = {
  className?: string;
  isLoading?: boolean;
};
export const SubscriptionCard = observer(({ className, isLoading }: Props) => {
  const {
    subscriptionStore: { subscription }
  } = useStores();

  if (!subscription) {
    return (
      <div className={clsx('border border-gray-200 p-3 md:p-4 rounded-lg bg-white relative', className)}>
        <Loading />
      </div>
    );
  }

  let unitProgress = 0;
  let userProgress = 0;
  unitProgress = (subscription!.usedUnits / subscription!.plan.units) * 100;
  userProgress = (subscription!.usedMembers / subscription!.plan.users) * 100;

  return (
    <div
      className={clsx(
        'border border-gray-200 p-3 md:p-4 rounded-lg bg-white text-gray-900 text-sm font-medium flex flex-col justify-between',
        className
      )}
    >
      <div className="flex flex-col md:flex-row md:justify-between w-full md:mb-8 mb-4">
        <div className="mb-4 md:mb-0 block">
          <Bagde title={subscription?.plan.name || ''} className="bg-green-100 text-green-700 text-xs md:text-sm" />
        </div>

        <div className="text-xs font-medium">
          <div className="text-gray-400">Next Payment</div>
          <div className="text-gray-900">{subscription?.nextPaymentDate}</div>
        </div>
      </div>
      <div className="mb-2">
        <p className="text-sm font-medium text-gray-900 ">
          {subscription?.usedUnits} of {pluralize(subscription?.plan.units, 'unit')}
        </p>
        <div className="progress-custom mt-1">
          <Progress progress={unitProgress} />
        </div>
      </div>
      <div>
        <p className="text-sm font-medium text-gray-900 ">
          {subscription?.usedMembers} of {pluralize(subscription?.plan.users, 'user')}
        </p>
        <div className="progress-custom mt-1">
          <Progress progress={userProgress} />
        </div>
      </div>
    </div>
  );
});
