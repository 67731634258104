import { ReactSVG } from 'react-svg';
import UnitImg from '../../assets/svg/unit-img.svg';

type Props = {
  className?: string;
};

export const Unit = ({ className }: Props) => {
  return <ReactSVG src={UnitImg} className={className} />;
};
