import clsx from 'clsx';
import { Section } from 'types';
import './index.scss';

type Props = {
  title: string;
  sections: Section[];
  className?: string;
  borderColor?: string;
  textColor?: string;
  headerColor?: string;
};

export const SectionTable = ({ title, sections, className, borderColor, headerColor }: Props) => {
  const Row = ({
    section,
    className,
    level = 1,
    firstRow = false
  }: {
    section: Section;
    className?: string;
    level?: number;
    firstRow?: boolean;
  }) => {
    const { children } = section;
    const marginLeft = `${16 * level}px`;

    return (
      <div className={className}>
        <div className={clsx('row', firstRow ? '' : 'border-t', borderColor)}>
          <p className={clsx('py-4 font-normal')} style={{ marginLeft: marginLeft }}>
            <div className="custom-inner-table" dangerouslySetInnerHTML={{ __html: section.content }}></div>
          </p>
        </div>
        {children.length > 0 &&
          children.map((section, index) => <Row key={index} section={section} level={level + 1} />)}
      </div>
    );
  };

  return (
    <div className={className}>
      <h3 className={clsx('uppercase mb-4 text-primary-500 text-xs font-semibold leading-5', headerColor)}>{title}</h3>
      <div className="unit-section border rounded-lg border-gray-200 shadow-sm text-sm font-medium text-gray-900">
        {sections.map((section, index) => {
          const first = index === 0;
          return <Row key={index} section={section} level={1} firstRow={first} />;
        })}
      </div>
    </div>
  );
};
