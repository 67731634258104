import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import * as yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import { useStores } from '../../hooks';
import { ErrorResponse } from '../../types';
import { useLocalObservable, observer } from 'mobx-react-lite';
import { PasswordField } from 'components';
import logo from 'assets/svg/logo.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Checkbox } from 'flowbite-react';
import checkCircle from 'assets/svg/check-circle.svg';
import lightBuld from 'assets/svg/light-buld.svg';
import lightningBolt from 'assets/svg/lightning-bolt.svg';
import download from 'assets/svg/download.svg';
import cloud from 'assets/svg/cloud.svg';
import database from 'assets/svg/database.svg';
import chat from 'assets/svg/chat.svg';
import './SignupForm.scss';

interface IFormInputs {
  email: string;
  password: string;
  passwordConfirmation: string;
  firstName: string;
  role: 'individual' | 'rto';
  surname: string;
  agreement: boolean;
}

interface State {
  loading: boolean;
  error: string;
  errors?: { [key: string]: string[] } | null;
}

const schema = yup
  .object({
    email: yup.string().required("Email can't be blank.").email('Please use a valid email address.'),
    firstName: yup.string().required("First name can't be blank."),
    surname: yup.string().required("Last name can't be blank."),
    role: yup.string().required('Role is required.'),
    password: yup.string().required("Password can't be blank.").min(6, 'Password must contain at least 6 characters.'),
    passwordConfirmation: yup
      .string()
      .required("Confirm password can't be blank.")
      .oneOf([yup.ref('password')], "Confirm password doesn't match Password."),
    agreement: yup
      .boolean()
      .required('The terms and conditions must be accepted.')
      .oneOf([true], 'The terms and conditions must be accepted.')
  })
  .required();

export const SignupForm = observer(() => {
  const queryClient = useQueryClient();
  const {
    control,
    register,
    handleSubmit,
    getFieldState,
    reset,
    formState: { errors }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  const websiteUrl = process.env.REACT_APP_LANDING_URL || '';

  const store = useStores();
  let navigate = useNavigate();
  let location = useLocation();

  const state = useLocalObservable<State>(() => ({
    loading: false,
    error: '',
    errors: null
  }));

  let from = (location as any).state?.from?.pathname || '/';

  const onSubmit = async (data: IFormInputs) => {
    state.error = '';
    state.errors = null;
    state.loading = true;
    try {
      await store.signup({
        user: {
          ...data
        }
      });

      // When user sign in/sign up, invalidate all queries to refresh the cache.
      queryClient.invalidateQueries();

      navigate(from, { replace: true });
    } catch (error: any) {
      state.error = (error as ErrorResponse).error || '';
      state.errors = (error as ErrorResponse).errors || {};
      return;
    } finally {
      state.loading = false;
      reset({}, { keepValues: true });
    }
  };

  const RoleControl = ({ field }: { field: ControllerRenderProps<IFormInputs, 'role'> }) => {
    return (
      <div className="flex">
        <button
          type="button"
          className={`bg-purple-100 font-medium text-sm text-purple-500 hover:bg-purple-300 py-2.5 px-4 rounded-md ${
            field.value === 'individual' ? 'bg-purple-300' : ''
          }`}
          onClick={() => {
            field.onChange('individual');
            field.onBlur();
          }}
        >
          Individual
        </button>
        <button
          type="button"
          className={`ml-5 bg-purple-100 font-medium text-sm text-purple-500 hover:bg-purple-300 py-2 px-4 rounded-md ${
            field.value === 'rto' ? 'bg-purple-300' : ''
          }`}
          onClick={() => {
            field.onChange('rto');
            field.onBlur();
          }}
        >
          RTO
        </button>
      </div>
    );
  };

  const onNavigateToWebsite = () => {
    window.location.href = websiteUrl;
  };

  return (
    <PerfectScrollbar>
      <div className="flex flex-col h-full">
        <div className="md:hidden flex justify-center items-center p-4 bg-white">
          <img className="cursor-pointer" onClick={() => onNavigateToWebsite()} src={logo} alt="Logo" />
        </div>
        <div className="w-100 flex flex-1 md:flex-row flex-col px-4 py-8 md:p-0 bg-sign-in-mobile-bg bg-cover bg-no-repeat bg-center">
          <div className="md:bg-white md:basis-1/2 md:h-full">
            <div className="md:flex md:flex-col">
              <div
                onClick={() => onNavigateToWebsite()}
                className="md:absolute top-16 left-16 hidden md:flex cursor-pointer"
              >
                <img src={logo} alt="" className="md:mb-0" />
              </div>

              <div className="md:flex-auto md:flex justify-center items-center w-100 bg-white md:bg-transparent rounded-mobile">
                <form className="form-width px-4 py-6 md:p-16 md:mt-20" onSubmit={handleSubmit(onSubmit)}>
                  <Helmet>
                    <title>Sign Up</title>
                  </Helmet>
                  <h1 className="text-c1 md:text-c3 text-left md:my-8 mb-6 font-bold text-gray-900">Sign Up</h1>
                  <div className="form-group">
                    <input
                      className={`form-control ${errors.firstName || state.errors?.firstName ? 'invalid' : ''}`}
                      id="firstName"
                      type="text"
                      placeholder="First name"
                      autoFocus={true}
                      {...register('firstName')}
                    />
                    <p className="feedback-invalid text-sm">
                      {errors.firstName?.message ||
                        (state.errors?.firstName && `First name ${state.errors?.firstName}`)}
                    </p>
                  </div>
                  <div className="form-group">
                    <input
                      className={`form-control ${errors.surname || state.errors?.surname ? 'invalid' : ''}`}
                      id="surname"
                      type="text"
                      placeholder="Last name"
                      {...register('surname')}
                    />
                    <p className="feedback-invalid text-sm">
                      {errors.surname?.message || (state.errors?.surname && `Last name ${state.errors?.surname}`)}
                    </p>
                  </div>
                  <div className="form-group">
                    <input
                      className={`form-control ${
                        errors.email || (state.errors?.email && !getFieldState('email').isDirty) ? 'invalid' : ''
                      }`}
                      id="email"
                      type="text"
                      placeholder="Email"
                      {...register('email')}
                    />
                    <p className="feedback-invalid text-sm">
                      {errors.email?.message ||
                        (state.errors?.email && !getFieldState('email').isDirty ? `Email ${state.errors?.email}` : '')}
                    </p>
                  </div>
                  <div className="form-group">
                    <Controller
                      name="password"
                      control={control}
                      render={({ field, fieldState }) => (
                        <PasswordField
                          className={`form-control ${
                            fieldState.error || state.errors?.password ? 'invalid' : ''
                          } !pr-10`}
                          value={field.value || ''}
                          onChange={field.onChange}
                          placeholder="Password"
                          name={field.name}
                        />
                      )}
                    />
                    <p className="feedback-invalid text-sm">
                      {errors.password?.message || (state.errors?.password && `Password ${state.errors?.password}`)}
                    </p>
                  </div>
                  <div className="form-group">
                    <Controller
                      name="passwordConfirmation"
                      control={control}
                      render={({ field, fieldState }) => (
                        <PasswordField
                          className={`form-control ${
                            fieldState.error || state.errors?.passwordConfirmation ? 'invalid' : ''
                          } !pr-10`}
                          value={field.value || ''}
                          onChange={field.onChange}
                          placeholder="Confirm password"
                          name={field.name}
                        />
                      )}
                    />
                    <p className="feedback-invalid text-sm">
                      {errors.passwordConfirmation?.message ||
                        (state.errors?.passwordConfirmation &&
                          `Confirm password ${state.errors?.passwordConfirmation}`)}
                    </p>
                  </div>
                  <div className="form-group">
                    <label className="block mb-2 text-gray-900 text-sm font-medium">What type of user are you?</label>
                    <Controller name="role" control={control} render={RoleControl} />
                    <p className="feedback-invalid text-sm">
                      {errors.role?.message || (state.errors?.role && `Role ${state.errors?.role}`)}
                    </p>
                  </div>
                  <div className="form-group mb-0">
                    <Controller
                      name="agreement"
                      control={control}
                      render={({ field }) => (
                        <label className="!flex cursor-pointer text-gray-500 text-sm !mb-0">
                          <Checkbox
                            {...field}
                            value={'true'}
                            checked={field.value}
                            className="cursor-pointer agreement-checkbox focus:ring-purple-500 text-purple-500 mt-1"
                          />

                          <div className="ml-2">
                            <span>By signing up, you are creating a MagicMap account, and you agree to MagicMap’s</span>{' '}
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`${websiteUrl}/terms`}
                              className="text-purple-500"
                            >
                              Terms of Use
                            </a>{' '}
                            and{' '}
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`${websiteUrl}/privacy-policy`}
                              className="text-purple-500"
                            >
                              Privacy Policy {field.value}
                            </a>
                            .
                          </div>
                        </label>
                      )}
                    />
                    <p className="feedback-invalid text-sm">{errors.agreement?.message || state.errors?.agreement}</p>
                  </div>
                  <div className="flex md:mt-8 mt-6">
                    <button disabled={state.loading} className="btn-primary w-full" type="submit">
                      Sign Up
                    </button>
                  </div>
                  {state.error && (
                    <div className="form-group mb-0 pt-5">
                      <p className="feedback-invalid text-sm">{state.error}</p>
                    </div>
                  )}
                  <div className="text-center md:mt-8 mt-6">
                    <span className="text-gray-900">
                      Have an account?
                      <NavLink
                        className="ml-1 inline-block align-baseline text-purple-500 md:hover:text-purple-800 font-medium"
                        to="/auth/login"
                      >
                        Sign In
                      </NavLink>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="md:basis-1/2 md:h-full flex md:bg-sign-up-right-bg bg-cover bg-no-repeat bg-bottom">
            <div className="flex justify-center items-center text-white min-h-full">
              <div className="md:p-20 p-0 pt-10">
                <h1 className="text-c4 md:text-c5 font-bold">7-Day Free Trial</h1>
                <div className="text-base mt-6 font-normal">
                  For a limit time we are offering a FREE 7 Day Trial and 3-month onboarding without limits. Set up your
                  free account once, and keep explore it as long as you like.
                </div>
                <div className="py-10">
                  <div className="flex items-start">
                    <img src={checkCircle} alt="" className="mr-2.5" />
                    <div className="flex-1">
                      <div className="text-lg font-medium">Sync directly from Training.Gov.Au</div>
                    </div>
                  </div>
                  <div className="flex items-start mt-4">
                    <img src={checkCircle} alt="" className="mr-2.5" />
                    <div className="flex-1">
                      <div className="text-lg font-medium">Instant VET Mapping Templates</div>
                    </div>
                  </div>
                  <div className="flex items-start mt-4">
                    <img src={checkCircle} alt="" className="mr-2.5" />
                    <div className="flex-1">
                      <div className="text-lg font-medium">Updates from Training.Gov.Au</div>
                    </div>
                  </div>
                  <div className="flex items-start mt-4">
                    <img src={checkCircle} alt="" className="mr-2.5" />
                    <div className="flex-1">
                      <div className="text-lg font-medium">Magic Map's Team Collaboration</div>
                    </div>
                  </div>
                </div>
                <div className="px-6 py-4 border-2 border-white rounded-xl">
                  <h4 className="text-lg font-semibold">Every Plan Includes:</h4>
                  <div className="mt-3">
                    <div className="flex items-start mb-4">
                      <img src={lightBuld} alt="" className="mr-2.5" />
                      <div className="flex-1">
                        <div className="text-base font-normal">Unlimited Maps Per Unit</div>
                      </div>
                    </div>
                    <div className="flex items-start mb-4">
                      <img src={lightningBolt} alt="" className="mr-2.5" />
                      <div className="flex-1">
                        <div className="text-base font-normal">Instant Mapping Template Creation</div>
                      </div>
                    </div>
                    <div className="flex items-start mb-4">
                      <img src={download} alt="" className="mr-2.5" />
                      <div className="flex-1">
                        <div className="text-base font-normal">Download Maps as Excel, Word, & PDF Files</div>
                      </div>
                    </div>
                    <div className="flex items-start mb-4">
                      <img src={cloud} alt="" className="mr-2.5" />
                      <div className="flex-1">
                        <div className="text-base font-normal">Online Mapping Template Creator</div>
                      </div>
                    </div>
                    <div className="flex items-start mb-4">
                      <img src={database} alt="" className="mr-2.5" />
                      <div className="flex-1">
                        <div className="text-base font-normal">Secure Data Storage</div>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <img src={chat} alt="" className="mr-2.5" />
                      <div className="flex-1">
                        <div className="text-base font-normal">
                          Live Chat During Business Hours & 24/7 Email Support
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PerfectScrollbar>
  );
});
