import clsx from 'clsx';
import { Bagde } from 'components/bagde';

export const SkillToolTip = ({ skills, className }: { skills: string[]; className?: string }) => {
  return (
    <div className={clsx('text-xs font-medium', className)}>
      <h5 className="mb-1">This criteria is linked to these Foundation Skills</h5>
      {skills.map((skill, index) => (
        <Bagde key={index} className="bg-orange-100 mr-1 mt-1 inline-block" title={skill} />
      ))}
    </div>
  );
};
