import { ReactSVG } from 'react-svg';
import MergeIconSvg from '../../assets/svg/merge-icon.svg';

type Props = {
  className?: string;
};

export const MergeIcon = ({ className }: Props) => {
  return <ReactSVG src={MergeIconSvg} className={className} />;
};
