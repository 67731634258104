import { ReactSVG } from 'react-svg';
import FolderEmptySvg from '../../assets/svg/folder-empty.svg';

type Props = {
  className?: string;
};

export const FolderEmpty = ({ className }: Props) => {
  return <ReactSVG src={FolderEmptySvg} className={className} />;
};
