import clsx from 'clsx';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { Avatar } from 'components/avatar';
import { LinkItem } from './LinkItem';

export const UserProfile = observer(() => {
  const store = useStores();
  const { uiStore, authStore } = useStores();
  const { isNavbarCollapsed } = uiStore;

  const logout = () => {
    store.logout();
  };

  return (
    <div className={clsx('max-w-[220px] p-4 pl-3')}>
      <div className="flex items-center">
        <Avatar
          label={authStore.user?.fullName}
          src={authStore.user?.avatarUrl}
          bgColor="bg-orange-100"
          txtColor="text-orange-6"
        />
        <span className="text-sm text-gray-700 font-semibold ml-3 truncate">{authStore.user?.fullName}</span>
      </div>
      <div className="flex flex-col mt-6 space-y-2">
        <div className={clsx('flex flex-col space-y-2 min-w-[180px]', isNavbarCollapsed ? 'pr-30' : '')}>
          <LinkItem title="User Profile" path="/profile" />
        </div>
        <div className="border-b border-gray-200" />
        <button
          className="text-left text-sm text-gray-700 hover:text-primary-500 font-medium py-1 duration-300"
          onClick={logout}
        >
          Sign Out
        </button>
      </div>
    </div>
  );
});
