import axios from './../lib/axios';
import { Member, AcceptInvitationRequest } from 'types';

export const allMembers = async (): Promise<Member[]> => {
  return await axios.get('members').then((res) => {
    return res.data;
  });
};

export const removeMember = async (id: string) => {
  return await axios.delete(`members/${id}`);
};

export const inviteNewMember = async (email: string): Promise<Member> => {
  return await axios
    .post('members/invite', {
      invite: {
        email: email
      }
    })
    .then((res) => {
      return res.data;
    });
};

export const acceptInvite = async (req: AcceptInvitationRequest) => {
  await axios.post('invitations/accept', req);
};

export const resendInvitation = async (id: number): Promise<void> => {
  await axios.post(`members/${id}/resend`);
};
