import { useState } from 'react';
import { format } from 'date-fns';
import {
  DocumentDuplicateIcon,
  TrashIcon,
  ChatBubbleOvalLeftIcon,
  EllipsisVerticalIcon,
  MapIcon
} from '@heroicons/react/24/outline';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Dropdown } from 'flowbite-react';
import { UnitNoMap } from './UnitNoMap';
import { ErrorResponse, PagedList, Map } from 'types';
import { fetchMaps } from 'api';
import { Loading, Pagination } from 'components';
import './index.scss';
import clsx from 'clsx';
import { useStores } from 'hooks';

export const UnitMaps = () => {
  const perPage = 12;
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(parseInt(page as string) || 1);
  const { mapUIStore } = useStores();

  const { data, isLoading } = useQuery<PagedList<Map>, ErrorResponse>(['unit', id, 'maps', currentPage], () =>
    fetchMaps(id as string, currentPage, perPage)
  );

  const onPageChange = (page: number) => {
    navigate({
      pathname: `/units/${id}/maps`,
      search: `?page=${page}`
    });
    setCurrentPage(page);
  };

  const Note = ({ comment }: { comment: string }) => {
    return (
      <div className={clsx('flex items-center hover:bg-gray-200 hover:rounded-md w-[90px] justify-center')}>
        <div className="dropdown">
          <Dropdown
            outline={false}
            color={'bg-white'}
            placement="bottom-end"
            size={14}
            arrowIcon={false}
            label={
              <div className="flex items-center">
                <ChatBubbleOvalLeftIcon className="h-[14px] w-auto mr-2" />
                <label className="cursor-pointer">Note</label>
              </div>
            }
          >
            <Dropdown.Item>
              <div className="md:max-w-[264px] max-w-[192px]">{comment}</div>
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
    );
  };

  const Map = ({ map }: { map: Map }) => {
    return (
      <div
        className={clsx(
          !map.name && 'justify-between',
          'unit-map mb-4 md:mb-0 border border-gray-200 p-4 rounded-lg bg-white text-gray-900 text-sm font-medium flex flex-col'
        )}
      >
        <div>
          <div className="flex justify-between">
            <div className="w-11/12">
              <Link to={`/maps/${map.id}`}>
                <div className="mb-2 truncate ... mr-4" title={map.name}>
                  {map.name || 'Untitled'}
                </div>
              </Link>
            </div>

            <div className="dropdown">
              <Dropdown
                outline={false}
                color={'bg-white'}
                placement="bottom-end"
                size={14}
                arrowIcon={false}
                label={<EllipsisVerticalIcon className="h-5 w-auto" />}
              >
                <Dropdown.Item
                  icon={DocumentDuplicateIcon}
                  onClick={() => {
                    mapUIStore.openDuplicateModal(map);
                  }}
                >
                  <div className="text-sm font-normal">Duplicate Map</div>
                </Dropdown.Item>
                <Dropdown.Item
                  icon={MapIcon}
                  onClick={() => {
                    mapUIStore.openDuplicateMapUnitModal(map);
                  }}
                >
                  <div className="text-sm font-normal">Duplicate Map to New Unit</div>
                </Dropdown.Item>
                <div className="border-t pt-1 mt-1">
                  <Dropdown.Item
                    icon={TrashIcon}
                    onClick={() => {
                      mapUIStore.openDeleteModal(map);
                    }}
                  >
                    <div className="text-sm font-normal">Delete</div>
                  </Dropdown.Item>
                </div>
              </Dropdown>
            </div>
          </div>
          <div className="mb-2">
            Last modified: <span className="font-normal">{format(new Date(map.updatedAt), 'dd/MM/yyyy')}</span>
          </div>
          <div className="mb-2">
            Release: <span className="text-primary-500">{map.releaseNumber}</span>
          </div>
        </div>

        <div className="flex text-sm">{map.comment && <Note comment={map.comment} />}</div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="min-h-[200px] relative">
        <Loading />
      </div>
    );
  }

  if (!data?.records?.length) {
    return <UnitNoMap />;
  }

  return (
    <div className="py-6 ">
      <div className="md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-4">
        {data.records.map((record, index) => (
          <Map key={index} map={record} />
        ))}
      </div>
      {data.totalPages > 1 && (
        <div className="flex justify-center md:justify-end pt-6 pb-4">
          <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={data.totalPages} />
        </div>
      )}
    </div>
  );
};
