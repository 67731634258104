import { makeAutoObservable } from 'mobx';
import { ShowModal } from 'types/enums';

export interface LibraryStore {
  showModal: ShowModal;
}

export class LibraryStore implements LibraryStore {
  showModal = ShowModal.None;
  constructor() {
    makeAutoObservable(this);
  }

  openDeleteModal = () => {
    this.showModal = ShowModal.Delete;
  };

  openNewModal = () => {
    this.showModal = ShowModal.New;
  };

  closeModal = () => {
    this.showModal = ShowModal.None;
  };
}
