import { FolderEmpty } from 'components/icons';
import { NodeType } from 'types';
import { RegularAction } from './RegularAction';
import { RTOAction } from './RTOAction';
import { UnitAction } from './UnitAction';
import { CourseAction } from './CourseAction';
import { useLocation } from 'react-router-dom';

type props = {
  onCreateNew?: () => void;
  typeFolder?: string;
};

export const EmptyData = ({ typeFolder, onCreateNew }: props) => {
  const location = useLocation();
  const isChildFolder = location.pathname.includes('folders');

  const EmptyAction = () => {
    switch (typeFolder) {
      case NodeType.REGULAR:
        return <RegularAction />;
      case NodeType.RTO:
        return <RTOAction isChildFolder={isChildFolder} />;
      case NodeType.COURSE:
        return <CourseAction isChildFolder={isChildFolder} />;
      case NodeType.UNIT:
        return <UnitAction isChildFolder={isChildFolder} />;

      default:
        return <FolderEmpty className="mb-8"></FolderEmpty>;
    }
  };
  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center mt-[100px]">
        <EmptyAction />
      </div>
    </div>
  );
};
