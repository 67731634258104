import { observer } from 'mobx-react';
import { fetchUnitDetail } from 'api';
import { useParams } from 'react-router-dom';
import { Unit, UnitDetail } from 'types/unit';
import { useQuery } from '@tanstack/react-query';
import { ErrorResponse } from 'types';
import './index.scss';

export const UnitDetails = observer(() => {
  const { id } = useParams();
  const { data } = useQuery<Unit, ErrorResponse>(['unit', id], () => fetchUnitDetail(id as string), {
    enabled: !!id
  });

  const { unitDetails: unit_details } = data as Unit;

  const Element = ({ unitDetail }: { unitDetail: UnitDetail }) => {
    const isTable = unitDetail.content.includes('table');
    const className = isTable ? 'overflow-x-auto relative' : 'bg-white rounded-lg border border-gray-200 p-4';

    return (
      <div className="unit-detail-section mb-8">
        <h3 className="text-primary-500 text-xs font-semibold mb-4 uppercase leading-5">{unitDetail.title}</h3>
        <div
          className={`text-sm break-words unit-element font-normal shadow-sm ${className}`}
          dangerouslySetInnerHTML={{
            __html: unitDetail.content
          }}
        ></div>
      </div>
    );
  };

  return (
    <div className="py-6">
      {unit_details.map((detail, index) => (
        <Element key={index} unitDetail={detail} />
      ))}
    </div>
  );
});
