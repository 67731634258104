import clsx from 'clsx';
import { flexRender, getCoreRowModel, useReactTable, Column, Row } from '@tanstack/react-table';

type Props<T extends object> = {
  data: T[];
  columns: Column<T>[];
  onRowClick?: (row: Row<T>) => void;
};

// TODO please apply for Course detail page
export const Table = <T extends object>({ data, columns, onRowClick }: Props<T>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <table className="w-full text-gray-900">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="bg-gray-200 rounded-lg">
            {headerGroup.headers.map((header, index) => {
              const meta = header.column.columnDef.meta as any;
              return (
                <th
                  key={header.id}
                  className={clsx(
                    'text-sm text-left font-medium px-1 md:px-2 lg:px-4 py-4 first:rounded-l-lg last:rounded-r-lg',
                    index === 0 && 'w-[100px]',
                    meta?.className
                  )}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className={clsx(
              'hover:bg-gray-200 duration-300 border-b last:border-none',
              !!onRowClick && 'cursor-pointer'
            )}
            onClick={() => onRowClick?.(row)}
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="text-left px-1 md:px-2 lg:px-4 py-4">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
