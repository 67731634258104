import { ReactSVG } from 'react-svg';
import UnitBlankSvg from '../../assets/svg/unit-blank.svg';

type Props = {
  className?: string;
};

export const UnitBlank = ({ className }: Props) => {
  return <ReactSVG src={UnitBlankSvg} className={className} />;
};
