import React from 'react';
import lock from 'assets/svg/lock.svg';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateWorkspaceDetails } from 'api';
import { useStores } from 'hooks';
import { TwoFactorAuthSetupModal } from 'components/profile/TwoFactorAuthSetupModal';
import { observer } from 'mobx-react-lite';
import { TwoFactorAuthWorkspaceConfirmModal } from 'components/profile/TwoFactorAuthWorkspaceConfirmModal';
import { TwoFactorAuthMessageModal } from 'components/profile/TwoFactorAuthMessageModal';
import { toast } from 'react-toastify';

export const TwoFactorAuthRequired = observer(() => {
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [show2FASetupModal, setShow2FASetupModal] = React.useState(false);
  const [showMessageModal, setShowMessageModal] = React.useState(false);
  const {
    uiStore,
    authStore: { otpRequiredForLogin }
  } = useStores();
  const { currentWorkspaceId, currentWorkspace } = uiStore;

  const queryClient = useQueryClient();

  const confirmEdit2FA = () => {
    setShowConfirmModal(true);
  };

  const checkSelfOTPRequired = (workspaceId: number, currentToggleValue: boolean) => {
    setShowConfirmModal(false);

    if (!currentToggleValue) {
      mutate({ id: workspaceId, otpEnforced: false });
      return;
    }

    if (!otpRequiredForLogin) {
      setShow2FASetupModal(true);
    } else {
      mutate({ id: workspaceId, otpEnforced: true });
    }
  };

  const { mutate } = useMutation(updateWorkspaceDetails, {
    onSuccess: (_, { otpEnforced }) => {
      uiStore.updateCurrentWorkspace({ ...currentWorkspace!, otpEnforced: otpEnforced });
      toast.success(`Two-factor authentication (2FA) has been ${otpEnforced ? 'enabled' : 'disabled'}.`);
    }
  });

  const title = currentWorkspace?.otpEnforced
    ? 'Two-factor authentication is required.'
    : 'Require two-factor authentication (2FA)';
  const subTitle = currentWorkspace?.otpEnforced
    ? 'All users will need to complete two-factor authentication to access the workspace.'
    : 'Enhance account security by mandating two-factor verification for all workspace users.';

  return (
    <>
      <div className="flex relative border border-gray-300 rounded px-6 py-4">
        <div className=" basis-2/3 flex items-center">
          <div className="pr-4">
            <h5 className="text-base font-weight-bold">{title}</h5>
            <p className="text-sm font-weight-400 text-gray-700">{subTitle}</p>
          </div>
          <div className="pl-6">
            <label className="inline-flex items-center me-5 cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={currentWorkspace?.otpEnforced}
                onChange={() => confirmEdit2FA()}
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-500"></div>
            </label>
          </div>
        </div>
        <img src={lock} alt="2fa" className="absolute right-8 bottom-0 h-14" />
      </div>
      {show2FASetupModal && (
        <TwoFactorAuthSetupModal
          show={show2FASetupModal}
          onClose={() => setShow2FASetupModal(false)}
          onSuccess={() => {
            mutate({ id: currentWorkspaceId, otpEnforced: true });
            setShowMessageModal(true);
            queryClient.invalidateQueries(['members']);
          }}
        />
      )}

      <TwoFactorAuthWorkspaceConfirmModal
        size="lg"
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        mode={currentWorkspace?.otpEnforced ? 'Disable' : 'Enable'}
        onConfirm={() => checkSelfOTPRequired(currentWorkspaceId, !currentWorkspace?.otpEnforced)}
      />

      <TwoFactorAuthMessageModal show={showMessageModal} onClose={() => setShowMessageModal(false)} />
    </>
  );
});
