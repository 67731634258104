import { useMemo } from 'react';
import { CourseUnit, CourseType } from 'types';
import { UnitTable } from './UnitTable';
import { CourseNoUnit } from './CourseNoUnit';

type Props = {
  activeTabIndex?: number;
  courseType?: CourseType;
  allUnits?: CourseUnit[];
  coreUnits?: CourseUnit[];
  electiveUnits?: CourseUnit[];
  onAddAddUnit?: (unitCode: string, unitTitle: string) => void;
};

export const CourseTabsContainer = ({
  activeTabIndex,
  courseType,
  allUnits,
  coreUnits,
  electiveUnits,
  onAddAddUnit
}: Props) => {
  const config = useMemo(() => {
    if (activeTabIndex === 1) {
      return { tableType: 'core', units: coreUnits };
    }
    if (activeTabIndex === 2) {
      return { tableType: 'elective', units: electiveUnits };
    }
    return { tableType: 'all', units: allUnits };
  }, [activeTabIndex, allUnits, coreUnits, electiveUnits]);

  if (!config.units?.length) return <CourseNoUnit />;

  return (
    <>
      <UnitTable
        units={config.units || []}
        tableType={config.tableType as any}
        courseType={courseType}
        onAddUnit={onAddAddUnit}
      />
    </>
  );
};
