import { MapIcon } from '@heroicons/react/24/outline';
import { ReactSVG } from 'react-svg';
import NodataSVG from '../../assets/svg/no-data.svg';
import { FaceFrownIcon } from 'components/icons';
import { UnitShowModalContext } from 'pages';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

export const UnitNoMap = () => {
  const { id } = useParams();
  const { showNewMapModal } = useContext(UnitShowModalContext);
  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center mt-[100px] w-[325px]">
        <ReactSVG src={NodataSVG} className="mb-4" />
        <div className="flex text-gray-900">
          <FaceFrownIcon className="mr-3 h-5 w-5"></FaceFrownIcon>
          <p className="mb-8 font-medium text-sm">Sorry, you have not yet created any map.</p>
        </div>
        <button className="btn-primary w-full" onClick={() => showNewMapModal(id || '')}>
          <MapIcon className="w-[14px] h-[14px] inline-block mr-2" />
          New Map
        </button>
      </div>
    </div>
  );
};
