import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@tanstack/react-query';
import { fetchLibraryDetail } from 'api';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ErrorResponse, Library, NodeType } from 'types';
import { useStores } from 'hooks/useStores';
import { LibraryContainer } from 'components/library/LibraryContainer';
import { HeaderActions } from './HeaderActions';
import { HeaderBreadcrumb } from './HeaderBreadcrumb';

export const FolderDetail = observer(() => {
  const { id } = useParams();
  const { sidebarTreeStore } = useStores();

  const {
    data: dataDetail,
    isLoading,
    refetch: getFolderDetail
  } = useQuery<Library, ErrorResponse>(['folder_detail', id], () => fetchLibraryDetail(id as string), {
    enabled: !!id
  });

  useEffect(() => {
    if (!id) return;
    if (!sidebarTreeStore.regularFolders.length) return;
    sidebarTreeStore.setCurrentFolderId(id);
  }, [id, sidebarTreeStore, sidebarTreeStore.regularFolders]);

  const refreshDataSidebar = async (type: string) => {
    switch (type) {
      case NodeType.REGULAR:
        await sidebarTreeStore.loadRegularFolders();
        break;
      case NodeType.COURSE:
        await sidebarTreeStore.loadCourseFolders();
        break;
      case NodeType.UNIT:
        await sidebarTreeStore.loadUnitFolders();
        break;
      case NodeType.RTO:
        await sidebarTreeStore.loadRtoFolders();
        break;
      default:
        break;
    }
  };

  const onActionCallback = async () => {
    getFolderDetail();
    if (dataDetail?.ancestors && dataDetail?.ancestors?.length > 0) {
      const lastItem = dataDetail?.ancestors[0];

      return await refreshDataSidebar(lastItem?.type);
    } else if (dataDetail?.type) {
      return await refreshDataSidebar(dataDetail?.type);
    }
  };

  return (
    <>
      <Helmet>
        <title>{id ? dataDetail?.shortName : 'Folders'}</title>
      </Helmet>

      <LibraryContainer
        parent={dataDetail}
        isLoading={isLoading}
        data={dataDetail?.children || []}
        typeFolder={dataDetail?.type}
        onActionCallback={onActionCallback}
        titleComponent={<HeaderBreadcrumb dataDetail={dataDetail} />}
        buttonComponent={<HeaderActions dataDetail={dataDetail} />}
      />
    </>
  );
});
