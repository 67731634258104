import { makeAutoObservable } from 'mobx';
import { ShowModal } from 'types/enums';
import { Template } from 'types';

export interface MyTemplatesStore {
  currentTemplate: Template | undefined;
  showModal: ShowModal;
}

export class MyTemplatesStore implements MyTemplatesStore {
  currentTemplate: Template | undefined;
  showModal = ShowModal.None;

  constructor() {
    makeAutoObservable(this);
  }

  openDeleteModal = (template: Template) => {
    this.currentTemplate = template;
    this.showModal = ShowModal.Delete;
  };

  openDownloadModal = () => {
    this.showModal = ShowModal.Download;
  };

  closeModal = () => {
    this.currentTemplate = undefined;
    this.showModal = ShowModal.None;
  };
}
