import { useMemo, useState } from 'react';
import { MapTablePreview } from './MapTablePreview';
import { Editor } from 'components/editor';
import NoImage from 'assets/svg/no-img.svg';
import clsx from 'clsx';
import { fontClassName } from 'data';
import { Controller, useFormContext } from 'react-hook-form';
import { BrandSettingsForm, FontAndTableStyles } from 'types';
const Paragraph = require('editorjs-paragraph-with-alignment');

type Props = {
  readOnly?: { [key: string]: boolean };
  setReadOnly?: (readOnly: { [key: string]: boolean }) => void;
  onError?: (error: any) => void;
};
export const BrandSettingPreview = ({ readOnly, setReadOnly, onError }: Props) => {
  const { watch, control } = useFormContext<BrandSettingsForm>();
  const fontAndTableStyles = watch('fontAndTableStyles');
  const logoAlignment = watch('logoAlignment');
  const logoDisplay = watch('logoDisplay');
  const footerDisplay = watch('footerDisplay');
  const textAfterMapDetailsDisplay = watch('textAfterMapDetailsDisplay');
  const unitDescriptionDisplay = watch('unitDescriptionDisplay');
  const logoUrl = watch('logoUrl');
  watch((values) => {
    setStyles({ ...values.fontAndTableStyles });
  });

  const [styles, setStyles] = useState<FontAndTableStyles | undefined>(fontAndTableStyles);
  const logo = useMemo(() => {
    if ((logoUrl as any) instanceof File) {
      return URL.createObjectURL(logoUrl as any);
    }
    return logoUrl;
  }, [logoUrl]);

  const logoInRightSide = logoAlignment === 'right-side';

  return (
    <div className={clsx('p-3 brand-setting-preview', fontClassName[styles?.fontFamily || ''])}>
      <div className="border shadow-lg rounded-lg">
        <div className={clsx({ 'flex flex-row-reverse': logoInRightSide })}>
          <div className={clsx('p-3', { hidden: !logoDisplay })} style={{ textAlign: logoAlignment as any }}>
            <img className="inline-block w-auto h-20" src={logo || NoImage} alt="Logo" />
          </div>
          <Controller
            control={control}
            name="unitDescriptionTemplateJson"
            render={({ field }) => (
              <Editor
                className={clsx('mb-2 flex-1', { hidden: !unitDescriptionDisplay })}
                id="unit-description"
                placeholder="Unit Description"
                data={field.value}
                onChange={field.onChange}
                styles={styles}
                readOnly={readOnly?.unitDesc}
                onModeChange={(mode) => setReadOnly?.({ ...readOnly, unitDesc: mode })}
              />
            )}
          />
        </div>
        <MapTablePreview fontAndTableStyles={styles} className="mb-3 mx-3" />
        <Controller
          control={control}
          name="textAfterMapDetailsTemplateJson"
          render={({ field }) => (
            <Editor
              className={clsx('mb-2', { hidden: !textAfterMapDetailsDisplay })}
              id="text-after-map-details"
              placeholder="Text"
              data={field.value}
              onChange={field.onChange}
              styles={styles}
              readOnly={readOnly?.text}
              onModeChange={(mode) => setReadOnly?.({ ...readOnly, text: mode })}
            />
          )}
        />
        <Controller
          control={control}
          name="footerTemplateJson"
          render={({ field }) => (
            <Editor
              className={clsx({ hidden: !footerDisplay })}
              id="footer"
              placeholder="Footer"
              config={{
                tools: {
                  paragraph: {
                    class: Paragraph,
                    inlineToolbar: true
                  }
                }
              }}
              data={field.value}
              onChange={field.onChange}
              limitBlocks={1}
              readOnly={readOnly?.footer}
              onModeChange={(mode) => setReadOnly?.({ ...readOnly, footer: mode })}
              onError={onError}
            />
          )}
        />
      </div>
    </div>
  );
};
