import { XMarkIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchTemplateOptions, fetchUnitOptions, newMap } from 'api';
import clsx from 'clsx';
import { Button } from 'components/buttons';
import { Loading } from 'components/loading';
import { reactSelectCustomStyle } from 'constant';
import { Modal, ModalProps } from 'flowbite-react';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { ErrorResponse, TemplateOption, UnitOption } from 'types';
import * as yup from 'yup';

const schema = yup
  .object({
    name: yup.string().required("Name can't be blank."),
    unitId: yup.string().required("Unit can't be blank.")
  })
  .required();

export type Option = {
  value: string;
  label: string;
  displayLabelOption: string;
};

type NewMapFormInputs = {
  unitId?: string | undefined;
  templateId?: string | undefined;
  folderIds?: string[];
  name?: string;
};

type Props = {
  unitId?: string | undefined;
  templateId?: string | undefined;
  folderIds?: string[];
  hide: Function;
} & ModalProps;

export const NewMapModal = ({ unitId, templateId, folderIds, hide, ...modalProps }: Props) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    setFocus,
    setValue,
    formState: { errors }
  } = useForm<NewMapFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      unitId: unitId,
      templateId: templateId,
      folderIds: folderIds || []
    }
  });

  useEffect(() => {
    setValue('unitId', unitId);
    setValue('templateId', templateId);
  }, [modalProps.show, unitId, templateId, setValue]);

  useEffect(() => {
    if (modalProps.show) {
      setFocus('name');
    }
  }, [modalProps.show, setFocus]);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(newMap, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['unit', unitId, 'maps']);
      queryClient.invalidateQueries(['maps', 'get_list']);
      toast(`${data.name} has been created successfully.`, {
        type: 'success'
      });
      handleClose();
      navigate({
        pathname: `/maps/${data.id}`
      });
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  const handleClose = () => {
    reset();
    hide();
  };

  const { data: listTemplateOptions, isLoading: templatesOptionsLoading } = useQuery<TemplateOption[], ErrorResponse>(
    ['templatesOptions'],
    () => fetchTemplateOptions(''),
    {
      staleTime: 300000 // Slate = Cache = 5 minutes
    }
  );

  const { data: listUnitOptions, isLoading: unitsOptionsLoading } = useQuery<UnitOption[], ErrorResponse>(
    ['unitsOptions'],
    () => fetchUnitOptions(''),
    {
      staleTime: 300000 // Slate = Cache = 5 minutes
    }
  );

  const unitOptions: Option[] = useMemo(() => {
    if (!listUnitOptions) return [];
    return listUnitOptions?.map((option) => {
      return {
        value: `${option.code}`,
        label: `${option.code} - ${option.title}`,
        displayLabelOption: `${option.code} - ${option.title}`
      } as Option;
    });
  }, [listUnitOptions]);

  const templateOptions: Option[] = useMemo(() => {
    if (!listTemplateOptions) return [];
    return listTemplateOptions?.map((option) => {
      return {
        value: `${option.id}`,
        label: `${option.name}`
      } as Option;
    });
  }, [listTemplateOptions]);

  const Option = (props: any) => {
    const { data, ...optionProps } = props;
    return (
      <components.Option {...optionProps}>
        <p>{data?.displayLabelOption}</p>
      </components.Option>
    );
  };

  if (templatesOptionsLoading || unitsOptionsLoading) {
    return <Loading />;
  }

  return (
    <Modal {...modalProps} size="md" position={'center'}>
      <div className="p-4 flex justify-between title">
        <h4 className="text-sm font-medium text-gray-900">New Map</h4>
        <Button
          disabled={isLoading}
          className={`form-control !text-sm !text-gray-900 ${errors.name ? 'invalid' : ''}`}
          onClick={() => handleClose()}
        >
          <XMarkIcon
            className={clsx(
              !isLoading && 'hover:rotate-90 duration-300 hover:text-primary-500',
              'text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none'
            )}
          />
        </Button>
      </div>
      <div className="p-4 pt-0">
        <form
          className="text-left"
          onSubmit={handleSubmit((value) => {
            if (!value?.name || !value?.unitId) {
              toast('Name or Unit cannot be blank', {
                type: 'info'
              });
              return;
            }

            mutate({
              templateId: value?.templateId,
              name: value?.name,
              unit: value?.unitId,
              folderIds: value?.folderIds
            });
          })}
        >
          <div className="form-group mb-4">
            <label className="text-sm font-normal text-gray-700">Map Name:*</label>
            <input
              {...register('name')}
              type="text"
              placeholder="Map name"
              className={`form-control !text-sm !text-gray-900 ${errors.name ? 'invalid' : ''}`}
              data-testid="mapNameInput"
            />
            <p className="feedback-invalid text-sm">{errors.name?.message}</p>
          </div>
          <div className="form-group mb-4">
            <label className="text-sm font-normal text-gray-700">Select a template:</label>
            <Controller
              name="templateId"
              control={control}
              render={({ field }) => (
                <Select
                  isMulti={false}
                  onBlur={field.onBlur}
                  onChange={(option: any) => {
                    const value = option?.value;
                    field.onChange(value);
                    setValue('templateId', value || '');
                  }}
                  options={templateOptions}
                  placeholder={`Choose template`}
                  styles={reactSelectCustomStyle}
                  classNamePrefix="react-select"
                  isClearable={true}
                  value={templateOptions?.find((option) => option.value === field.value) || null}
                />
              )}
            />
          </div>
          <div className="form-group mb-4">
            <label className="text-sm font-normal text-gray-700">Select unit in library:*</label>
            <Controller
              name="unitId"
              control={control}
              render={({ field }) => (
                <Select
                  className={`${errors.unitId?.message ? 'react-select-invalid' : ''}`}
                  isMulti={false}
                  onBlur={field.onBlur}
                  onChange={(option: any) => {
                    const value = option?.value;
                    field.onChange(value);
                    setValue('unitId', value || '');
                  }}
                  options={unitOptions}
                  placeholder={`Choose unit`}
                  components={{ Option }}
                  styles={reactSelectCustomStyle}
                  classNamePrefix="react-select"
                  isClearable={true}
                  value={unitOptions?.find((option) => option.value === field.value) || null}
                />
              )}
            />
            <p className="feedback-invalid text-sm">{errors.unitId?.message}</p>
          </div>
          <div className="flex justify-end">
            <Button
              isDisabled={isLoading}
              className="btn-primary text-sm font-medium bg-white text-gray-900 hover:bg-gray-200 disabled:bg-white disabled:hover:bg-white mr-2"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={isLoading}
              className="btn-primary font-normal disabled:hover:bg-purple-100"
              data-testid="createMapBtn"
            >
              Create
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
