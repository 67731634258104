import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/outline';
import { ChangeEventHandler, useState } from 'react';

interface Props {
  className: string;
  value: string;
  placeholder?: string;
  onChange: ChangeEventHandler<HTMLInputElement> | undefined;
  name: string;
  autoComplete?: string;
}

export const PasswordField = ({ className, onChange, value, placeholder, name, autoComplete }: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative flex">
      <input
        autoComplete={autoComplete}
        className={className}
        id={name}
        name={name}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      <div className="absolute cursor-pointer px-3 h-full flex items-center right-0" onClick={toggleShowPassword}>
        {showPassword ? (
          <EyeIcon className="h-5 w-5 text-gray-300" />
        ) : (
          <EyeSlashIcon className="h-5 w-5 text-gray-300" />
        )}
      </div>
    </div>
  );
};
