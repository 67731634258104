import { ReactNode, useState } from 'react';
import { RectangleStackIcon, MapIcon } from '@heroicons/react/24/outline';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchUnitDetail, generateDownloadUrl } from 'api';
import { DownloadDropdown, Header, Loading, Tabs } from 'components';
import { AddUnitModal } from 'components/course/modals';
import { NewMapModal, DeleteMapModal, DuplicateMapModal } from 'components/unit/modals';
import { UnitInReview } from 'components/unit/UnitInReview';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useParams } from 'react-router-dom';
import { ErrorResponse, Unit as IUnit } from 'types';
import { ShowModal } from 'types/enums';
import { DuplicateNewMapModal } from 'components/modals';

export const Unit = observer(() => {
  const {
    mapUIStore: { showModal }
  } = useStores();
  const {
    mapUIStore: { showModal: showModalAddUnit, openNewModal: openNewModalAddUnit },
    subscriptionStore
  } = useStores();
  const [showNewMapModal, setShowNewMapModal] = useState(false);
  const [unitId, setUnitId] = useState('');
  const { id } = useParams();
  const { data, isLoading } = useQuery<IUnit, ErrorResponse>(['unit', id], () => fetchUnitDetail(id as string), {
    enabled: !!id
  });
  const unit: IUnit = (data as IUnit) || {};
  unit.code = id as string;
  unit.title = unit.title || '...';
  unit.releaseNumber = unit.releaseNumber || '...';

  const queryClient = useQueryClient();
  const badges = [
    {
      title: 'Current',
      className: 'bg-green-100 text-green-700'
    },
    {
      title: `Release ${unit?.releaseNumber}`,
      className: 'bg-primary-200 text-primary-500'
    }
  ];

  const handleCallback = () => {
    queryClient.setQueryData(['unit', id], {
      ...data,
      inLibrary: true
    });
  };

  const UnitHeader = ({ children }: { children?: ReactNode }) => {
    return (
      <>
        <Helmet>
          <title>
            {unit?.code} - {unit?.title}
          </title>
        </Helmet>
        <Header title={unit?.code} subTitle={unit?.title} badges={badges}>
          <div className="mt-4 lg:mt-0">{children}</div>
        </Header>
      </>
    );
  };

  const UnitBody = () => {
    if (isLoading) {
      return (
        <div className="min-h-[200px] relative">
          <Loading />
        </div>
      );
    }

    return <Outlet />;
  };

  if (data?.message) {
    badges.pop();
    return (
      <>
        <UnitHeader></UnitHeader>
        <UnitInReview />
      </>
    );
  }

  const tabs = [
    {
      to: 'details',
      title: 'Unit Details'
    },
    {
      to: 'competency-evidence-required',
      title: 'Competency Evidence Required'
    }
  ];

  if (unit.inLibrary) {
    tabs.unshift({
      to: 'maps',
      title: 'Maps'
    });
  }

  const onShowNewMapModal = (unitId: string) => {
    setUnitId(unitId);
    setShowNewMapModal(true);
  };

  const addUnitToLibrary = () => {
    if (subscriptionStore.isAbleToAddMoreUnit()) {
      openNewModalAddUnit();
    } else {
      subscriptionStore.showPlanOptionsModal(true);
    }
  };

  return (
    <>
      <UnitShowModalContext.Provider value={{ showNewMapModal: onShowNewMapModal }}>
        <UnitHeader>
          {unit.inLibrary && (
            <div className="flex">
              <DownloadDropdown id={unit.code} downloadAPI={generateDownloadUrl} btnTitle="Download Unit Pack" />
              <button
                className="btn-primary text-sm ml-2 sm:ml-3"
                onClick={() => {
                  setShowNewMapModal(true);
                }}
                data-testid="newMapBtn"
              >
                <MapIcon className="w-[14px] h-[14px] inline-block mr-2" />
                New Map
              </button>
            </div>
          )}
          {!unit.inLibrary && (
            <button className="btn-primary" onClick={addUnitToLibrary} data-testid="addToLibraryBtn">
              <RectangleStackIcon className="w-[14px] h-[14px] inline-block mr-2" />
              Add to Library
            </button>
          )}
        </UnitHeader>
        <div className="pt-4 px-2 md:px-8 relative">
          <Tabs tabs={tabs} />
          <UnitBody />
        </div>
      </UnitShowModalContext.Provider>

      <NewMapModal
        unitId={id || unitId}
        show={showNewMapModal}
        hide={() => setShowNewMapModal(false)}
        size="md"
        position={'center'}
      />
      <DeleteMapModal show={showModal === ShowModal.Delete} size={'md'} position={'center'} />
      <DuplicateMapModal show={showModal === ShowModal.Duplicate} size={'md'} position={'center'} />
      <DuplicateNewMapModal show={showModal === ShowModal.DuplicateMapUnit} size={'md'} position={'center'} />
      {/* modals */}
      <AddUnitModal
        unit={unit}
        show={showModalAddUnit === ShowModal.New}
        size="md"
        position={'center'}
        handleCallback={handleCallback}
        directToFolder={true}
      />
    </>
  );
});

export const UnitShowModalContext = React.createContext({ showNewMapModal: (unitId: string) => {} });
