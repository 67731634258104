import { useQuery } from '@tanstack/react-query';
import { fetchLibraryDetail, fetchRtoCourses, fetchRtoDetail } from 'api';
import clsx from 'clsx';
import { NotAvailable } from 'components/icons';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { ErrorResponse, Library, RTOCoursePaging, CourseItem, RTO } from 'types';
import { Configuration } from './Configuration';
import { CourseSelect } from './CourseSelect';
import { RTOConfigurationHeader } from './Header';

type Props = {
  code: string;
  folderId: string;
  className?: string;
  type?: 'Page' | 'Modal';
  onCancel?: () => void;
};

export const RTOConfigurationContainer = ({ code, folderId, className, type = 'Page', onCancel }: Props) => {
  const { isMobile } = useMediaQuery();

  const { data: folder = {} as Library, isLoading: isLibraryLoading } = useQuery(
    ['library', 'rto', folderId],
    () => {
      if (folderId === '0') {
        return {
          id: '0',
          children: new Array<Library>()
        } as Library;
      }
      return fetchLibraryDetail(folderId as string);
    },
    {
      enabled: true
    }
  );

  const { data: rto = {} as RTO } = useQuery<RTO, ErrorResponse>(['rto', code], () => fetchRtoDetail(code as string), {
    enabled: !!code,
    staleTime: 300000
  });

  const { data: rtoCourse = {} as RTOCoursePaging, isLoading } = useQuery<RTOCoursePaging, ErrorResponse>(
    ['rto', code, 'courses'],
    () => fetchRtoCourses(code as string, null),
    {
      enabled: !!code,
      staleTime: 300000
    }
  );

  const selectedItems =
    folder.children
      ?.filter((item) => !item._destroy)
      .map((item) => {
        return {
          id: item.sourceId?.toString(),
          code: item.source.code,
          title: item.source.title,
          usage_recommendation: item.source.usageRecommendation,
          type: item.metadata.type
        } as CourseItem;
      }) || [];

  const courses =
    rtoCourse.records?.map((course) => {
      return {
        id: course.id.toString(),
        code: course.code,
        title: course.title,
        usage_recommendation: course.usageRecommendation,
        type: course.componentType
      } as CourseItem;
    }) || [];

  if (isMobile) {
    return (
      <div className="mapping flex flex-col h-full">
        <div className="flex flex-col items-center pt-24">
          <NotAvailable className="mb-8" />
          <div className="font-medium text-sm text-gray-900">RTO Configuration is not available on mobile devices.</div>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx('flex flex-col h-screen w-full', className)}>
      <RTOConfigurationHeader
        {...{
          name: folderId !== '0' ? folder.name : `${rto.code} - ${rto.legalName}`,
          folderId,
          rtoId: rto.id,
          onCancel,
          className: clsx(type === 'Modal' && '!bg-transparent')
        }}
      />
      <div className="flex px-8 py-6 gap-4 flex-1 h-1">
        <Configuration
          {...{ items: selectedItems, isLoading: isLoading || isLibraryLoading, folderId }}
          className="basis-3/5 flex-1"
        />
        <CourseSelect
          {...{ defaultItems: courses, selectedItems, folderId, isLoading: isLibraryLoading }}
          className="basis-2/5 flex-1"
        />
      </div>
    </div>
  );
};
