import clsx from 'clsx';
import { Avatar } from 'components/avatar';
import { useStores } from 'hooks';
import { observer } from 'mobx-react';
import { components } from 'react-select';

const SubscriptionExpiredMenu = observer(({ children, options, ...props }: any) => {
  const { uiStore } = useStores();

  const [selectedValue] = props.getValue();

  const { handleClickSwitchWorkspace, switchWorkspaces } = props.selectProps;

  if (!selectedValue) return <components.Menu {...props}>{children}</components.Menu>;
  return (
    <components.Menu {...props}>
      {options.map((option: any) => (
        <div
          key={option.value}
          className={clsx('flex', 'align-middle', 'gap-4', 'p-2', 'hover:bg-[#F8F9FA]', 'hover:cursor-pointer', {
            'bg-[#F8F9FA]': option.value === selectedValue.value ? true : false
          })}
          onClick={() => {
            switchWorkspaces.toggleMenu();
            handleClickSwitchWorkspace(option.value);
          }}
        >
          <Avatar
            label={option.label}
            size={32}
            className={clsx(option.value === uiStore?.currentWorkspaceId ? 'border-green-1 border-2' : '')}
          />
          <div className="flex-auto m-auto text-sm text-[#282E37]">{option.label}</div>
        </div>
      ))}
    </components.Menu>
  );
});

export default SubscriptionExpiredMenu;
