import { Header, Loading } from 'components';
import { useState } from 'react';
import { Library } from 'types';
import { DeleteFolderModal, NewFolderModal, EmptyData } from 'components/library';
import { LibraryTable } from 'components/library/tables/LibraryTable';
import { useStores } from 'hooks';
import { ShowModal } from 'types/enums';
import { observer } from 'mobx-react';
import 'styles/pages/library.scss';
import { DuplicateNewMapModal } from 'components/modals';
import { DeleteMapModal, DuplicateMapModal } from 'components/unit/modals';

type Props = {
  parent?: Library;
  data: Library[];
  isLoading?: boolean;
  typeFolder?: string;
  onActionCallback?: () => void;
  titleComponent?: JSX.Element;
  buttonComponent?: JSX.Element;
};

export const LibraryContainer = observer(
  ({ parent, data, titleComponent, typeFolder, onActionCallback, buttonComponent, isLoading }: Props) => {
    const {
      libraryStore,
      mapUIStore: { showModal }
    } = useStores();

    const [currentFolder, setCurrentFolder] = useState<Library | undefined>();

    const onDeleteFolder = (folder: Library) => {
      setCurrentFolder(folder);
      libraryStore.openDeleteModal();
    };

    const onRenameFolder = (folder: Library) => {
      setCurrentFolder(folder);
      libraryStore.openNewModal();
    };

    const onNewRegularFolder = () => {
      setCurrentFolder(undefined);
      libraryStore.openNewModal();
    };

    const onCloseModal = () => {
      libraryStore.closeModal();
      setCurrentFolder(undefined);
    };

    const Body = () => {
      if (isLoading) {
        return (
          <div className="min-h-[200px] relative">
            <Loading />
          </div>
        );
      }

      if (!data.length) {
        return <EmptyData onCreateNew={onNewRegularFolder} typeFolder={typeFolder} />;
      }

      return (
        <>
          <div className="bg-white rounded-lg p-4 shadow">
            <LibraryTable data={data} onDelete={onDeleteFolder} onRename={onRenameFolder} />
          </div>
        </>
      );
    };

    return (
      <>
        <Header
          titleComponent={titleComponent}
          className="flex flex-col md:flex-row justify-between text-gray-900 sticky min-h-[62px]"
        >
          <div className="flex mt-4 md:mt-0">{buttonComponent && <div>{buttonComponent} </div>}</div>
        </Header>
        <div className="pt-4 px-2 md:px-8 relative">
          <Body />
        </div>
        <DeleteFolderModal
          folder={currentFolder}
          show={libraryStore.showModal === ShowModal.Delete}
          size="sm"
          position={'top-center'}
          handleCallback={onActionCallback}
          onClose={onCloseModal}
        />
        <NewFolderModal
          folder={libraryStore.showModal === ShowModal.New ? currentFolder : undefined}
          show={libraryStore.showModal === ShowModal.New}
          size="sm"
          position={'top-center'}
          handleCallback={onActionCallback}
          onClose={onCloseModal}
        />
        <DeleteMapModal
          handleCallback={onActionCallback}
          show={showModal === ShowModal.Delete}
          size={'md'}
          position={'center'}
        />
        <DuplicateMapModal show={showModal === ShowModal.Duplicate} size={'md'} position={'center'} />
        <DuplicateNewMapModal show={showModal === ShowModal.DuplicateMapUnit} size={'md'} position={'center'} />
      </>
    );
  }
);
