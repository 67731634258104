import { Modal, ModalProps } from 'flowbite-react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/buttons';

interface Props extends ModalProps {
  title: string;
  message: string;
  closeModal: () => void;
  onSave: (next?: Function) => void;
  confirmButtonTitle?: string;
  isloading?: boolean;
}
export const ConfirmationModal = (props: Props) => {
  const { show, size, isloading } = props;
  return (
    <Modal {...{ show, size }}>
      <div className="p-4">
        <div className="flex justify-between title">
          <p className="text-sm font-medium text-gray-900">{props.title}</p>
          <Button
            disabled={isloading}
            className="ml-auto bg-white border-0 text-gray-700 opacity-100 float-right text-3xl leading-none font-semibold outline-none focus:outline-none max-w-[24px] max-h-[24px] rounded"
            onClick={() => props.closeModal()}
          >
            <XMarkIcon className="text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none hover:rotate-90 duration-300 hover:text-primary-500" />
          </Button>
        </div>
        <div className="mt-2 content">
          <p className="text-sm font-normal text-gray-700">{props.message}</p>
        </div>
        <div className="mt-4 action flex justify-end space-x-4">
          <Button
            disabled={isloading}
            className="btn-primary text-sm font-medium bg-white text-gray-900 hover:bg-gray-200 disabled:bg-white disabled:hover:bg-white"
            onClick={() => props.closeModal()}
          >
            Cancel
          </Button>
          <Button
            isLoading={isloading}
            className="btn-primary font-medium justify-center outline-0 text-center disabled:hover:bg-purple-100"
            onClick={(next) => props.onSave(next)}
          >
            {props.confirmButtonTitle || 'Confirm'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
