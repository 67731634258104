import { HeartIcon, PresentationChartBarIcon, UsersIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { getPortalUpdatePlan } from 'api';
import clsx from 'clsx';
import { Bagde } from 'components/bagde';
import { Button } from 'components/buttons';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { pluralize } from 'utils/helpers';

type Props = {
  className?: string;
};

export const RecommendedSubscriptionCard = observer(({ className }: Props) => {
  const {
    subscriptionStore: { recommendedPlan, showPlanOptionsModal }
  } = useStores();

  const { mutate, isLoading } = useMutation(getPortalUpdatePlan, {
    onSuccess: (res: any) => {
      if (!res) {
        return;
      }
      window.location.assign(res?.url);
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Something went wrong! Please try again. ';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  const redirectToStripePortal = () => {
    if (!recommendedPlan) {
      return;
    }

    const returnUrl = window.location.origin + window.location.pathname;

    mutate(
      { id: recommendedPlan.id, returnUrl: returnUrl },
      {
        onSuccess: (data: any) => {
          window.location.href = data.url;
        }
      }
    );
  };

  const openHelpScout = () => {
    const Beacon = (window as any).Beacon;
    Beacon?.('open');
    Beacon?.('navigate', '/ask/message');
  };

  if (!recommendedPlan) {
    return (
      <div
        className={clsx(
          'border border-gray-200 p-3 md:p-4 rounded-lg bg-white text-gray-900 text-sm font-medium flex flex-col justify-between',
          className
        )}
      >
        <div className="mb-6">
          <label className="block text-primary-500 text-sm font-medium mb-2">Need more user or unit?</label>
          <label className="block text-gray-900 text-xs font-normal leading-5">
            Get in touch using the options below.
          </label>
        </div>
        <div>
          <button className="btn-secondary w-full mb-2 uppercase" onClick={() => openHelpScout()}>
            Send Us A Message
          </button>
          <button className="btn-secondary w-full">
            <a href="tel:1800841669">+1800 841 669</a>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        'border border-gray-200 p-3 md:p-4 rounded-lg bg-white text-gray-900 text-sm font-medium flex flex-col justify-between',
        className
      )}
    >
      <div className="flex justify-between w-full mb-4">
        <Bagde title={recommendedPlan?.name || 'NA'} className="bg-green-100 text-green-700 flex text-xs md:text-sm" />

        <div className="flex items-center text-primary-500">
          <span className="xl:flex hidden ">Recommended</span> <HeartIcon className="h-3 w-auto ml-2" />
        </div>
      </div>
      <ul className="mb-4 text-xs font-normal text-gray-900">
        <li className="flex items-center">
          <PresentationChartBarIcon className="h-3 mr-2" /> {pluralize(recommendedPlan?.units, 'Unit')}
        </li>
        <li className="flex items-center">
          <UsersIcon className="h-3 mr-2" /> {pluralize(recommendedPlan?.users, 'User')}
        </li>
      </ul>

      <div className="flex flex-col xl:flex-row">
        <Button
          className="btn-secondary mb-2 xl:mr-1 xl:mb-0 flex-1 bg-primary-100"
          isLoading={isLoading}
          onClick={redirectToStripePortal}
        >
          Upgrade
        </Button>
        <button type="button" className="btn-outline xl:ml-1" onClick={() => showPlanOptionsModal()}>
          View All Plans
        </button>
      </div>
    </div>
  );
});
