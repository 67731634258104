import { Elements, AddressElement } from '@stripe/react-stripe-js';
import {
  StripeAddressElementOptions,
  loadStripe,
  StripeAddressElementChangeEvent,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { useQuery } from '@tanstack/react-query';
import { fetchWorkspaceDetails } from 'api';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { FormManagePayment } from 'pages';
import { useFormContext } from 'react-hook-form';
import { ErrorResponse, WorkspaceBilling } from 'types';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '', {
  betas: ['address_element_beta_1']
});

export const AddressForm = observer(() => {
  const methods = useFormContext<FormManagePayment>();

  const { uiStore } = useStores();
  const { currentWorkspaceId } = uiStore;
  const { data, isLoading } = useQuery<WorkspaceBilling, ErrorResponse>(['workspaces', currentWorkspaceId], () =>
    fetchWorkspaceDetails(currentWorkspaceId)
  );

  if (isLoading) {
    return <></>;
  }

  const onValueChange = (event: StripeAddressElementChangeEvent) => {
    const {
      name,
      address: { line1, line2, city, state, postal_code, country }
    } = event.value;

    methods.setValue('billingName', name);
    methods.setValue('billingLine1', line1);
    methods.setValue('billingLine2', line2);
    methods.setValue('billingCity', city);
    methods.setValue('billingState', state);
    methods.setValue('billingPostalCode', postal_code);
    methods.setValue('billingCountry', country);
  };

  const options: StripeAddressElementOptions = {
    mode: 'billing',
    defaultValues: {
      name: data?.billingName,
      address: {
        line1: data?.billingLine1,
        line2: data?.billingLine2,
        city: data?.billingCity,
        state: data?.billingState,
        postal_code: data?.billingPostalCode,
        country: data?.billingCountry || 'AU'
      }
    },
    fields: {
      phone: 'never'
    }
  };

  const elementOptions: StripeElementsOptions = {
    appearance: {
      theme: 'stripe',
      variables: {
        fontSizeBase: '14px'
      }
    }
  };

  return (
    <Elements stripe={stripePromise} options={elementOptions}>
      <AddressElement options={options} onChange={onValueChange} />
    </Elements>
  );
});
