import { Link } from 'react-router-dom';
import { Avatar } from 'components/avatar';
import { LinkItem } from './LinkItem';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';

export const WorkspaceActions = observer(() => {
  const { uiStore } = useStores();
  const { currentWorkspace } = uiStore;

  return (
    <div className="pr-16 max-w-[240px] z-0 px-3 py-5 border-b border-gray-200">
      <Link className="flex items-center" to="/">
        {currentWorkspace ? <Avatar label={currentWorkspace?.name}></Avatar> : <></>}
        <span className="text-sm text-gray-700 font-semibold ml-3 truncate">{currentWorkspace?.name}</span>
      </Link>
      {currentWorkspace?.isOwner && (
        <div className="flex flex-col mt-6 space-y-2">
          <LinkItem title="Manage Users" path="/manage-users" />
          <LinkItem title="Settings" path="/subscriptions" />
          <LinkItem title="Brand Settings" path="/brand-settings" />
          <LinkItem title="Billing Information" path="/subscriptions" />
          <LinkItem title="Subscription" path="/subscriptions" />
        </div>
      )}
    </div>
  );
});
