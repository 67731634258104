import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchMapAssignmentTree } from 'api';
import { Tree } from 'react-arborist';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { AssignmentTreeNote, Mapping, NodeType } from 'types';
import { AssignmentTreeItem } from './AssignTreeItem';

type Props = {
  id: string;
  width: number;
  height: number;
  formMethods: UseFormReturn<{ [key: string]: boolean }, any>;
};

export const AssignMap = ({ id, width, height, formMethods }: Props) => {
  const queryClient = useQueryClient();
  const { data } = useQuery<AssignmentTreeNote[]>(['map', id, 'assignments'], () =>
    fetchMapAssignmentTree(id).then((items) => {
      const map = queryClient.getQueryData<Mapping>(['maps', id]);
      const folderIds = (map?.folderIds || []).map((item) => item.toString());

      const folders = items.filter((item) => item.type === NodeType.REGULAR);
      const courses = items.filter((item) => item.type === NodeType.COURSE);
      const rtos = items.filter((item) => item.type === NodeType.RTO);

      const root = [
        { id: 'folder', name: 'Folder (s)', children: folders, type: NodeType.CUSTOM },
        {
          id: 'course',
          name: 'Course (s)',
          children: courses,
          type: NodeType.CUSTOM
        },
        { id: 'rto', name: 'RTO (s)', children: rtos, type: NodeType.CUSTOM }
      ] as AssignmentTreeNote[];

      const setAssignedFolder = (nodes: AssignmentTreeNote[]) => {
        if (!nodes?.length) {
          return;
        }
        nodes.forEach((node) => {
          node.selected = folderIds.includes(node.id);
          setAssignedFolder(node.children);
        });
      };

      setAssignedFolder(root);

      return root;
    })
  );

  return (
    <FormProvider {...formMethods}>
      <Tree
        className="w-full"
        data={data}
        rowHeight={40}
        indent={12}
        width={width}
        height={height}
        openByDefault={true}
      >
        {AssignmentTreeItem}
      </Tree>
    </FormProvider>
  );
};
