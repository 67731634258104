import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal, ModalProps } from 'flowbite-react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { duplicateMap } from 'api';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { useStores } from 'hooks';
import { Button } from 'components/buttons';
import { useEffect } from 'react';
import clsx from 'clsx';

interface IFormInputs {
  name: string;
}

const schema = yup
  .object({
    name: yup.string().required("Name can't be blank.")
  })
  .required();

export const DuplicateMapModal = (props: ModalProps) => {
  const { id } = useParams();
  const { mapUIStore } = useStores();
  const { currentMap } = mapUIStore;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setFocus,
    clearErrors,
    setValue,
    formState: { errors }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (props.show) {
      setFocus('name');
      setValue('name', `Copy of ${currentMap?.name ?? 'Untitled'}`);
    }
  }, [props.show, currentMap, setFocus, setValue]);

  const { mutate, isLoading } = useMutation(duplicateMap, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries(['unit', id, 'maps']);
      toast(`${currentMap?.name || 'Untitled'} has been duplicated successfully.`, {
        type: 'success'
      });
      handleClose();
      navigate({
        pathname: `/maps/${data.id}`
      });
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  const handleClose = () => {
    clearErrors(['name']);
    reset();
    mapUIStore.closeModal();
  };

  return (
    <Modal {...props}>
      <div className="p-4 flex justify-between title">
        <h4 className="text-sm font-medium text-gray-900">Save As</h4>
        <Button
          disabled={isLoading}
          className="ml-auto bg-white border-0 text-gray-700 opacity-100 float-right text-3xl leading-none font-semibold outline-none focus:outline-none max-w-[24px] max-h-[24px] rounded"
          onClick={() => handleClose()}
        >
          <XMarkIcon
            className={clsx(
              !isLoading && 'hover:rotate-90 duration-300 hover:text-primary-500',
              'text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none'
            )}
          />
        </Button>
      </div>
      <div className="p-4 pt-0">
        <form
          className="text-left"
          onSubmit={handleSubmit((value) => {
            const req = { id: currentMap?.id || 0, name: value.name };
            mutate(req);
          })}
        >
          <div className="form-group mb-4">
            <input
              className={`form-control text-sm font-normal leading-5 only:ring-0 focus:ring-0 ${
                errors.name?.message ? 'invalid' : ''
              }`}
              id="name"
              type="text"
              placeholder="Map name"
              autoFocus={true}
              {...register('name')}
            />
            <p className="feedback-invalid text-sm">{errors.name?.message}</p>
          </div>
          <div className="flex justify-end">
            <Button
              type="submit"
              isLoading={isLoading}
              className="btn-primary font-normal disabled:hover:bg-purple-100"
            >
              <CheckIcon className="h-5 w-auto mr-2" />
              Save
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
