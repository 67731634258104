import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
  ColumnSizingState
} from '@tanstack/react-table';
import { Fragment, useMemo } from 'react';
import { AssessmentCondition, AssessmentTask, CompetencyEvidenceType, Content, UpdateMapRequest } from 'types';
import clsx from 'clsx';
import { TextAreaCell } from './cells';
import { DefaultHeader } from './headers';

const columnHelper = createColumnHelper<AssessmentCondition>();

const defaultColumns = [
  columnHelper.accessor('content', {
    cell: ({ getValue, column: { columnDef }, table: { options } }) => {
      const initialValue = getValue() as Content;
      const meta = columnDef.meta as any;
      const { numbering, textClass } = options.meta as any;

      const width = meta?.width || 0;
      return (
        <td className="header-sticky" style={{ left: `${width}px` }}>
          <div style={{ paddingLeft: `${initialValue.ancestryDepth! * 16}px` }}>
            {numbering && (
              <span className={clsx(textClass, initialValue.level ? 'mr-1' : '')}>{initialValue.level}</span>
            )}
            <div className="custom-inner-table inline" dangerouslySetInnerHTML={{ __html: initialValue.content }}></div>
          </div>
        </td>
      );
    },
    footer: (info) => info.column.id,
    meta: {
      pinning: true,
      width: 0,
      headerTitle: 'Items'
    },
    size: 600
  })
];

// Give our default column cell renderer editing superpowers!
const defaultColumn: Partial<ColumnDef<any>> = {
  header: DefaultHeader
};

type Props = {
  title: string;
  bgClass?: string;
  textClass?: string;
  data: AssessmentCondition[];
  assessmentTasks: AssessmentTask[];
  columnSizing?: ColumnSizingState;
  trafficMode?: boolean;
  numbering?: boolean;
  onNumberingChange?: (value: Partial<UpdateMapRequest>) => void;
  type: CompetencyEvidenceType;
  onColumnSizingChange: (
    columnSizingState: ColumnSizingState,
    tableId?: 'elements' | 'foundationSkills' | 'section' | 'task'
  ) => void;
};

export const SectionTable = ({
  title,
  bgClass,
  textClass,
  data,
  assessmentTasks,
  columnSizing,
  numbering,
  trafficMode,
  onNumberingChange,
  type,
  onColumnSizingChange
}: Props) => {
  const columnSizingChange = (updater: any) => {
    onColumnSizingChange(updater(), 'section');
  };

  const columns = useMemo(() => {
    const extraColumns = assessmentTasks.map((item) => {
      return columnHelper.accessor(`assessmentTask${item.id}`, {
        cell: TextAreaCell,
        footer: (props) => props.column.id,
        size: 200,
        meta: {}
      });
    });
    return [...defaultColumns, ...extraColumns];
  }, [assessmentTasks]);

  const setNumbering = (value: boolean) => {
    let req: Partial<UpdateMapRequest> = {};
    switch (type) {
      case CompetencyEvidenceType.PerformanceEvidence:
        req.performanceEvidenceNumbering = value;
        break;
      case CompetencyEvidenceType.KnowledgeEvidence:
        req.knowledgeEvidenceNumbering = value;
        break;
      case CompetencyEvidenceType.AssessmentCondition:
        req.assessmentConditionNumbering = value;
        break;
    }
    onNumberingChange && onNumberingChange(req);
  };

  const { getHeaderGroups, getRowModel } = useReactTable({
    data,
    columns,
    defaultColumn,
    columnResizeMode: 'onEnd',
    state: {
      columnSizing
    },
    onColumnSizingChange: columnSizingChange,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      numbering: numbering,
      trafficMode,
      updateData: (rowIndex: number, columnId: string, value: any) => {
        data[rowIndex][columnId] = value as AssessmentCondition;
      },
      bgClass,
      textClass
    }
  });

  return (
    <table className="table-mapping">
      <thead>
        <tr className="">
          <th className={clsx(bgClass, textClass, 'header-sticky top-0 !z-30 left-0 h-th')}>
            <div className="flex items-center">
              <span className="mr-4 text-xs font-semibold uppercase">{title}</span>
              <label htmlFor={title} className="inline-flex relative items-center cursor-pointer">
                <input
                  type="checkbox"
                  id={title}
                  className="sr-only peer"
                  checked={numbering}
                  onChange={(_) => setNumbering(!numbering)}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-primary-300  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-600"></div>
                <span className="ml-3 text-sm font-normal text-gray-900">Numbering</span>
              </label>
            </div>
          </th>
          <th
            className={clsx(bgClass, textClass, '!border-l-0 header-sticky top-0 !z-[12]')}
            colSpan={assessmentTasks.length}
          ></th>
          <th className={clsx('min-w-th !border-l-0 header-sticky top-0 !z-[12]', bgClass)}></th>
        </tr>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="header-sticky top-th !z-[11]">
            {headerGroup.headers
              .filter((header) => !header.id.includes('assessmentTask'))
              .map((header) => (
                <Fragment key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</Fragment>
              ))}
            {headerGroup.headers
              .filter((header) => header.id.includes('assessmentTask'))
              .map((header) => (
                <Fragment key={header.id}>{flexRender(DefaultHeader, header.getContext())}</Fragment>
              ))}
            <th className="min-w-th bg-white"></th>
          </tr>
        ))}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <Fragment key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>
            ))}
            <td></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
