import _ from 'lodash';
import { Location } from 'react-router-dom';
import { CourseUsageRecommendation, RTO } from 'types';

export const getDefaultFilter = (courseType?: CourseUsageRecommendation) => {
  return {
    page: 1,
    per_page: 10,
    q: '',
    usage_recommendation: courseType
  };
};

export const updateFilter = (activeTab: string, filter: any, data: any) => {
  const nextFilter = _.cloneDeep(filter);
  const activeTabFilter = nextFilter[activeTab];
  _.merge(activeTabFilter, data);

  return nextFilter;
};

// update search for all tabs
// reset page to 1
export const updateSearch = (filter: any, q: string) => {
  const clonedFilter = _.cloneDeep(filter);
  const nextFilter = _.mapValues(clonedFilter, (item) => ({ ...item, q, page: 1 }));

  return nextFilter;
};

export const getTabItem = (to: string, title: string, totalItems: number) => {
  return {
    to,
    title: `${title} (${totalItems || 0})`,
    activeExact: true
  };
};

export const getTabs = (rto: RTO, courseCount: any) => {
  const { allCoursesCount, currentCoursesCount, supersededCoursesCount } = courseCount;
  const tabLink = `/rtos/${rto.code}`;

  const defaultTab = getTabItem(tabLink, 'All', allCoursesCount);
  const currentCourseTab = getTabItem(`${tabLink}/current-courses`, 'Current', currentCoursesCount);
  const supersededCourseTab = getTabItem(`${tabLink}/superseded-courses`, 'Superseded', supersededCoursesCount);

  return [defaultTab, currentCourseTab, supersededCourseTab];
};

export const getActiveTab = (location: Location) => {
  const paths = location?.pathname?.split('/');

  if (paths?.includes('current-courses')) return 'current';
  if (paths?.includes('superseded-courses')) return 'superseded';
  return 'all';
};
