import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { fetchInvoices } from 'api';
import clsx from 'clsx';
import { Bagde } from 'components/bagde';
import { Loading } from 'components/loading';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { ErrorResponse, InvoiceData } from 'types';

type Props = {
  className?: string;
};
export const Invoices = observer(({ className }: Props) => {
  const {
    uiStore: { currentWorkspaceId }
  } = useStores();

  const { data, isLoading } = useQuery<InvoiceData[], ErrorResponse>(
    ['workspaces', currentWorkspaceId, 'invoices'],
    fetchInvoices
  );

  if (isLoading && !data) {
    return (
      <div className={clsx('overflow-x-auto border rounded-lg relative min-h-[100px]', className)}>
        <Loading />;
      </div>
    );
  }

  return (
    <div className={clsx('overflow-x-auto relative border rounded-lg', className)}>
      <table className="w-full text-sm text-left font-medium">
        <tbody>
          {data?.map((row, index) => (
            <tr key={index} className="border-b">
              <td className="p-4">
                <a target="_blank" rel="noreferrer" href={row.hostedInvoiceUrl} className="flex items-center">
                  {row.createdAt} <ArrowTopRightOnSquareIcon className="h-4 w-auto ml-3 cursor-pointer" />
                </a>
              </td>
              <td className="p-4">{row.total}</td>
              <td className="p-4 hidden md:table-cell">
                {row.paid && <Bagde className="bg-green-100 text-green-700 text-xs" title="Paid" />}
              </td>
              <td className="p-4">{row.number}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});
