import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { allMembers } from 'api';
import { Loading } from 'components/loading';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { useStores } from 'hooks';
import { useState } from 'react';
import { ErrorResponse, Member } from 'types';
import { PlanType, Role } from 'types/enums';
import { fullName } from 'utils/helpers';
import './index.scss';
import { MembersTable } from './MembersTable';
import { MembersTableMobile } from './mobile/MembersTableMobile';
import { SingleUnit } from './SingleUnit';
import { TwoFactorAuthRequired } from './TwoFactorAuthRequired';

export const ManageMembers = () => {
  const {
    subscriptionStore: { subscription: currentSubscription }
  } = useStores();
  const [members, setMembers] = useState<Member[] | undefined>([]);
  const [searchResult, setSearchResult] = useState<Member[] | undefined>([]);

  const { isTabletAndDown } = useMediaQuery();
  const { isLoading } = useQuery<Member[], ErrorResponse>(['members'], () => allMembers(), {
    onSuccess: (data) => {
      const workSpaceMembers = data?.filter((x) => x.role === Role.Owner);
      workSpaceMembers.push(...data?.filter((x) => x.role !== Role.Owner));

      setMembers(workSpaceMembers);
      setSearchResult(workSpaceMembers);
    }
  });

  const Table = () => {
    if (isLoading) {
      return (
        <div className="manage-members relative min-h-[200px]">
          <Loading />
        </div>
      );
    }

    if (!searchResult || !searchResult?.length) {
      return <></>;
    }

    return (
      <div className="manage-members">
        {!isTabletAndDown ? (
          <MembersTable members={searchResult || []} />
        ) : (
          <MembersTableMobile members={searchResult || []} />
        )}
      </div>
    );
  };

  const onSearchInputChange = (event: any) => {
    const keyword = event.target.value?.toLocaleLowerCase();
    if (!keyword) {
      setSearchResult(members);
      return;
    }

    const searchResult = members?.filter(
      (x) =>
        fullName(x?.firstName, x?.surname)?.toLocaleLowerCase()?.includes(keyword) ||
        x.email?.toLocaleLowerCase()?.includes(keyword) ||
        x.status?.toLocaleLowerCase()?.includes(keyword) ||
        x.role?.toLocaleLowerCase()?.includes(keyword)
    );
    setSearchResult(searchResult);
  };

  return (
    <>
      {currentSubscription?.plan.name === PlanType.SingleUnit ? (
        <SingleUnit />
      ) : (
        <div className="border border-gray-200 p-4 rounded-lg bg-white text-gray-900 text-sm font-medium flex flex-col justify-between">
          <div className="flex md:flex-row flex-col justify-between">
            <p className="text-xs leading-5 font-semibold text-primary-500">ALL USERS</p>
            <div className="input-group relative mb-4 w-full md:w-[360px] mt-4 md:mt-0 flex items-center">
              <div className="aa-InputWrapperPrefix absolute">
                <label className="aa-Label flex pl-4 text-gray-900">
                  <button className="aa-SubmitButton w-[18px] h-[18px]" type="button">
                    <MagnifyingGlassIcon />
                  </button>
                </label>
              </div>
              <div className="aa-InputWrapper w-full">
                <input
                  className="aa-Input outline-none focus:ring-0 border border-gray-200 rounded-md bg-white w-full h-[40px] text-sm p-4 pl-10 placeholder:text-gray-300 placeholder:text-sm placeholder:font-normal"
                  placeholder="Search by name or email"
                  type={'text'}
                  onChange={onSearchInputChange}
                />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <TwoFactorAuthRequired />
          </div>
          <Table />
        </div>
      )}
    </>
  );
};
