import { useOutletContext } from 'react-router-dom';
import { CourseTable } from 'components/rto';
import { TableEmpty } from 'components/table';

export const SupersededCourses = () => {
  const { courses, onViewCourse } = useOutletContext() as any;

  if (!courses?.length) {
    return <TableEmpty text="Course can't be found. Please try again." />;
  }

  return <CourseTable tableType="superseded" courses={courses} onViewCourse={onViewCourse} />;
};
