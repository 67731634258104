import { EllipsisVerticalIcon, MapIcon, TrashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Dropdown } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { Template } from 'types';
import { format } from 'date-fns';
import { useStores } from 'hooks';
import { observer } from 'mobx-react';

type Props = {
  template: Template;
  onOpenNewMapModal: Function;
};

export const TemplateCard = observer(({ template, onOpenNewMapModal }: Props) => {
  const navigate = useNavigate();
  const {
    myTemplatesStore: { openDeleteModal }
  } = useStores();

  return (
    <div
      className={clsx(
        !template.name && 'justify-between',
        'unit-map mb-4 md:mb-0 border border-gray-200 p-4 rounded-lg bg-white text-gray-900 flex flex-col'
      )}
    >
      <div>
        <div className="flex justify-between">
          <div
            className="w-11/12 truncate text-sm font-medium cursor-pointer"
            onClick={() => {
              navigate({
                pathname: `${template.id}`
              });
            }}
          >
            {template.name || 'Untitled'}
          </div>

          <div className="dropdown">
            <Dropdown
              outline={false}
              color={'bg-white'}
              placement="bottom-end"
              size={14}
              arrowIcon={false}
              label={<EllipsisVerticalIcon className="h-5 w-auto" />}
            >
              <Dropdown.Item icon={MapIcon} onClick={() => onOpenNewMapModal(template.id)}>
                <div className="text-sm font-normal">Create Map</div>
              </Dropdown.Item>
              {!template.predefined && (
                <div className="border-t pt-1 mt-1">
                  <Dropdown.Item
                    icon={TrashIcon}
                    onClick={() => {
                      openDeleteModal(template);
                    }}
                  >
                    <div className="text-sm font-normal">Delete</div>
                  </Dropdown.Item>
                </div>
              )}
            </Dropdown>
          </div>
        </div>
        <div className="text-xs leading-5 font-medium">
          Last modified: <span className="font-normal">{format(new Date(template.updatedAt), 'dd/MM/yyyy')}</span>
        </div>
      </div>
    </div>
  );
});
