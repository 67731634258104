import { ChevronDownIcon, ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { Avatar } from 'components/avatar';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { Tooltip } from 'components/tooltip';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const Workspaces = observer(() => {
  const { uiStore } = useStores();
  const { workspaces } = uiStore;
  const scrollRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();

  const handleClickAddUserWorkspace = () => {
    uiStore.toggleNewUserWorkspaceModal();
    uiStore.toggleUserWorkspace(false);
  };

  const handleClickSwitchWorkspace = async (workspaceId: number) => {
    await uiStore.switchWorkspace(workspaceId);
    queryClient.invalidateQueries();
    uiStore.toggleUserWorkspace(false);
    toast(`Your current workspace: ${uiStore.currentWorkspace?.name}.`, {
      type: 'success'
    });
    if (isMobile) {
      uiStore.collapseNav();
    }
    navigate({
      pathname: `library`
    });
    navigate(0);
  };

  const scrollLeft = () => {
    scrollRef.current?.scrollBy({
      left: -60
    });
  };

  const scrollRight = () => {
    scrollRef.current?.scrollBy({
      left: 60
    });
  };

  const handleClose = () => {
    uiStore.toggleUserWorkspace(false);
  };

  return (
    <div className="px-2 border-b border-gray-200 flex items-center">
      <div className="z-10">
        <button className="w-5 h-5 flex items-center" onClick={scrollLeft}>
          <ChevronLeftIcon className="text-primary-200 w-6 h-6 hover:text-primary-600" />
        </button>
      </div>

      <div className="px-2 w-[150px]">
        <div
          ref={scrollRef}
          className="flex border-gray-200 overflow-auto no-scrollbar flex-row pl-1 py-4 space-x-2 w-[132px]"
        >
          {workspaces?.map((workspace, index) => (
            <Tooltip key={index} id={`company-workspace-${index}`} title={workspace.name} placement="bottom">
              <Avatar
                label={workspace.name}
                size={36}
                className={clsx(workspace.id === uiStore?.currentWorkspaceId ? 'border-2 border-[#cc5cbe]' : '')}
                onClick={() => handleClickSwitchWorkspace(workspace.id)}
              />
            </Tooltip>
          ))}
          <Tooltip id="new-workspace" placement="bottom" title="Add Workspace">
            <Avatar label="+" size={36} onClick={() => handleClickAddUserWorkspace()} />
          </Tooltip>
        </div>
      </div>
      <button className="w-5 h-5 flex items-center" onClick={scrollRight}>
        <ChevronDownIcon className="w-6 h-6 first-line:duration-300 text-primary-200 hover:text-primary-600 -rotate-90" />
      </button>
      <button className="w-6 h-6 ml-2 hover:text-primary-500  rounded-md" onClick={handleClose}>
        <XMarkIcon className="hover:rotate-90 duration-300  text-gray-700 hover:text-primary-500" />
      </button>
    </div>
  );
});
