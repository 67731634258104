import { PencilIcon } from '@heroicons/react/24/outline';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchWorkspaceDetails } from 'api';
import clsx from 'clsx';
import { Avatar } from 'components/avatar';
import { useStores } from 'hooks';
import { observer } from 'mobx-react';
import { FormManagePayment } from 'pages';
import { Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { WorkspaceBilling, ErrorResponse } from 'types';

export const Title: React.FC = observer(() => {
  const { uiStore } = useStores();
  const { currentWorkspaceId } = uiStore;
  const {
    register,
    setValue,
    getValues,
    formState: { errors }
  } = useFormContext<FormManagePayment>();

  const queryClient = useQueryClient();

  const { data: currentWorkspace } = useQuery<WorkspaceBilling, ErrorResponse>(['workspaces', currentWorkspaceId], () =>
    fetchWorkspaceDetails(currentWorkspaceId)
  );

  const onBlur: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const name = e.target.value;

    if (name) {
      setValue('name', name);

      queryClient.setQueryData(['workspaces', 'current'], (oldValue: any) => {
        return {
          ...oldValue,
          id: currentWorkspaceId,
          name
        };
      });
    }
  };

  // If user clear input && reload website => Set default value "name" input
  useEffect(() => {
    if (!getValues('name') && currentWorkspace) {
      setValue('name', currentWorkspace?.name!);
    }
  }, [currentWorkspace, getValues, setValue]);

  const hasError = !!Object.keys(errors).length;

  const classes = clsx(
    'border-b',
    'bg-transparent',
    'pr-5',
    'text-sm',
    'text-gray-900',
    'font-normal',
    'outline-none',
    'leading-8',
    {
      'border-red-500': hasError
    }
  );

  return (
    <Fragment>
      <div className="flex items-center relative">
        <Avatar label={currentWorkspace?.name || ''} size={56} className="mr-2" txtSize="text-xl" />
        <div>
          <input
            {...register('name', {
              onBlur: onBlur
            })}
            className={classes}
            defaultValue={getValues('name') || currentWorkspace?.name}
          />
          {errors.name && <p className="text-xs text-red-500 mt-1">{errors?.name?.message}</p>}
        </div>

        <div className="absolute right-[-20px] flex items-center">
          <PencilIcon className="h-4 w-auto" />
        </div>
      </div>
    </Fragment>
  );
});
