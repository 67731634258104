import clsx from 'clsx';
import { CourseUnitType, CourseUsageRecommendation } from 'types';

type Props = {
  type: CourseUnitType | CourseUsageRecommendation;
};

export const TableLabel = ({ type }: Props) => {
  const getClasses = (type: CourseUnitType | CourseUsageRecommendation) => {
    switch (type) {
      case 'Core':
      case 'Current':
        return 'text-green-700 bg-green-100';
      case 'Elective':
        return 'text-yellow-700 bg-yellow-100';
      case 'Superseded':
        return 'text-red-600 bg-red-1';
      default:
        return 'text-green-700 bg-green-100';
    }
  };

  return (
    <div className="flex">
      <div
        className={clsx('flex items-center justify-center h-6 px-3 rounded-3xl text-xs font-medium', getClasses(type))}
      >
        {type}
      </div>
    </div>
  );
};
