import { useMemo } from 'react';
import { format } from 'date-fns';
import { createColumnHelper } from '@tanstack/react-table';
import { Map } from 'types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { Table } from 'components/table';

import { MapIcon } from '@heroicons/react/24/outline';

type Props = {
  data: Map[];
};

const columnHelper = createColumnHelper<Map>();

export const RecentTable = ({ data }: Props) => {
  const { isMobile } = useMediaQuery();

  const columns: any = useMemo(() => {
    const defaultColumns = [
      columnHelper.accessor('name', {
        header: () => <span className="font-medium">Name</span>,
        meta: {
          className: '!w-auto'
        },
        cell: (info) => {
          const id = info.row?.original?.id;
          return (
            <Link to={`/maps/${id}`}>
              <span className="flex items-center">
                <MapIcon className="h-5 w-5 mr-4 min-w-5" />
                <span className="text-sm font-normal">{info.getValue()}</span>
              </span>
            </Link>
          );
        }
      })
    ];

    const updatedAtColumn = columnHelper.accessor('updatedAt', {
      header: () => <span className="font-medium">Last Modified</span>,
      meta: {
        className: 'w-[216px]'
      },
      cell: (info) => {
        const updatedAt = info.row?.original?.updatedAt;
        if (!updatedAt) return <></>;

        return <span className="text-sm font-normal">{format(new Date(updatedAt), 'dd/MM/yyyy')}</span>;
      }
    });

    if (isMobile) {
      return [...defaultColumns];
    }

    return [...defaultColumns, updatedAtColumn];
  }, [isMobile]);

  return <Table data={data} columns={columns} />;
};
