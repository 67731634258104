import { Helmet } from 'react-helmet-async';
import { HomeIcon, PresentationChartBarIcon } from '@heroicons/react/24/outline';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchLibraryUnit } from 'api';
import { Breadcrumb, BreadcrumbItem, Loading } from 'components';
import { observer } from 'mobx-react';
import { ErrorResponse, Library, NodeType } from 'types';
import { LibraryContainer } from 'components/library/LibraryContainer';

import { Link } from 'react-router-dom';
import { useStores } from 'hooks/useStores';
import { useEffect } from 'react';

export const UnitFolder = observer(() => {
  const queryClient = useQueryClient();
  const { sidebarTreeStore, uiStore } = useStores();
  const { currentWorkspaceId } = uiStore;
  const { data: listData, isLoading } = useQuery<Library[], ErrorResponse>(
    ['unit_folder_list', currentWorkspaceId],
    () => fetchLibraryUnit()
  );

  useEffect(() => {
    sidebarTreeStore.setCurrentFolderId('unit');
  }, [sidebarTreeStore]);

  const onOpenModalNewFolder = () => {
    uiStore.openSearchBar();
  };

  const onActionCallback = async () => {
    await sidebarTreeStore.loadUnitFolders();
    queryClient.invalidateQueries(['unit_folder_list', currentWorkspaceId]);
  };

  if (isLoading) {
    return <Loading />;
  }

  const ActionButton = () => {
    return (
      <>
        <button className="btn-primary text-sm ml-3 hidden md:block" onClick={onOpenModalNewFolder}>
          <PresentationChartBarIcon className="w-5 h-5 inline-block mr-2" />
          Add New Unit
        </button>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Units</title>
      </Helmet>

      <LibraryContainer
        data={listData || []}
        onActionCallback={onActionCallback}
        buttonComponent={<ActionButton />}
        typeFolder={NodeType.UNIT}
        titleComponent={
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">
                <HomeIcon className="w-5 text-gray-600" />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>Units</BreadcrumbItem>
          </Breadcrumb>
        }
      />
    </>
  );
});
