import { Modal, ModalProps } from 'flowbite-react';
import LockClosed from 'assets/svg/lock-closed.svg';

type Props = ModalProps;
export const TwoFactorAuthMessageModal = (props: Props) => {
  return (
    <Modal {...props} size="lg">
      <div className="flex items-center justify-center h-full ">
        <div className="bg-white p-8 rounded-lg text-center">
          <div className="p-2 inline-block rounded-full bg-purple-100 mx-auto">
            <img src={LockClosed} alt="lock" className="mx-auto w-5 h-5" />
          </div>
          <p className="text-gray-700 mb-4">Two-factor authentication (2FA) has been enabled.</p>
          <button
            className="btn-primary w-full"
            onClick={() => {
              props.onClose?.();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};
