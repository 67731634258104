import clsx from 'clsx';
import { Breadcrumb as BreadcrumbComponent } from 'flowbite-react';
import { ReactNode } from 'react';
import 'styles/breadcrumb.scss';

type Props = {
  className?: string;
  children: ReactNode;
};

export const Breadcrumb = ({ className, children, ...props }: Props) => {
  return <BreadcrumbComponent className={clsx('dls-breadcrumb', className)}>{children}</BreadcrumbComponent>;
};
