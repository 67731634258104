import { AMEX, Discover, JCB, Mastercard, Unionpay, VisaIcon } from 'components/icons';

type Props = {
  brand: string;
};
export const PayIcon = ({ brand }: Props) => {
  switch (brand) {
    case 'Visa':
      return <VisaIcon />;
    case 'Mastercard':
      return <Mastercard />;
    case 'Amex':
      return <AMEX />;
    case 'Discover':
      return <Discover />;
    case 'Unionpay':
      return <Unionpay />;
    case 'Jcb':
      return <JCB />;
    default:
      return <>{brand}</>;
  }
};
