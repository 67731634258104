import axios from '../lib/axios';
import { InvoiceData, Plan, PortalPlanUpdateParams, Subscription } from 'types';

export const currentSubscription = async (params: any): Promise<Subscription> => {
  return await axios.get('/subscriptions/current', { params: params }).then((res) => {
    return res.data;
  });
};

export const fetchAllPlans = async (): Promise<Plan[]> => {
  return await axios.get('/subscriptions/plans').then((res) => {
    return res.data;
  });
};

export const fetchInvoices = async (): Promise<InvoiceData[]> => {
  return await axios.get('subscriptions/invoices').then((res) => {
    return res.data;
  });
};

export const getPortalUpdatePlan = async (request: PortalPlanUpdateParams) => {
  return await axios.post('subscriptions/portal_update_plan', { plan: request }).then((res) => {
    return res.data;
  });
};

export const fetchLatestInvoice = async (): Promise<InvoiceData> => {
  return await axios.get('subscriptions/latest_invoice').then((res) => {
    return res.data;
  });
};

export const getCheckoutSession = async (request: PortalPlanUpdateParams) => {
  return await axios.post('subscriptions/checkout', { plan: request }).then((res) => {
    return res.data;
  });
};
