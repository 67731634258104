import { Helmet } from 'react-helmet-async';
import { useQuery } from '@tanstack/react-query';
import { fetchListMaps } from 'api';
import { Header, Loading } from 'components';
import { observer } from 'mobx-react';
import { formatDistance } from 'date-fns';
import { ErrorResponse, Map, PagedList } from 'types';

import { Snapshot } from 'components/library/my-library/Snapshot';

import 'styles/pages/library.scss';
import { SimpleAction } from 'components/library/my-library/SimpleAction';
import { RecentMap } from 'components/library/my-library/RecentMap';
import { useStores } from 'hooks';
import { Badge } from 'flowbite-react';

export const MyLibrary = observer(() => {
  const { authStore, subscriptionStore } = useStores();
  const { data: listMaps, isLoading } = useQuery<PagedList<Map>, ErrorResponse>(['maps', 'get_list'], () =>
    fetchListMaps()
  );

  const Body = () => {
    if (isLoading) {
      return (
        <div className="min-h-[200px] relative">
          <Loading />
        </div>
      );
    }

    if (listMaps && listMaps?.records?.length > 0) {
      return <RecentMap data={listMaps?.records as Map[]} isLoading={isLoading} />;
    }

    return <SimpleAction />;
  };

  return (
    <>
      <Helmet>
        <title>My Library</title>
      </Helmet>
      <Header title={`Welcome ${authStore.user?.firstName}!`}>
        {subscriptionStore.subscription?.onTrial && (
          <div className="flex items-center">
            <Badge color="success" size="sm">
              TRIAL
            </Badge>
            <span className="ml-2 text-sm text-gray-400">Remaining: </span>
            <span className="ml-1 text-sm text-gray-900">
              {formatDistance(new Date(), new Date(subscriptionStore.subscription.trialEndsAt))}
            </span>
          </div>
        )}
      </Header>
      <div className="p-4 md:p-8 relative">
        <Snapshot />
        <div className="mt-6">
          <Body />
        </div>
      </div>
    </>
  );
});
