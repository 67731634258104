import { ResetPasswordRequest } from './../types/auth';
import { ForgotPasswordRequest, LoginRequest, SignUpRequest, User } from '../types';
import axios from './../lib/axios';
import { ErrorResponse } from '../types/error';

export const signin = async (req: LoginRequest): Promise<User | ErrorResponse> => {
  return await axios.post('signin', req).then((res) => {
    const token = res.headers['authorization'];
    res.data.token = token;
    return Promise.resolve(res.data);
  });
};

export const signup = async (req: SignUpRequest): Promise<User | ErrorResponse> => {
  return await axios.post('signup', req).then((res) => {
    const token = res.headers['authorization'];
    res.data.token = token;
    return Promise.resolve(res.data);
  });
};

export const signout = async () => {
  return await axios.get('signout').then((res) => {
    return Promise.resolve(res.data);
  });
};

export const forgotPassword = async (req: ForgotPasswordRequest): Promise<any | ErrorResponse> => {
  return await axios.post('/passwords/request', req).then((res) => {
    return Promise.resolve(res.data);
  });
};

export const resetPassword = async (req: ResetPasswordRequest): Promise<any | ErrorResponse> => {
  return await axios.post('/passwords/reset', req).then((res) => {
    return Promise.resolve(res.data);
  });
};
