interface Props {
  content: string;
}

const SubscriptionExpiredTitle: React.FC<Props> = ({ content }) => {
  return (
    <h1 className="text-3xl font-bold leading-10 mt-3 mb-10 text-gray-900 xs:order-1 xs:mt-0 xs:mb-0 xs:text-c1 xs:text-center md:!text-c2 md:!text-left">
      {content}
    </h1>
  );
};

export default SubscriptionExpiredTitle;
