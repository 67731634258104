export enum FileType {
  Docx = 'docx',
  Pdf = 'pdf',
  Xlsx = 'xlsx'
}

export enum ShowModal {
  None,
  New,
  Duplicate,
  DuplicateMapUnit,
  Delete,
  Cancel,
  Download,
  ChangePassword
}

export enum PlanType {
  SingleUnit = 'Single Unit',
  Tier1 = 'TIER 1',
  Tier2 = 'TIER 2',
  Tier3 = 'TIER 3 '
}

export enum MemberStatus {
  Active = 'Active',
  Pending = 'Pending'
}

export enum Role {
  Owner = 'Owner',
  Collaborator = 'Collaborator'
}
