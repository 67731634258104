import { IdObj } from 'react-arborist/dist/types/utils';

export interface SidebarTreeNode extends IdObj {
  id: string;
  name: string;
  shortName?: string;
  type: NodeType;
  childrenCount?: number;
  ancestryDepth?: number;
  parentId?: number;
  sourceId?: number;
  sourceType?: string;
  children?: SidebarTreeNode[];
  icon?: any;
  linkTo?: string;
  onClick?: Function;
  badgeNumber?: number;
}

export enum NodeType {
  REGULAR = 'Folders::Regular',
  COURSE = 'Folders::Course',
  RTO = 'Folders::Rto',
  UNIT = 'Folders::Unit',
  MAP = 'Map',
  CUSTOM = 'Custom',
  NOTIFICATION = 'Notification'
}
