export const fonts = [
  'Arial',
  'Times New Roman',
  'Verdana',
  'Comfortaa',
  'Fira Sans',
  'Inter',
  'Libre Baskerville',
  'Lobster',
  'Lora',
  'Merriweather',
  'Montserrat',
  'Nunito',
  'Open Sans',
  'Oswald',
  'Pacifico',
  'Playfair Display',
  'Poppins',
  'PT Serif',
  'Raleway',
  'Roboto'
];

export const fontClassName: { [key: string]: string } = {
  Arial: 'font-arial',
  'Times New Roman': 'font-times-new-roman',
  Verdana: 'font-verdana',
  Comfortaa: 'font-comfortaa',
  'Fira Sans': 'font-fira-sans',
  Inter: 'font-inter',
  'Libre Baskerville': 'font-libre-baskerville',
  Lobster: 'font-lobster',
  Lora: 'font-lora',
  Merriweather: 'font-merriweather',
  Montserrat: 'font-montserrat',
  Nunito: 'font-nunito',
  'Open Sans': 'font-open-sans',
  Oswald: 'font-oswald',
  Pacifico: 'font-pacifico',
  'Playfair Display': 'font-playfair-display',
  Poppins: 'font-poppins',
  'PT Serif': 'font-pt-serif',
  Raleway: 'font-raleway',
  Roboto: 'font-roboto'
};
