import { ReactNode, ChangeEvent, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import { useStores } from 'hooks';
import { RectangleStackIcon } from '@heroicons/react/24/outline';
import { Header, Loading, Tabs } from 'components';
import { AddUnitModal } from 'components/course/modals';
import { ErrorResponse, Course as ICourse, CourseUnit, Unit } from 'types';
import { ShowModal } from 'types/enums';
import { fetchCourseDetail } from 'api';
import { UnitSearch } from './UnitSearch';
import { CourseTabsContainer } from './CourseTabsContainer';
import { getHeaderBadges, searchUnit, getTabs } from 'domains/course';
import './style.scss';

type Props = {
  code?: string;
  isModal?: boolean;
};

export const CourseContainer = observer(({ code, isModal = false }: Props) => {
  const navigate = useNavigate();
  const {
    mapUIStore: { showModal, openNewModal }
  } = useStores();

  const {
    data: course = {} as ICourse,
    isLoading,
    refetch
  } = useQuery<ICourse, ErrorResponse>(['course', code], () => fetchCourseDetail(code as string), {
    enabled: !!code
  });

  const [unitSearchText, setUnitSearchText] = useState('');
  const [activeTabIndex, setActiveTabIndex] = useState(isModal ? 0 : undefined);
  const [unit, setUnit] = useState<Pick<Unit, 'code' | 'title'>>();

  const tabs = useMemo(() => getTabs(course, unitSearchText), [course, unitSearchText]);

  const allUnits = useMemo(() => {
    return searchUnit(course?.units || [], unitSearchText);
  }, [course, unitSearchText]);

  const coreUnits = useMemo(() => {
    return allUnits?.filter((unit: CourseUnit) => unit.type === 'Core') || [];
  }, [allUnits]);

  const electiveUnits = useMemo(() => {
    return allUnits?.filter((unit: CourseUnit) => unit.type === 'Elective') || [];
  }, [allUnits]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUnitSearchText(value);
  };

  const handleClearSearch = () => {
    setUnitSearchText('');
  };

  const handleAddUnit = (unitCode: string, unitTitle: string) => {
    openNewModal();
    setUnit({ code: unitCode, title: unitTitle });
  };

  const handleAddedUnitCallback = () => {
    refetch();
  };

  const CourseHeader = ({ children }: { children?: ReactNode }) => {
    const title = course?.title || '...';
    const badges = getHeaderBadges(course);

    return (
      <>
        {!isModal && (
          <Helmet>
            <title>
              {code} - {title}
            </title>
          </Helmet>
        )}

        <Header
          title={code}
          subTitle={title}
          badges={badges}
          className={clsx('flex flex-col lg:flex-row', isModal && 'header-modal')}
          isShowCollapsedButton={!isModal}
        >
          <div className="mt-4 lg:mt-0">{children}</div>
        </Header>
      </>
    );
  };

  const CourseBody = () => {
    if (isLoading) {
      return (
        <div className="min-h-[200px] relative">
          <Loading />
        </div>
      );
    }

    const courseType = course?.componentType;
    const props = {
      courseType,
      allUnits,
      coreUnits,
      electiveUnits,
      onAddAddUnit: handleAddUnit
    };

    return !isModal ? <Outlet context={props} /> : <CourseTabsContainer activeTabIndex={activeTabIndex} {...props} />;
  };

  const renderBodyRender = () => {
    return (
      <div className={clsx('md:p-8', isModal ? 'p-4' : 'p-2')}>
        <div className={clsx('bg-white rounded-lg', !isModal && 'p-4 shadow')}>
          <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between mb-4">
            {/* Tabs */}
            <Tabs
              tabs={tabs}
              classes={{
                root: 'bg-primary-200 border-none rounded-lg p-1 space-x-2',
                tab: 'flex items-center bg-primary-200 hover:bg-primary-400 duration-300 rounded-lg text-primary-500 text-xs md:text-sm h-8 md:h-10 px-2 md:px-4'
              }}
              isUpdateUrl={!isModal}
              activeTabIndex={activeTabIndex}
              onChange={setActiveTabIndex}
            />

            {/* Search */}
            <UnitSearch
              searchText={unitSearchText}
              isNoSearchResult={!isLoading && !allUnits?.length}
              onSearch={handleSearch}
              onClearSearch={handleClearSearch}
            />
          </div>
          <CourseBody />
        </div>
      </div>
    );
  };

  return (
    <>
      <CourseHeader>
        <button
          className="btn-primary text-xs sm:text-sm px-3 sm:px-4"
          onClick={() => navigate({ pathname: `/library/courses/${code}/new` })}
        >
          <RectangleStackIcon className="w-4 sm:w-5 h-4 sm:h-5 inline-block mr-2" />
          Modify & Add Course to Library
        </button>
      </CourseHeader>

      {/* content */}
      {isModal ? (
        <PerfectScrollbar options={{ suppressScrollX: true }}>{renderBodyRender()}</PerfectScrollbar>
      ) : (
        renderBodyRender()
      )}
      {/* modals */}
      <AddUnitModal
        unit={unit}
        show={showModal === ShowModal.New}
        size="md"
        position={'center'}
        handleCallback={handleAddedUnitCallback}
      />
    </>
  );
});
