import { TrashIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteMapColumn } from 'api';
import { useStores } from 'hooks';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConfirmationModal } from 'components/modals';
import { Popover } from 'react-tiny-popover';

export const HeaderDropDown = ({ columnId, value }: { index?: number; columnId: string; value: string }) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const {
    mapUIStore: { setSyncStatus }
  } = useStores();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { mutate: deleteColumn, isLoading } = useMutation(
    () => {
      const assessmentTaskId = parseInt(columnId.split('assessmentTask')[1]);
      return deleteMapColumn({ mapId: parseInt(id as string), assessmentTaskId });
    },
    {
      onMutate: () => {
        setSyncStatus('saving');
      },
      onSuccess: () => {
        setSyncStatus('saved');
        queryClient.invalidateQueries(['maps', id]);
      },
      onError: () => {
        setSyncStatus('error');
      }
    }
  );

  return (
    <div className="flex items-center justify-end w-full dropdown-container absolute right-[4px] top-0 header-dropdown">
      <div className="dropdown">
        <Popover
          isOpen={isPopoverOpen}
          positions={['bottom']}
          reposition={false}
          onClickOutside={() => setIsPopoverOpen(false)}
          containerClassName="!z-50 rounded-md divide-y divide-gray-100 shadow transition-opacity duration-100 border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white mt-2 opacity-100"
          align="end"
          content={() => (
            <div className="py-1 text-sm text-gray-700 dark:text-gray-200 px-2" onClick={() => setOpenModal(true)}>
              <ul className="py-1">
                <li className="flex items-center justify-start py-2 cursor-pointer dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white p-2 hover:bg-gray-200 text-gray-900 text-xs rounded-lg">
                  <TrashIcon className="w-5 mr-2" />
                  <span className="font-medium text-sm">Delete Column</span>
                </li>
              </ul>
            </div>
          )}
        >
          <div className="flex items-center cursor-pointer" onClick={() => setIsPopoverOpen((prev) => !prev)}>
            <ChevronDownIcon className="h-[14px] w-auto" />
          </div>
        </Popover>
      </div>
      {openModal && (
        <ConfirmationModal
          show={openModal}
          size="sm"
          isloading={isLoading}
          title={'Remove column?'}
          message={`The column ${value || ''} will be deleted forever and you won’t be able to restore it.`}
          closeModal={() => setOpenModal(false)}
          onSave={() => {
            deleteColumn();
            setOpenModal(false);
          }}
        />
      )}
    </div>
  );
};
