import { XMarkIcon, PlusIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import './index.scss';
import TextareaAutosize from 'react-textarea-autosize';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TemplateInputsType } from './TemplateDetails';

export const TemplateTable = () => {
  const tableRowColors = [
    'bg-primary-100 rounded-tl-lg',
    'bg-orange-100',
    'bg-blue-100',
    'bg-yellow-100',
    'bg-green-100 rounded-bl-lg'
  ];

  const { control, register, setFocus } = useFormContext<TemplateInputsType>();

  const {
    fields: columnsField,
    append: appendColumn,
    remove: removeColumn
  } = useFieldArray<TemplateInputsType>({
    control: control,
    name: 'columns'
  });

  const [isFocusing, setIsFocusing] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const scrollRef = useRef<HTMLDivElement>(null);

  const onAddNewColumn = () => {
    appendColumn({ value: '' });
    setCurrentIndex(columnsField.length);
    setIsFocusing(true);
    setTimeout(() => {
      scrollRef.current?.scrollBy({
        left: 500,
        behavior: 'smooth'
      });
    }, 100);
  };

  const onFocus = (index: number) => {
    setCurrentIndex(index);
    setIsFocusing(true);
    setFocus(`columns.${index}.value`);
  };

  const onTextareaBlur = () => {
    setIsFocusing(false);
  };

  return (
    <div className="pt-4 px-2 pb-6 md:px-8 scroll-smooth w-full overflow-x-auto my-template" ref={scrollRef}>
      <table>
        <thead>
          <tr className="flex pb-4">
            <th className={clsx('w-[234px] p-0')}></th>
            {columnsField.map((columnField, index) => (
              <th className="p-0 w-[234px]" key={`header_${index}`} onClick={() => onFocus(index)}>
                <div
                  className={clsx(
                    'h-full relative items-center border bg-white',
                    index === 0 && 'rounded-l-lg',
                    isFocusing && index === currentIndex ? 'border-blue-200 outline-offset-[-3px]' : ''
                  )}
                >
                  <TextareaAutosize
                    style={{ resize: 'none' }}
                    className={clsx(
                      `!h-full w-full pr-8 focus:ring-0 !text-sm !text-gray-900 border-0 !bg-transparent font-normal overflow-hidden`,
                      index === 0 && 'rounded-l-lg'
                    )}
                    key={columnField.id}
                    {...register(`columns.${index}.value` as const)}
                    onBlur={() => onTextareaBlur()}
                    data-testid={`column${index}`}
                  />
                  <div className="h-full absolute right-3 top-0 bottom-1 flex items-center">
                    <button
                      onClick={() => removeColumn(index)}
                      className="ml-2 bg-none text-gray-700 opacity-100 text-3xl leading-none font-semibold outline-none focus:outline-none max-w-[24px] max-h-[24px] rounded-md justify-center items-center flex"
                      type="button"
                    >
                      <XMarkIcon className="text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none hover:text-primary-500" />
                    </button>
                  </div>
                </div>
              </th>
            ))}
            <th
              className={clsx(
                'min-h-[38px] w-12 flex justify-center bg-white border rounded-r-lg  border-gray-20 hover:bg-gray-200  p-0 cursor-pointer'
              )}
              onClick={onAddNewColumn}
              data-testid="addNewColumnButton"
            >
              <PlusIcon className="h5 w-5" />
            </th>
          </tr>
        </thead>
        <tbody>
          {tableRowColors.map((record, recordIndex) => (
            <tr
              key={`row_${recordIndex}`}
              className={clsx('p-0 flex', recordIndex < tableRowColors.length - 1 && 'border-b')}
            >
              <td key={`row_color_${recordIndex}`} className={clsx('h-14 w-[234px] min-w-[234px]', record)}></td>
              {columnsField.map((columnField, index) => (
                <td key={`${recordIndex}_${index}`} className={clsx('h-14 w-[234px] border-r')}></td>
              ))}
              <td key={`body_${record.length + 1}`} className={clsx('h-12 w-10')}></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
