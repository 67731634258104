import { useQuery } from '@tanstack/react-query';
import { currentSubscription } from 'api';
import { ErrorResponse, Subscription } from 'types';

export const useGetCurrentSubscription = ({
  params,
  onSuccess
}: {
  params: any;
  onSuccess?: (data: Subscription) => void;
}) => {
  return useQuery<Subscription, ErrorResponse>(
    ['workspaces', 'subscription', 'current'],
    () => currentSubscription(params),
    {
      onSuccess: (data) => {
        onSuccess?.(data);
      }
    }
  );
};
