import { Modal } from 'flowbite-react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { CourseContainer } from 'components/course';
import './style.scss';

type Props = {
  courseCode: string;
  onClose?: () => void;
};

export const CourseDetailModal = ({ courseCode, onClose }: Props) => {
  if (!courseCode) return null;

  return (
    <Modal show={true} position="top-center" size="" id="course-detail-modal">
      <div className="flex h-[78vh]">
        <div className="flex flex-col flex-1">
          <div className="flex items-center justify-end w-full h-12 bg-white sticky top-0 z-10 px-4 lg:px-8">
            <XMarkIcon
              className="hover:rotate-90 duration-300 text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none hover:text-primary-500 cursor-pointer"
              onClick={onClose}
            />
          </div>
          <CourseContainer code={courseCode} isModal={true} />
        </div>
      </div>
    </Modal>
  );
};
