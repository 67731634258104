import { Controller, useFormContext } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { PasswordField } from 'components';
import { useMutation } from '@tanstack/react-query';
import { LoginFormInputs } from 'pages';

type Props = {
  onSubmit: (data: LoginFormInputs) => any;
};

export const SigninForm = observer(({ onSubmit }: Props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useFormContext<LoginFormInputs>();

  const { mutate, error, isLoading } = useMutation<LoginFormInputs, string, LoginFormInputs, unknown>(onSubmit);

  return (
    <form
      className="form-width px-4 py-6 md:p-16 bg-white md:bg-transparent rounded-mobile"
      onSubmit={handleSubmit((values) => mutate(values))}
    >
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <h1 className="text-c1 md:text-c3 text-left md:mb-8 mb-6 font-bold text-gray-900">Sign In</h1>
      <div className="form-group">
        <input
          className={`form-control ${errors.email ? 'invalid' : ''}`}
          id="email"
          type="text"
          placeholder="Email"
          autoFocus={true}
          {...register('email')}
        />
        <p className="feedback-invalid text-sm">{errors.email?.message}</p>
      </div>
      <div className="form-group">
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <PasswordField
              className={`form-control ${fieldState.error ? 'invalid' : ''} !pr-10`}
              value={field.value || ''}
              onChange={field.onChange}
              placeholder="Password"
              name={field.name}
            />
          )}
        />
        <p className="feedback-invalid text-sm">{errors.password?.message}</p>
      </div>
      <div className="form-group flex md:justify-start md:items-center mb-6 md:mb-5">
        <NavLink
          className="inline-block align-baseline text-purple-500 hover:text-purple-700 font-medium text-sm"
          to="/auth/forgot-password"
        >
          Forgot password?
        </NavLink>
      </div>
      <div className="flex">
        <button disabled={isLoading} className="btn-primary w-full" type="submit">
          Sign In
        </button>
      </div>
      {error && (
        <div className="form-group mb-0 mt-5">
          <p className="feedback-invalid text-sm">{error}</p>
        </div>
      )}
      <div className="text-center mt-6 md:mt-8">
        <span className="text-gray-900">
          Don’t have an account?
          <NavLink
            className="ml-1 inline-block align-baseline text-purple-500 md:hover:text-purple-800 font-medium"
            to="/auth/register"
          >
            Sign Up
          </NavLink>
        </span>
      </div>
    </form>
  );
});
