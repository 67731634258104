import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline';
import { Dropdown } from 'flowbite-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

export const MappingNote = ({ comment, onSave }: { comment: string; onSave: (comment: string) => void }) => {
  const { register, setValue, getValues, setFocus } = useForm<{ comment: string }>();
  useEffect(() => {
    if (comment) {
      setValue('comment', comment || '');
    }
  }, [comment, setValue]);

  return (
    <Dropdown
      dismissOnClick={false}
      inline={true}
      arrowIcon={false}
      label={
        <div className="flex items-center" onClick={() => setTimeout(() => setFocus('comment'))}>
          <ChatBubbleOvalLeftIcon className="h-[14px] w-auto mr-2" /> Note
        </div>
      }
    >
      <div className="bg-wshite w-[250px] p-3 px-4 flex flex-col">
        <div className="form-group mb-0 text-right">
          <TextareaAutosize
            autoFocus
            {...register('comment')}
            onBlur={() => onSave(getValues('comment'))}
            placeholder="Input your note (optional)"
            className="form-control overflow-hidden"
          />
        </div>
      </div>
    </Dropdown>
  );
};
