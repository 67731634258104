import { useStores } from 'hooks';
import { observer, useLocalObservable } from 'mobx-react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { addWorkspace } from 'api';
import { ErrorResponse } from 'types';
import { BriefcaseIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

interface IFormInputs {
  name: string;
}

const schema = yup
  .object({
    name: yup.string().required("Workspace name can't be blank.")
  })
  .required();

export const AddWorkspace = observer(() => {
  const { uiStore } = useStores();
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  const state = useLocalObservable(() => ({
    loading: false,
    error: '',
    clearError() {
      this.error = '';
    },
    setError(error: string) {
      this.error = error;
    },
    startLoading() {
      this.loading = true;
    },
    endLoading() {
      this.loading = false;
    },
    reset() {
      this.loading = false;
      this.error = '';
    }
  }));

  const handleClose = () => {
    uiStore.toggleNewUserWorkspaceModal();
    clearErrors(['name']);
  };

  const onSubmit = async ({ name }: IFormInputs) => {
    state.startLoading();
    state.clearError();
    try {
      const workspace = await addWorkspace(name);
      uiStore.addWorkspace(workspace);
      uiStore.updateCurrentWorkspaceId(workspace.id);
      queryClient.invalidateQueries();
      toast(`${name}  has been created successfully.`, {
        type: 'success'
      });
      navigate({
        pathname: '/library'
      });
      handleClose();
    } catch (error: any) {
      state.setError((error as ErrorResponse).error || '');
      return;
    } finally {
      state.endLoading();
    }
  };

  return (
    <div className="z-40 fixed top-0 left-0 right-0 bottom-0 bg-gray-900/[0.25] block md:pt-28 pt-24 md:pb-20 px-4">
      <form className="form-width card md:p-16 p-6 contents" onSubmit={handleSubmit(onSubmit)}>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none px-4">
          <div className="relative my-6 mx-auto max-w-3xl w-full max-w-648 bg-gray-200 rounded-lg">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none drop-shadow-md">
              {/*header*/}
              <div className="flex items-start justify-between pl-6 pr-6 pt-6 pb-4 rounded-t">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Create new workspace</h3>
                <button
                  className="ml-auto text-gray-700 opacity-100 float-right text-3xl leading-none font-semibold outline-none focus:outline-none max-w-[24px] max-h-[24px] rounded"
                  type="button"
                  onClick={() => handleClose()}
                >
                  <XMarkIcon className="hover:rotate-90 duration-300 text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none hover:text-primary-500" />
                </button>
              </div>
              {/*body*/}
              <div className="relative pl-6 pr-6 flex-auto">
                <div className="form-group">
                  <label className="block mb-2 text-gray-900  font-medium text-sm leading-5">
                    Name your workspace:
                  </label>
                  <input
                    className={`form-control ring-0 focus:ring-0 drop-shadow ${errors.name?.message ? 'invalid' : ''}`}
                    id="name"
                    type="text"
                    placeholder="Name"
                    autoFocus={true}
                    {...register('name')}
                  />
                  <p className="feedback-invalid text-sm font-normal text-red-500">{errors.name?.message}</p>
                  <p className="mt-2 text-sm font-normal text-gray-700">
                    You can also use the name of your company or organisation
                  </p>
                </div>
              </div>
              {/*footer*/}
              <div className="items-center justify-end pl-6 pr-6 pb-6 rounded-b">
                <button
                  disabled={state.loading}
                  className="flex items-center btn-primary w-full font-medium justify-center outline-0"
                  type="submit"
                >
                  <BriefcaseIcon className="max-w-[14px] mr-2"></BriefcaseIcon>Create your workspace
                </button>
                <p className="feedback-invalid text-sm font-normal text-red-500 mt-4">
                  {errors.name?.message ? 'Oops! Looks like you are missed some fields. Please re-enter.' : ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
});
