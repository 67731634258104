import { useRef, useState } from 'react';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

type Props = {
  className?: string;
  accept?: string;
  onChange?: (event: any) => void;
  onPreview?: (event: string) => void;
  placeholder?: string;
  name?: string;
  defaultValue?: string;
  showPreview?: boolean;
};

export const UploadFile = ({
  name,
  placeholder,
  defaultValue,
  showPreview = true,
  onChange,
  onPreview,
  accept,
  className
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDragActive, setDragActive] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<string | undefined>(defaultValue);

  const onReadFileUpload = (file: File) => {
    onChange?.(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e: any) {
      const imgResult = e.target.result;
      showPreview && setImagePreview(imgResult);
      onPreview?.(imgResult);
    };
  };

  const handleChange = (event: any) => {
    const files = inputRef.current?.files as File[] | undefined;
    if (files && files[0]) {
      onReadFileUpload(files[0]);
    }
  };

  const handleDrop = (event: any) => {
    setDragActive(false);
    const files = event?.dataTransfer?.files as File[] | undefined;
    if (files && files[0]) {
      onReadFileUpload(files[0]);
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrag = (event: any) => {
    if (!isDragActive) {
      setDragActive(true);
    }
    event.preventDefault();
  };

  const handleDragEnd = (event: any) => {
    setDragActive(false);
    event.preventDefault();
  };

  const Body = () => {
    return (
      <>
        <div
          className={clsx('flex flex-col justify-center items-center p-4', {
            '!hidden': imagePreview
          })}
        >
          <div className="w-[48px] h-[48px] flex items-center justify-center rounded-full bg-purple-100 text-primary-500">
            <CloudArrowUpIcon className="w-6 h-6" />
          </div>
          <p className="mb-2 text-sm font-normal  text-center text-gray-900">
            <span className="font-medium">Click to upload</span> or drag and drop
          </p>
          {placeholder && <p className="text-xs font-normal leading-5 text-center text-gray-900">{placeholder}</p>}
        </div>
        <div
          className={clsx('hidden justify-center p-4', {
            '!flex': imagePreview
          })}
        >
          <img className="w-full max-w-[400px]" src={imagePreview} alt="file preview" />
        </div>
      </>
    );
  };

  return (
    <div className={clsx(className)}>
      <div
        className="flex justify-center items-center w-full"
        onDragOver={handleDrag}
        onDragLeave={handleDragEnd}
        onDrop={handleDrop}
      >
        <label
          htmlFor="dropzone-file"
          className={clsx(
            'flex flex-col justify-center items-center w-full min-h-32 bg-white rounded-lg border border-gray-300 border-dotted cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600',
            {
              'bg-gray-100': isDragActive
            }
          )}
        >
          <Body />
          <input
            ref={inputRef}
            id="dropzone-file"
            name={name}
            type="file"
            className="hidden"
            accept={accept}
            onChange={handleChange}
          />
        </label>
      </div>
    </div>
  );
};
