import { Unit, FolderImg, Course, RTOImg } from 'components/icons';
import { Link } from 'react-router-dom';

const listItem = [
  {
    icon: Unit,
    title: 'Units',
    url: '/library/units'
  },
  {
    icon: FolderImg,
    title: 'Folders',
    url: '/library/regulars'
  },
  {
    icon: Course,
    title: 'Courses',
    url: '/library/courses'
  },
  {
    icon: RTOImg,
    title: 'RTOs',
    url: '/library/rtos'
  }
];

export const Snapshot = () => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-8">
      {listItem.map(({ icon: IconComponent, title, url }, key) => (
        <Link to={url} key={key.toString()}>
          <div className="bg-white rounded-lg py-8 px-4 shadow">
            <div className="snapshot-item flex flex-col items-center justify-center">
              <IconComponent className="w-full h-[112px] flex items-end justify-center" />
              <p className="text-lg font-semibold text-gray-900 mt-6">{title}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};
