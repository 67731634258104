import { ReactNode } from 'react';
import { Navbar } from 'components/layout/navbar';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';
import { SearchBar } from 'components/search-bar';
import { AddWorkspace } from '../user-workspace/AddWorkspace';
import { Impersonation } from 'components/impersonation';

type Props = {
  children: ReactNode;
};

export const Layout = observer(({ children }: Props) => {
  const { uiStore } = useStores();
  const { isSearchBarVisible, isNewWorkspaceModalShown } = uiStore;
  return (
    <>
      <Impersonation />
      <div className="flex h-full bg-gray-100 overflow-hidden">
        <Navbar />
        {isSearchBarVisible && <SearchBar />}
        {isNewWorkspaceModalShown && <AddWorkspace />}
        <div className="flex-1 overflow-y-auto">{children}</div>
      </div>
    </>
  );
});
