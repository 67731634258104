import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

export const ProtectedRoute = observer(
  ({ isAllowed, redirectPath, children }: { isAllowed: boolean; redirectPath: string; children: JSX.Element }) => {
    let location = useLocation();

    if (!isAllowed && location.pathname !== redirectPath) {
      return <Navigate to={redirectPath} state={{ from: location }} replace />;
    }

    return children ? children : <Outlet />;
  }
);
