import { Bagde } from 'components/bagde';
import { Progress } from 'flowbite-react';
import { useStores } from 'hooks';
import { observer } from 'mobx-react';
import { PlanType } from 'types/enums';
import './index.scss';

export const Subscription = observer(() => {
  const { subscriptionStore } = useStores();
  const { subscription } = subscriptionStore;
  const usedMembers = subscription?.usedMembers || 0;
  const users = subscription?.plan?.users || 0;
  const progress = (usedMembers / users) * 100;

  return (
    <div className="border border-gray-200 p-3  md:p-4 rounded-lg bg-white text-gray-900 text-sm font-medium flex flex-col justify-between w-7/12 lg:w-3/4 h-[134px]">
      <div className="flex justify-between w-full">
        <Bagde
          title={subscription?.plan?.name || ''}
          className={`text-xs leading-5 mr-2 bg-green-100 text-green-700`}
        />
      </div>
      <div className="mt-8">
        <p className="text-sm font-medium text-gray-900 ">
          {subscription?.usedMembers} of {subscription?.plan?.users}{' '}
          {subscription?.plan?.name === PlanType.SingleUnit ? 'user' : 'users'}
        </p>
        <div className="progress-custom mt-1">
          <Progress progress={progress} />
        </div>
      </div>
    </div>
  );
});
