import { FoundationSkill } from 'types';
import { Tooltip } from 'flowbite-react';
import 'index.scss';
import clsx from 'clsx';

type Props = {
  className?: string;
  foundationSkills: FoundationSkill[];
  levels: any;
};

export const FoundationSkillsTableMobile = ({ foundationSkills, levels, className }: Props) => {
  const hasPerformanceCriteria = foundationSkills.findIndex((f) => f.kind === 'performance_criteria') > -1;
  const hasSkillCol = foundationSkills.findIndex((f) => f.skill) > -1;

  const Row = ({ foundationSkill }: { foundationSkill: FoundationSkill }) => {
    const { foundationContents: rawFoundationContents, performanceCriteria } = foundationSkill;
    const foundationContents = [...rawFoundationContents!];

    return (
      <table className="table w-full mb-4">
        {(hasSkillCol || hasPerformanceCriteria) && (
          <thead className="bg-orange-100">
            <tr>
              <th className="text-left font-medium border-t">
                <div>
                  {hasSkillCol && (
                    <h4 className={clsx('font-medium text-sm', hasPerformanceCriteria ? 'mb-4' : '')}>
                      {foundationSkill.skill}
                    </h4>
                  )}
                  {hasPerformanceCriteria && (
                    <div>
                      {performanceCriteria.map((item, index) => (
                        <div
                          key={index}
                          className="performance-criteria inline-flex justify-center items-center mr-2 mb-2"
                        >
                          <Tooltip content={levels[`${item}`]} arrow={false} placement="top-start">
                            <button className="w-[40px] h-[40px] rounded-md bg-primary-100 text-primary-500 text-sm font-medium hover:bg-primary-200">
                              {item}
                            </button>
                          </Tooltip>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          {foundationContents.map((foundation, index) => (
            <tr key={index}>
              <td className="border-t border-l">{foundation.content}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className={className}>
      <h3 className="uppercase mb-4 text-orange-500 text-xs font-semibold">Foundation Skills</h3>
      {foundationSkills.map((foundationSkill, index) => (
        <Row key={index} foundationSkill={foundationSkill} />
      ))}
    </div>
  );
};
