import { Loading } from 'components';
import { PlanOptionsModal } from 'components/manage-payment/modals/PlanOptionsModal';
import { useGetCurrentSubscription } from 'hooks/useGetCurrentSubscription';
import { useStores } from 'hooks';
import { observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';

export const Expired = observer(() => {
  const { uiStore } = useStores();
  const { currentWorkspace } = uiStore;
  const isOwner = currentWorkspace?.isOwner || false;
  const { data: subscription, isLoading } = useGetCurrentSubscription({ params: { sync: true } });

  if (isLoading) return <Loading />;

  const hasIncompletePayment: boolean = subscription?.hasIncompletePayment ?? true;
  const subscriptionCancelled: boolean = subscription?.status === 'canceled';

  const contentExpired = isOwner
    ? 'Your subscription/trial has expired. To keep using Magic Map without interruption, choose a plan or complete your incomplete payment to reactivate.'
    : "Please contact Workspace's Owner to continue using our services without disruptions.";

  if (!hasIncompletePayment && !subscriptionCancelled) {
    return <Navigate to="/" replace />;
  }

  return <PlanOptionsModal onClose={() => {}} show={true} customHeading={contentExpired} hideCloseButton={true} />;
});
