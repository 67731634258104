// libs
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { observer } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
// types
import { ErrorResponse, ResetPasswordRequest } from 'types';
// api
import { resetPassword } from 'api';
// components
import { PasswordField } from 'components/password-field';
import PasswordLayout from './PasswordLayout';

interface IResetPassword {
  password: string;
  passwordConfirmation: string;
}

const schema = yup
  .object({
    password: yup.string().required("Password can't be blank.").min(6, 'Password must contain at least 6 characters.'),
    passwordConfirmation: yup
      .string()
      .required("Confirm password can't be blank.")
      .oneOf([yup.ref('password')], "Confirm password doesn't match password.")
  })
  .required();

export const ResetPasswordForm = observer(() => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<IResetPassword>({
    resolver: yupResolver(schema)
  });

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  if (!token) {
    return <Navigate to="/auth/login" replace={true} />;
  }

  const { mutate, isLoading, isError, error } = useMutation<any, ErrorResponse, ResetPasswordRequest>({
    mutationFn: resetPassword,
    onSuccess: () => {
      toast('Your password has been updated successfully. You now can log in with the new password', {
        type: 'success'
      });
      navigate('/auth/login');
    }
  });

  const onSubmit = async (values: IResetPassword) => {
    const params = {
      user: {
        ...values,
        resetPasswordToken: token
      }
    };
    mutate(params);
  };

  return (
    <PasswordLayout>
      <form className="form-width bg-white md:card md:p-16 px-4 py-6 rounded-mobile" onSubmit={handleSubmit(onSubmit)}>
        <Helmet>
          <title>Reset your password</title>
        </Helmet>
        <h1 className="text-c1 md:text-c3 md:text-left text-left md:mb-8 mb-6 font-bold text-gray-900">
          Change Password
        </h1>
        <div className="form-group">
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState }) => (
              <PasswordField
                className={`form-control ${fieldState.error ? 'invalid' : ''} !pr-10`}
                value={field.value || ''}
                onChange={field.onChange}
                placeholder="Input Password"
                name={field.name}
              />
            )}
          />
          <p className="feedback-invalid text-sm">{errors?.password?.message}</p>
        </div>
        <div className="form-group">
          <Controller
            name="passwordConfirmation"
            control={control}
            render={({ field, fieldState }) => (
              <PasswordField
                className={`form-control ${fieldState.error ? 'invalid' : ''} !pr-10`}
                value={field.value || ''}
                onChange={field.onChange}
                placeholder="Confirm Password"
                name={field.name}
              />
            )}
          />
          <p className="feedback-invalid text-sm">{errors?.passwordConfirmation?.message}</p>
        </div>
        <div className="form-group mb-0">
          <button disabled={isLoading} className="btn-primary w-full" type="submit">
            Change Password
          </button>
        </div>
        {isError && (
          <div className="form-group mb-0 mt-5">
            <p className="feedback-invalid">{error?.message}</p>
          </div>
        )}
      </form>
    </PasswordLayout>
  );
});
