import { ReactSVG } from 'react-svg';
import Svg from '../../assets/svg/visa.svg';

type Props = {
  className?: string;
};

export const VisaIcon = ({ className }: Props) => {
  return <ReactSVG src={Svg} className={className} />;
};
