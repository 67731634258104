import { ReactSVG } from 'react-svg';
import CourseImg from '../../assets/svg/course-img.svg';

type Props = {
  className?: string;
};

export const Course = ({ className }: Props) => {
  return <ReactSVG src={CourseImg} className={className} />;
};
