import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { Loading } from 'components/loading';
import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { LibraryFolder, CourseItem as Course, Library } from 'types';
import { CourseItem } from './CourseItem';
import NodataSVG from 'assets/svg/no-units.svg';
import SimpleBar from 'simplebar-react';
import { SearchBox } from 'components/search-box';
import { CourseConfigurationModal } from '../course';

type Props = {
  className?: string;
  items: Course[];
  isLoading?: boolean;
  folderId?: string;
};

const ListItem = ({ items, folderId }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentCourseFolder, setCurrentCourseFolder] = useState<Library>();
  const queryClient = useQueryClient();

  const removeCourse = (course: Partial<Course>) => {
    queryClient.setQueryData<Library>(['library', 'rto', folderId], (library) => {
      if (!library) {
        return library;
      }

      const { children } = library;
      const newChildren = children ? [...children] : [];

      const removeIndex = newChildren.findIndex((x) => x.sourceId === parseInt(course.id!) && !x._destroy);
      const removeItem = { ...newChildren[removeIndex] };

      if (removeItem?.id) {
        removeItem._destroy = true;
        newChildren.splice(removeIndex, 1, removeItem);
      } else {
        newChildren.splice(removeIndex, 1);
      }

      return {
        ...library,
        children: [...newChildren]
      };
    });
  };

  const modifyCourse = (course: Partial<Course>) => {
    const rtoFolder = queryClient.getQueryData<Library>(['library', 'rto', folderId]);
    const courseFolder = rtoFolder?.children?.find((c) => c.sourceId?.toString() === course.id);
    setCurrentCourseFolder(courseFolder);
    setOpenModal(true);
  };

  const updateCourse = (course: LibraryFolder) => {
    queryClient.setQueryData<Library>(['library', 'rto', folderId], (library) => {
      if (!library) {
        return library;
      }
      const { children } = library;
      const newChildren = children ? [...children] : [];

      const index = newChildren.findIndex((x) => x.sourceId === course.sourceId && !x._destroy);

      const updatedCourse = Object.keys(course)
        .filter((k: string) => (course as any)[k] != null)
        .reduce((a, k: string) => ({ ...a, [k]: (course as any)[k] }), {});

      const item = { ...newChildren[index], ...updatedCourse, _edited: true };

      newChildren.splice(index, 1, item as unknown as Library);

      return {
        ...library,
        children: [...newChildren]
      };
    });
  };

  if (!items.length) {
    return (
      <div className="flex flex-col justify-center items-center p-12">
        <ReactSVG src={NodataSVG} className="mb-4" />
        <p className="text-sm text-gray-900 font-normal text-center">
          Please select the courses on the right hand side, that you wish to add to your RTO. You also have the ability
          to search and import a different course to be added to your RTO.
        </p>
      </div>
    );
  }

  return (
    <>
      <SimpleBar autoHide={true} className="flex-1 h-1 w-full">
        <div className="flex flex-col">
          {items.map((item, index) => (
            <CourseItem key={index} course={item} onRemove={removeCourse} onModify={modifyCourse} />
          ))}
        </div>
      </SimpleBar>
      {openModal && (
        <CourseConfigurationModal
          show={openModal}
          onCancel={() => setOpenModal(false)}
          onSave={updateCourse}
          {...{
            code: currentCourseFolder!.source.code,
            folderId: currentCourseFolder?.id?.toString() || '0',
            folder: currentCourseFolder
          }}
        />
      )}
    </>
  );
};

export const Configuration = ({ className, items: units, isLoading, folderId }: Props) => {
  const [filterItems, setFilterItems] = useState<Course[]>(units);

  useEffect(() => {
    setFilterItems(units);
  }, [units]);

  const changeKeyword = (keyword: string) => {
    if (!keyword) {
      setFilterItems(units);
      return;
    }

    setFilterItems(
      units.filter(
        (u) =>
          u.code.toLowerCase().includes(keyword.toLowerCase()) || u.title.toLowerCase().includes(keyword.toLowerCase())
      )
    );
  };

  return (
    <div className={clsx(className, 'border rounded-lg bg-white shadow p-4 flex flex-col')}>
      <div className="text-xs text-primary-500 leading-5 font-semibold mb-3 uppercase">RTO Configuration</div>
      <SearchBox id="course-search-box-configuration" onChange={changeKeyword} placeholder="Search for courses" />
      {!isLoading && <ListItem {...{ items: filterItems, folderId }} />}
      {isLoading && (
        <div className="min-h-[200px] relative">
          <Loading />
        </div>
      )}
    </div>
  );
};
