import { useMutation } from '@tanstack/react-query';
import { deleteLibraryFolder } from 'api';
import { Button } from 'components/buttons';
import { Modal, ModalProps } from 'flowbite-react';
import { toast } from 'react-toastify';
import { Library } from 'types';

type Props = {
  folder?: Pick<Library, 'id' | 'name'>;
  handleCallback?: () => void;
} & ModalProps;

export const DeleteFolderModal = ({ folder, handleCallback, onClose, ...modalProps }: Props) => {
  const { mutate, isLoading: mutationLoading } = useMutation(deleteLibraryFolder, {
    onSuccess: () => {
      toast(`"${folder?.name}" successfully deleted.`, {
        type: 'success'
      });
      handleCallback?.();
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Something wrong! Please try again. ';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  const handleClose = () => {
    onClose?.();
  };

  const handleConfirmSubmit = () => {
    if (!folder?.id) return;
    if (mutationLoading) return;
    mutate(folder?.id);
  };

  return (
    <Modal {...modalProps} id="library-modal">
      <div className="p-4">
        <h4 className="text-sm font-medium text-gray-900">Delete forever?</h4>
        <span className="text-sm text-gray-700">
          “{folder?.name}” and all subfolders will be deleted from your Library and you won’t be able to restore it.
        </span>
      </div>

      {/* actions */}
      <div className="p-4 pt-0">
        <div className="flex justify-end">
          <button
            type="button"
            className="hover:bg-gray-200 px-4 h-10 rounded-md mr-4 text-sm font-medium text-gray-900"
            onClick={handleClose}
          >
            No
          </button>
          <Button
            type="button"
            className="bg-primary-500 hover:bg-primary-700 px-4 h-10 rounded-md text-white text-sm font-medium"
            onClick={handleConfirmSubmit}
            isLoading={mutationLoading}
            data-testid="deleteFolderButton"
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};
