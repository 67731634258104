import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchMapDetail, fetchStatus, updateMap } from 'api';
import { Loading } from 'components';
import { ElementTable, FoundationSkillTable, SectionTable } from 'components/mapping';
import { useParams } from 'react-router-dom';
import { Mapping as Map, UpdateMapRequest, CompetencyEvidenceType, MappingFoundationSkill, MapStatus } from 'types';
import 'styles/pages/mapping.scss';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { MappingHeader } from 'components/mapping/Header';
import { useEffect, useRef } from 'react';
import { useStores } from 'hooks';
import { NotAvailable } from 'components/icons';
import { TaskTable } from 'components/mapping/TaskTable';
import { MinCellWidth } from 'constant';
import { ColumnSizingState } from '@tanstack/react-table';
import ReactTooltip from 'react-tooltip';
import { StatusAlert } from 'components/mapping/Alert';

const defaultTableColumnSizing = {
  taskSizes: {
    content: 600
  },
  elementSizes: {
    content: 300,
    performanceCriteria: 300
  },
  foundationSkillSizes: {
    skill: 175,
    performanceCriteria: 175,
    foundationContent: 250
  },
  sectionSizes: {
    content: 600
  }
};

const findSkills = (foundationSkills: MappingFoundationSkill[], level?: string) => {
  if (!level) return [];
  const array = foundationSkills.filter((x) => x.performanceCriteria.includes(level)).map((item) => item.skill);
  return Array.from(new Set(array));
};

export const Mapping = () => {
  const { id } = useParams();
  const {
    uiStore,
    mapUIStore: { setSyncStatus, clearSyncStatus }
  } = useStores();

  const { isMobile } = useMediaQuery();

  const { data, isLoading } = useQuery<Map>(
    ['maps', id],
    () =>
      fetchMapDetail(parseInt(id as string)).then((value) => {
        if (!value.columnSizing || !Object.keys(value.columnSizing).length) {
          value.columnSizing = defaultTableColumnSizing;
        }
        value.elements.map((element) => {
          element.performanceCriteria.reference = findSkills(value.foundationSkills, element.performanceCriteria.level);
          return element;
        });

        return value;
      }),
    {
      enabled: !!id,
      cacheTime: Infinity
    }
  );

  const { data: dataMapStatus } = useQuery<MapStatus>(
    ['mapStatus', id],
    () =>
      fetchStatus(parseInt(id as string)).then((value) => {
        return value;
      }),
    {
      enabled: !!id,
      cacheTime: Infinity
    }
  );
  const queryClient = useQueryClient();

  const setNumbering = async (value: Partial<UpdateMapRequest>) => {
    let req: UpdateMapRequest = {
      id: id as string,
      ...value
    };
    queryClient.setQueryData(['maps', id], (old: any) => {
      return {
        ...old,
        ...req,
        id: unitId
      };
    });
    await updateMap(req);
  };

  const { mutate: onNumberingChange } = useMutation(setNumbering, {
    onSuccess: () => {
      setSyncStatus('saved');
    },
    onMutate: () => {
      setSyncStatus('saving');
    },
    onError: () => {
      setSyncStatus('error');
    }
  });

  const { mutate: updateMapColumnSizing } = useMutation(updateMap, {
    onSuccess: () => {
      setSyncStatus('saved');
    },
    onMutate: () => {
      setSyncStatus('saving');
    },
    onError: () => {
      setSyncStatus('error');
    }
  });

  const columnSizingChange = (
    columnSizingState: ColumnSizingState,
    tableId?: 'elements' | 'foundationSkills' | 'section' | 'task'
  ) => {
    Object.keys(columnSizingState).forEach((key) => {
      if (columnSizingState[key] < MinCellWidth) {
        columnSizingState[key] = MinCellWidth;
      }
    });

    queryClient.setQueriesData(['maps', id], (map: any) => {
      const {
        columnSizing: { ...oldColumnSizing }
      } = map as Map;
      if (!oldColumnSizing) {
        return;
      }
      let { taskSizes, elementSizes, foundationSkillSizes, sectionSizes } = oldColumnSizing;
      let newColumnSizing = {};

      const rightColumnSizingState = Object.keys(columnSizingState)
        .filter((key) => key.includes('assessmentTask'))
        .reduce((acc: any, key) => {
          acc[key] = columnSizingState[key];
          return { ...acc };
        }, {});

      let totalSize = 0;
      if (tableId === 'elements') {
        elementSizes = { ...elementSizes, ...columnSizingState };
        totalSize = elementSizes.performanceCriteria + elementSizes.content;
      }

      if (tableId === 'foundationSkills') {
        foundationSkillSizes = { ...foundationSkillSizes, ...columnSizingState };
        totalSize =
          foundationSkillSizes.performanceCriteria +
          foundationSkillSizes.skill +
          foundationSkillSizes.foundationContent;
      }

      if (tableId === 'section') {
        sectionSizes = { ...sectionSizes, ...columnSizingState };
        totalSize = sectionSizes.content;
      }

      if (tableId === 'task') {
        taskSizes = { ...taskSizes, ...columnSizingState };
        totalSize = taskSizes.content;
      }
      // Resizing right columns
      taskSizes.content = totalSize;
      sectionSizes.content = totalSize;

      if (tableId !== 'foundationSkills') {
        foundationSkillSizes.foundationContent =
          totalSize - foundationSkillSizes.skill - foundationSkillSizes.performanceCriteria;
        if (foundationSkillSizes.foundationContent < MinCellWidth) {
          foundationSkillSizes.foundationContent = MinCellWidth;
          foundationSkillSizes.performanceCriteria =
            totalSize - foundationSkillSizes.skill - foundationSkillSizes.foundationContent;
        }
        if (foundationSkillSizes.performanceCriteria < MinCellWidth) {
          foundationSkillSizes.performanceCriteria = MinCellWidth;
          foundationSkillSizes.skill =
            totalSize - foundationSkillSizes.performanceCriteria - foundationSkillSizes.foundationContent;
        }
      } else {
        foundationSkillSizes = { ...foundationSkillSizes, ...columnSizingState };
      }

      if (tableId !== 'elements') {
        elementSizes.performanceCriteria = totalSize - elementSizes.content;
      } else {
        elementSizes = { ...elementSizes, ...columnSizingState };
      }

      newColumnSizing = {
        ...oldColumnSizing,
        elementSizes: { ...elementSizes, ...rightColumnSizingState },
        taskSizes: { ...taskSizes, ...rightColumnSizingState },
        foundationSkillSizes: { ...foundationSkillSizes, ...rightColumnSizingState },
        sectionSizes: { ...sectionSizes, ...rightColumnSizingState }
      };

      if (JSON.stringify(newColumnSizing) === JSON.stringify(oldColumnSizing)) {
        return;
      }

      updateMapColumnSizing({ id: id as string, columnSizing: newColumnSizing });

      return {
        ...map,
        columnSizing: newColumnSizing
      };
    });
  };

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      clearSyncStatus();
      uiStore.collapseNav();
      firstRender.current = false;
    }
  });

  if (isLoading) {
    return (
      <div className="min-h-[200px] relative">
        <Loading />
      </div>
    );
  }

  const {
    assessmentTasks,
    elements,
    foundationSkills,
    performanceEvidences,
    knowledgeEvidences,
    assessmentConditions,
    name,
    code,
    comment,
    releaseNumber,
    performanceEvidenceNumbering,
    foundationSkillNumbering,
    knowledgeEvidenceNumbering,
    assessmentConditionNumbering,
    columnSizing = defaultTableColumnSizing,
    trafficMode,
    id: unitId
  } = data as Map;

  const levels = elements
    .map((element) => element.performanceCriteria)
    .flat()
    .reduce((accumulator, value) => {
      return { ...accumulator, [value.level!]: value.content };
    }, {});

  if (isMobile) {
    return (
      <div className="mapping flex flex-col h-full">
        <MappingHeader unitId={unitId} name={name} code={code} releaseNumber={releaseNumber} comment={comment || ''} />
        <div className="flex flex-col items-center pt-24">
          <NotAvailable className="mb-8" />
          <div className="font-medium text-sm text-gray-900">Map is not available on mobile devices.</div>
        </div>
      </div>
    );
  }

  return (
    <div className="mapping flex flex-col h-screen">
      <StatusAlert dataMapStatus={dataMapStatus} />
      <ReactTooltip
        id="mapping-tooltip"
        effect="solid"
        type="light"
        className="rounded-lg !py-2 !px-2 text-sm font-medium !shadow-sm !bg-white !border !border-gray-200 !opacity-100 max-w-sm"
        arrowColor="transparent"
        multiline={true}
      />
      <MappingHeader
        unitId={unitId}
        name={name}
        code={code}
        releaseNumber={releaseNumber}
        comment={comment || ''}
        trafficMode={trafficMode}
      />
      <div className="relative h-full flex-1 overflow-auto">
        <TaskTable
          assessmentTasks={assessmentTasks}
          columnSizing={columnSizing?.taskSizes}
          onColumnSizingChange={columnSizingChange}
        />
        <ElementTable
          assessmentTasks={assessmentTasks}
          data={elements}
          columnSizing={columnSizing?.elementSizes}
          onColumnSizingChange={columnSizingChange}
          trafficMode={trafficMode}
        ></ElementTable>
        {foundationSkills.length > 0 && (
          <FoundationSkillTable
            assessmentTasks={assessmentTasks}
            data={foundationSkills}
            columnSizing={columnSizing?.foundationSkillSizes}
            numbering={foundationSkillNumbering}
            onNumberingChange={onNumberingChange}
            onColumnSizingChange={columnSizingChange}
            trafficMode={trafficMode}
            levels={levels}
          />
        )}
        {performanceEvidences.length > 0 && (
          <SectionTable
            title="Performance Evidence (or Required Skills)"
            assessmentTasks={assessmentTasks}
            data={performanceEvidences}
            columnSizing={columnSizing?.sectionSizes}
            bgClass={'!bg-blue-100'}
            textClass="text-blue-500"
            numbering={performanceEvidenceNumbering}
            onNumberingChange={onNumberingChange}
            type={CompetencyEvidenceType.PerformanceEvidence}
            onColumnSizingChange={columnSizingChange}
            trafficMode={trafficMode}
          />
        )}
        {knowledgeEvidences.length > 0 && (
          <SectionTable
            title="Knowledge Evidence (or Required Knowledge)"
            assessmentTasks={assessmentTasks}
            data={knowledgeEvidences}
            columnSizing={columnSizing?.sectionSizes}
            bgClass={'!bg-yellow-100'}
            textClass="text-yellow-500"
            numbering={knowledgeEvidenceNumbering}
            onNumberingChange={onNumberingChange}
            type={CompetencyEvidenceType.KnowledgeEvidence}
            onColumnSizingChange={columnSizingChange}
            trafficMode={trafficMode}
          />
        )}
        {assessmentConditions.length > 0 && (
          <SectionTable
            title="Assessment Conditions"
            assessmentTasks={assessmentTasks}
            data={assessmentConditions}
            columnSizing={columnSizing?.sectionSizes}
            bgClass={'!bg-green-100'}
            textClass="text-green-500"
            numbering={assessmentConditionNumbering}
            onNumberingChange={onNumberingChange}
            type={CompetencyEvidenceType.AssessmentCondition}
            onColumnSizingChange={columnSizingChange}
            trafficMode={trafficMode}
          />
        )}
      </div>
    </div>
  );
};
