import {
  CreateCourseFolderRequest,
  Library,
  LibraryFolder,
  CreateFolderRequest,
  UpdateFolderRequest,
  RenameFolderRequest,
  CreateRTOFolderRequest
} from 'types';
import axios from 'lib/axios';

export const fetchLibraryRegular = async (): Promise<Library[]> => {
  return await axios.get(`library/regulars`).then((res) => {
    return res.data;
  });
};

export const createLibraryRegular = async (req: CreateFolderRequest): Promise<Library> => {
  return await axios
    .post(`library/regulars`, {
      folder: {
        name: req?.name,
        parentId: req?.parentId
      }
    })
    .then((res) => {
      return res.data;
    });
};

export const renameLibraryRegular = async (req: RenameFolderRequest): Promise<Library> => {
  return await axios
    .put(`library/regulars/${req.id}`, {
      folder: {
        id: req.id,
        name: req.name
      }
    })
    .then((res) => {
      return res.data;
    });
};

export const fetchLibraryUnit = async (): Promise<Library[]> => {
  return await axios.get(`library/units`).then((res) => {
    return res.data;
  });
};

export const fetchLibraryCourse = async (): Promise<Library[]> => {
  return await axios.get(`library/courses`).then((res) => {
    return res.data;
  });
};

export const fetchLibraryRTO = async (): Promise<Library[]> => {
  return await axios.get(`library/rtos`).then((res) => {
    return res.data;
  });
};

export const fetchLibraryDetail = async (id: string): Promise<Library> => {
  return await axios.get(`library/folders/${id}`).then((res) => {
    return res.data;
  });
};

export const updateCourseFolder = async (req: UpdateFolderRequest): Promise<LibraryFolder> => {
  return await axios.put(`library/courses/${req.folder.id}`, req).then((res) => {
    return res.data;
  });
};

export const createCourseFolder = async (req: CreateCourseFolderRequest): Promise<LibraryFolder> => {
  return await axios.post(`library/courses`, req).then((res) => {
    return res.data;
  });
};

export const deleteLibraryFolder = async (id: string): Promise<Library> => {
  return await axios.delete(`library/folders/${id}`).then((res) => {
    return res.data;
  });
};

export const createRTOFolder = async (req: CreateRTOFolderRequest): Promise<LibraryFolder> => {
  return await axios.post(`library/rtos`, req).then((res) => {
    return res.data;
  });
};

export const updateRTOFolder = async (req: UpdateFolderRequest): Promise<LibraryFolder> => {
  return await axios.put(`library/rtos/${req.folder.id}`, req).then((res) => {
    return res.data;
  });
};
