import clsx from 'clsx';
import { BreadcrumbItemProps } from 'flowbite-react/lib/esm/components/Breadcrumb/BreadcrumbItem';

interface Props extends BreadcrumbItemProps {
  className?: string;
}

export const BreadcrumbItem = ({ href, className, children, ...props }: Props) => {
  return (
    <li className="group">
      <span className="flex items-center">
        <span className="icon-group mx-4 text-sm font-medium text-gray-500">/</span>
        <span
          className={clsx(
            'text-breadcrumb truncate text-gray-600 text-base font-medium hover:text-primary-500 duration-300 ',
            className
          )}
        >
          {children}
        </span>
      </span>
    </li>
  );
};
