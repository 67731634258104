import { Tooltip } from 'flowbite-react';
import { SkillToolTip } from './SkillToolTip';
import { Element } from 'types';

type Props = {
  performanceCriterion?: Element;
  skills: string[];
};

export const PerformanceCriterionLevel = ({ performanceCriterion, skills }: Props) => {
  if (skills.length) {
    return (
      <>
        {skills.length && (
          <Tooltip content={<SkillToolTip skills={skills} />} arrow={false} placement="top-start">
            <span className="text-primary-500 text-sm font-medium mr-1 cursor-default">
              {performanceCriterion?.level}
            </span>
          </Tooltip>
        )}
        {performanceCriterion?.content}
      </>
    );
  }
  return (
    <>
      <span className="text-primary-500 text-sm font-medium mr-1 cursor-default">{performanceCriterion?.level}</span>
      {performanceCriterion?.content}
    </>
  );
};
