import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ProtectedRoute } from './protectedRoute';
import { useQuery } from '@tanstack/react-query';
import { ErrorResponse, Plan } from 'types';
import { fetchAllPlans } from 'api';
import { Loading } from 'components';
import { useStores } from 'hooks';
import { useGetCurrentSubscription } from 'hooks/useGetCurrentSubscription';

export const SubscriptionProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { subscriptionStore } = useStores();
  const { search } = useLocation();
  let params = { sync: new URLSearchParams(search).get('sync') || false } as any;

  const { data: subscription, isLoading } = useGetCurrentSubscription({
    params,
    onSuccess: (data) => subscriptionStore.setSubscription(data)
  });

  useQuery<Plan[], ErrorResponse>(['workspaces', 'subscription', 'plans'], fetchAllPlans, {
    cacheTime: 300000,
    staleTime: 300000,
    onSuccess: (data) => {
      subscriptionStore.setPlans(data);
    }
  });

  if (isLoading) return <Loading />;

  const hasIncompletePayment: boolean = subscription?.hasIncompletePayment ?? true;
  const subscriptionCancelled: boolean = subscription?.status === 'canceled';

  return (
    <ProtectedRoute isAllowed={!hasIncompletePayment && !subscriptionCancelled} redirectPath="/subscriptions/expired">
      {children ? children : <Outlet />}
    </ProtectedRoute>
  );
};
