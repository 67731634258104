import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { User } from 'types';

export interface IAuthStore {
  token: string;
  user: User | null;
}

export class AuthStore implements IAuthStore {
  token = '';
  user: User | null = null;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, { name: 'AuthStore', properties: ['token', 'user'], storage: window.localStorage });
  }

  get authenticated() {
    // TODO: This logic can be changed on demand
    return !!this.token;
  }

  get otpRequiredForLogin() {
    return this.user?.otpRequiredForLogin || false;
  }

  clear() {
    this.token = '';
    this.user = null;
  }

  updateUser(user: User) {
    if (user.token) {
      this.token = user.token;
    }
    this.user = user as User;
    this.user.fullName = `${user?.firstName} ${user?.surname}`;
    this.user.otpRequiredForLogin = user.otpRequiredForLogin || false;
  }

  setOTPRequiredForLogin(otpRequiredForLogin: boolean) {
    if (this.user) {
      this.user.otpRequiredForLogin = otpRequiredForLogin;
    }
  }
}
