import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { CourseUnit, CourseType } from 'types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { Table, TableLabel } from 'components/table';

import { UnitAction } from './UnitAction';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';

type Props = {
  units: CourseUnit[];
  tableType: 'all' | 'core' | 'elective';
  courseType?: CourseType;
  onAddUnit?: (unitCode: string, unitTitle: string) => void;
};

const columnHelper = createColumnHelper<CourseUnit>();

export const UnitTable = observer(({ units, tableType, courseType, onAddUnit }: Props) => {
  const { isMobile } = useMediaQuery();
  const { subscriptionStore } = useStores();

  // generate dynamic columns
  const columns: any = useMemo(() => {
    const defaultColumns = [
      columnHelper.accessor('code', {
        header: () => <span className="font-medium">Unit Code</span>,
        cell: (info) => (
          <Link to={`/units/${info.row?.original?.code}`}>
            <span className="text-sm font-medium uppercase">{info.getValue()}</span>
          </Link>
        )
      }),
      columnHelper.accessor('title', {
        header: () => <span className="font-medium">Unit Name</span>,
        cell: (info) => (
          <Link to={`/units/${info.row?.original?.code}`}>
            <span className="text-sm">{info.getValue()}</span>
          </Link>
        )
      })
    ];

    const statusColumn = columnHelper.accessor('usageRecommendation', {
      header: () => <span className="font-medium">Status</span>,
      cell: (info) => <TableLabel type={info.getValue()} />
    });

    const typeColumn = columnHelper.accessor('type', {
      header: () => <span className="font-medium">Type</span>,
      cell: (info) => <TableLabel type={info.getValue()} />
    });

    const actionColumn = columnHelper.accessor('added', {
      header: () => <span className="font-medium">My Library</span>,
      cell: (info) => (
        <UnitAction
          added={info.renderValue()}
          onClick={() => {
            if (subscriptionStore.isAbleToAddMoreUnit()) {
              const unitCode = info.row?.original?.code;
              const unitTitle = info.row?.original?.title;
              onAddUnit?.(unitCode, unitTitle);
            } else {
              subscriptionStore.showPlanOptionsModal(true);
            }
          }}
        />
      ),
      maxSize: 20
    });

    // hide status and type columns for small device
    if (isMobile) {
      return [...defaultColumns, actionColumn];
    }

    // show full columns
    const isShowAllColumns = tableType === 'all' && courseType === 'qualification';
    if (isShowAllColumns) {
      return [...defaultColumns, statusColumn, typeColumn, actionColumn];
    }

    // hide type column if course type is skill set
    return [...defaultColumns, statusColumn, actionColumn];
  }, [tableType, courseType, onAddUnit, isMobile, subscriptionStore]);

  return <Table data={units} columns={columns} />;
});
