import { ArrowDownTrayIcon, RectangleGroupIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { fetchTemplates } from 'api';
import { DeleteTemplateModal, Header, Loading, NoTemplates, Pagination } from 'components';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorResponse, PagedList } from 'types';
import { Template } from 'types';
import { useStores } from 'hooks';
import { ShowModal } from 'types/enums';
import { TemplateCard } from 'components/my-templates';
import { Helmet } from 'react-helmet-async';
import { NewMapModal } from 'components/modals/NewMapModal';
import { UnitTemplateDownloadModal } from 'components/my-templates/modals/UnitTemplateDownloadModal';
import { Button } from 'components/buttons/Button';

export const MyTemplates = observer(() => {
  const perPage = 24;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const page = searchParams.get('page') || '1';
  const [currentPage, setCurrentPage] = useState(parseInt(page as string) || 1);
  const [showNewMapModal, setShowNewMapModal] = useState(false);
  const [currentTemplateId, setCurrentTemplateId] = useState(0);
  if (parseInt(page as string) !== currentPage) {
    setCurrentPage(parseInt(page as string));
  }

  const {
    myTemplatesStore: { showModal, openDownloadModal }
  } = useStores();

  const onPageChange = (page: number) => {
    navigate({
      pathname: `/templates`,
      search: `?page=${page}`
    });
    setCurrentPage(page);
  };

  const { data, isLoading } = useQuery<PagedList<Template>, ErrorResponse>(['templates', page], () =>
    fetchTemplates(currentPage, perPage)
  );

  const onOpenNewMapModal = (templateId: number) => {
    setCurrentTemplateId(templateId);
    setShowNewMapModal(true);
  };

  if (isLoading || !data) {
    return <Loading />;
  }

  const Body = () => {
    if (!data?.records?.length) {
      return <NoTemplates />;
    }

    return (
      <>
        <div className="my-template pt-4 px-2 py-6 md:px-8 relative">
          <div className="md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-4">
            {data.records.map((record, index) => (
              <TemplateCard key={index} template={record} onOpenNewMapModal={onOpenNewMapModal} />
            ))}
          </div>

          {data.totalPages > 1 && (
            <div className="flex justify-center md:justify-end pt-6 pb-4">
              <Pagination currentPage={currentPage} onPageChange={onPageChange} totalPages={data.totalPages} />
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>My Templates</title>
      </Helmet>
      <Header title="My Templates" className="flex flex-col md:flex-row justify-between text-gray-900 sticky">
        <div className="flex mt-4 md:mt-0">
          {data?.records?.length > 0 && (
            <Button className="btn-outline flex" onClick={openDownloadModal}>
              <ArrowDownTrayIcon className="h-5 w-auto mr-2" />
              <span>Download</span>
            </Button>
          )}
          <button
            className="btn-primary text-sm ml-3 hidden md:block"
            onClick={() => {
              navigate({
                pathname: `new`
              });
            }}
            data-testid="newTemplateButton"
          >
            <RectangleGroupIcon className="w-5 h-5 inline-block mr-2" />
            New Template
          </button>
        </div>
      </Header>
      <Body />
      <NewMapModal templateId={`${currentTemplateId}`} show={showNewMapModal} hide={() => setShowNewMapModal(false)} />
      <DeleteTemplateModal show={showModal === ShowModal.Delete} size={'md'} position={'center'} />
      <UnitTemplateDownloadModal show={showModal === ShowModal.Download} size={'md'} position={'center'} />
    </>
  );
});
