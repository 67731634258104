import { useQuery } from '@tanstack/react-query';
import { fetchUnitDetail } from 'api';
import { useParams } from 'react-router-dom';
import { ErrorResponse, Unit } from 'types';
import { FoundationSkillsTable } from './FoundationSkillsTable';
import { PerformanceCriteriaTable } from './PerformanceCriteriaTable';
import { PerformanceCriteriaTableMobile, FoundationSkillsTableMobile } from './mobile';
import { SectionTable } from './SectionTable';
import { useMediaQuery } from 'components/MediaQueryProvider';

export const CompentecyEvidenceRequired = () => {
  const { isMobile } = useMediaQuery();
  const { id } = useParams();
  const { data } = useQuery<Unit, ErrorResponse>(['unit', id], () => fetchUnitDetail(id as string), {
    enabled: !!id
  });

  const { elements, foundationSkills, performanceEvidences, knowledgeEvidences, assessmentConditions } = data as Unit;

  const levels = elements
    .map((element) => {
      return element.performanceCriterions || [];
    })
    .flat()
    .reduce((accumulator, value) => {
      return { ...accumulator, [value.level]: value.content };
    }, {});

  const MobileResponsive = () => {
    if (isMobile) {
      return (
        <>
          <PerformanceCriteriaTableMobile elements={elements} foundationSkills={foundationSkills} className="mb-8" />
          <FoundationSkillsTableMobile foundationSkills={foundationSkills} levels={levels} className="mb-8" />
        </>
      );
    }

    return (
      <>
        <PerformanceCriteriaTable elements={elements} foundationSkills={foundationSkills} className="mb-8" />
        <FoundationSkillsTable foundationSkills={foundationSkills} levels={levels} className="mb-8" />
      </>
    );
  };

  return (
    <div className="py-6">
      <MobileResponsive />
      <SectionTable
        title="Performance Evidence (or Required Skills)"
        sections={performanceEvidences}
        className="mb-8"
        borderColor="border-blue-200"
        headerColor="text-blue-500"
      />
      <SectionTable
        title="Knowledge Evidence"
        sections={knowledgeEvidences}
        className="mb-8"
        borderColor="border-yellow-300"
        headerColor="text-yellow-600"
      />
      <SectionTable
        title="Assessment Conditions"
        sections={assessmentConditions}
        borderColor="border-green-200"
        headerColor="text-green-500"
      />
    </div>
  );
};
