import { ChangeEvent, useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import './style.scss';

type Props = {
  searchText: string;
  isNoSearchResult?: boolean;
  onSearch?: (value: string) => void;
  onClearSearch?: () => void;
};

// TODO move component to core component
export const CourseSearch = ({ searchText, isNoSearchResult, onSearch, onClearSearch }: Props) => {
  const [value, setValue] = useState(searchText);

  useEffect(() => {
    setValue(searchText);
  }, [searchText]);

  // eslint-disable-next-line
  const debounceFn = useCallback(
    _.debounce((value: string) => {
      onSearch?.(value);
    }, 500),
    []
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    return debounceFn(value);
  };

  return (
    <div className="form-group relative w-full lg:w-[280px] xl:w-[360px] mb-0 mt-4 lg:mt-0">
      <MagnifyingGlassIcon strokeWidth={2} className="w-4 h-4 absolute top-3 left-3 text-gray-900" />
      <input
        className={clsx('form-control search-input text-sm', value && isNoSearchResult && 'invalid')}
        placeholder="Search all courses from this RTO"
        value={value}
        onChange={handleInputChange}
      />
      {!!value && (
        <div
          role="button"
          className="text-gray-400 w-4 h-4 absolute top-3 right-3 hover:text-gray-700"
          onClick={onClearSearch}
        >
          <XMarkIcon strokeWidth={2} />
        </div>
      )}
    </div>
  );
};
