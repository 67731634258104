import { forwardRef } from 'react';
import clsx from 'clsx';
import { Workspaces } from './Workspaces';
import { WorkspaceActions } from './Actions';
import { UserProfile } from './UserProfile';
import { useStores } from 'hooks';
import { observer } from 'mobx-react';

type Props = {};

export const UserWorkspace = observer(
  forwardRef((props: Props, ref: any) => {
    const { uiStore } = useStores();
    const { isNavbarCollapsed, isUserWorkspaceShown } = uiStore;

    if (!isUserWorkspaceShown) return null;

    return (
      <div
        ref={ref}
        className={clsx('ml-6 mb-4 fixed left-0 bottom-0 z-50 max-h-[552px]', isNavbarCollapsed ? 'static' : '')}
      >
        <div className="relative flex bg-white border border-gray-200 shadow-sm rounded-lg">
          <div className="flex flex-col max-w-[256px]">
            <Workspaces />
            <WorkspaceActions />
            <UserProfile />
          </div>
        </div>
      </div>
    );
  })
);
