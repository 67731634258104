import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/buttons';
import { Modal, ModalProps } from 'flowbite-react';
import { AssignMap } from './AssignMap';
import useResizeObserver from 'use-resize-observer';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Mapping, UpdateMapRequest } from 'types';
import { updateMap } from 'api';
import { toast } from 'react-toastify';

interface Props extends ModalProps {
  id: string;
  onClose?: () => void;
}
export const AssignMapModal = ({ id, onClose, ...modalProps }: Props) => {
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();
  const formMethods = useForm<{ [key: string]: boolean }>({ defaultValues: {} });
  const queryClient = useQueryClient();

  const { mutate: updateMapMutate, isLoading } = useMutation(updateMap, {
    onSuccess: (res) => {
      toast('Your map has been assigned successfully.', {
        type: 'success'
      });

      queryClient.setQueryData<Mapping>(['maps', id], (map) => {
        const { folderIds } = res || {};
        if (!map) {
          return map;
        }
        return {
          ...map,
          folderIds
        };
      });

      onClose && onClose();
    },
    onError: () => {
      toast('Something went wrong! Please try again.', {
        type: 'error'
      });
    }
  });

  const onSubmit = async (items: { [key: string]: boolean }) => {
    const folderIds = Object.keys(items).filter((key) => items[key]);
    updateMapMutate({ ...({ id, folderIds } as UpdateMapRequest) });
  };

  return (
    <Modal {...modalProps}>
      <form className="h-[80vh] flex flex-col" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="flex justify-between border-b p-4">
          <p className="text-sm font-medium text-gray-900">Assign Map</p>
          <Button
            className="ml-auto bg-white border-0 text-gray-700 opacity-100 float-right text-3xl leading-none font-semibold outline-none focus:outline-none max-w-[24px] max-h-[24px] rounded"
            onClick={() => onClose && onClose()}
          >
            <XMarkIcon className="text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none hover:rotate-90 duration-300 hover:text-primary-500" />
          </Button>
        </div>
        <div className="flex flex-1 p-4 overflow-y-auto overflow-x-hidden">
          <div className="w-full" ref={ref}>
            <AssignMap {...{ id, width, height, formMethods }} />
          </div>
        </div>
        <div className="p-4 flex justify-end border-t">
          <button className="btn rounded-md hover:bg-gray-200" onClick={onClose} disabled={isLoading}>
            Cancel
          </button>
          <Button className="btn-primary ml-2" type="submit" isLoading={isLoading}>
            Assign
          </Button>
        </div>
      </form>
    </Modal>
  );
};
