import React, { useEffect } from 'react';
import { TwoFactorAuthSetupModal } from './TwoFactorAuthSetupModal';
import { TwoFactorAuthMessageModal } from './TwoFactorAuthMessageModal';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

type Props = {
  checked?: boolean;
  onChange?: (value: boolean) => void;
};
export const TwoFactorAuthToggle = ({ checked = false, onChange }: Props) => {
  const [TFAOn, setTFAOn] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showMessageModal, setShowMessageModal] = React.useState(false);
  const onToggle = (value: boolean) => {
    // If turned on do nothing
    if (checked) return;
    onChange?.(value);
    setTFAOn(value);
    setShowModal(value);
  };

  useEffect(() => {
    setTFAOn(checked);
  }, [checked]);

  const title = checked ? 'Two-factor authentication is on.' : 'Enable two-factor authentication (2FA)';
  const subTitle = checked
    ? `When signing in, you will need to enter a code to verify that it's you after entering your password.`
    : 'Help protect your account from unauthorized access by requiring a second authentication method in addition to your password.';

  return (
    <>
      <div className="basis-4/5 flex items-center justify-between border border-gray-300 rounded p-4">
        <div className="pr-4">
          <h5 className="text-base font-weight-bold">{title}</h5>
          <p className="text-sm font-weight-400 text-gray-700">{subTitle}</p>
        </div>
        <div className="pl-4">
          {!checked && (
            <label className="inline-flex items-center me-5 cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={TFAOn}
                onChange={(event) => onToggle(event.target.checked)}
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-purple-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-500"></div>
            </label>
          )}
          {checked && <CheckCircleIcon className=" me-5 w-8 h-8 text-purple-500" />}
        </div>
      </div>
      {showModal && (
        <TwoFactorAuthSetupModal
          show={showModal}
          onClose={() => {
            setTFAOn(false);
            setShowModal(false);
          }}
          onSuccess={() => setShowMessageModal(true)}
        />
      )}
      <TwoFactorAuthMessageModal show={showMessageModal} onClose={() => setShowMessageModal(false)} />
    </>
  );
};
