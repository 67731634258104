import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import { useMutation } from '@tanstack/react-query';
import { forgotPassword } from 'api';
import { ErrorResponse, ForgotPasswordRequest } from 'types';
import { NavLink, useNavigate } from 'react-router-dom';
import PasswordLayout from './PasswordLayout';

interface IForgotPassword {
  email: string;
}

const schema = yup
  .object({
    email: yup.string().required("Email can't be blank.").email('Please use a valid email address.')
  })
  .required();

export const ForgotPasswordForm = observer(() => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty }
  } = useForm<IForgotPassword>({
    resolver: yupResolver(schema)
  });

  const { mutate, isLoading, isSuccess, isError, error } = useMutation<any, ErrorResponse, ForgotPasswordRequest>(
    forgotPassword
  );

  const onSubmit = async (values: IForgotPassword) => {
    mutate({
      user: {
        email: values.email
      }
    });
    reset({}, { keepValues: true });
  };

  const navigate = useNavigate();

  if (isSuccess) {
    return (
      <PasswordLayout>
        <div className="form-width bg-white md:card md:p-16 p-6 px-4 py-6 rounded-mobile">
          <Helmet>
            <title>Forgot Password?</title>
          </Helmet>
          <h1 className="text-c1 md:text-c3 md:text-left text-left md:mb-4 mb-2 font-bold text-gray-900">
            Forgot Password?
          </h1>
          <div className="form-group text-gray-900 font-normal text-sm md:mb-8 mb-6">
            No worries, an email with a password reset link has been sent.
          </div>
          <div className="form-group mb-0">
            <button
              onClick={() => navigate('/auth/login')}
              disabled={isLoading}
              className="btn-primary w-full"
              type="submit"
            >
              Back to Sign In
            </button>
          </div>
        </div>
      </PasswordLayout>
    );
  }

  return (
    <PasswordLayout>
      <form className="form-width bg-white rounded-mobile md:card px-4 py-6 md:p-16" onSubmit={handleSubmit(onSubmit)}>
        <Helmet>
          <title>Forgot Password?</title>
        </Helmet>
        <h1 className="text-c1 md:text-c3 md:text-left text-left md:mb-4 mb-2 font-bold text-gray-900">
          Forgot Password?
        </h1>
        <div className="form-group text-gray-900 font-normal text-sm md:mb-8 mb-6">
          Enter your email address, and we’ll send you an email to reset your password.
        </div>
        <div className="form-group mb-6">
          <input
            className={`form-control ${errors.email || (error?.errors?.email && !isDirty) ? 'invalid' : ''}`}
            id="email"
            type="text"
            placeholder="Email"
            autoFocus={false}
            {...register('email')}
          />
          <p className="feedback-invalid text-sm">
            {errors.email?.message || (error?.errors?.email && !isDirty ? 'Email does not exist!' : '')}
          </p>
        </div>
        <div className="form-group mb-0">
          <button disabled={isLoading} className="btn-primary w-full" type="submit">
            Send
          </button>
        </div>
        <div className="text-center mt-4 py-2.5">
          <NavLink className=" text-purple-500 md:hover:text-purple-800 font-medium text-sm" to="/auth/login">
            Back to Sign In
          </NavLink>
        </div>
        {isError && (
          <div className="form-group mb-0 mt-5">
            <p className="feedback-invalid">{error?.message}</p>
          </div>
        )}
      </form>
    </PasswordLayout>
  );
});
