import { CheckIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createCourseFolder, updateCourseFolder } from 'api';
import clsx from 'clsx';
import { Button } from 'components/buttons';
import { Header } from 'components/layout';
import { useStores } from 'hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateCourseFolderRequest, Library, LibraryFolder, UpdateFolderRequest } from 'types';

interface IFormInputs {
  name: string;
}

type Props = {
  name: string;
  folderId: string;
  courseId?: number;
  className?: string;
  onCancel?: () => void;
  onSave?: (folder: LibraryFolder) => void;
};

export const CourseConfigurationHeader = ({ name, folderId, courseId, className, onCancel, onSave }: Props) => {
  const { sidebarTreeStore } = useStores();
  const navigate = useNavigate();
  const { register, setValue, getValues } = useForm<IFormInputs>({
    defaultValues: {
      name: name
    }
  });
  const queryClient = useQueryClient();

  const addCourse = async () => {
    const library = queryClient.getQueryData<Library>(['library', 'courses', folderId]);
    if (!library) {
      return;
    }
    const { children } = library;

    const updateChildren =
      children
        ?.filter((item) => item._destroy || !item.id)
        .map((item) => {
          return {
            id: item.id ? parseInt(item.id) : undefined,
            sourceId: item.sourceId,
            source: item.source,
            name: item.name,
            _destroy: item._destroy,
            metadata: item.metadata
          } as LibraryFolder;
        }) || [];

    if (!parseInt(library.id)) {
      const request: CreateCourseFolderRequest = {
        folder: {
          name: getValues('name'),
          sourceId: courseId,
          source: library.source,
          metadata: library.metadata,
          sourceType: 'Nrt',
          children: updateChildren
        }
      };

      if (onSave) {
        onSave(request.folder);
        return -1;
      }

      return createCourseFolder(request);
    }

    const request: UpdateFolderRequest = {
      folder: {
        id: parseInt(library.id.toString()),
        name: getValues('name'),
        sourceId: library.sourceId,
        source: library.source,
        metadata: library.metadata,
        sourceType: 'Nrt',
        children: updateChildren
      }
    };

    if (onSave) {
      onSave(request.folder);
      queryClient.removeQueries(['library', 'courses', folderId]);

      return -1;
    }

    return updateCourseFolder(request);
  };

  const { mutate: addCourseMutate, isLoading } = useMutation(addCourse, {
    onSuccess: async (res) => {
      if (res === -1) {
        onCancel && onCancel();
        return;
      }

      let message = 'Your changes have been saved.';
      if (!parseInt(folderId)) {
        message = 'Course successfully added to Library.';
      }

      toast(message, {
        type: 'success'
      });

      await sidebarTreeStore.loadCourseFolders();
      await sidebarTreeStore.loadRtoFolders;
      queryClient.removeQueries(['library', 'courses', folderId]);

      if (onCancel) {
        onCancel();
        queryClient.invalidateQueries(['folder_detail', folderId]);
        return;
      }

      navigate({
        pathname: `/library/folders/${res?.id}`
      });
    },
    onError: () => {
      toast('Something wrong! Please try again.', {
        type: 'error'
      });
    }
  });

  useEffect(() => {
    if (name) {
      setValue('name', name);
    }
  }, [name, setValue]);

  const handleCancel = () => {
    queryClient.removeQueries(['library', 'courses', folderId]);

    if (onCancel) onCancel();
  };

  const Title = () => {
    return (
      <div className="form-group !mb-0 w-full min-w-96">
        <input className="form-control !leading-6 !text-gray-900" {...register('name')} />
      </div>
    );
  };

  return (
    <Header titleComponent={<Title />} className={clsx(className, 'flex flex-col lg:flex-row')}>
      <div className="flex mt-4 lg:mt-0 basis-2/5 justify-end">
        {onCancel && (
          <Button
            disabled={isLoading}
            className="btn !inline-flex mr-3 hover:bg-gray-200 rounded-md"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
        )}
        <Button isLoading={isLoading} className="btn-primary !inline-flex" onClick={() => addCourseMutate()}>
          <CheckIcon className="h-5 w-auto mr-1" />
          {onCancel ? 'Save' : 'Add Course to Library'}
        </Button>
      </div>
    </Header>
  );
};
