import axios from './../lib/axios';
import { Workspace, WorkspaceBilling } from 'types';

export const fetchWorkspace = async (): Promise<Workspace[]> => {
  return await axios.get('workspaces').then((res) => {
    return Promise.resolve(res.data);
  });
};

export const switchWorkspace = async (workspaceId: number) => {
  await axios.post(`workspaces/${workspaceId}/switch`);
};

export const addWorkspace = async (name: string): Promise<Workspace> => {
  return await axios
    .post('workspaces', {
      workspace: {
        name: name
      }
    })
    .then((res) => {
      return Promise.resolve(res.data);
    });
};

export const fetchWorkspaceDetails = async (id: number): Promise<WorkspaceBilling> => {
  return await axios.get(`workspaces/${id}`).then((res) => {
    return Promise.resolve(res.data);
  });
};

export const updateWorkspaceDetails = async (workspace: WorkspaceBilling) => {
  return await axios
    .put(`workspaces/${workspace.id}`, {
      workspace
    })
    .then((res) => {
      return Promise.resolve(res.data);
    });
};
