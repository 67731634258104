import { ReactSVG } from 'react-svg';
import Svg from '../../assets/svg/amex.svg';

type Props = {
  className?: string;
};

export const AMEX = ({ className }: Props) => {
  return <ReactSVG src={Svg} className={className} />;
};
