import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchLatestInvoice, getCheckoutSession, getPortalUpdatePlan } from 'api';
import clsx from 'clsx';
import { Button } from 'components/buttons';
import { Badge } from 'flowbite-react';
import { useStores } from 'hooks';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { ErrorResponse, InvoiceData, Plan } from 'types';
import { pluralize } from 'utils/helpers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import * as yup from 'yup';

interface IFormInputs {
  quantity: number;
}

const schema = yup.object({
  quantity: yup.number().min(1, 'Quantity must be at least 1').typeError('Quantity is required')
});

const SubscriptionPlanCard = observer(({ plan }: { plan: Plan }) => {
  const { subscriptionStore } = useStores();
  const { subscription } = subscriptionStore;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const hasIncompletePayment: boolean = subscription?.hasIncompletePayment ?? false;
  const subscriptionCancelled: boolean = subscription?.status === 'canceled';

  const isCurrent = subscriptionStore.isCurrentPlan(plan);
  const isRecommended = subscriptionStore.isRecommendedPlan(plan);
  const isSingleUnit = subscriptionStore.isSingleUnit();

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors }
  } = useForm<IFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const { data: latestInvoice } = useQuery<InvoiceData, ErrorResponse>(['latest_invoice'], fetchLatestInvoice, {
    enabled: hasIncompletePayment
  });

  const mutateEndpoint = subscriptionCancelled ? getCheckoutSession : getPortalUpdatePlan;

  const { mutate, isLoading } = useMutation(mutateEndpoint, {
    onSuccess: (res: any) => {
      if (!res) {
        return;
      }
      window.location.assign(res?.url);
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Something went wrong! Please try again. ';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  const redirectToStripePortal = (plan: Plan, quantity: number = 1) => {
    const returnUrl = window.location.origin + window.location.pathname;
    const successUrl = window.location.origin;

    mutate({ id: plan.id, returnUrl: returnUrl, quantity: quantity, successUrl });
  };

  const features =
    isCurrent && subscription ? (
      <>
        <span>
          {subscription?.usedUnits} of {pluralize(subscription.plan.units, 'unit')}
        </span>
        <br />
        <span>
          {subscription.usedMembers} of {pluralize(subscription.plan.users, 'user')}
        </span>
      </>
    ) : (
      <span>
        {pluralize(plan.units, 'Unit')} - {pluralize(plan.users, 'User')}
      </span>
    );

  return (
    <div role="listitem" className={clsx('bg-white border rounded-lg mt-3 flex relative')}>
      {isCurrent && <div className="w-2.5  h-auto bg-primary-600 rounded-tl-md rounded-bl-md" />}
      <div className="w-full p-4">
        <div className="md:flex items-center justify-between">
          <div className="flex capitalize">
            <h2 className="text-xl font-semibold leading-6 text-gray-900 mr-2">{plan.name}</h2>
            {isRecommended && <Badge color="success">Recommended</Badge>}
            {isCurrent && (
              <>
                <Badge>Current</Badge>
                <span className="ml-2">
                  <Badge>{subscription?.status}</Badge>
                </span>
              </>
            )}
          </div>
          <p className="text-xl md:mt-0 mt-4 font-semibold leading-6 text-gray-900">
            {plan.formattedPrice}
            <span className="font-normal text-sm">/yr{plan?.name === 'Single Unit' && <span>/unit</span>}</span>
          </p>
        </div>
        <div className="flex justify-between items-center mt-2">
          <p className="text-sm leading-6 text-gray-800">{features}</p>
          {(!isCurrent || subscriptionCancelled) && (
            <Button
              className="btn-secondary py-2 bg-primary-100"
              type="button"
              isLoading={isLoading}
              disabled={
                !isCurrent &&
                (subscriptionStore.isUnSelectable(plan) || Number(subscription?.usedMembers) >= plan.users)
              }
              onClick={() => {
                redirectToStripePortal(plan);
              }}
            >
              Select
            </Button>
          )}
          {isCurrent && hasIncompletePayment && (
            <Button
              className="btn-secondary py-2 bg-primary-100"
              isLoading={isLoading}
              onClick={() => {
                window.location.assign(latestInvoice?.hostedInvoiceUrl || '#');
              }}
            >
              Review Incomplete Invoice
            </Button>
          )}
          {isCurrent && isSingleUnit && !hasIncompletePayment && !subscriptionCancelled && (
            <Popover
              isOpen={isPopoverOpen}
              positions={['bottom']}
              reposition={true}
              onClickOutside={() => setIsPopoverOpen(false)}
              containerClassName="z-50"
              containerStyle={{ marginTop: '8px' }}
              content={({ position, childRect, popoverRect }) => (
                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor={'#0000ff14'}
                  arrowSize={10}
                  arrowStyle={{ opacity: 0.7 }}
                  className="popover-arrow-container"
                  arrowClassName="popover-arrow"
                >
                  <div className="bg-white w-[250px] py-3 px-4 flex flex-col z-auto rounded-lg border shadow">
                    <div className="form-group mb-0 text-right">
                      <form onSubmit={handleSubmit((d) => redirectToStripePortal(plan, d.quantity))}>
                        <label className="block text-gray-900 font-medium text-left mr-2">Quantity</label>
                        <div className="flex">
                          <input
                            type="number"
                            {...register('quantity')}
                            className={`form-control !w-1/3 flex-initial text-center ${
                              errors.quantity ? 'invalid' : ''
                            }`}
                            defaultValue={1}
                            onKeyDown={(e) => {
                              e.key === 'Enter' && e.preventDefault();
                            }}
                            min={1}
                          />
                          <Button
                            type="submit"
                            isLoading={isLoading}
                            className={
                              'btn-primary disabled:bg-primary-500 disabled:opacity-50 disabled:cursor-not-allowed flex-auto ml-4'
                            }
                            disabled={errors.hasOwnProperty('quantity')}
                          >
                            Buy More Unit
                          </Button>
                        </div>
                        {errors.quantity?.message && (
                          <p className="mt-2 text-start text-red-400 mb-0">{errors.quantity?.message}</p>
                        )}
                      </form>
                    </div>
                  </div>
                </ArrowContainer>
              )}
            >
              <div
                className="flex items-center btn-secondary py-2 bg-primary-100 relative"
                onClick={() => {
                  setIsPopoverOpen(!isPopoverOpen);
                  setTimeout(() => setFocus('quantity'), 100);
                }}
              >
                Add More
              </div>
            </Popover>
          )}
        </div>
        {subscriptionStore.isUnSelectable(plan) && !isCurrent && !subscriptionCancelled && (
          <span className="text-xs italic font-light text-gray-600">
            * Cannot select this plan because you have more units.
          </span>
        )}
      </div>
    </div>
  );
});

export default SubscriptionPlanCard;
