import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { rootStore } from '../stores';

export const onRequest = (config: InternalAxiosRequestConfig) => {
  const token = rootStore.authStore.token;

  if (!token) {
    return config;
  }

  config.headers['Authorization'] = `${token}`;
  const currentWorkspaceId = rootStore.uiStore.currentWorkspaceId;

  if (currentWorkspaceId) {
    config.headers['X-Workspace-ID'] = currentWorkspaceId;
  }

  return config;
};

export const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

export const onResponse = (config: AxiosResponse): AxiosResponse => {
  return config;
};

export const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  switch (error.response?.status) {
    case 401:
      rootStore.authStore.clear();
      break;
  }
  return Promise.reject(error.response?.data);
};
