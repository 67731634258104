import clsx from 'clsx';
import { NavbarLogo } from './Logo';
import { NavbarListItem } from './ListItem';
import { NavbarProfile } from './Profile';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';

export const Navbar = observer(() => {
  const { uiStore } = useStores();
  const { isNavbarCollapsed } = uiStore;

  return (
    <div
      className={clsx(
        'w-250 flex flex-col bg-white border-r border-gray-200 duration-300 z-40',
        isNavbarCollapsed && 'hidden sm:flex sm:w-90 '
      )}
    >
      <NavbarLogo />
      <NavbarListItem />
      <NavbarProfile />
    </div>
  );
});
