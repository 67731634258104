import { RTO, RTOCoursePaging } from 'types';
import axios from 'lib/axios';

export const fetchRtoDetail = async (code: string): Promise<RTO> => {
  return await axios.get(`rtos/${code}`).then((res) => {
    return res.data;
  });
};

export const fetchRtoCourses = async (code: string, filter: any): Promise<RTOCoursePaging> => {
  return await axios.get(`rtos/${code}/courses`, { params: filter }).then((res) => {
    return res.data;
  });
};
