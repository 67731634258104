import { useMemo } from 'react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { RTOCourse } from 'types';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { Table, TableLabel } from 'components/table';

type Props = {
  courses: RTOCourse[];
  tableType: 'all' | 'current' | 'superseded';
  onViewCourse?: (code: string) => void;
};

const columnHelper = createColumnHelper<RTOCourse>();

export const CourseTable = ({ courses, tableType, onViewCourse }: Props) => {
  const { isMobile } = useMediaQuery();

  const handleRowClick = (row: Row<RTOCourse>) => {
    const courseCode = row.original.code;
    onViewCourse?.(courseCode);
  };

  // generate dynamic columns
  const columns: any = useMemo(() => {
    const defaultColumns = [
      columnHelper.accessor('code', {
        header: () => <span className="font-medium">Code</span>,
        cell: (info) => <span className="text-sm font-medium uppercase">{info.getValue()}</span>
      }),
      columnHelper.accessor('title', {
        header: () => <span className="font-medium">Course Name</span>,
        cell: (info) => <span className="text-sm">{info.getValue()}</span>
      })
    ];

    const statusColumn = columnHelper.accessor('usageRecommendation', {
      header: () => <span className="font-medium">Status</span>,
      cell: (info) => <TableLabel type={info.getValue()} />
    });

    // hide status columns for small device and not `all` tab
    if (isMobile || ['current', 'superseded'].includes(tableType)) {
      return [...defaultColumns];
    }

    return [...defaultColumns, statusColumn];
  }, [tableType, isMobile]);

  return <Table data={courses} columns={columns} onRowClick={handleRowClick} />;
};
