import { ReactSVG } from 'react-svg';
import Svg from '../../assets/svg/discover.svg';

type Props = {
  className?: string;
};

export const Discover = ({ className }: Props) => {
  return <ReactSVG src={Svg} className={className} />;
};
