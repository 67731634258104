import { Element, FoundationSkill } from 'types';
import { PerformanceCriterionLevel } from '../PerformanceCriterionLevel';
type Props = {
  className?: string;
  elements: Element[];
  foundationSkills: FoundationSkill[];
};

export const PerformanceCriteriaTableMobile = ({ elements, foundationSkills, className }: Props) => {
  const findSkills = (level?: string) => {
    if (!level) return [];
    return foundationSkills.filter((x) => x.performanceCriteria.includes(level)).map((item) => item.skill);
  };

  const ElementRow = ({ element }: { element: Element }) => {
    const { performanceCriterions: rawPerformanceCriterions } = element;
    const performanceCriterions = [...rawPerformanceCriterions!];
    return (
      <table className="table w-full mb-4">
        <thead className="bg-primary-100">
          <tr>
            <th className="text-left font-medium border-t">
              {element.level}. {element.content}
            </th>
          </tr>
        </thead>
        <tbody>
          {element.performanceCriterions?.length! > 0 &&
            performanceCriterions?.map((performanceCriterion, index) => {
              const skills = findSkills(performanceCriterion.level);
              return (
                <tr key={index}>
                  <td className="text-left border-t border-l foundation-skill">
                    <PerformanceCriterionLevel
                      key={index}
                      performanceCriterion={performanceCriterion}
                      skills={skills}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  };

  return (
    <div className={className}>
      <h3 className="uppercase mb-4 text-primary-500 text-xs font-semibold">Elements and Performance Criteria</h3>
      {elements.map((element, index) => (
        <ElementRow key={index} element={element} />
      ))}
    </div>
  );
};
