import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { Loading } from 'components/loading';
import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { LibraryFolder, UnitItem as Unit } from 'types';
import { UnitItem } from './UnitItem';
import NodataSVG from 'assets/svg/no-units.svg';
import SimpleBar from 'simplebar-react';
import { SearchBox } from 'components/search-box';

type Props = {
  className?: string;
  units: Unit[];
  isLoading?: boolean;
  folderId?: string;
};

const UnitSelectList = ({ units, folderId }: Props) => {
  const queryClient = useQueryClient();

  const removeUnit = (unit: Partial<Unit>) => {
    queryClient.setQueryData(['library', 'courses', folderId], (oldData: any) => {
      const { children } = oldData as LibraryFolder;
      const newChildren = [...children];

      const removeIndex = newChildren.findIndex((x) => x.sourceId === parseInt(unit.id!) && !x._destroy);
      const removeItem = { ...newChildren[removeIndex] };

      if (removeItem?.id) {
        removeItem._destroy = true;
        newChildren.splice(removeIndex, 1, removeItem);
      } else {
        newChildren.splice(removeIndex, 1);
      }

      return {
        ...oldData,
        children: [...newChildren]
      };
    });
  };

  if (!units.length) {
    return (
      <div className="flex flex-col justify-center items-center p-12">
        <ReactSVG src={NodataSVG} className="mb-4" />
        <p className="text-sm text-gray-900 font-normal text-center">
          Please select the units on the right hand side, that you wish to add to your course. You also have the ability
          to search and import a different unit to be added to your course.
        </p>
      </div>
    );
  }

  return (
    <SimpleBar autoHide={true} className="flex-1 h-1 w-full">
      <div className="flex flex-col">
        {units.map((unit, index) => (
          <UnitItem key={index} unit={unit} onRemove={removeUnit} />
        ))}
      </div>
    </SimpleBar>
  );
};

export const Configuration = ({ className, units, isLoading, folderId }: Props) => {
  const [filterUnits, setFilterUnits] = useState<Unit[]>(units);

  useEffect(() => {
    setFilterUnits(units);
  }, [units]);

  const changeKeyword = (keyword: string) => {
    if (!keyword) {
      setFilterUnits(units);
      return;
    }

    setFilterUnits(
      units.filter(
        (u) =>
          u.code.toLowerCase().includes(keyword.toLowerCase()) || u.title.toLowerCase().includes(keyword.toLowerCase())
      )
    );
  };

  return (
    <div className={clsx(className, 'border rounded-lg bg-white shadow p-4 flex flex-col')}>
      <div className="text-xs text-primary-500 leading-5 font-semibold mb-3 uppercase">Course Configuration</div>
      <SearchBox id="unit-search-box-configuration" onChange={changeKeyword} placeholder="Search for units" />
      {!isLoading && <UnitSelectList {...{ units: filterUnits, folderId }} />}
      {isLoading && (
        <div className="min-h-[200px] relative">
          <Loading />
        </div>
      )}
    </div>
  );
};
