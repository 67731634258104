import { useMediaQuery } from 'components/MediaQueryProvider';
import { useStores } from 'hooks';
import { useNavigate } from 'react-router-dom';

type Props = {
  title: string;
  path: string;
};

export function LinkItem({ title, path }: Props) {
  const navigate = useNavigate();
  const { uiStore } = useStores();
  const { isMobile } = useMediaQuery();

  const navigateTo = () => {
    uiStore.toggleUserWorkspace(false);
    if (isMobile) {
      uiStore.collapseNav();
    }

    navigate({
      pathname: path
    });
  };

  return (
    <div
      className="text-sm text-gray-700 hover:text-primary-500 font-medium py-1 duration-300 cursor-pointer"
      onClick={() => navigateTo()}
    >
      {title}
    </div>
  );
}
