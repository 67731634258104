import clsx from 'clsx';
import { FC, useState } from 'react';
import { ChromePicker } from 'react-color';
import { Popover } from 'react-tiny-popover';

interface Props {
  title: string;
  initColor?: string;
  onColorChange?: (color: string) => void;
  hasError?: boolean;
}

export const BSFColorPicker: FC<Props> = ({ title, initColor, onColorChange, hasError }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  return (
    <div className="bsf-color-picker">
      <Popover
        isOpen={isPopoverOpen}
        transform={{ top: 20, left: 100 }}
        transformMode="relative"
        onClickOutside={() => setIsPopoverOpen(!isPopoverOpen)}
        content={
          <ChromePicker
            color={initColor}
            onChange={(color) => {
              onColorChange?.(color.hex);
            }}
          />
        }
      >
        <div className="w-36 cursor-pointer" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
          <div
            className={clsx('text-xs  mt-3 mb-2', {
              'text-gray-500': !hasError,
              'text-red-400': hasError
            })}
          >
            {title}
          </div>
          <div
            className={clsx('flex gap-x-2 items-center justify-between border bg-[#FFF] py-2 px-3 rounded-md', {
              'border-gray-200': !hasError,
              'border-red-400': hasError
            })}
          >
            <input
              className="bg-transparent outline-none w-20 text-gray-900 border-none"
              value={initColor}
              onChange={(e) => onColorChange?.(e.target.value)}
            />
            <div
              className="rounded-sm w-5 h-5 border border-gray-200"
              style={{
                background: initColor
              }}
            ></div>
          </div>
        </div>
      </Popover>
    </div>
  );
};
