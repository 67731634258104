import { NotAvailable } from 'components/icons';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { RTOConfigurationContainer } from 'components/library/rto';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

export const RTOConfiguration = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  let { code = '', id: folderId = '' } = useParams();

  if (folderId === 'new') {
    folderId = '0';
  }

  const handleCancel = () => {
    if (folderId !== '0') {
      queryClient.removeQueries(['library', 'rto', folderId]);
      navigate(`/library/folders/${folderId}`);

      return;
    }

    navigate('/library/rtos');
  };

  if (isMobile) {
    return (
      <div className="mapping flex flex-col h-full">
        <div className="flex flex-col items-center pt-24">
          <NotAvailable className="mb-8" />
          <div className="font-medium text-sm text-gray-900">
            Course Configuration is not available on mobile devices.
          </div>
        </div>
      </div>
    );
  }

  return <RTOConfigurationContainer {...{ code, folderId, onCancel: handleCancel }} />;
};
