import axios from '../lib/axios';
import { SetupIntent, PaymentMethodData } from 'types';

export const fetchPaymentMethods = async (): Promise<PaymentMethodData[]> => {
  return await axios.get('subscriptions/payment_methods').then((res) => {
    return res.data;
  });
};

export const setupIntent = async (): Promise<SetupIntent> => {
  return await axios.post('subscriptions/payment_methods/setup_intent').then((res) => {
    return res.data;
  });
};

export const createPaymentMethod = async (id: string): Promise<any> => {
  return await axios.post('subscriptions/payment_methods', { paymentMethod: { id } }).then((res) => {
    return res.data;
  });
};

export const setDefaultPaymentMethod = async (id: string): Promise<any> => {
  return await axios.put(`subscriptions/payment_methods/${id}/default`).then((res) => {
    return res.data;
  });
};

export const removePaymentMethod = async (id: string): Promise<any> => {
  return await axios.delete(`subscriptions/payment_methods/${id}`).then((res) => {
    return res.data;
  });
};
