import { ReactSVG } from 'react-svg';
import Svg from '../../assets/svg/mastercard.svg';

type Props = {
  className?: string;
};

export const Mastercard = ({ className }: Props) => {
  return <ReactSVG src={Svg} className={className} />;
};
