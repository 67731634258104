import { useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { CellContext } from '@tanstack/react-table';
import clsx from 'clsx';
import { DropDownCell } from './DropDownCell';
import { useMutation } from '@tanstack/react-query';
import { createMapCell, updateMapCell } from 'api';
import { AssessmentTaskDetails } from 'types';
import { useParams } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useStores } from 'hooks';

export const TextAreaCell = ({
  getValue,
  row: { index },
  column: { id },
  table: { options }
}: CellContext<any, any>) => {
  const {
    mapUIStore: { setSyncStatus }
  } = useStores();

  const { id: mapId } = useParams();
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue || {});
  const [focus, setFocus] = useState(false);
  const [trafficStatus, setTrafficStatus] = useState('');
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { trafficMode } = (options?.meta as any) || {};

  const update = async () => {
    if (initialValue.references === value.references) {
      return;
    }
    const mId = parseInt(mapId as string);
    const data = options.data;
    const tableId = options.meta?.tableId as string;

    const cell = data[index][id] as AssessmentTaskDetails;

    if (cell.id) {
      await updateMapCell({ mapId: mId, cellId: cell.id, references: value.references });
      return;
    }

    let elementableType = '';
    let elementableId = 0;
    switch (tableId) {
      case 'elements':
        elementableType = 'PerformanceCriterion';
        elementableId = data[index].performanceCriteria.id;
        break;
      case 'foundationSkills':
        elementableType = 'FoundationContent';
        elementableId = data[index].foundationContent.id;
        break;
      default:
        elementableId = data[index].content.id;
        elementableType = 'CompetencyEvidence';
    }

    const assessmentTaskId = parseInt(id.split('assessmentTask')[1]);
    await createMapCell({
      mapId: mId,
      assessmentTaskId,
      elementableId,
      elementableType,
      references: value.references
    });
  };

  const { mutate: updateCell, isError } = useMutation(update, {
    onSuccess: () => {
      setSyncStatus('saved');
    },
    onMutate: () => {
      setSyncStatus('saving');
    },
    onError: () => {
      setSyncStatus('error');
    }
  });

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    updateCell();
    setFocus(false);
  };

  const onFocus = () => {
    inputRef.current?.focus();
    setFocus(true);
  };

  useEffect(() => {
    setValue(initialValue);
    setTrafficStatus('');
    if (trafficMode) {
      switch (initialValue?.status) {
        case 'marked':
          setTrafficStatus('!bg-green-100');
          return;
        case 'typing':
        case 'pin':
          setTrafficStatus('!bg-red-100');
          return;
      }
    }
  }, [initialValue, trafficMode]);

  // KEEP THESE FUNCTIONS
  const addRow = (position: 'above' | 'below') => {};

  const duplicateRow = () => {};

  const removeRow = () => {};

  return (
    <td className="!p-0">
      <div
        className={clsx('min-h-full p-2', focus && 'outline outline-primary-200 outline-offset-[-3px]', trafficStatus)}
        onClick={onFocus}
      >
        <TextareaAutosize
          ref={inputRef}
          className={clsx(
            'border-0 outline-none focus:outline-none focus:ring-0 bg-white w-full p-0 overflow-hidden',
            trafficStatus
          )}
          style={{ resize: 'none' }}
          value={(value?.references as string) || ''}
          onChange={(e) => setValue({ ...value, references: e.target.value })}
          onBlur={onBlur}
          onFocus={(_) => setFocus(true)}
        />
      </div>

      <DropDownCell index={index} addRow={addRow} duplicateRow={duplicateRow} removeRow={removeRow} />

      {isError && (
        <button
          className="absolute right-1 bottom-1 text-red-500 text-xs flex flex-col items-center cursor-pointer"
          title="Retry"
        >
          <ExclamationTriangleIcon className="h-4 w-auto" />
        </button>
      )}
    </td>
  );
};
