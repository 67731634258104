import { Workspace } from 'types/workspace';
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { switchWorkspace } from 'api';
import MeiliSearch, { SearchParams } from 'meilisearch';
import createMeiliSearch from 'lib/meili-search';

export interface UIStore {
  isNavbarCollapsed: boolean;
  workspaces: Workspace[] | [];
  currentWorkspaceId: number;
  meilisearch: MeiliSearch | null;
  isSearchBarVisible: boolean;
  isUserWorkspaceShown: boolean;
  isNewWorkspaceModalShown: boolean;
}

export class UIStore implements UIStore {
  isNavbarCollapsed = false;
  workspaces: Workspace[] | [] = [];
  currentWorkspaceId = 0;
  meilisearch: MeiliSearch | null = null;
  isSearchBarVisible = false;
  isUserWorkspaceShown = false;
  isNewWorkspaceModalShown = false;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'UiStore',
      properties: ['isNavbarCollapsed', 'currentWorkspaceId'],
      storage: window.localStorage
    });

    this.updateMeiliSearchClient();
  }

  get currentWorkspaceOTPEnforced() {
    return this.currentWorkspace?.otpEnforced;
  }

  get isOwnerCurrentWorkspace() {
    return this.currentWorkspace?.isOwner;
  }

  collapseNav() {
    this.isNavbarCollapsed = true;
  }

  expandNav() {
    this.isNavbarCollapsed = false;
  }

  toggleNav() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  updateCurrentWorkspaceId(currentWorkspaceId: number) {
    this.currentWorkspaceId = currentWorkspaceId;
    this.sortWorkspaces();
  }

  async switchWorkspace(workspaceId: number) {
    await switchWorkspace(workspaceId);
    this.updateCurrentWorkspaceId(workspaceId);
    this.updateMeiliSearchClient();
    this.sortWorkspaces();
  }

  updateWorkspaces(workspaces: Workspace[]) {
    this.workspaces = workspaces;
    this.updateMeiliSearchClient();
    this.sortWorkspaces();
  }

  get currentWorkspace(): Workspace | undefined {
    return this.workspaces.find((w) => w.id === this.currentWorkspaceId);
  }

  updateCurrentWorkspace(workspace: Workspace) {
    this.workspaces = this.workspaces.map((w) => (w.id === workspace.id ? workspace : w));
  }

  openSearchBar() {
    this.isSearchBarVisible = true;
  }

  closeSearchBar() {
    this.isSearchBarVisible = false;
  }

  search = async (index: string, searchParams: SearchParams): Promise<any> => {
    return (this.meilisearch as MeiliSearch)
      .index(index)
      .search(searchParams.q, searchParams)
      .then((res) => {
        return res.hits;
      });
  };

  private updateMeiliSearchClient() {
    if (!this.currentWorkspace) {
      return;
    }
    const token = this.currentWorkspace.searchToken;
    this.meilisearch = createMeiliSearch(token);
  }

  addWorkspace(workspace: Workspace) {
    this.workspaces = [...this.workspaces, workspace];
    this.sortWorkspaces();
  }

  toggleUserWorkspace(isUserWorkspaceShown: boolean) {
    this.isUserWorkspaceShown = isUserWorkspaceShown;
  }

  toggleNewUserWorkspaceModal() {
    this.isNewWorkspaceModalShown = !this.isNewWorkspaceModalShown;
  }

  clear() {
    this.isNavbarCollapsed = false;
    this.workspaces = [];
    this.currentWorkspaceId = 0;
    this.meilisearch = null;
    this.isSearchBarVisible = false;
    this.isUserWorkspaceShown = false;
    this.isNewWorkspaceModalShown = false;
  }

  private sortWorkspaces() {
    const currentWorkSpace = this.workspaces.filter(({ id }) => id === this.currentWorkspaceId);
    const restWorkspace = this.workspaces.filter(({ id }) => id !== this.currentWorkspaceId);

    this.workspaces = [...currentWorkSpace, ...restWorkspace];
  }
}
