import React from 'react';
import logo from 'assets/svg/logo.svg';

const PasswordLayout = ({ children }: any) => {
  return (
    <div className="md:flex flex-col items-center h-full bg-auth-bg-mobile md:bg-auth-bg bg-cover bg-no-repeat bg-center md:bg-bottom md:px-8 overflow-y-auto no-scrollbar">
      <div className="md:absolute top-16 left-16 flex md:justify-between bg-white w-screen justify-center p-4 md:p-0 md:bg-transparent">
        <img src={logo} alt="" className="md:mb-0" />
      </div>
      <div className="md:flex md:flex-1 md:items-center md:justify-center md:m-4 m-4 mt-8">
        <>{children}</>
      </div>
    </div>
  );
};

export default PasswordLayout;
