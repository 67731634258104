import { useMemo } from 'react';
import { format } from 'date-fns';
import { createColumnHelper } from '@tanstack/react-table';
import { Library, NodeType } from 'types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { Table } from 'components/table';

import { LibraryAction } from './LibraryAction';
import { TreeNodeIcon } from 'components/sidebar-tree/TreeNodeIcon';

type Props = {
  data: Library[];
  onRename?: (record: Library) => void;
  onDelete?: (record: Library) => void;
};

const columnHelper = createColumnHelper<Library>();

export const LibraryTable = ({ data, onRename, onDelete }: Props) => {
  const { isMobile } = useMediaQuery();

  // generate dynamic columns
  const columns: any = useMemo(() => {
    const defaultColumns = [
      columnHelper.accessor('name', {
        header: () => <span className="font-medium">Name</span>,
        meta: {
          className: '!w-auto'
        },
        cell: (info) => {
          const id = info.row?.original?.id;
          const type = info.row?.original?.type;
          let linkDirectToDetail = `/library/folders/${id}`;
          if (type) {
            switch (type) {
              case NodeType.MAP:
                linkDirectToDetail = `/maps/${id}`;
                break;
              case NodeType.REGULAR:
                linkDirectToDetail = `/library/folders/${id}`;
                break;
              default:
                linkDirectToDetail = `/library/folders/${id}`;
                break;
            }
          }

          return (
            <Link to={linkDirectToDetail}>
              <span className="flex items-center">
                <TreeNodeIcon className="mr-4 min-w-5" nodeType={info.row?.original?.type as NodeType} />
                <span className="text-sm font-normal">{info.getValue()}</span>
              </span>
            </Link>
          );
        }
      })
    ];

    const updatedAtColumn = columnHelper.accessor('updatedAt', {
      header: () => <span className="font-medium">Last Modified</span>,
      meta: {
        className: 'w-[216px]'
      },
      cell: (info) => {
        const updatedAt = info.row?.original?.updatedAt;
        if (!updatedAt) return <></>;

        return <span className="text-sm font-normal">{format(new Date(updatedAt), 'dd/MM/yyyy')}</span>;
      }
    });

    const actionColumn = columnHelper.accessor('id', {
      header: () => <span className="font-medium"></span>,
      meta: {
        className: 'w-[64px]'
      },
      cell: (info) => {
        return (
          <LibraryAction
            libraryNode={info.row?.original}
            onRename={() => {
              onRename?.(info.row?.original);
            }}
            onDelete={() => {
              onDelete?.(info.row?.original);
            }}
          />
        );
      }
    });

    if (isMobile) {
      return [...defaultColumns, actionColumn];
    }

    return [...defaultColumns, updatedAtColumn, actionColumn];
  }, [onRename, onDelete, isMobile]);

  return <Table data={data} columns={columns} />;
};
