import { CellContext } from '@tanstack/react-table';
import clsx from 'clsx';
export const RowSpanCell = ({ getValue, row: { index }, column: { id, columnDef }, table }: CellContext<any, any>) => {
  const initialValue = getValue() as any;
  const getRowSpan = () => {
    const data = table.options.data;
    const rowSpans = [...data].filter((e) => e[id] === initialValue);
    if (rowSpans.length > 1 && index !== 0) {
      const current = data[index];
      const previous = data[index - 1];
      if (current[id] === previous[id]) {
        return -1;
      }
    }
    return rowSpans.length;
  };

  const rowSpan = getRowSpan();
  if (rowSpan < 0) return null;

  const meta = columnDef.meta as any;
  const width = meta?.width || 0;
  const tdClass = meta?.tdClass;
  if (meta.pinning) {
    return (
      <td className={clsx('header-sticky', tdClass)} style={{ left: `${width}px` }} rowSpan={rowSpan}>
        {initialValue}
      </td>
    );
  }
  return <td rowSpan={rowSpan}>{initialValue}</td>;
};
