import clsx from 'clsx';
import { ArrowRightIcon } from 'components/icons';
import { MouseEventHandler, useEffect } from 'react';
import { NodeRendererProps } from 'react-arborist';
import { useFormContext } from 'react-hook-form';
import { AssignmentTreeNote, NodeType } from 'types';

const ToggleButton = ({
  isLeaf,
  isOpen,
  onClick
}: {
  isLeaf: boolean;
  isOpen: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) => {
  if (isLeaf) {
    return (
      <button onClick={onClick} type="button" title="Expand">
        <ArrowRightIcon
          className={clsx('w-6', {
            'rotate-90': isOpen
          })}
        />
      </button>
    );
  }

  return <div className="w-6"></div>;
};

export const AssignmentTreeItem = (props: NodeRendererProps<AssignmentTreeNote>) => {
  const { register, setValue } = useFormContext();
  const { node, tree, style } = props;
  const { id, name, shortName, type, selected, children } = node.data;
  const { isOpen } = node;
  const isLeaf = children.length > 0 || type === NodeType.CUSTOM;

  const onToggleClick = () => {
    const nodeId = node?.data.id;
    tree.toggle(nodeId);
  };

  useEffect(() => {
    setValue(node.id, selected);
  }, [node.id, selected, setValue]);

  if (type === NodeType.COURSE || type === NodeType.REGULAR) {
    return (
      <form className="flex items-center" style={style} title={name}>
        <ToggleButton isLeaf={isLeaf} isOpen={isOpen} onClick={onToggleClick} />
        <input
          id={`checkbox-${id}`}
          type="checkbox"
          {...register(`${node.id}`)}
          className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 cursor-pointer"
        />
        <label htmlFor={`checkbox-${id}`} className="ml-2 text-sm font-normal !text-gray-900 cursor-pointer">
          {shortName || name}
        </label>
      </form>
    );
  }

  return (
    <div className="flex items-center" style={style} onClick={onToggleClick}>
      <ToggleButton isLeaf={isLeaf} isOpen={isOpen} />
      <label className="text-sm font-medium !text-gray-900 cursor-pointer" title={name}>
        {shortName || name}
      </label>
    </div>
  );
};
