import clsx from 'clsx';

import { NodeRendererProps } from 'react-arborist';
import { ArrowRightIcon } from 'components/icons';
import { NodeType, SidebarTreeNode } from 'types';
import { NavLink, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'hooks';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { MouseEventHandler, useRef } from 'react';
import { TreeNodeIcon } from './TreeNodeIcon';

import {
  INovuThemePopoverProvider,
  NovuProvider,
  PopoverNotificationCenter,
  ButtonTypeEnum
} from '@novu/notification-center';

function ToggleButton({
  isLeaf,
  isOpen,
  onClick
}: {
  isLeaf: boolean;
  isOpen: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) {
  if (isLeaf) {
    return (
      <button onClick={onClick} type="button" title="Expand">
        <ArrowRightIcon
          className={clsx('w-6', {
            'rotate-90': isOpen
          })}
        />
      </button>
    );
  }

  return <div className="w-6"></div>;
}

export const TreeNode = observer((props: NodeRendererProps<SidebarTreeNode>) => {
  const navigate = useNavigate();
  const { uiStore, authStore } = useStores();
  const { isNavbarCollapsed } = uiStore;
  const { isTabletAndDown } = useMediaQuery();
  const notificationItem = useRef<HTMLDivElement>(null);

  const { node, tree, style } = props;
  const { id, name, shortName, icon, linkTo, onClick, childrenCount, type } = node.data;
  const path = linkTo || `/library/folders/${id}`;

  const isOpen = !!node.isOpen;
  const isLeaf = (childrenCount && childrenCount > 0) as boolean;

  const onNavItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      event.preventDefault();
      onClick(event);
    }
    // Close nav on tablet and down
    if (isTabletAndDown) {
      uiStore.collapseNav();
      tree.close('my_library');
    }
  };

  const onToggleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    const nodeId = node?.data.id;
    tree.toggle(nodeId);

    // When expand a nested menu item -> expand nav bar
    uiStore.expandNav();
  };

  const StandardNavLink = ({ unseenCount = 0, notificationItem }: { unseenCount?: number; notificationItem?: any }) => {
    const unseenValue = unseenCount >= 100 ? '99+' : unseenCount;

    return (
      <NavLink
        onClick={onNavItemClick}
        to={path}
        title={name}
        className={clsx(
          'flex items-center w-full h-12 justify-between rounded-tl-lg rounded-bl-lg pr-4 hover:bg-primary-100  hover:text-primary-500 duration-300 whitespace-nowrap relative cursor-pointer',
          {
            'text-primary-500': node.isSelected,
            'text-gray-700': !node.isSelected
          }
        )}
        ref={notificationItem}
        style={style}
      >
        <div className={clsx('flex items-center', isNavbarCollapsed && 'justify-center')}>
          <ToggleButton isLeaf={isLeaf} isOpen={isOpen} onClick={onToggleClick} />
          <div className="w-6 h-6 flex items-center justify-center">{<TreeNodeIcon icon={icon} nodeType={type} />}</div>

          {!isNavbarCollapsed && <span className="ml-2 text-sm font-medium">{shortName || name}</span>}
        </div>

        {unseenCount > 0 && (
          <span
            className={clsx(
              'flex items-center justify-center min-w-4 px-1 bg-red-400 text-white rounded-lg text-xs font-medium',
              isNavbarCollapsed && 'absolute top-1 right-1 text-8 leading-normal'
            )}
          >
            {unseenValue}
          </span>
        )}
      </NavLink>
    );
  };

  const onNotificationClick = (notification: any) => {
    let url = '';
    switch (notification.templateIdentifier) {
      case 'create-map':
        url = `/maps/${notification.payload.metadata.map_id}`;
        break;
      case 'create-folder':
      case 'add-unit-to-library':
      case 'add-course-to-library':
      case 'add-rto-to-library':
        url = `/library/folders/${notification.payload.metadata.folder_id}`;
        break;
    }
    notificationItem.current?.click();
    if (url !== '') navigate(url);
  };

  const onActionClick = (templateIdentifier: string, _type: ButtonTypeEnum, notification: any) => {
    let url = '';

    switch (templateIdentifier) {
      case 'migrate-map':
        url = `/units/${notification.payload.metadata.unit_code}/migrate?planId=${notification.payload.metadata.plan_id}`;
        break;
    }

    notificationItem.current?.click();
    if (url !== '') navigate(url);
  };

  const notificationNavLink = () => {
    const theme: INovuThemePopoverProvider = {
      light: {
        notificationItem: {
          unread: { notificationItemBeforeBrandColor: '#E346D1' },
          buttons: {
            primary: {
              backGroundColor: '#18ff98',
              fontColor: '#212529',
              removeCircleColor: ''
            },
            secondary: { backGroundColor: '', fontColor: '', removeCircleColor: '' },
            clicked: { backGroundColor: '', fontColor: '#828299', removeCircleColor: '' }
          }
        }
      }
    };

    return (
      <NovuProvider
        subscriberId={`${uiStore.currentWorkspaceId}_${authStore.user?.id}` || '0'}
        applicationIdentifier={process.env.REACT_APP_NOVU_APPLICATION_ID || ''}
      >
        <PopoverNotificationCenter
          onNotificationClick={onNotificationClick}
          onActionClick={onActionClick}
          colorScheme={'light'}
          position="right-start"
          showUserPreferences={false}
          footer={() => <></>}
          theme={theme}
        >
          {({ unseenCount }) => <StandardNavLink notificationItem={notificationItem} unseenCount={unseenCount ?? 0} />}
        </PopoverNotificationCenter>
      </NovuProvider>
    );
  };

  return type === NodeType.NOTIFICATION ? notificationNavLink() : <StandardNavLink />;
});
