import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchCourseDetail, fetchLibraryDetail } from 'api';
import clsx from 'clsx';
import { NotAvailable } from 'components/icons';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { Configuration, CourseConfigurationHeader, UnitSelect } from 'components/library/course';
import { ErrorResponse, Course as ICourse, UnitItem, Library, LibraryFolder } from 'types';
import { useEffect } from 'react';

type Props = {
  className?: string;
  code: string;
  folderId: string;
  library?: Library;
  type?: 'Page' | 'Modal';
  onCancel?: () => void;
  onSave?: (folder: LibraryFolder) => void;
};

export const CourseConfigurationContainer = ({
  code,
  folderId,
  className,
  library,
  type = 'Page',
  onCancel,
  onSave
}: Props) => {
  const { isMobile } = useMediaQuery();
  const queryClient = useQueryClient();
  const { data: folder = {} as Library, isLoading: isLibraryLoading } = useQuery(
    ['library', 'courses', folderId],
    () => {
      if (folderId === '0') {
        return {
          id: '0',
          children: new Array<Library>()
        } as Library;
      }

      return fetchLibraryDetail(folderId as string);
    },
    {
      enabled: !!folderId,
      staleTime: 300000
    }
  );

  useEffect(() => {
    if (folderId === '0' && library) {
      queryClient.setQueryData(['library', 'courses', folderId], library);
    }
  }, [library, folderId, queryClient]);

  const { data: course = {} as ICourse, isLoading } = useQuery<ICourse, ErrorResponse>(
    ['course', code],
    () => fetchCourseDetail(code as string),
    {
      enabled: !!code,
      staleTime: 300000
    }
  );

  const selectedUnits =
    folder.children
      ?.filter((item) => !item._destroy)
      .map((item) => {
        return {
          id: item.sourceId?.toString(),
          code: item.source.code,
          title: item.source.title,
          usage_recommendation: item.source.usageRecommendation,
          type: item.metadata.type
        } as UnitItem;
      }) || [];

  const units =
    course.units?.map((unit) => {
      return {
        id: unit.id.toString(),
        code: unit.code,
        title: unit.title,
        usage_recommendation: unit.usageRecommendation,
        type: unit.type
      } as UnitItem;
    }) || [];

  if (isMobile) {
    return (
      <div className="mapping flex flex-col h-full">
        <div className="flex flex-col items-center pt-24">
          <NotAvailable className="mb-8" />
          <div className="font-medium text-sm text-gray-900">
            Course Configuration is not available on mobile devices.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx('flex flex-col h-screen w-full', className)}>
      <CourseConfigurationHeader
        {...{
          className: clsx(type === 'Modal' && '!bg-transparent'),
          name: folderId !== '0' ? folder.name : `${course.code} - ${course.title}`,
          folderId,
          courseId: course.id,
          onCancel,
          onSave
        }}
      />
      <div className="flex px-8 py-6 gap-4 flex-1 h-1">
        <Configuration
          {...{ units: selectedUnits, isLoading: isLoading || isLibraryLoading, folderId }}
          className="basis-3/5 flex-1"
        />
        <UnitSelect
          {...{ defaultUnits: units, selectedUnits, folderId, isLoading: isLibraryLoading }}
          className="basis-2/5 flex-1"
        />
      </div>
    </div>
  );
};
