import React from 'react';
import { ReactSVG } from 'react-svg';
import UnitInReviewSVG from '../../assets/svg/validate.svg';

export const UnitInReview = () => {
  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center mt-[100px]">
        <ReactSVG src={UnitInReviewSVG} className="mb-4" />
        <p className="mb-8 font-medium text-sm text-gray-900">
          This unit is new and being reviewed by the Magic Map team and will be made available shortly.
        </p>
      </div>
    </div>
  );
};
