import { Button } from 'components/buttons';
import Select, { StylesConfig } from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import { PreviousMap } from 'types';
import { useMemo } from 'react';

const colourStyles: StylesConfig<any> = {
  input: (styles) => ({ ...styles, ...{ boxShadow: 'none' } }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  clearIndicator: (styles) => ({ ...styles, cursor: 'pointer' }),
  indicatorsContainer: (styles) => ({ ...styles, cursor: 'pointer' })
};

type Props = {
  unitCode?: string;
  maps: PreviousMap[];
  onSubmit: ({ mapIds }: { mapIds: string[]; next?: () => void }) => void;
};
export const MigrationFooter = ({ unitCode, onSubmit, maps }: Props) => {
  const {
    getValues,
    control,
    formState: { isValid }
  } = useForm({ mode: 'onChange' });

  const options = useMemo(() => {
    return maps.map((x) => {
      return {
        value: x.id?.toString() || '',
        label: x.name || ''
      };
    });
  }, [maps]);

  if (!unitCode) {
    return <></>;
  }

  const submit = (next?: () => void) => {
    onSubmit({ mapIds: getValues().mapIds.map((x: any) => x.value), next });
  };

  return (
    <form className="sticky text-sm border-t bottom-0 left-0 right-0 py-4 px-8 flex justify-between items-end">
      <div className="flex flex-col">
        <label htmlFor="selectMap" className="mb-1 text-sm font-medium">
          Select the maps you wish to duplicate & auto-populate with the new version.
        </label>
        <Controller
          name="mapIds"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              className="multi-select mr-5"
              styles={colourStyles}
              ref={field.ref}
              onChange={field.onChange}
              onBlur={field.onBlur}
              menuPlacement="top"
              closeMenuOnSelect={false}
              defaultValue={field.value}
              isMulti
              options={options as any}
              placeholder="Select maps"
            />
          )}
        />
      </div>
      <div className="flex flex-col min-w-[350px]">
        <Button
          onClick={submit}
          progress={true}
          type="button"
          disabled={!isValid}
          className="btn-primary mb-2 disabled:hover:bg-purple-100"
        >
          Duplicate and create new maps
        </Button>
        <span className="text-sm italic">* Duplication does not overwrite your existing maps.</span>
      </div>
    </form>
  );
};
