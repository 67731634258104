import clsx from 'clsx';
import { Button } from 'components/buttons';
import { UnitItem as Unit } from 'types';
import './index.scss';

type Props = {
  className?: string;
  onAdd?: (unit: Partial<Unit>) => void;
  onRemove?: (unit: Partial<Unit>) => void;
  unit: Partial<Unit>;
};

export const UnitItem = ({ className, onAdd, onRemove, unit }: Props) => {
  return (
    <div
      className={clsx(className, 'flex justify-between items-center py-2 px-4 border-b border-b-gray-200 unit-item')}
    >
      <div className="flex flex-col mr-4">
        <div className="flex items-center">
          <h5 className="text-gray-900 text-base font-semibold">{unit.code}</h5>
          <span
            className={clsx(
              unit.usage_recommendation === 'Current' && 'text-green-600',
              unit.usage_recommendation === 'Superseded' && 'text-red-600',
              'ml-4 text-xs font-medium'
            )}
          >
            {unit.usage_recommendation}
          </span>
          <span className="ml-4 text-xs font-medium text-gray-600">{unit.type}</span>
        </div>
        <p className="text-sm font-normal text-gray-900">{unit.title}</p>
      </div>
      {onAdd && (
        <Button
          className="text-sm text-gray-900 font-medium bg-green-100 py-1.5 px-8 rounded-md"
          onClick={() => onAdd(unit)}
        >
          Add
        </Button>
      )}
      {onRemove && (
        <Button
          className="text-sm text-gray-900 bg-gray-100 py-1.5 px-4 rounded-md font-medium"
          onClick={() => onRemove(unit)}
        >
          Remove
        </Button>
      )}
    </div>
  );
};
