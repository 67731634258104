import { useQueryClient } from '@tanstack/react-query';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { useStores } from 'hooks';
import { observer, useLocalObservable } from 'mobx-react';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import SubscriptionControl from './SubscriptionControl';
import SubscriptionExpiredMenu from './SubscriptionMenu';

interface Options {
  value: string | number;
  label: string;
}

const SubscriptionExpiredSelect = observer(() => {
  const { uiStore } = useStores();

  const switchWorkspaces = useLocalObservable(() => ({
    isShow: false,
    toggleMenu() {
      this.isShow = !this.isShow;
    },
    setIsShow(value: boolean) {
      this.isShow = value;
    }
  }));

  const { workspaces, currentWorkspaceId } = uiStore;

  const options: Options[] = useMemo(() => {
    const newArray = workspaces.map(({ id, name }) => ({ value: id, label: name }));
    return newArray;
  }, [workspaces]);

  const selectedWorkspace = useMemo(
    () => options.find((option) => option.value === currentWorkspaceId),
    [currentWorkspaceId, options]
  );

  const IndicatorSeparator = () => null;

  const queryClient = useQueryClient();

  const { isMobile } = useMediaQuery();

  const navigate = useNavigate();

  const handleClickSwitchWorkspace = async (workspaceId: number) => {
    await uiStore.switchWorkspace(workspaceId);
    queryClient.invalidateQueries();
    uiStore.toggleUserWorkspace(false);
    toast(`Your current workspace: ${uiStore.currentWorkspace?.name}.`, {
      type: 'success'
    });
    if (isMobile) {
      uiStore.collapseNav();
    }
    navigate({
      pathname: '/library'
    });
    navigate(0);
  };

  return (
    <ReactSelect
      value={selectedWorkspace}
      options={options}
      components={{ IndicatorSeparator, Control: SubscriptionControl, Menu: SubscriptionExpiredMenu }}
      isSearchable={false}
      placeholder=""
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          border: '1px solid #E7EBEF',
          borderRadius: '6px',
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid #E7EBEF'
          }
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          border: '1px solid #E7EBEF',
          borderRadius: '6px',
          maxHeight: '300px',
          overflow: 'scroll',
          '@media only screen and (max-width: 1200px)': {
            maxHeight: '200px'
          }
        })
      }}
      menuIsOpen={switchWorkspaces.isShow}
      // @ts-ignore
      switchWorkspaces={switchWorkspaces}
      handleClickSwitchWorkspace={handleClickSwitchWorkspace}
    />
  );
});

export default SubscriptionExpiredSelect;
