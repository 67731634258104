import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Modal, ModalProps } from 'flowbite-react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { inviteNewMember } from 'api';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/buttons';
import { useStores } from 'hooks';
import clsx from 'clsx';
import { useEffect } from 'react';

interface IFormInputs {
  email: string;
}

const schema = yup
  .object({
    email: yup.string().required("Email can't be blank.").email('Please use a valid email address.')
  })
  .required();

export const InviteMemberModal = (props: ModalProps) => {
  const { manageUsersStore } = useStores();
  const {
    register,
    handleSubmit,
    reset,
    setFocus,
    clearErrors,
    formState: { errors }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (props.show) {
      setFocus('email');
    }
  }, [props.show, setFocus]);

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(inviteNewMember, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['members']);
      queryClient.invalidateQueries(['workspaces', 'subscription', 'current']);
      toast('You have invited a member to your workspace.', {
        type: 'success'
      });
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  const handleClose = () => {
    clearErrors(['email']);
    reset();
    manageUsersStore.closeModal();
  };

  return (
    <Modal {...props}>
      <div className="p-4 flex justify-between title w-[344px] md:w-[648px]">
        <h4 className="text-base font-semibold text-gray-900">Invite New User</h4>
        <Button
          disabled={isLoading}
          className="ml-auto bg-white border-0 text-gray-700 opacity-100 float-right text-3xl leading-none font-semibold outline-none focus:outline-none max-w-[24px] max-h-[24px] rounded"
          onClick={() => handleClose()}
        >
          <XMarkIcon
            className={clsx(
              !isLoading && 'hover:rotate-90 duration-300 hover:text-primary-500',
              'text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none'
            )}
          />
        </Button>
      </div>
      <div className="p-4 pt-0">
        <form
          className="text-left"
          onSubmit={handleSubmit((value) => {
            mutate(value.email);
          })}
        >
          <div className="form-group mb-4">
            <label className="text-sm font-medium text-gray-900">Email</label>
            <input
              {...register('email')}
              type="text"
              placeholder="Email"
              className={`form-control !text-sm !text-gray-900 ${errors.email ? 'invalid' : ''}`}
            />
            <p className="feedback-invalid text-sm">{errors.email?.message}</p>
          </div>
          <div className="flex justify-end space-x-4">
            <Button
              disabled={isLoading}
              className="btn-primary text-sm font-medium bg-white text-gray-900 hover:bg-gray-200 disabled:bg-white disabled:hover:bg-white mr-2"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={isLoading}
              className="btn-primary font-normal disabled:hover:bg-purple-100"
            >
              Invite
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
