import { ReactSVG } from 'react-svg';
import RTOImgSvg from '../../assets/svg/rto-img.svg';

type Props = {
  className?: string;
};

export const RTOImg = ({ className }: Props) => {
  return <ReactSVG src={RTOImgSvg} className={className} />;
};
