import { deleteMap } from 'api';
import { Modal, ModalProps } from 'flowbite-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/buttons';
import { useStores } from 'hooks';
import { Map } from 'types';
import { useParams, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

type Props = {
  handleCallback?: () => void;
} & ModalProps;

export const DeleteMapModal = (props: Props) => {
  const { id } = useParams();
  const { mapUIStore } = useStores();
  const { currentMap } = mapUIStore;
  const queryClient = useQueryClient();
  const [, setSearchParams] = useSearchParams();

  const { mutate, isLoading } = useMutation(deleteMap, {
    onSuccess: () => {
      setSearchParams({ page: '1' });
      queryClient.invalidateQueries(['unit', id, 'maps']);
      toast(`${currentMap?.name || 'Untitled'} has been deleted successfully.`, {
        type: 'success'
      });
      props.handleCallback && props.handleCallback();
      mapUIStore.closeModal();
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  return (
    <Modal {...props}>
      <div className="p-4">
        <div className="flex justify-between title">
          <p className="text-sm font-medium text-gray-900">Delete forever?</p>
          <Button
            disabled={isLoading}
            className="ml-auto bg-white border-0 text-gray-700 opacity-100 float-right text-3xl leading-none font-semibold outline-none focus:outline-none max-w-[24px] max-h-[24px] rounded"
            onClick={() => mapUIStore.closeModal()}
          >
            <XMarkIcon
              className={clsx(
                !isLoading && 'hover:rotate-90 duration-300 hover:text-primary-500',
                'text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none'
              )}
            />
          </Button>
        </div>
        <div className="mt-2 content">
          <p className="text-sm font-normal text-gray-700">
            "{currentMap?.name || 'Untitled'}" and all data associated with it will be deleted forever and you won't be
            able to restore it.
          </p>
        </div>
        <div className="mt-4 action flex justify-end space-x-4">
          <Button
            disabled={isLoading}
            className="btn-primary text-sm font-medium bg-white text-gray-900 hover:bg-gray-200 disabled:bg-white disabled:hover:bg-white"
            onClick={() => mapUIStore.closeModal()}
          >
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            className="btn-primary font-medium justify-center outline-0 text-center disabled:hover:bg-purple-100"
            onClick={() => {
              mutate((currentMap as Map).id);
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};
