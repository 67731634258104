import { ColumnSizingState } from '@tanstack/react-table';
import { NodeType } from './sidebar-tree';

export interface Folder {
  id: number;
  name: string;
}

export interface Rto {
  id: number;
  code: string;
  name: string;
}

export interface Map {
  id: number;
  name: string;
  releaseNumber: string;
  updatedAt: Date;
  comment: string;
  folders: Folder[];
  rtos: Rto[];
}

export interface Mapping {
  id: number;
  code: string;
  title: string;
  name: string;
  comment?: string;
  usageRecommendation: string;
  releaseNumber: string;
  updatedAt: Date;
  mapVersion: string;
  assessmentTasks: AssessmentTask[];
  elements: MappingElement[];
  performanceEvidenceNumbering: boolean;
  knowledgeEvidenceNumbering: boolean;
  foundationSkillNumbering: boolean;
  assessmentConditionNumbering: boolean;
  foundationSkills: MappingFoundationSkill[];
  performanceEvidences: AssessmentCondition[];
  knowledgeEvidences: AssessmentCondition[];
  assessmentConditions: AssessmentCondition[];
  trafficMode?: boolean;
  folderIds?: string[];
  columnSizing?: {
    [x: string]: ColumnSizingState;
  };
}

export interface MappingElement {
  id: number;
  level: string;
  content: string;
  performanceCriteria: Content;
  [key: string]: AssessmentTaskDetails | any;
}

export interface MappingFoundationSkill {
  id: number;
  kind: string;
  skill: string;
  performanceCriteria: string[] | any;
  foundationContent: Content;
  [key: string]: AssessmentTaskDetails | any;
}
export interface Content {
  id: number;
  ancestry?: any;
  content: string;
  level?: string;
  ancestryDepth?: number;
  displayOrder?: any;
  [key: string]: any;
}

export interface AssessmentCondition {
  content: Content;
  [key: string]: AssessmentTaskDetails | any;
}

export interface AssessmentTaskDetails {
  id: number;
  references: string;
  status: 'typing' | 'prepare';
  elementableType: 'CompetencyEvidence' | 'FoundationContent' | 'PerformanceCriterion' | string;
  elementableId: number;
  assessmentTaskId?: number;
}

export interface AssessmentTask {
  id: number;
  task: string;
  columnNumber: any;
}
export interface NewMapRequest {
  name: string;
  unit: string;
  templateId?: string;
  folderIds?: string[];
}

export interface UpdateMapRequest {
  id: string;
  name?: string;
  comment?: string;
  trafficMode?: boolean;
  performanceEvidenceNumbering?: boolean;
  knowledgeEvidenceNumbering?: boolean;
  foundationSkillNumbering?: boolean;
  assessmentConditionNumbering?: boolean;
  folderIds?: string[];
  columnSizing?: {
    [x: string]: ColumnSizingState;
  };
}

export interface UpdateCellRequest {
  mapId: number;
  cellId: number;
  references: string;
}

export interface CreateCellRequest {
  mapId: number;
  assessmentTaskId: number;
  elementableType: string;
  elementableId: number;
  references: string;
}

export interface CreateColumnRequest {
  mapId: number;
  task: string;
}

export interface UpdateColumnRequest {
  mapId: number;
  assessmentTaskId: number;
  task: string;
}

export interface DeleteColumnRequest {
  mapId: number;
  assessmentTaskId: number;
}

export enum CompetencyEvidenceType {
  PerformanceEvidence,
  KnowledgeEvidence,
  AssessmentCondition
}

export interface AssignmentTreeNote {
  id: string;
  name: string;
  shortName: string;
  ancestry: string;
  ancestryDepth: number;
  type: NodeType;
  selected?: boolean;
  children: AssignmentTreeNote[];
}

export interface MapStatus {
  unitCode: string;
  unitTitle: string;
  currentReleaseNumber: string;
  newRelease?: NewRelease;
  reverseMappings: ReverseMapping[];
}

export interface NewRelease {
  planId?: number;
  latestReleaseNumber?: string;
  latestReleaseId?: number;
  linkPreviewNewRelease?: string;
}

export interface ReverseMapping {
  linkCheckoutSuperseded: string;
  isEquivalent: boolean;
  reverseMappingCode: string;
  reverseMappingTitle: string;
}
