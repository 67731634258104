import { useQuery } from '@tanstack/react-query';
import { fetchWorkspace } from 'api';
import clsx from 'clsx';
import { Avatar } from 'components/avatar';
import { useStores } from 'hooks';
import { observer } from 'mobx-react';
import { ErrorResponse, Workspace } from 'types';

export const NavbarProfileWorkspace = observer(() => {
  const { uiStore, authStore } = useStores();
  const { isNavbarCollapsed, currentWorkspace } = uiStore;

  useQuery<Workspace[], ErrorResponse>(['workspaces'], fetchWorkspace, {
    staleTime: 300000,
    onSuccess: (data) => {
      uiStore.updateWorkspaces(data);
    }
  });

  return (
    <div className={clsx('flex items-center', isNavbarCollapsed && 'w-full flex-col')}>
      {currentWorkspace ? (
        <>
          <Avatar label={currentWorkspace.name} size={36} className="border-2 border-white rounded-full" />
          <Avatar
            label={authStore.user?.fullName}
            src={authStore.user?.avatarUrl}
            size={36}
            className={clsx(
              'border-2 border-white rounded-full text-color-orange',
              isNavbarCollapsed ? '-mt-3' : '-ml-3'
            )}
            bgColor="bg-orange-100"
            txtColor="text-orange-6"
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
});
