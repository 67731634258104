import { useMutation, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { Loading } from 'components/loading';
import { useStores } from 'hooks';
import { SearchParams } from 'meilisearch';
import { useEffect, useState } from 'react';
import { LibraryFolder, UnitItem as Unit } from 'types';
import { UnitItem } from './UnitItem';
import SimpleBar from 'simplebar-react';
import { SearchBox } from 'components/search-box';

type Props = {
  className?: string;
  defaultUnits: Unit[];
  selectedUnits: Unit[];
  isLoading?: boolean;
  folderId: string;
};

const UnitList = ({ units, folderId }: { units: Unit[]; folderId: string }) => {
  const queryClient = useQueryClient();

  const addUnit = (unit: Partial<Unit>) => {
    queryClient.setQueryData<LibraryFolder>(['library', 'courses', folderId], (library) => {
      if (!library) {
        return library;
      }
      const { children } = library;
      const newChildren = children ? [...children] : [];

      const addIndex = newChildren.findIndex((x) => x.sourceId === parseInt(unit.id!));
      const addItem = { ...newChildren[addIndex] };

      if (addItem.id) {
        addItem._destroy = false;
        newChildren.splice(addIndex, 1, addItem);
      } else {
        newChildren?.unshift({
          name: `${unit.code} - ${unit.title}` || '',
          sourceId: parseInt(unit.id || ''),
          sourceType: 'Nrt',
          source: { ...unit, usageRecommendation: unit.usage_recommendation },
          metadata: { type: unit.type },
          children: []
        });
      }

      return {
        ...library,
        children: [...newChildren]
      };
    });
  };

  return (
    <SimpleBar autoHide={true} className="flex-1 h-1">
      <div className="flex flex-col">
        {units.map((unit, index) => (
          <UnitItem key={index} unit={unit} onAdd={addUnit} />
        ))}
      </div>
    </SimpleBar>
  );
};

export const UnitSelect = ({ className, defaultUnits, selectedUnits, folderId, isLoading }: Props) => {
  const { uiStore } = useStores();
  const { search } = uiStore;
  const [searchUnits, setSearchUnits] = useState<Unit[]>([]);
  const [units, setUnits] = useState<Unit[]>(defaultUnits);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    const filterUnits = keyword ? searchUnits : defaultUnits;
    const displayUnits = filterUnits.filter((item) => selectedUnits.findIndex((select) => select.id === item.id) < 0);
    setUnits(displayUnits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultUnits, selectedUnits, searchUnits]);

  const searchUnit = (params: SearchParams) => {
    if (!params.q) {
      return Promise.resolve([]);
    }

    return search('Unit', params).then((hits: Unit[]) => {
      if (!hits) {
        return [];
      }
      return hits.filter((item) => selectedUnits.findIndex((select) => select.id === item.id) < 0);
    });
  };

  const { mutate, isLoading: isSearchUnitLoading } = useMutation(searchUnit, {
    onSuccess: (units: Unit[]) => {
      setSearchUnits(units);
    }
  });

  const changeKeyword = (keyword: string, superseded?: boolean) => {
    setKeyword(keyword);

    if (!keyword) {
      const displayUnits = defaultUnits.filter(
        (item) => selectedUnits.findIndex((select) => select.id === item.id) < 0
      );
      setUnits(displayUnits);
      return;
    }

    const params: SearchParams = {
      q: keyword,
      limit: 10,
      attributesToHighlight: ['*'],
      filter: 'usage_recommendation = "Current"'
    };

    if (superseded) {
      delete params.filter;
    }

    mutate(params);
  };

  return (
    <div className={clsx(className, 'border rounded-lg bg-gray-100 shadow p-4 flex flex-col')}>
      <div className="text-xs text-primary-500 leading-5 font-semibold mb-3 uppercase">
        Select units below to add to your course
      </div>
      <SearchBox
        id="unit-search-box-select"
        superseded={true}
        onChange={changeKeyword}
        placeholder="Search for units"
      />
      {!(isLoading || isSearchUnitLoading) && <UnitList {...{ units, folderId }} />}
      {(isLoading || isSearchUnitLoading) && (
        <div className="min-h-[200px] relative">
          <Loading />
        </div>
      )}
    </div>
  );
};
