export function getInitials(fullName: string | undefined) {
  if (!fullName) {
    return '';
  }
  const allNames = fullName.trim().split(' ');

  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }

    return acc;
  }, '');

  return initials;
}

export function fullName(firstName: string, surname: string) {
  if (!firstName && !surname) {
    return null;
  }

  if (!firstName) {
    return surname.trim();
  }

  return `${firstName} ${surname}`.trim();
}

export const pluralize = (count: number, noun: string, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;
