import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, {
  Connection,
  Edge,
  EdgeChange,
  MarkerType,
  Node,
  PanOnScrollMode,
  addEdge,
  applyEdgeChanges,
  updateEdge
} from 'reactflow';
import { MigrationNode, MigrationNodeSource, MigrationNodeTarget } from 'components/migration';
import { Release, Element, FoundationSkill, Section, MigrationPlan, ElementType, Unit } from 'types';
import { uniqBy, maxBy } from 'lodash';
import { format, parseISO } from 'date-fns';
import { MigrationDiff } from './MigrationDiff';
import { getVirtualHeight } from 'utils/getVirtualHeight';
import { isHtmlTag } from 'utils/htmlTag';

const PADDING = 32;
const GAP = 16;
const titleHeight = 46;
const nodeHeight = 66;
const nodeTypes = { source: MigrationNodeSource, target: MigrationNodeTarget, label: MigrationNode };
const proOptions = { hideAttribution: true };

type NodeData = {
  id?: string;
  type: 'source' | 'target' | 'label';
  level?: string;
  style?: React.CSSProperties;
  className?: string;
  color?: string;
  styleLevel?: number;
  height?: number;
  label?: string;
};

type Props = {
  clientWidth: number;
  previousRelease?: Release;
  currentRelease?: Release;
  plans?: MigrationPlan[];
  onPlanChange: (plans: MigrationPlan[]) => void;
  currentUnit?: Partial<Unit>;
  previousUnit?: Partial<Unit>;
};

export const MigrationSection = ({
  clientWidth,
  previousRelease,
  currentRelease,
  plans,
  onPlanChange,
  currentUnit,
  previousUnit
}: Props) => {
  const sectionHeight = {
    element: 0,
    foundation_skill: 0,
    performance_evidence: 0,
    knowledge_evidence: 0,
    assessment_condition: 0
  } as any;

  const mappingNode = (item: any, data: NodeData = { type: 'label', styleLevel: 0 }): Node<NodeData> => {
    data.type = data.type || 'label';
    data.styleLevel = data.styleLevel || 0;
    return {
      id: item.id?.toString(),
      type: data.type,
      data: {
        ...data,
        label: item.content,
        level: item.level
      }
    } as Node<NodeData>;
  };
  const mappingElement = (elements: Element[], type: 'source' | 'target') => {
    let sources: any[] = [];
    elements.forEach((item) => {
      sources.push(
        mappingNode(
          {
            ...item,
            content: `<div>Element ${item.level}</div> <div>${item.content}</div>`,
            id: `element-label-${item.id}`
          },
          { type: 'label', color: 'primary', height: nodeHeight + 2 }
        )
      );
      sources = sources.concat(
        item.performanceCriterions?.map((subItem) => {
          return mappingNode(
            { ...subItem, id: `element-${type}-${subItem.id}` },
            { type, color: 'primary', className: 'element' }
          );
        })
      );
    });
    return sources;
  };
  const mappingFoundationSkills = (items: FoundationSkill[], type: 'source' | 'target') => {
    return items
      .map((item) => {
        return item.foundationContents.map((subItem) => {
          return mappingNode(
            { ...subItem, id: `foundation_skill-${type}-${subItem.id}`, level: '' },
            { type, color: 'orange', className: 'foundation_skill' }
          );
        });
      })
      .flat();
  };
  const mappingOtherType = (items: Section[], type: 'source' | 'target', section: ElementType, level = -1): any[] => {
    level = level + 1;
    return items
      .map((item) => {
        const elementType = item.type || section;
        const currentNode = mappingNode(
          { ...item, id: `${elementType}-${type}-${item.id}`, level: '' },
          { type, styleLevel: level, className: section }
        );
        return [currentNode, ...mappingOtherType(item.children, type, section, level)];
      })
      .flat();
  };
  const mappingNodes = (release: Release, type: 'source' | 'target') => {
    const nodeWidth = (clientWidth - PADDING * 2 - GAP * 8) / 2;
    const elementNodes =
      previousRelease?.elements?.length || currentRelease?.elements?.length
        ? [
            {
              id: `element-release-${type}-${release.releaseNumber}`,
              type: 'label',
              data: {
                label: `${
                  currentUnit && previousUnit
                    ? `<div class='line-clamp-1'>${
                        type === 'source'
                          ? `${previousUnit.code} - ${previousUnit.title}`
                          : `${currentUnit.code} - ${currentUnit.title}`
                      } </div>`
                    : ``
                }Release Number ${release.releaseNumber} - ${format(
                  parseISO(release.releaseDate as string),
                  'dd/MM/yyyy'
                )}`,
                height: currentUnit ? nodeHeight : titleHeight,
                color: 'primary',
                className: 'text-center'
              }
            },
            ...mappingElement((release?.elements as Element[]) || [], type)
          ]
        : [];
    const foundationSkillNodes =
      previousRelease?.foundationSkills?.length || currentRelease?.foundationSkills?.length
        ? [
            {
              id: 'label-foundation_skill-' + type,
              type: 'label',
              data: {
                label: 'Foundation Skill',
                style:
                  type === 'source' ? { width: clientWidth - 2 * PADDING, textAlign: 'center' } : { display: 'none' },
                color: 'orange',
                height: titleHeight
              }
            } as Node<NodeData>,
            ...mappingFoundationSkills((release?.foundationSkills as FoundationSkill[]) || [], type)
          ]
        : [];
    const performanceEvidenceNodes =
      previousRelease?.performanceEvidences?.length || currentRelease?.performanceEvidences?.length
        ? [
            {
              id: 'label-performance_evidence-' + type,
              type: 'label',
              data: {
                label: 'Performance Evidence (or Required Skills)',
                style:
                  type === 'source' ? { width: clientWidth - 2 * PADDING, textAlign: 'center' } : { display: 'none' },
                color: 'blue',
                height: titleHeight
              }
            },
            ...mappingOtherType((release?.performanceEvidences as Section[]) || [], type, 'performance_evidence')
          ]
        : [];
    const knowledgeEvidenceNodes =
      previousRelease?.knowledgeEvidences?.length || currentRelease?.knowledgeEvidences?.length
        ? [
            {
              id: 'label-knowledge_evidence-' + type,
              type: 'label',
              data: {
                label: 'Knowledge Evidence (or Required Knowledge)',
                style:
                  type === 'source' ? { width: clientWidth - 2 * PADDING, textAlign: 'center' } : { display: 'none' },
                color: 'yellow',
                height: titleHeight
              }
            },
            ...mappingOtherType((release?.knowledgeEvidences as Section[]) || [], type, 'knowledge_evidence')
          ]
        : [];

    const assessmentConditionNodes =
      previousRelease?.assessmentConditions?.length || currentRelease?.assessmentConditions?.length
        ? [
            {
              id: 'label-assessment_condition-' + type,
              type: 'label',
              data: {
                label: 'Assessment Conditions',
                style:
                  type === 'source' ? { width: clientWidth - 2 * PADDING, textAlign: 'center' } : { display: 'none' },
                color: 'green',
                height: titleHeight
              }
            },
            ...mappingOtherType((release?.assessmentConditions as Section[]) || [], type, 'assessment_condition')
          ]
        : [];

    const calVirtualHeight = (current: Node<NodeData>) => {
      const isHtml = isHtmlTag(current.data.label!);
      const width = nodeWidth - PADDING * (current?.data?.styleLevel || 0);
      current.data!.height =
        current?.data?.height ||
        getVirtualHeight(`${isHtml ? '' : current.data?.level || ''}` + current?.data.label || '', width);
      return current;
    };
    const elementSection = elementNodes.reduce((accumulator: number, current: Node<NodeData>) => {
      current = calVirtualHeight(current);
      return accumulator + current.data!.height! + GAP;
    }, GAP);
    const foundationSkillSection = foundationSkillNodes.reduce((accumulator: number, current: Node<NodeData>) => {
      current = calVirtualHeight(current);
      return accumulator + current.data!.height! + GAP;
    }, 0);
    const performanceEvidenceSection = performanceEvidenceNodes.reduce(
      (accumulator: number, current: Node<NodeData>) => {
        current = calVirtualHeight(current);
        return accumulator + current.data!.height! + GAP;
      },
      0
    );

    const knowledgeEvidenceSection = knowledgeEvidenceNodes.reduce((accumulator: number, current: Node<NodeData>) => {
      current = calVirtualHeight(current);
      return accumulator + current.data!.height! + GAP;
    }, 0);

    const assessmentConditionSection = assessmentConditionNodes.reduce(
      (accumulator: number, current: Node<NodeData>) => {
        current = calVirtualHeight(current);
        return accumulator + current.data!.height! + GAP;
      },
      0
    );
    sectionHeight.element = sectionHeight.element < elementSection ? elementSection : sectionHeight.element;
    sectionHeight.foundation_skill =
      sectionHeight.foundation_skill < foundationSkillSection ? foundationSkillSection : sectionHeight.foundation_skill;
    sectionHeight.performance_evidence =
      sectionHeight.performance_evidence < performanceEvidenceSection
        ? performanceEvidenceSection
        : sectionHeight.performance_evidence;
    sectionHeight.knowledge_evidence =
      sectionHeight.knowledge_evidence < knowledgeEvidenceSection
        ? knowledgeEvidenceSection
        : sectionHeight.knowledge_evidence;
    sectionHeight.assessment_condition =
      sectionHeight.assessment_condition < assessmentConditionSection
        ? assessmentConditionSection
        : sectionHeight.assessment_condition;

    return [
      ...elementNodes,
      ...foundationSkillNodes,
      ...performanceEvidenceNodes,
      ...knowledgeEvidenceNodes,
      ...assessmentConditionNodes
    ];
  };

  const [nodes, setNodes] = useState<Node<any>[]>([]);
  const [edges, setEdges] = useState<Edge<any>[]>([]);

  const sourceNodes = useMemo(() => {
    return mappingNodes(previousRelease!, 'source');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousRelease, clientWidth]);

  const targetNodes = useMemo(() => {
    return mappingNodes(currentRelease!, 'target');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRelease, clientWidth]);

  useEffect(() => {
    if (plans) {
      const uniqPlans = uniqBy(plans, (item) => {
        return item.currentId;
      });
      const edgeNodes: Edge<any>[] = uniqPlans
        ?.filter((plan) => plan.currentId && plan.previousId)
        .map((plan) => {
          const type = plan.type;
          return {
            id: `edge-${plan?.previousId}-${plan.currentId}`,
            source: `${type}-source-${plan.previousId}`,
            sourceHandle: 'source',
            target: `${type}-target-${plan.currentId}`,
            markerEnd: { type: MarkerType.ArrowClosed }
          } as Edge<any>;
        });
      setEdges(edgeNodes);
    }
  }, [plans]);

  useEffect(() => {
    const nodeWidth = (clientWidth - PADDING * 2 - GAP * 8) / 2;
    const nodeStyles = { height: nodeHeight, width: nodeWidth };
    let startSourceY = GAP;
    let startTargetY = GAP;
    let sectionSourceY = 0;
    let sectionTargetY = 0;

    const renderNodes = [
      ...sourceNodes.map((node: Node<NodeData>, _index) => {
        const styles = {
          ...nodeStyles,
          height: node?.data!.height!,
          width: nodeWidth - PADDING * (node?.data?.styleLevel || 0)
        };
        const rs = {
          ...node,
          position: {
            x: PADDING + PADDING * (node?.data?.styleLevel || 0),
            y: startSourceY
          },
          data: {
            ...node?.data,
            style: { ...styles, ...node?.data?.style }
          }
        };
        const nextNode = sourceNodes[_index + 1] as Node<NodeData>;
        if (node?.id?.includes('element') && !nextNode?.id?.includes('element')) {
          startSourceY = sectionHeight.element;
          sectionSourceY = startSourceY;
        } else if (node?.id?.includes('foundation_skill') && !nextNode?.id?.includes('foundation_skill')) {
          startSourceY = sectionSourceY + sectionHeight.foundation_skill;
          sectionSourceY = startSourceY;
        } else if (
          (node?.id?.includes('performance_evidence') || node?.id?.includes('required_skill')) &&
          !nextNode?.id?.includes('performance_evidence') &&
          !nextNode?.id?.includes('required_skill')
        ) {
          startSourceY = sectionSourceY + sectionHeight.performance_evidence;
          sectionSourceY = startSourceY;
        } else if (
          (node?.id?.includes('knowledge_evidence') || node?.id?.includes('required_knowledge')) &&
          !nextNode?.id?.includes('knowledge_evidence') &&
          !nextNode?.id?.includes('required_knowledge')
        ) {
          startSourceY = sectionSourceY + sectionHeight.knowledge_evidence;
          sectionSourceY = startSourceY;
        } else if (node?.id?.includes('assessment_condition') && !nextNode?.id?.includes('assessment_condition')) {
          startSourceY = sectionSourceY + sectionHeight.assessment_condition;
          sectionSourceY = startSourceY;
        } else {
          startSourceY = startSourceY + styles.height + GAP;
        }
        return rs;
      }),
      ...targetNodes.map((node, _index) => {
        const styles = {
          ...nodeStyles,
          height: node?.data?.height,
          width: nodeWidth - PADDING * (node?.data?.styleLevel || 0)
        };
        const rs = {
          ...node,
          position: {
            x: nodeWidth + PADDING + GAP * 8 + PADDING * (node?.data?.styleLevel || 0),
            y: startTargetY
          },
          data: {
            ...node?.data,
            style: { ...styles, ...node?.data?.style }
          }
        };

        const nextNode = targetNodes[_index + 1] as Node<NodeData>;
        if (node?.id?.includes('element') && !nextNode?.id?.includes('element')) {
          startTargetY = sectionHeight.element;
          sectionTargetY = startTargetY;
        } else if (node?.id?.includes('foundation_skill') && !nextNode?.id?.includes('foundation_skill')) {
          startTargetY = sectionTargetY + sectionHeight.foundation_skill;
          sectionTargetY = startTargetY;
        } else if (
          (node?.id?.includes('performance_evidence') || node?.id?.includes('required_skill')) &&
          !nextNode?.id?.includes('performance_evidence') &&
          !nextNode?.id?.includes('required_skill')
        ) {
          startTargetY = sectionTargetY + sectionHeight.performance_evidence;
          sectionTargetY = startTargetY;
        } else if (
          (node?.id?.includes('knowledge_evidence') || node?.id?.includes('required_knowledge')) &&
          !nextNode?.id?.includes('knowledge_evidence') &&
          !nextNode?.id?.includes('required_knowledge')
        ) {
          startTargetY = sectionTargetY + sectionHeight.knowledge_evidence;
          sectionTargetY = startTargetY;
        } else if (node?.id?.includes('assessment_condition') && !nextNode?.id?.includes('assessment_condition')) {
          startTargetY = sectionTargetY + sectionHeight.assessment_condition;
          sectionTargetY = startTargetY;
        } else {
          startTargetY = startTargetY + styles.height + GAP;
        }

        return rs;
      })
    ];
    setNodes(renderNodes);
    // eslint-disable-next-line
  }, [clientWidth, sourceNodes, targetNodes]);

  useEffect(() => {
    const plans = edges.map((edge) => {
      const sources = edge.source.split('-');
      const targets = edge.target.split('-');
      return {
        type: sources[0],
        previousId: sources[2],
        currentId: targets[2]
      } as MigrationPlan;
    });
    onPlanChange(plans);
  }, [edges, onPlanChange]);

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => setEdges((eds: Edge<any>[]) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const onConnect = useCallback(
    (connection: Connection) => {
      if (!isValidConnection(connection)) {
        return;
      }

      setEdges((eds) => {
        const newEds = eds.filter((e) => e.source !== connection.source && e.target !== connection.target);
        return addEdge({ ...connection, markerEnd: { type: MarkerType.ArrowClosed } }, newEds);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEdges]
  );

  const isValidConnection = (connection: Connection) => {
    const sourceType = connection.source?.split('-')[0] || '';
    const targetType = connection.target?.split('-')[0] || '';
    if (sourceType !== targetType) {
      const requiredSkillArrays = ['required_skill', 'performance_evidence'];
      const requiredKnowledgeArrays = ['required_knowledge', 'knowledge_evidence'];
      if (![...requiredSkillArrays, ...requiredKnowledgeArrays].includes(sourceType)) {
        return false;
      }
      if (
        !(
          (requiredSkillArrays.includes(sourceType) && requiredSkillArrays.includes(targetType)) ||
          (requiredKnowledgeArrays.includes(sourceType) && requiredKnowledgeArrays.includes(targetType))
        )
      ) {
        return false;
      }
    }
    return true;
  };

  const [sourceNode, setSourceNode] = useState<Node<NodeData>>();
  const [targetNode, setTargetNode] = useState<Node<NodeData>>();
  const [diffOpened, setDiffOpened] = useState<boolean>(false);
  const edgeUpdateSuccessful = useRef(true);

  const openDiff = (node: Node<NodeData>) => {
    setTargetNode(undefined);
    setSourceNode(undefined);
    if (node.type === 'source') {
      setSourceNode(node);
      const targetId = edges.find((e) => e.source === node.id)?.target;
      const targetNode = nodes.find((n) => n.id === targetId);
      if (targetNode) {
        setTargetNode(targetNode);
      }
    } else if (node.type === 'target') {
      setTargetNode(node);
      const sourceId = edges.find((e) => e.target === node.id)?.source;
      const sourceNode = nodes.find((n) => n.id === sourceId);
      if (sourceNode) {
        setSourceNode(sourceNode);
      }
    } else {
      return;
    }

    setDiffOpened(true);
  };

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge: Edge<NodeData>, newConnection: Connection) => {
    if (!isValidConnection(newConnection)) {
      return;
    }

    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));

    setEdges((eds) => {
      const newEds = eds.filter((e) => e.source !== newConnection.source && e.target !== newConnection.target);
      return addEdge({ ...newConnection, markerEnd: { type: MarkerType.ArrowClosed } }, newEds);
    });
  }, []);

  const onEdgeUpdateEnd = useCallback((_: any, edge: Edge<NodeData>) => {
    if (edge.source?.split('-')[0] !== edge.target?.split('-')[0]) {
      return;
    }

    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  const maxHeight = useMemo(() => {
    const node = maxBy(nodes, (node) => node.position.y || 0);
    return (node?.position.y || 0) + (node?.data.height || 0) + GAP;
  }, [nodes]) 

  if (!nodes.length) {
    return <></>;
  }

  return (
    <div className="flex-1">
      <ReactFlow
        nodes={[...nodes]}
        edges={edges}
        nodeTypes={nodeTypes}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
        selectionOnDrag={false}
        zoomOnScroll={false}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        panOnScrollMode={PanOnScrollMode.Vertical}
        panOnDrag={false}
        panOnScroll={true}
        proOptions={proOptions}
        draggable={false}
        selectNodesOnDrag={false}
        nodesDraggable={false}
        maxZoom={1}
        minZoom={1}
        onNodeClick={(event, node) => {
          openDiff(node);
        }}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        translateExtent={[
          [0, 0],
          [clientWidth, maxHeight]
        ]}
      ></ReactFlow>
      <MigrationDiff
        open={diffOpened}
        onClose={() => setDiffOpened(false)}
        oldText={sourceNode?.data.label || ''}
        newText={targetNode?.data.label || ''}
        previousRelease={previousRelease?.name || ''}
        currentRelease={currentRelease?.name || ''}
      />
    </div>
  );
};
