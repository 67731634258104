import { useMutation, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { Loading } from 'components/loading';
import { useStores } from 'hooks';
import { SearchParams } from 'meilisearch';
import { useEffect, useState } from 'react';
import { CourseItem as Course, LibraryFolder } from 'types';
import { CourseItem } from './CourseItem';
import SimpleBar from 'simplebar-react';
import { SearchBox } from 'components/search-box';

type Props = {
  className?: string;
  defaultItems: Course[];
  selectedItems: Course[];
  isLoading?: boolean;
  folderId: string;
};

const ListItem = ({ courses, folderId }: { courses: Course[]; folderId: string }) => {
  const queryClient = useQueryClient();

  const addItem = (item: Partial<Course>) => {
    queryClient.setQueryData(['library', 'rto', folderId], (oldData: any) => {
      const { children } = oldData as LibraryFolder;
      const newChildren = [...children];

      const addIndex = newChildren.findIndex((x) => x.sourceId === parseInt(item.id!));
      const addItem = { ...newChildren[addIndex] };

      if (addItem.id) {
        addItem._destroy = false;
        newChildren.splice(addIndex, 1, addItem);
      } else {
        newChildren?.unshift({
          name: `${item.code} - ${item.title}` || '',
          sourceId: parseInt(item.id || ''),
          sourceType: 'Nrt',
          source: { ...item, usageRecommendation: item.usage_recommendation },
          metadata: { type: item.type },
          children: []
        });
      }

      return {
        ...oldData,
        children: [...newChildren]
      };
    });
  };

  return (
    <SimpleBar autoHide={true} className="flex-1 h-1">
      <div className="flex flex-col">
        {courses.map((item, index) => (
          <CourseItem key={index} course={item} onAdd={addItem} />
        ))}
      </div>
    </SimpleBar>
  );
};

export const CourseSelect = ({ className, defaultItems, selectedItems, folderId, isLoading }: Props) => {
  const { uiStore } = useStores();
  const { search } = uiStore;
  const [searchItems, setSearchItems] = useState<Course[]>([]);
  const [units, setUnits] = useState<Course[]>(defaultItems);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    const filterItems = keyword ? searchItems : defaultItems;
    const displayUnits = filterItems.filter((item) => selectedItems.findIndex((select) => select.id === item.id) < 0);
    setUnits(displayUnits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultItems, selectedItems, searchItems]);

  const searchCourse = (params: SearchParams) => {
    if (!params.q) {
      return Promise.resolve([]);
    }

    return search('Course', params).then((hits: Course[]) => {
      if (!hits.length) {
        return [];
      }
      return hits
        .map((item) => {
          item.type = item.component_type;
          return item;
        })
        .filter((item) => selectedItems.findIndex((select) => select.id === item.id) < 0);
    });
  };

  const { mutate, isLoading: isSearchCourseLoading } = useMutation(searchCourse, {
    onSuccess: (items: Course[]) => {
      setSearchItems(items);
    }
  });

  const changeKeyword = (keyword: string, superseded?: boolean) => {
    setKeyword(keyword);

    if (!keyword) {
      const displayUnits = defaultItems.filter(
        (item) => selectedItems.findIndex((select) => select.id === item.id) < 0
      );
      setUnits(displayUnits);
      return;
    }

    const params: SearchParams = {
      q: keyword,
      limit: 10,
      attributesToHighlight: ['*'],
      filter: 'usage_recommendation = "Current"'
    };

    if (superseded) {
      delete params.filter;
    }

    mutate(params);
  };

  return (
    <div className={clsx(className, 'border rounded-lg bg-gray-100 shadow p-4 flex flex-col')}>
      <div className="text-xs text-primary-500 leading-5 font-semibold mb-3 uppercase">
        Select courses below to add to your RTO
      </div>
      <SearchBox
        id="course-search-box-select"
        superseded={true}
        onChange={changeKeyword}
        placeholder="Search for courses"
      />
      {!(isLoading || isSearchCourseLoading) && <ListItem {...{ courses: units, folderId }} />}
      {(isLoading || isSearchCourseLoading) && (
        <div className="min-h-[200px] relative">
          <Loading />
        </div>
      )}
    </div>
  );
};
