import { Modal, ModalProps } from 'flowbite-react';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import SubscriptionActions from './SubscriptionActions';
import { useMediaQuery } from 'components/MediaQueryProvider';
import SubscriptionCloseButton from './SubscriptionCloseButton';
import SubscriptionTitle from './SubscriptionTitle';
import SubscriptionHeading from './SubscriptionHeading';
import SubscriptionListPlan from './SubscriptionListPlan';
import SubscriptionDescription from './SubscriptionDescription';

interface Props extends ModalProps {
  onClose: () => void;
  customHeading?: string;
  hideCloseButton?: boolean;
}

export const PlanOptionsModal = observer((props: Props) => {
  const { subscriptionStore, uiStore } = useStores();
  const subscription = subscriptionStore.subscription;
  const hasIncompletePayment: boolean = subscription?.hasIncompletePayment ?? false;
  const subscriptionCancelled: boolean = subscription?.status === 'canceled';

  const isExpired = hasIncompletePayment || subscriptionCancelled;

  const { isTabletAndDown } = useMediaQuery();
  const { currentWorkspace } = uiStore;
  const isOwner = currentWorkspace?.isOwner || false;

  return (
    <Modal size="5xl" position="center" show={props.show}>
      <div className="flex justify-between items-center w-full px-4 pt-4">
        {!props.hideCloseButton && <SubscriptionCloseButton onClose={props.onClose} />}
      </div>

      <div className="pt-0 pb-6 px-6 xs:p-5 xs:m-auto md:!p-10 xl:!pt-4 xl:mx-auto xl:container">
        <div className="flex lg:!flex-row gap-x-8 xs:flex-col">
          <div
            className={clsx('sm:order-1', 'w-full', 'h-auto', 'flex', 'flex-col', {
              'justify-between': isExpired,
              'justify-center': !isExpired,
              'lg:w-1/2': isOwner
            })}
          >
            <div className="xs:flex xs:flex-col">
              <SubscriptionHeading customHeading={props?.customHeading} />
              <SubscriptionTitle content="Our Pricing Plans" />
              {isOwner && <SubscriptionDescription />}
            </div>

            {!isTabletAndDown && isExpired && <SubscriptionActions />}
          </div>

          {isOwner && <SubscriptionListPlan isExpired={isExpired} />}
        </div>
      </div>
    </Modal>
  );
});
