import clsx from 'clsx';
import { Avatar } from 'components/avatar';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { components } from 'react-select';

const Icon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 8L10 13L5 8" stroke="#282E37" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const SubscriptionControl = observer(({ children, ...props }: any) => {
  const { uiStore } = useStores();

  const [selectedValue] = props.getValue();

  const { selectProps } = props;

  const {
    switchWorkspaces: { toggleMenu }
  } = selectProps;

  if (!selectedValue) return <components.Control {...props}>{children}</components.Control>;

  return (
    <components.Control {...props}>
      <div
        className="flex items-center justify-between px-4 py-2.5 space-x-2 w-full rounded-md bg-gray-100 hover:cursor-pointer"
        onClick={toggleMenu}
        onTouchStart={toggleMenu}
      >
        <div className="flex items-center space-x-2">
          <Avatar
            label={selectedValue.label}
            size={32}
            className={clsx(selectedValue.value === uiStore?.currentWorkspaceId ? 'border-green-1 border-2' : '')}
          />
          <div className="text-sm text-gray-900 truncate">{selectedValue.label}</div>
        </div>

        <Icon />
      </div>
    </components.Control>
  );
});

export default SubscriptionControl;
