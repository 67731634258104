import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MediaQueryProvider from 'components/MediaQueryProvider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

const queryClient = new QueryClient();
const gtmParams = { id: process.env.REACT_APP_GMT_ID || '' };

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={true} />
      <MediaQueryProvider>
        <GTMProvider state={gtmParams}>
          <HelmetProvider>
            <Helmet defaultTitle="Magic Map" titleTemplate="%s | Magic Map" />
            <App />
          </HelmetProvider>
        </GTMProvider>
      </MediaQueryProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
