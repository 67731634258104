import { makeAutoObservable } from 'mobx';
import { Plan, Subscription } from 'types';
import { UIStore } from './uiStore';

export interface ISubscriptionStore {
  subscription: Subscription | undefined;
  plans: Plan[];
  uiStore: UIStore;
  planOptionsModalVisibility: boolean;
  planOptionsModalPaymentPrompt: boolean;
}

export class SubscriptionStore implements ISubscriptionStore {
  subscription: Subscription | undefined;
  plans: Plan[] = [];
  uiStore: UIStore;
  planOptionsModalVisibility: boolean = false;
  planOptionsModalPaymentPrompt: boolean = false;

  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;

    makeAutoObservable(this);
  }

  setSubscription = (subscription: Subscription) => {
    this.subscription = subscription;
  };

  setPlans = (plans: Plan[]) => {
    this.plans = plans;
  };

  get recommendedPlan(): Plan | null {
    const currentPlanIndex = this.plans.findIndex((x) => x.name === this.subscription?.plan?.name);
    return currentPlanIndex < this.plans.length - 1 ? this.plans[currentPlanIndex + 1] : null;
  }

  isSingleUnit = () => {
    return this.subscription?.plan?.name === 'Single Unit';
  };

  isCurrentPlan = (plan: Plan): boolean => {
    return plan.name === this.subscription?.plan?.name;
  };

  isRecommendedPlan = (plan: Plan): boolean => {
    return plan.name === this.recommendedPlan?.name;
  };

  isUnSelectable = (plan: Plan): boolean => {
    const usedUnits = this.subscription?.usedUnits || 0;
    return usedUnits >= plan.units;
  };

  isAbleToAddMoreUnit = (): boolean => {
    const usedUnits = this.subscription?.usedUnits || 0;
    const currentPlanMaxUnits = this.subscription?.plan?.units || 0;
    return usedUnits < currentPlanMaxUnits;
  };

  showPlanOptionsModal = (paymentPrompt: boolean = false) => {
    this.planOptionsModalVisibility = true;
    this.planOptionsModalPaymentPrompt = paymentPrompt;
  };

  hidePlanOptionsModal = () => {
    this.planOptionsModalVisibility = false;
    this.planOptionsModalPaymentPrompt = false;
  };
}
