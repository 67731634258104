import { CourseBlank } from 'components/icons';
import { useStores } from 'hooks';

export const CourseAction = ({ isChildFolder }: { isChildFolder: boolean }) => {
  const { uiStore } = useStores();

  const onOpenModalAddNewCourse = () => {
    uiStore.openSearchBar();
  };

  if (isChildFolder) {
    return (
      <>
        <CourseBlank className="mb-8" />
        <p className="text-sm text-gray-900">
          Click
          <span className="relative px-1 inline-block text-sm  text-primary-500 group active:text-orange-500 focus:outline-none focus:ring">
            Modify
          </span>
          on header to start adding units to this Course.
        </p>
      </>
    );
  }

  return (
    <>
      <CourseBlank className="mb-8" />
      <p className="text-sm text-gray-900">
        Click
        <span
          className="relative px-1 inline-block text-sm  text-primary-500 group active:text-orange-500 focus:outline-none focus:ring cursor-pointer"
          onClick={onOpenModalAddNewCourse}
        >
          Add New Course
        </span>
        or select
        <span
          className="relative px-1 inline-block text-sm  text-primary-500 group active:text-orange-500 focus:outline-none focus:ring cursor-pointer"
          onClick={onOpenModalAddNewCourse}
        >
          Add New
        </span>
        from sidebar to search for a course from training.gov.au and add to your Library.
      </p>
    </>
  );
};
