import { XMarkIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { createLibraryRegular, renameLibraryRegular } from 'api';
import clsx from 'clsx';
import { Button } from 'components/buttons';
import { Modal, ModalProps } from 'flowbite-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateFolderRequest, Library } from 'types';
import * as yup from 'yup';

const schema = yup
  .object({
    name: yup.string().required("Name can't be blank.")
  })
  .required();

type Props = {
  folder?: Pick<Library, 'id' | 'name'>;
  handleCallback?: () => void;
} & ModalProps;

export const NewFolderModal = ({ folder, handleCallback, onClose, ...modalProps }: Props) => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    setFocus,
    setValue,
    formState: { errors }
  } = useForm<CreateFolderRequest>({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (modalProps.show) {
      setFocus('name');
    }
  }, [modalProps.show, setFocus]);

  useEffect(() => {
    if (folder?.id) {
      setValue('name', folder?.name);
    }
  }, [setValue, folder]);

  const { mutate, isLoading } = useMutation(
    (variables: any) => {
      if (folder?.id) {
        return renameLibraryRegular({ name: variables?.name, id: folder.id });
      }
      return createLibraryRegular({ name: variables?.name, parentId: id });
    },
    {
      onSuccess: (data) => {
        const msgSuccess = folder?.id ? 'Your changes have been saved.' : 'Folder successfully created.';
        toast(msgSuccess, {
          type: 'success'
        });
        handleClose();
        handleCallback?.();
      },
      onError: (error: any) => {
        const errorMessage = error?.message ?? 'Please try again.';
        toast(errorMessage, {
          type: 'error'
        });
      }
    }
  );

  const handleClose = () => {
    reset();
    onClose?.();
  };

  return (
    <Modal {...modalProps} size="md" position={'top-center'} id="library-modal">
      <div className="p-4 flex justify-between title">
        <h4 className="text-sm font-medium text-gray-900">{folder?.id ? 'Rename' : 'New Folder'}</h4>
        <Button
          disabled={isLoading}
          className={`form-control !text-sm !text-gray-900 ${errors.name ? 'invalid' : ''}`}
          onClick={() => handleClose()}
        >
          <XMarkIcon
            className={clsx(
              !isLoading && 'hover:rotate-90 duration-300 hover:text-primary-500',
              'text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none'
            )}
          />
        </Button>
      </div>
      <div className="p-4 pt-0">
        <form
          className="text-left"
          onSubmit={handleSubmit((values) => {
            mutate(values);
          })}
        >
          <div className="form-group mb-4">
            <label className="text-sm font-normal text-gray-700">Name: *</label>
            <input
              {...register('name')}
              type="text"
              placeholder="Name"
              data-testid="folderNameInput"
              className={`form-control !text-sm !text-gray-900 ${errors.name ? 'invalid' : ''}`}
            />
            <p className="feedback-invalid text-sm">{errors.name?.message}</p>
          </div>
          <div className="flex justify-end">
            <Button
              isDisabled={isLoading}
              className="btn-primary text-sm font-medium bg-white text-gray-900 hover:bg-gray-200 disabled:bg-white disabled:hover:bg-white mr-2"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={isLoading}
              className="btn-primary font-normal disabled:hover:bg-purple-100"
              data-testid="saveFolder"
            >
              {folder?.id ? 'Save' : 'Create'}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
