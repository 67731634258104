import { Button } from 'components/buttons';

type Props = {
  onSubmit: (next?: () => void) => void;
};
export const MapMigrationFooter = ({ onSubmit }: Props) => {
  const submit = (next?: () => void) => {
    onSubmit(next);
  };

  return (
    <form className="sticky text-sm border-t bottom-0 left-0 right-0 py-4 px-8 flex justify-between items-end">
      <div className="flex flex-col"></div>
      <div className="flex flex-col min-w-[350px]">
        <Button
          onClick={submit}
          progress={true}
          type="button"
          className="btn-primary mb-2 disabled:hover:bg-purple-100"
        >
          Duplicate and create new map
        </Button>
        <span className="text-sm italic">* Duplication does not overwrite your existing map.</span>
      </div>
    </form>
  );
};
