import { useRef } from 'react';
import { useOnClickOutside, useStores } from 'hooks';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { UserWorkspace } from 'components/layout/user-workspace';
import { NavbarProfileWorkspace } from './ProfileWorkspace';
import { observer } from 'mobx-react-lite';

export const NavbarProfile = observer(() => {
  const userWorkSpaceRef = useRef<any>(null);
  const { uiStore } = useStores();

  useOnClickOutside(userWorkSpaceRef, () => uiStore.toggleUserWorkspace(false));

  const handleShowWorkspace = () => {
    uiStore.toggleUserWorkspace(true);
  };

  return (
    <>
      <div className="min-h-88 p-4 bg-white sticky bottom-0 border-t border-gray-200">
        <div
          role="button"
          className="p-4 flex items-center justify-between rounded-lg hover:bg-primary-100 cursor-pointer duration-300"
          onClick={handleShowWorkspace}
        >
          <NavbarProfileWorkspace />
          {!uiStore.isNavbarCollapsed && <ChevronDownIcon className="h-4 w-4 text-gray-700" />}
        </div>
      </div>

      <UserWorkspace ref={userWorkSpaceRef} />
    </>
  );
});
