import React from 'react';

const SubscriptionDescription = () => {
  return (
    <div className="xs:order-3 xs:text-center xs:mb-4 md:!text-left">
      <p className="font-medium mt-5 text-gray-600 text-sm">* Prices Exclude GST</p>
      <p className="text-gray-600 text-sm">Prices represent a 12 month subscription.</p>
      <p className="text-gray-600 text-sm">
        In the case that a user upgrades within their current 12 month subscription, the upgrade price will be
        calculated on a pro rata basis.
      </p>
    </div>
  );
};

export default SubscriptionDescription;
