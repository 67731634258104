import { signin, signout, signup } from 'api';
import { createContext } from 'react';
import { LoginRequest, SignUpRequest, User } from 'types';
import { AuthStore } from './authStore';
import { UIStore } from './uiStore';
import { MapUIStore } from './mapUIStore';
import { ManageUsersStore } from './manageUsersStore';
import { MyTemplatesStore } from './myTemplatesStore';
import { SidebarTreeStore } from './sidebarTreeStore';
import { LibraryStore } from './libraryStore';
import { SubscriptionStore } from './subscriptionStore';

export interface IRootStore {
  authStore: AuthStore;
  uiStore: UIStore;
  mapUIStore: MapUIStore;
  myTemplatesStore: MyTemplatesStore;
  sidebarTreeStore: SidebarTreeStore;
  libraryStore: LibraryStore;
  subscriptionStore: SubscriptionStore;
}
export class RootStore implements IRootStore {
  authStore: AuthStore;
  uiStore: UIStore;
  mapUIStore: MapUIStore;
  manageUsersStore: ManageUsersStore;
  myTemplatesStore: MyTemplatesStore;
  sidebarTreeStore: SidebarTreeStore;
  libraryStore: LibraryStore;
  subscriptionStore: SubscriptionStore;

  constructor() {
    this.authStore = new AuthStore();
    this.uiStore = new UIStore();
    this.mapUIStore = new MapUIStore();
    this.manageUsersStore = new ManageUsersStore();
    this.myTemplatesStore = new MyTemplatesStore();
    this.libraryStore = new LibraryStore();
    this.sidebarTreeStore = new SidebarTreeStore(this.uiStore);
    this.subscriptionStore = new SubscriptionStore(this.uiStore);
  }

  async login(req: LoginRequest) {
    var user = (await signin(req)) as User;
    this.authStore.updateUser(user);
    this.uiStore.updateCurrentWorkspaceId(user.currentWorkspaceId);
    return user;
  }

  async logout() {
    await signout();
    this.authStore.clear();
    this.uiStore.clear();
    this.sidebarTreeStore.clear();
  }

  async signup(req: SignUpRequest) {
    var user = (await signup(req)) as User;
    this.authStore.updateUser(user);
    this.uiStore.updateCurrentWorkspaceId(user.currentWorkspaceId);
    return user;
  }
}
export const rootStore = new RootStore();
export const rootStoreContext = createContext(rootStore);
