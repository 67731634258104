import { MeiliSearch } from 'meilisearch';

const createMeiliSearch = (token: string) => {
  return new MeiliSearch({
    host: process.env.REACT_APP_SEARCH_BASE_URL || 'http://127.0.0.1:7700',
    apiKey: token
  });
};

export default createMeiliSearch;
