import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import EditorJS, { EditorConfig } from '@editorjs/editorjs';
import Table from '@editorjs/table';
import './style.scss';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import clsx from 'clsx';
import edjsHTML from 'lib/editorjs-html';
import { ExclamationTriangleIcon, InformationCircleIcon, PencilIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/buttons';
import { FontAndTableStyles } from 'types';
import { ShortCodes } from './ShortCodes';
import { Tooltip } from 'flowbite-react';
const Paragraph = require('editorjs-paragraph-with-alignment');
const edjsParser = edjsHTML();

type Props = {
  id: string;
  onChange?: (data: any) => void;
  config?: EditorConfig;
  data?: any;
  className?: string;
  styles?: FontAndTableStyles;
  placeholder?: string;
  limitBlocks?: number;
  readOnly?: boolean;
  onModeChange?: (viewMode: boolean) => void;
  onError?: (error: any) => void;
};
export const Editor = (props: Props) => {
  const data = { ...props.data };
  const { onModeChange, readOnly } = props;
  const [valid, setValid] = React.useState(true);
  const ejInstance = useRef<any>();

  const initEditor = useCallback(() => {
    const editor = new EditorJS({
      holder: props.id,
      onReady: () => {
        ejInstance.current = editor;
      },
      autofocus: true,
      data: props.data,
      tools: {
        paragraph: {
          class: Paragraph,
          inlineToolbar: true
        },
        header: Header,
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered'
          }
        } as any,
        table: Table
      },
      placeholder: 'Type something...',
      onChange(api) {
        if (props.limitBlocks && api.blocks.getBlocksCount() > props.limitBlocks) {
          props.onError?.(`Please keep your footer content to ${props.limitBlocks} blocks or less to maintain a clean and organized
          template.`);
          return setValid(false);
        }
        props.onError?.(undefined);
        setValid(true);
      },
      minHeight: 0,
      ...props.config
    });
  }, [props.config, props.data, props.id]);

  useEffect(() => {
    if (ejInstance.current === null) {
      initEditor();
    }

    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
      setValid(true);
    };
  }, [props.data]);

  const parseHtml = (data: any, styles?: FontAndTableStyles) => {
    const blocks = (data?.blocks || []).map((block: any) => {
      return {
        ...block,
        data: {
          ...block.data,
          styles: styles
        }
      };
    });
    return edjsParser.parse({ ...props.data, blocks })?.join('');
  };

  const html = useMemo(() => {
    if (readOnly && props.data) {
      return parseHtml(props.data, props.styles);
    }
    return '';
  }, [readOnly, props?.data, props?.styles]);

  const onSave = async () => {
    try {
      let content = await ejInstance.current.saver.save();
      if (valid) {
        props.onChange?.(content);
      }
    } catch {
      props.onChange?.('');
    } finally {
      if (valid) {
        onModeChange?.(true);
      }
    }
  };

  return (
    <div className={clsx('editor', props?.className)}>
      <div className={clsx({ hidden: readOnly }, 'border m-3 border-gray-200 rounded-lg')}>
        <div className="p-3" id={props.id}></div>
        <div className="flex items-center justify-between p-3 border-t">
          <p className="flex items-center ">
            <Tooltip
              className="w-80"
              content={
                <>
                  <div className="text-gray-900 text-xs font-semibold">
                    Use the code shortcuts to dynamically insert information to your content.
                  </div>
                  <div className="text-xs text-gray-900 font-normal">
                    <span className="text-gray-600 font-medium">Example: </span>
                    {'This mapping unit belong to {{unit_code}}:{{unit_name}}'}
                  </div>
                </>
              }
              style="light"
              animation="duration-500"
            >
              <InformationCircleIcon className="w-6 h-6 text-gray-700" />
            </Tooltip>
            <strong className="mx-2">Code shortcuts:</strong> <ShortCodes />
          </p>
          <div className="flex">
            <button
              type="button"
              className="btn hover:bg-gray-200 rounded-md mr-2"
              onClick={() => {
                onModeChange?.(true);
                props.onChange?.(data);
              }}
            >
              Cancel
            </button>
            <Button type="button" className="btn-primary font-normal disabled:hover:bg-purple-100" onClick={onSave}>
              Update
            </Button>
          </div>
        </div>
      </div>

      {readOnly && (
        <div className="bg-cover hover:bg-primary-600 p-3 rounded hover:bg-opacity-30 cursor-pointer">
          <button
            className=""
            type="button"
            onClick={() => {
              onModeChange?.(false);
            }}
          >
            <PencilIcon className="w-4 h-4 mr-1 inline-block" />
            Edit
          </button>
          {html ? (
            <div className="html-preview" dangerouslySetInnerHTML={{ __html: html }}></div>
          ) : (
            <div className="html-preview p-3 text-center">
              <p>
                <PencilIcon className="w-4 h-4 mr-2 inline-block" />
                Click <strong>Edit</strong> to add and edit {props.placeholder}
              </p>
            </div>
          )}
        </div>
      )}

      {!valid && (
        <div className="text-red-400 p-3">
          <ExclamationTriangleIcon className="w-4 h-4 inline-block mr-3" />
          Please keep your footer content to {props.limitBlocks} blocks or less to maintain a clean and organized
          template.
        </div>
      )}
    </div>
  );
};
