import { Button, Modal, ModalProps } from 'flowbite-react';
import { useStores } from 'hooks';
import React from 'react';

type Props = { onConfirm: () => void } & ModalProps;
export const TwoFactorAuthConfirmModal = (props: Props) => {
  const { uiStore } = useStores();
  const { currentWorkspace } = uiStore;
  return (
    <Modal {...props}>
      <div className="p-4">
        <h4 className="text-base font-medium text-gray-900">Two Factor Authentication</h4>
      </div>
      <hr className="mx-4" />
      <div className="p-4 text-sm">
        <p className="mb-3 font-bold">
          To access {currentWorkspace?.name}, you must first set up two-factor authentication (2FA).
        </p>
        <p>Please click the button below to start your configuration.</p>
      </div>
      <div className="p-4 pt-0">
        <div className="flex justify-end">
          <Button
            type="button"
            className="bg-primary-500 hover:bg-primary-700 px-4 h-10 rounded-md text-white text-sm font-medium"
            onClick={() => props.onConfirm()}
          >
            Configure 2FA
          </Button>
        </div>
      </div>
    </Modal>
  );
};
