import { CheckIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createRTOFolder, updateRTOFolder } from 'api';
import clsx from 'clsx';
import { Button } from 'components/buttons';
import { Header } from 'components/layout';
import { useStores } from 'hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateRTOFolderRequest, Library, LibraryFolder, UpdateFolderRequest } from 'types';

interface IFormInputs {
  name: string;
}

type Props = {
  name: string;
  folderId: string;
  rtoId?: number;
  className?: string;
  onCancel?: () => void;
};

export const RTOConfigurationHeader = ({ name, folderId, rtoId, className, onCancel }: Props) => {
  const { sidebarTreeStore } = useStores();
  const navigate = useNavigate();
  const { register, setValue, getValues } = useForm<IFormInputs>({
    defaultValues: {
      name: name
    }
  });
  const queryClient = useQueryClient();

  const addRTO = async () => {
    const library = queryClient.getQueryData<Library>(['library', 'rto', folderId]);
    if (!library) {
      return;
    }
    const { children } = library;

    const updateChildren =
      children
        ?.filter((x) => x._edited || x._destroy || !x.id)
        .map((item) => {
          return {
            id: item.id ? parseInt(item.id) : undefined,
            sourceId: item.sourceId,
            name: item.name,
            _destroy: item._destroy,
            metadata: item.metadata,
            source: item.source,
            children: item.children as any
          } as LibraryFolder;
        }) || [];

    if (!parseInt(library.id)) {
      const request: CreateRTOFolderRequest = {
        folder: {
          name: getValues('name'),
          sourceId: rtoId,
          sourceType: 'Nrt',
          source: library.source,
          metadata: library.metadata,
          children: updateChildren
        }
      };
      return createRTOFolder(request);
    }

    const request: UpdateFolderRequest = {
      folder: {
        id: parseInt(library.id.toString()),
        name: getValues('name'),
        children: updateChildren
      }
    };

    return updateRTOFolder(request);
  };

  const { mutate: addRTOMutate, isLoading } = useMutation(addRTO, {
    onSuccess: async (res) => {
      let message = 'Your changes have been saved.';
      if (!parseInt(folderId)) {
        message = 'RTO successfully added to Library.';
      }

      toast(message, {
        type: 'success'
      });

      await sidebarTreeStore.loadRtoFolders();
      queryClient.invalidateQueries(['rto_folder_list']);
      queryClient.removeQueries(['library', 'rto', folderId]);
      queryClient.removeQueries({
        predicate: (query) => query.queryKey[0] === 'library' && query.queryKey[1] === 'courses'
      });

      if (folderId) {
        queryClient.invalidateQueries(['folder_detail', folderId]);
      }

      if (onCancel) {
        onCancel();
        return;
      }

      navigate({
        pathname: `/library/folders/${res?.id || folderId}`
      });
    },
    onError: () => {
      toast('Something wrong! Please try again.', {
        type: 'error'
      });
    }
  });

  useEffect(() => {
    if (name) {
      setValue('name', name);
    }
  }, [name, setValue]);

  const Title = () => {
    return (
      <div className="form-group !mb-0 w-full min-w-96">
        <input className="form-control !leading-6 !text-gray-900" {...register('name')} />
      </div>
    );
  };

  return (
    <Header titleComponent={<Title />} className={clsx(className, 'flex flex-col lg:flex-row')}>
      <div className="flex mt-4 lg:mt-0 basis-2/5 justify-end">
        {onCancel && (
          <Button
            disabled={isLoading}
            className="btn !inline-flex mr-3 text-gray-900 hover:bg-gray-200 rounded-md"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
        )}
        <Button isLoading={isLoading} className="btn-primary !inline-flex" onClick={() => addRTOMutate()}>
          <CheckIcon className="h-5 w-auto mr-1" />
          {folderId !== '0' ? 'Save' : 'Add & Configure RTO'}
        </Button>
      </div>
    </Header>
  );
};
