import { ReactSVG } from 'react-svg';
import LogoSmallSvg from '../../assets/svg/logo-small.svg';

type Props = {
  className?: string;
};

export const LogoSmallIcon = ({ className }: Props) => {
  return <ReactSVG src={LogoSmallSvg} className={className} />;
};
