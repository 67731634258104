import { ChangeEvent } from 'react';
import clsx from 'clsx';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import './style.scss';

type Props = {
  searchText: string;
  isNoSearchResult?: boolean;
  onSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClearSearch?: () => void;
};

export const UnitSearch = ({ searchText, isNoSearchResult, onSearch, onClearSearch }: Props) => {
  return (
    <div className="form-group relative w-full lg:w-[280px] xl:w-[360px] mb-0 mt-4 lg:mt-0">
      <MagnifyingGlassIcon strokeWidth={2} className="w-4 h-4 absolute top-3 left-3 text-gray-900" />
      <input
        className={clsx('form-control search-input text-sm', isNoSearchResult && 'invalid')}
        placeholder="Search all units in this course"
        value={searchText}
        onChange={onSearch}
      />
      {!!searchText && (
        <div
          role="button"
          className="text-gray-400 w-4 h-4 absolute top-3 right-3 hover:text-gray-700"
          onClick={onClearSearch}
        >
          <XMarkIcon strokeWidth={2} />
        </div>
      )}
    </div>
  );
};
