import { ReactSVG } from 'react-svg';
import RTOBlankSvg from '../../assets/svg/rto-blank.svg';

type Props = {
  className?: string;
};

export const RTOBlank = ({ className }: Props) => {
  return <ReactSVG src={RTOBlankSvg} className={className} />;
};
