import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { setup2FA, verify2FA } from 'api/two-factor-auth';
import { Button } from 'components/buttons';
import { Modal, ModalProps, Tooltip } from 'flowbite-react';
import { Square2StackIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Controller, useForm } from 'react-hook-form';
import { PasswordField } from 'components/password-field';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { Loading } from 'components/loading';
import { useCopyToClipboard } from 'react-use';

interface FormInputs {
  otpAttempt: string;
  password: string;
}

const schema = yup
  .object({
    otpAttempt: yup.string().required("OTP can't be blank."),
    password: yup.string().required("Password can't be blank.")
  })
  .required();

type Props = { onSuccess?: () => void } & ModalProps;
export const TwoFactorAuthSetupModal = (modalProps: Props) => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(['two-factor-auth'], setup2FA, {
    staleTime: 1000 * 60 * 5
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FormInputs>({
    resolver: yupResolver(schema)
  });

  const { mutate } = useMutation(verify2FA);

  const onSubmit = (value: FormInputs, next?: () => void) => {
    mutate(value, {
      onError: (error: any) => {
        toast.error(error?.message ?? 'Something went wrong! Please try again.');
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['me']);
        modalProps.onClose?.();
        modalProps.onSuccess?.();
      },
      onSettled: () => {
        next?.();
      }
    });
  };

  const [stateCopy, copyToClipboard] = useCopyToClipboard();

  return (
    <Modal {...modalProps}>
      <div className="p-4 flex justify-between title">
        <h4 className="text-base font-medium text-gray-900">Two Factor Authentication</h4>
        <Button
          disabled={isLoading}
          className={`form-control !text-sm !text-gray-900`}
          onClick={() => modalProps.onClose?.()}
        >
          <XMarkIcon
            className={clsx(
              !isLoading && 'hover:rotate-90 duration-300 hover:text-primary-500',
              'text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none'
            )}
          />
        </Button>
      </div>
      <hr className="mx-4" />
      <form autoComplete="off" className="p-4 text-sm">
        <ul className="list-decimal pl-4">
          <li className="mb-2">
            Before you start please make sure you have an OTP (one-time-password) compatible app such as Google
            Authenticator or Authy installed on your device.
          </li>
          <li className="mb-2">
            If you do have an OTP-compatible app, please open it and use it to scan the QR code below
            <div className="flex justify-center p-3">
              {!isLoading && (
                <img
                  className="w-32 h-32 text-center"
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(data?.qrCodeSvg)}`}
                  alt="QrCode"
                />
              )}
              {isLoading && (
                <div className="w-32 h-32 relative">
                  <Loading />
                </div>
              )}
            </div>
          </li>
          <li className="mb-2 w-full">
            If you cannot scan please use the OTP compatible app and manually enter the following code:
            <div className="p-2 text-center ">
              <span className="text-lg font-medium text-primary-500">{data?.otpSecret}</span>
              <div className="inline-block">
                <Tooltip trigger="click" content={stateCopy.error ? 'Unable to copy' : 'Copied'}>
                  <Square2StackIcon
                    data-tooltip-target="tooltip-copy-otp"
                    onClick={() => copyToClipboard(data?.otpSecret)}
                    type="button"
                    className="w-5 h-5 inline-block ml-2 text-primary-500 cursor-pointer"
                  />
                </Tooltip>
              </div>
            </div>
          </li>
          <li className="mb-2">
            Once you have completed the above steps on the OTP compatible app, you should have a code that you can enter
            below as well as your MAGICMAP login password to verify the configuration of your two-factor authentication
            (2FA).
          </li>
        </ul>
        <div className="flex text-sm justify-between mt-4">
          <div className="form-group mb-0 flex-1 mr-4">
            <label htmlFor="otpAttempt">OTP</label>
            <input
              className={`form-control ${errors.otpAttempt ? 'invalid' : ''}`}
              id="otpAttempt"
              type="text"
              placeholder="OTP"
              autoFocus={true}
              autoComplete="off"
              {...register('otpAttempt')}
              name="otpAttempt"
            />
            <p className="feedback-invalid text-sm">{errors.otpAttempt?.message}</p>
          </div>
          <div className="form-group mb-0 ml-4 flex-1">
            <label htmlFor="password">Password</label>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => (
                <PasswordField
                  className={`form-control ${fieldState.error ? 'invalid' : ''} !pr-10`}
                  value={field.value || ''}
                  onChange={field.onChange}
                  placeholder="Password"
                  name={field.name}
                  autoComplete="new-password"
                />
              )}
            />
            <p className="feedback-invalid text-sm">{errors.password?.message}</p>
          </div>
        </div>
      </form>
      <div className="p-4 pt-0">
        <div className="flex justify-end">
          <button
            type="button"
            className="hover:bg-gray-200 px-4 h-10 rounded-md mr-4 text-sm font-medium text-gray-900"
            onClick={() => modalProps.onClose?.()}
          >
            Cancel
          </button>
          <Button
            type="button"
            className="bg-primary-500 hover:bg-primary-700 px-4 h-10 rounded-md text-white text-sm font-medium"
            onClick={(next) => {
              handleSubmit((value) => onSubmit(value, next))();
              next?.();
            }}
          >
            Confirm and enable 2FA
          </Button>
        </div>
      </div>
    </Modal>
  );
};
