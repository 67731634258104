import { CheckIcon, MinusCircleIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchPaymentMethods, removePaymentMethod, setDefaultPaymentMethod, setupIntent } from 'api';
import clsx from 'clsx';
import { Button } from 'components/buttons';
import { ConfirmationModal } from 'components/modals';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ErrorResponse, SetupIntent, PaymentMethodData } from 'types';
import { PaymentMethodModal } from './modals/PaymentModal';
import { PayIcon } from './PayIcon';

type Props = {
  className?: string;
};
export const PaymentMethod = observer(({ className }: Props) => {
  const {
    uiStore: { currentWorkspaceId }
  } = useStores();
  const [openedPayment, setOpenedPayment] = useState<boolean>(false);
  const { data } = useQuery<PaymentMethodData[], ErrorResponse>(
    ['workspaces', currentWorkspaceId, 'payments'],
    fetchPaymentMethods
  );

  const { mutate: setupIntentPayment, isLoading, data: paymentIntent } = useMutation<SetupIntent>(setupIntent);

  return (
    <div className={className}>
      {!data?.length && <div className="text-xs text-gray-700 font-normal mb-2">No payment method.</div>}
      {data
        ?.sort((a, b) => Number(a.processorId) - Number(b.processorId))
        ?.map((item, index) => (
          <CardInfo key={index} item={item} className="mb-2" />
        ))}
      <Button
        isLoading={isLoading}
        className="btn btn-secondary"
        onClick={async () => {
          await setupIntentPayment();
          setOpenedPayment(true);
        }}
      >
        Add Payment Method
      </Button>

      {paymentIntent && (
        <PaymentMethodModal
          show={openedPayment}
          size="md"
          paymentIntent={paymentIntent}
          onClose={() => {
            setOpenedPayment(false);
          }}
        />
      )}
    </div>
  );
});

export const CardInfo = observer(({ className, item }: { item: PaymentMethodData; className?: string }) => {
  const {
    uiStore: { currentWorkspaceId }
  } = useStores();
  const queryClient = useQueryClient();
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

  const setDefaultPayment = async ({ id, next }: { id: string; next?: () => void }) => {
    const res = await setDefaultPaymentMethod(id);
    next && next();
    return res;
  };

  const { mutate: setDefault } = useMutation(setDefaultPayment, {
    onSuccess: () => {
      queryClient.invalidateQueries(['workspaces', currentWorkspaceId, 'payments']);
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  const { mutate: removePayment, isLoading: isRemovePaymentLoading } = useMutation(removePaymentMethod, {
    onSuccess: () => {
      queryClient.invalidateQueries(['workspaces', currentWorkspaceId, 'payments']);
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error'
      });
    },
    onSettled: () => {
      setOpenConfirmationModal(false);
    }
  });

  return (
    <div
      className={clsx(
        'flex border border-gray-200 rounded-lg p-4 justify-between items-center w-full text-xs font-medium text-gray-600',
        className
      )}
    >
      {item.default ? (
        <span>Default</span>
      ) : (
        <Button
          progress={true}
          className="hover:opacity-100 text-xs font-medium text-gray-400 relative !justify-start"
          onClick={(next) => setDefault({ id: item.processorId, next })}
        >
          Make default
        </Button>
      )}
      <div className="flex">
        <span className="mr-2">
          <PayIcon brand={item.data.brand} />
        </span>{' '}
        <span className="mr-2">**** **** **** {item.data.last4}</span>
        {item.default ? (
          <CheckIcon className="h-4 w-auto text-green-500" />
        ) : (
          <MinusCircleIcon
            onClick={() => setOpenConfirmationModal(true)}
            className="h-4 w-auto text-gray-400 cursor-pointer"
          />
        )}
        <ConfirmationModal
          show={openConfirmationModal}
          size="sm"
          isloading={isRemovePaymentLoading}
          title={'Delete payment method?'}
          message={'This will permanently delete your payment method.'}
          closeModal={() => setOpenConfirmationModal(false)}
          onSave={() => {
            removePayment(item.processorId);
          }}
        />
      </div>
    </div>
  );
});
