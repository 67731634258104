import { XMarkIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchTemplateOptions, fetchUnitOptions, generateDownloadUnitTemplateUrl } from 'api';
import clsx from 'clsx';
import { Button } from 'components/buttons';
import { reactSelectCustomStyle } from 'constant';
import { Modal, ModalProps } from 'flowbite-react';
import { useStores } from 'hooks';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { DownloadUnitTemplateRequest, ErrorResponse, TemplateOption, UnitOption } from 'types';
import { FileType } from 'types/enums';
import * as yup from 'yup';

const schema = yup
  .object({
    templateId: yup.string().required("Template can't be blank."),
    unitIds: yup.array().required("Units can't be blank.")
  })
  .required();

type Option = {
  value: string;
  label: string;
  displayLabelOption?: string;
};

export const UnitTemplateDownloadModal = (modalProps: ModalProps) => {
  const { myTemplatesStore } = useStores();
  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    resetField,
    formState: { errors }
  } = useForm<DownloadUnitTemplateRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      fileType: FileType.Xlsx
    }
  });

  const { mutate, isLoading } = useMutation(generateDownloadUnitTemplateUrl, {
    onSuccess: (res: any) => {
      if (!res) {
        return;
      }

      toast('Please wait, your file download will begin shortly', {
        type: 'success'
      });

      window.location.assign(res?.url);
      handleClose();
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  const handleClose = () => {
    clearErrors(['templateId', 'unitIds']);
    reset();
    myTemplatesStore.closeModal();
  };

  const { data: listTemplateOptions } = useQuery<TemplateOption[], ErrorResponse>(
    ['templatesOptions'],
    () => fetchTemplateOptions(''),
    {
      staleTime: 300000 // Slate = Cache = 5 minutes
    }
  );

  const { data: listUnitOptions } = useQuery<UnitOption[], ErrorResponse>(
    ['unitsOptions'],
    () => fetchUnitOptions(''),
    {
      staleTime: 300000 // Slate = Cache = 5 minutes
    }
  );

  const unitOptions: Option[] = useMemo(() => {
    if (!listUnitOptions) return [];
    return listUnitOptions?.map((option) => {
      return {
        value: `${option.id}`,
        label: `${option.code}`,
        displayLabelOption: `${option.code} - ${option.title}`
      } as Option;
    });
  }, [listUnitOptions]);

  const templateOptions: Option[] = useMemo(() => {
    if (!listTemplateOptions) return [];
    return listTemplateOptions?.map((option) => {
      return {
        value: `${option.id}`,
        label: `${option.name}`
      } as Option;
    });
  }, [listTemplateOptions]);

  const Option = (props: any) => {
    const { data, ...optionProps } = props;
    return (
      <components.Option {...optionProps}>
        <p>{data?.displayLabelOption}</p>
      </components.Option>
    );
  };

  const filterOption = (option: any, searchText: string) => {
    return option.data.displayLabelOption.toLowerCase().includes(searchText.toLowerCase());
  };

  return (
    <Modal {...modalProps} size="md" position={'center'}>
      <div className="p-4 flex justify-between title">
        <h4 className="text-sm font-medium text-gray-900">Unit Template Download</h4>
        <Button disabled={isLoading} className={`form-control !text-sm !text-gray-900`} onClick={() => handleClose()}>
          <XMarkIcon
            className={clsx(
              !isLoading && 'hover:rotate-90 duration-300 hover:text-primary-500',
              'text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none'
            )}
          />
        </Button>
      </div>
      <div className="p-4 pt-0">
        <form
          className="text-left"
          onSubmit={handleSubmit((value) => {
            mutate({
              ...value
            });
          })}
        >
          <div className="form-group mb-4">
            <label className="text-sm font-normal text-gray-700">Select a template:*</label>
            {modalProps.show && (
              <>
                <Controller
                  name="templateId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      className={`${errors.templateId?.message ? 'react-select-invalid' : ''}`}
                      isMulti={false}
                      onBlur={field.onBlur}
                      onChange={(option: any) => {
                        field.onChange(option?.value);
                        setValue('templateId', option?.value);
                      }}
                      options={templateOptions}
                      placeholder={`Choose a template`}
                      styles={reactSelectCustomStyle}
                      classNamePrefix="react-select"
                      isClearable={true}
                    />
                  )}
                />
                <p className="feedback-invalid text-sm">{errors.templateId?.message}</p>
              </>
            )}
          </div>
          <div className="form-group mb-4">
            <label className="text-sm font-normal text-gray-700">Select units in library:*</label>
            {modalProps.show && (
              <>
                <Controller
                  name="unitIds"
                  control={control}
                  render={({ field }) => (
                    <Select
                      className={`${errors.unitIds?.message ? 'react-select-invalid' : ''}`}
                      isMulti={true}
                      onBlur={field.onBlur}
                      onChange={(options: any) => {
                        const unitIds = options?.map((option: Option) => {
                          return parseInt(option?.value) || 0;
                        });
                        field.onChange(unitIds);
                        if (unitIds?.length > 0) {
                          setValue('unitIds', unitIds);
                          return;
                        }

                        resetField('unitIds');
                      }}
                      options={unitOptions}
                      placeholder={`Choose units`}
                      components={{ Option }}
                      styles={reactSelectCustomStyle}
                      classNamePrefix="react-select"
                      isClearable={true}
                      filterOption={filterOption}
                    />
                  )}
                />
                <p className="feedback-invalid text-sm">{errors.unitIds?.message}</p>
              </>
            )}
          </div>
          <Controller
            name="fileType"
            control={control}
            render={({ field }) => (
              <div className="form-group mb-4 flex space-x-4 items-center text-sm font-normal text-gray-900">
                <input
                  className="checked:bg-primary-500 focus:ring-primary-500 hover:cursor-pointer"
                  type="radio"
                  value={FileType.Xlsx}
                  id={FileType.Xlsx}
                  checked={field.value === FileType.Xlsx}
                  onChange={(event) => {
                    if (event.target.checked && event.target.value) {
                      setValue('fileType', event.target.value as FileType);
                    }
                  }}
                />
                <label htmlFor={FileType.Xlsx} className="!mb-0 hover:cursor-pointer">
                  EXCEL
                </label>
                <input
                  className="checked:bg-primary-500 focus:ring-primary-500 hover:cursor-pointer"
                  type="radio"
                  value={FileType.Pdf}
                  id={FileType.Pdf}
                  checked={field.value === FileType.Pdf}
                  onChange={(event) => {
                    if (event.target.checked && event.target.value) {
                      setValue('fileType', event.target.value as FileType);
                    }
                  }}
                />
                <label htmlFor={FileType.Pdf} className="!mb-0 hover:cursor-pointer">
                  PDF
                </label>
                <input
                  className="checked:bg-primary-500 focus:ring-primary-500 hover:cursor-pointer"
                  type="radio"
                  value={FileType.Docx}
                  id={FileType.Docx}
                  checked={field.value === FileType.Docx}
                  onChange={(event) => {
                    if (event.target.checked && event.target.value) {
                      setValue('fileType', event.target.value as FileType);
                    }
                  }}
                />
                <label htmlFor={FileType.Docx} className="!mb-0 hover:cursor-pointer">
                  WORD
                </label>
              </div>
            )}
          />

          <div className="flex justify-end">
            <Button
              isDisabled={isLoading}
              className="btn-primary text-sm font-medium bg-white text-gray-900 hover:bg-gray-200 disabled:bg-white disabled:hover:bg-white mr-2"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={isLoading}
              className="btn-primary font-normal disabled:hover:bg-purple-100"
            >
              Download
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
