import { ChevronDownIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Radio } from 'components/layout/common/Radio';
import { SmallToggle } from 'components/layout/common/SmallToggle';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { BrandSettingsForm } from 'types/brand-settings';
import { BSFColorPicker } from './components/BSFColorPicker';
import { BSFUpload } from './components/BSFUpload';
import { BSFFonts } from './components/BSFFonts';
import { toast } from 'react-toastify';

enum LogoPosition {
  left = 'left',
  right = 'right',
  center = 'center',
  rightSide = 'right-side'
}

export const BrandSettingForm = () => {
  const [isOpenFontTableSection, setIsFontTableSection] = useState(true);
  const {
    control,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext<BrandSettingsForm>();

  const logoDisplay = watch('logoDisplay');

  useEffect(() => {
    if (!errors.logoUrl) return;

    toast.error(errors.logoUrl?.message);
  }, [errors]);

  return (
    <div className="p-4 bg-white min-h-screen border-r border-gray-200">
      <div className="bg-primary-100 rounded-md py-2 px-3 mb-5">
        <p className="text-sm font-semibold text-primary-500 mb-2">Edit Section</p>
        <p className="text-sm text-gray-700">
          Want to change a section? <span className="font-semibold">Hover</span> over it and click the{' '}
          <span className="font-semibold">Edit</span> button.
        </p>
      </div>

      <div className="border-b border-gray-200">
        <div className="flex items-center justify-between py-4">
          <Controller
            control={control}
            name="logoDisplay"
            render={({ field: { onChange, value } }) => (
              <SmallToggle
                label="Logo"
                inputProps={{
                  checked: value,
                  onChange: onChange
                }}
              />
            )}
          />

          <ChevronDownIcon
            className={clsx('w-5 h-5 transition-all duration-300 transform cursor-pointer', {
              'rotate-180': !logoDisplay
            })}
            onClick={() => setValue('logoDisplay', !logoDisplay)}
          />
        </div>

        <div
          className={clsx('logo-wrapper transition-all duration-300 transform origin-top', {
            'scale-y-0 h-0 opacity-0': !logoDisplay,
            'scale-y-100 h-auto opacity-100': logoDisplay
          })}
        >
          <div className="transition-all duration-300">
            <Controller
              name="logoUrl"
              control={control}
              render={({ field: { name, value, onChange } }) => {
                return <BSFUpload name={name} onChange={onChange} value={value} />;
              }}
            />
          </div>

          <div className="mt-3 transition-all duration-300">
            <p className="text-xs text-gray-700 mb-2">Alignment</p>
            <div className="mb-4">
              <div className="flex items-center justify-start gap-x-5 w-full">
                <Controller
                  control={control}
                  name="logoAlignment"
                  render={({ field }) => (
                    <Radio
                      id="logo-left"
                      label="Left"
                      inputProps={{
                        ...field,
                        value: LogoPosition.left,
                        checked: field.value === LogoPosition.left
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="logoAlignment"
                  render={({ field }) => (
                    <Radio
                      id="logo-center"
                      label="Center"
                      inputProps={{
                        ...field,
                        value: LogoPosition.center,
                        checked: field.value === LogoPosition.center
                      }}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="logoAlignment"
                  render={({ field }) => (
                    <Radio
                      id="logo-right"
                      label="Right"
                      inputProps={{
                        ...field,
                        value: LogoPosition.right,
                        checked: field.value === LogoPosition.right
                      }}
                    />
                  )}
                />
              </div>

              <Controller
                control={control}
                name="logoAlignment"
                render={({ field }) => (
                  <Radio
                    id="logo-unitDescription"
                    label={
                      <span>
                        On the right side of <b>Unit Description</b>
                      </span>
                    }
                    inputProps={{
                      ...field,
                      value: LogoPosition.rightSide,
                      checked: field.value === LogoPosition.rightSide
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="unit-description-wrapper border-b border-gray-200 py-4">
          <Controller
            control={control}
            name="unitDescriptionDisplay"
            render={({ field: { onChange, value } }) => (
              <SmallToggle
                label="Unit Description"
                inputProps={{
                  checked: value,
                  onChange
                }}
              />
            )}
          />
        </div>

        <div className={clsx('border-b border-gray-200', { 'pb-4': isOpenFontTableSection })}>
          <div className="flex items-center justify-between py-4">
            <div className="font-semibold text-sm text-gray-700">Font & Table</div>
            <ChevronDownIcon
              className={clsx('w-5 h-5 transition-all duration-300 transform cursor-pointer', {
                'rotate-180': !isOpenFontTableSection
              })}
              onClick={() => setIsFontTableSection(!isOpenFontTableSection)}
            />
          </div>

          <div
            className={clsx('font-table-wrapper transition-all duration-300 transform origin-top', {
              'scale-y-0 h-0 opacity-0': !isOpenFontTableSection,
              'scale-y-100 h-auto opacity-100': isOpenFontTableSection
            })}
          >
            <Controller
              name="fontAndTableStyles.fontFamily"
              control={control}
              render={({ field: { value, onChange } }) => (
                <BSFFonts valueProps={value || ''} onChangeProps={onChange} />
              )}
            />

            <div className="flex flex-wrap justify-between gap-x-2">
              <Controller
                rules={{ required: true }}
                control={control}
                name="fontAndTableStyles.headerBgColor"
                render={({ field: { value, onChange }, fieldState }) => (
                  <BSFColorPicker
                    title="Header background"
                    initColor={value}
                    onColorChange={onChange}
                    hasError={!!fieldState.error}
                  />
                )}
              />

              <Controller
                rules={{ required: true }}
                control={control}
                name="fontAndTableStyles.headerTextColor"
                render={({ field: { value, onChange }, fieldState }) => (
                  <BSFColorPicker
                    title="Header text"
                    initColor={value}
                    onColorChange={onChange}
                    hasError={!!fieldState.error}
                  />
                )}
              />

              <Controller
                rules={{ required: true }}
                control={control}
                name="fontAndTableStyles.subHeaderBgColor"
                render={({ field: { value, onChange }, fieldState }) => (
                  <BSFColorPicker
                    title="Sub-header background"
                    initColor={value}
                    onColorChange={onChange}
                    hasError={!!fieldState.error}
                  />
                )}
              />

              <Controller
                rules={{ required: true }}
                control={control}
                name="fontAndTableStyles.subHeaderTextColor"
                render={({ field: { value, onChange }, fieldState }) => (
                  <BSFColorPicker
                    title="Sub-header text"
                    initColor={value}
                    onColorChange={onChange}
                    hasError={!!fieldState.error}
                  />
                )}
              />

              <Controller
                rules={{ required: true }}
                control={control}
                name="fontAndTableStyles.borderColor"
                render={({ field: { value, onChange }, fieldState }) => (
                  <BSFColorPicker
                    title="Border color"
                    initColor={value}
                    onColorChange={onChange}
                    hasError={!!fieldState.error}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="unit-description-wrapper border-b border-gray-200 py-4">
          <Controller
            control={control}
            name="textAfterMapDetailsDisplay"
            render={({ field: { value, onChange } }) => (
              <SmallToggle
                label="Additional Info"
                inputProps={{
                  checked: value,
                  onChange
                }}
              />
            )}
          />
          <div className="ml-12 text-sm text-gray-700">
            This content will be displayed below the Unit Mapping table.
          </div>
        </div>

        <div className="unit-description-wrapper pt-4">
          <Controller
            control={control}
            name="footerDisplay"
            render={({ field: { value, onChange } }) => (
              <SmallToggle
                label="Footer"
                inputProps={{
                  checked: value,
                  onChange
                }}
              />
            )}
          />
          <div className="ml-12 text-sm text-gray-700">Footer will be displayed in all pages.</div>
        </div>
      </div>
    </div>
  );
};
