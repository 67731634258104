import { makeAutoObservable } from 'mobx';
import { Map } from 'types';
import { ShowModal } from 'types/enums';

type SyncStatus = {
  status: 'saved' | 'saving' | 'error';
  datetime?: Date;
};

export interface MapUIStore {
  syncStatus: SyncStatus;
  currentMap: Map | undefined;
  showModal: ShowModal;
}

export class MapUIStore implements MapUIStore {
  syncStatus: SyncStatus;
  currentMap: Map | undefined;
  showModal = ShowModal.None;
  constructor() {
    this.syncStatus = {
      status: 'saved'
    };
    makeAutoObservable(this);
  }

  openDeleteModal = (map: Map) => {
    this.currentMap = map;
    this.showModal = ShowModal.Delete;
  };

  openDuplicateModal = (map: Map) => {
    this.currentMap = map;
    this.showModal = ShowModal.Duplicate;
  };

  openDuplicateMapUnitModal = (map: Map) => {
    this.currentMap = map;
    this.showModal = ShowModal.DuplicateMapUnit;
  };

  openNewModal = () => {
    this.showModal = ShowModal.New;
  };

  closeModal = () => {
    this.currentMap = undefined;
    this.showModal = ShowModal.None;
  };

  setSyncStatus = (status: 'saved' | 'saving' | 'error') => {
    this.syncStatus = {
      status,
      datetime: new Date()
    };
  };

  clearSyncStatus = () => {
    this.syncStatus = {
      status: 'saved'
    };
  };
}
