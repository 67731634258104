import { useParams, useOutletContext, Navigate } from 'react-router-dom';
import { UnitTable } from './UnitTable';
import { CourseNoUnit } from './CourseNoUnit';

export const CoreUnits = () => {
  const { courseType, coreUnits, onAddAddUnit } = useOutletContext() as any;
  const { id } = useParams();

  if (courseType === 'skill') {
    return <Navigate to={`/courses/${id}`} />;
  }

  if (!coreUnits?.length) return <CourseNoUnit />;

  return (
    <div>
      <UnitTable units={coreUnits} tableType="core" courseType={courseType} onAddUnit={onAddAddUnit} />
    </div>
  );
};
