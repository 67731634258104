import { Helmet } from 'react-helmet-async';
import { HomeIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchLibraryRegular } from 'api';
import { Breadcrumb, BreadcrumbItem, Loading } from 'components';
import { observer } from 'mobx-react';
import { ErrorResponse, Library, NodeType } from 'types';
import { LibraryContainer } from 'components/library/LibraryContainer';

import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useStores } from 'hooks';

export const Folder = observer(() => {
  const { sidebarTreeStore, libraryStore, uiStore } = useStores();
  const { currentWorkspaceId } = uiStore;
  const queryClient = useQueryClient();

  const { data: listData, isLoading } = useQuery<Library[], ErrorResponse>(['folder_list', currentWorkspaceId], () =>
    fetchLibraryRegular()
  );

  useEffect(() => {
    sidebarTreeStore.setCurrentFolderId('folder');
  }, [sidebarTreeStore]);

  const onOpenModalNewFolder = () => {
    libraryStore.openNewModal();
  };

  const onActionCallback = async () => {
    await sidebarTreeStore.loadRegularFolders();
    queryClient.invalidateQueries(['folder_list', currentWorkspaceId]);
  };

  if (isLoading) {
    return <Loading />;
  }

  const ActionButton = () => {
    return (
      <>
        <button
          className="btn-primary text-sm ml-3 hidden md:block"
          onClick={onOpenModalNewFolder}
          data-testid="newFolderBtn"
        >
          <PlusIcon className="w-5 h-5 inline-block mr-2" />
          New Folder
        </button>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Folders</title>
      </Helmet>

      <LibraryContainer
        data={listData || []}
        typeFolder={NodeType.REGULAR}
        onActionCallback={onActionCallback}
        buttonComponent={<ActionButton />}
        titleComponent={
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">
                <HomeIcon className="w-5 text-gray-600" />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>Folders</BreadcrumbItem>
          </Breadcrumb>
        }
      />
    </>
  );
});
