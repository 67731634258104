import { useStores } from 'hooks';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { Tree, TreeApi } from 'react-arborist';
import { SidebarTreeNode } from 'types';
import { TreeNode } from './TreeNode';
import { useLocation } from 'react-router-dom';

export const SidebarTree = observer(({ width, height }: { width: number; height: number }) => {
  const { sidebarTreeStore, uiStore } = useStores();
  const { currentWorkspaceId } = uiStore;
  const treeRef = useRef<TreeApi<SidebarTreeNode>>();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    const loadSidebar = async () => {
      await sidebarTreeStore.loadSidebar();
    };

    loadSidebar();
  }, [sidebarTreeStore, currentWorkspaceId]);

  useEffect(() => {
    const tree = treeRef.current;
    if (!tree) return;

    const notClickOnSidebar = !(pathname.includes('library') || pathname.includes('templates'));
    if (notClickOnSidebar) {
      tree.deselectAll();
    }
  }, [pathname]);

  useEffect(() => {
    const dispose = autorun(() => {
      if (treeRef.current && uiStore.isNavbarCollapsed === true) {
        treeRef.current.close('my_library');
      }
    });

    return () => {
      dispose();
    };
  });

  if (sidebarTreeStore.isLoading) {
    return <></>;
  }

  return (
    <Tree
      ref={treeRef}
      className="w-full !h-full"
      height={height}
      width={width}
      data={sidebarTreeStore.treeData}
      openByDefault={false}
      rowHeight={48}
      indent={20}
      selection={sidebarTreeStore.folderId}
    >
      {TreeNode}
    </Tree>
  );
});
