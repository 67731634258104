import * as yup from 'yup';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Header } from 'components';
import { Button } from 'components/buttons';
import { EditProfileForm, IFormInputs } from 'components/profile';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useStores } from 'hooks';
import { ChangePasswordModal } from 'components/profile/ChangePasswordModal';
import { observer } from 'mobx-react';
import { fetchUser, updateUser } from 'api/user';
import { User } from 'types';
import { useState } from 'react';

const schema = yup
  .object({
    firstName: yup.string().required("First name can't be blank."),
    surname: yup.string().required("Last  name can't be blank.")
  })
  .required();

export const Profile = observer(() => {
  const { authStore } = useStores();
  const [showModalChangePass, setShowModalChangePass] = useState<boolean>(false);

  const { data: user } = useQuery(['me'], fetchUser, {
    onSuccess: (data) => {
      authStore.updateUser(data);
      methods.setValue('firstName', data?.firstName);
      methods.setValue('surname', data?.surname);
    },
    staleTime: 0,
    cacheTime: 300000
  });

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  });

  const { isLoading, mutate } = useMutation((variables: IFormInputs) => updateUser(variables), {
    onSuccess: (response: User) => {
      authStore.updateUser(response);
      methods.setValue('firstName', response?.firstName);
      methods.setValue('surname', response?.surname);
      toast(`Your changes have been saved. `, {
        type: 'success'
      });
    },
    onError: (error: any) => {
      toast(error.message || 'Something wrong! Please try again.', {
        type: 'error'
      });
    }
  });

  return (
    <>
      <Helmet>
        <title>User Profile</title>
      </Helmet>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((values) => {
            mutate(values);
          })}
        >
          <Header title="User Profile" className="flex justify-between text-gray-900">
            <Button
              isLoading={isLoading}
              className="btn-primary font-normal disabled:hover:bg-purple-100"
              type="submit"
            >
              <CheckIcon className="h-5 w-auto mr-2" />
              Save
            </Button>
          </Header>
          <div className="md:px-8 py-6 px-2">
            <EditProfileForm user={user} onOpenModalChangePassword={() => setShowModalChangePass(true)} />
          </div>
        </form>
      </FormProvider>
      <ChangePasswordModal show={showModalChangePass} onClose={() => setShowModalChangePass(false)} />
    </>
  );
});
