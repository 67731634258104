import { useStores } from '../hooks';
import { Layout } from 'components/layout';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { useQuery } from '@tanstack/react-query';
import { PlanOptionsModal } from 'components/manage-payment/modals/PlanOptionsModal';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { TwoFactorAuthConfirmModal } from 'components/profile';
import { TwoFactorAuthSetupModal } from 'components/profile/TwoFactorAuthSetupModal';
import { fetchUser } from 'api/user';

export const AuthLayout = observer(() => {
  const { uiStore, subscriptionStore, authStore } = useStores();
  const { isTabletAndDown } = useMediaQuery();
  const { currentWorkspaceOTPEnforced, isOwnerCurrentWorkspace } = uiStore;
  const [show2FASetupModal, setShow2FASetupModal] = useState(false);

  const { data: user } = useQuery(['me'], fetchUser, {
    onSuccess: (data) => {
      authStore.updateUser(data);
    },
    staleTime: 300000,
    cacheTime: 300000
  });

  if (isTabletAndDown) {
    uiStore.collapseNav();
  }

  useEffect(() => {
    const user = authStore.user;

    (window as any)?.Beacon?.('identify', {
      name: `${user?.firstName} ${user?.surname}`,
      email: `${user?.email}`
    });
  }, [authStore.user]);

  ///----> Good place to call APIs for data that require for the whole app.

  return (
    <Layout>
      <Outlet />
      <PlanOptionsModal
        show={subscriptionStore.planOptionsModalVisibility}
        onClose={subscriptionStore.hidePlanOptionsModal}
      />
      <TwoFactorAuthConfirmModal
        onConfirm={() => setShow2FASetupModal(true)}
        show={currentWorkspaceOTPEnforced && !user.otpRequiredForLogin && !isOwnerCurrentWorkspace}
        size="lg"
      />
      {show2FASetupModal && (
        <TwoFactorAuthSetupModal show={show2FASetupModal} onClose={() => setShow2FASetupModal(false)} />
      )}
    </Layout>
  );
});
