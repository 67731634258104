import { ReactSVG } from 'react-svg';
import FolderImgSvg from '../../assets/svg/folder-img.svg';

type Props = {
  className?: string;
};

export const FolderImg = ({ className }: Props) => {
  return <ReactSVG src={FolderImgSvg} className={className} />;
};
