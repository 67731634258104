import React, { useEffect } from 'react';
import { useCopyToClipboard } from 'react-use';

export const ShortCodes = () => {
  const shortCodesArray = [
    'unit_code',
    'unit_name',
    'unit_release',
    'map_name',
    'map_version',
    'author',
    'created_at',
    'updated_at',
    'file_name'
  ];

  const ShortCode = ({ code }: { code: string }) => {
    const [copyState, copyToClipboard] = useCopyToClipboard();
    const [copyMessage, setCopyMessage] = React.useState('');

    useEffect(() => {
      if (copyState.error) {
        setCopyMessage('Unable to copy');
      } else if (copyState.value) {
        setCopyMessage('Copied');
      }

      setTimeout(() => {
        setCopyMessage('');
      }, 1000);
    }, [copyState]);

    return (
      <div className="mr-2 hover:text-primary-500 cursor-pointer inline-block relative">
        <span onClick={() => copyToClipboard(`{{${code}}}`)}>{`{{${code}}}`}</span>
        {copyMessage && (
          <span className="bg-black bg-opacity-50 rounded-lg px-1 py-1 absolute top-8 left-3 text-white">
            {copyMessage}
          </span>
        )}
      </div>
    );
  };

  return (
    <div>
      {shortCodesArray.map((code, index) => {
        return <ShortCode key={index} code={code} />;
      })}
    </div>
  );
};
