import { MigrateMapUnitRequest, MigrateUnitRequest, UnitMigrationPlan } from 'types';
import axios from 'lib/axios';

export const fetchUnitMigration = async (unitCode: string, planId: string): Promise<UnitMigrationPlan> => {
  return await axios.get(`units/${unitCode}/migrate/${planId}`).then((res) => {
    return res.data;
  });
};

export const fetchPlanDuplicateToUnit = async (mapId: string, unitCode: string): Promise<UnitMigrationPlan> => {
  return await axios.get(`maps/${mapId}/plan_duplicate_to_unit?unit=${unitCode}`).then((res) => {
    return res.data;
  });
};

export const migrateUnitMap = async (request: MigrateUnitRequest): Promise<UnitMigrationPlan> => {
  return await axios.post(`units/${request.unitCode}/migrate/${request.planId}`, request).then((res) => {
    return res.data;
  });
};

export const duplicatePlanToUnit = async (request: MigrateMapUnitRequest): Promise<UnitMigrationPlan> => {
  return await axios.post(`maps/${request.mapId}/duplicate_to_unit?unit=${request.unitId}`, request).then((res) => {
    return res.data;
  });
};
