import { ReactSVG } from 'react-svg';
import PdfSvg from '../../assets/svg/pdf.svg';

type Props = {
  className?: string;
};

export const PdfIcon = ({ className }: Props) => {
  return <ReactSVG src={PdfSvg} className={className} />;
};
