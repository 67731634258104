import React from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import './style.scss';
import { isHtmlTag } from 'utils/htmlTag';

export type NodeData = {
  id: string;
  label: string;
  level?: string;
  style?: React.CSSProperties;
  className?: string;
  color?: string;
};

export const MigrationNodeSource = ({ data, isConnectable }: NodeProps<NodeData>) => {
  const isHtml = isHtmlTag(data.label);

  return (
    <div
      className={`cursor-pointer text-sm p-3 rounded-lg border node-wrapper ${data.className} border-${data.color}-100`}
      style={data?.style}
    >
      {isHtml ? (
        <div className="h-full unit-element" dangerouslySetInnerHTML={{ __html: data.label }}></div>
      ) : (
        <div className="node-title h-full">
          {data.level && <span>{data.level} </span>} {data.label}
        </div>
      )}
      <Handle
        id="source"
        type="source"
        className={`!w-5 !h-5 !right-[-10px] node-handle`}
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  );
};

export const MigrationNodeTarget = ({ data, isConnectable }: NodeProps<NodeData>) => {
  const isHtml = isHtmlTag(data.label);

  return (
    <div
      className={`cursor-pointer text-sm p-3 rounded-lg border node-wrapper ${data.className} border-${data.color}-100`}
      style={data?.style}
    >
      {isHtml ? (
        <div className="h-full unit-element" dangerouslySetInnerHTML={{ __html: data.label }}></div>
      ) : (
        <div className="node-title h-full">
          {data.level && <span>{data.level} </span>} {data.label}
        </div>
      )}
      <Handle
        id="target"
        type="target"
        className={`!w-5 !h-5 !left-[-10px] node-handle`}
        position={Position.Left}
        isConnectable={isConnectable}
      />
    </div>
  );
};

export const MigrationNode = ({ data }: NodeProps<NodeData>) => {
  return (
    <div
      className={`cursor-text text-sm p-3 rounded-lg border font-medium ${data.className} border-${data.color}-500 bg-${data.color}-100`}
      style={data?.style}
    >
      <div className="line-clamp-2 text-center h-full" dangerouslySetInnerHTML={{ __html: data.label }}></div>
    </div>
  );
};
