import { FaceFrownIcon } from '@heroicons/react/24/outline';

export const NoResult = ({ includeSuperseded }: { includeSuperseded?: boolean }) => {
  const errorMessage = !!includeSuperseded
    ? 'Could not find any results. Please try again.'
    : 'Your item may be superseded. Please tick the checkbox above.';
  return (
    <div className="text-center mt-4">
      <div className="m-auto mb-4 flex justify-center items-center text-gray-900 font-semibold text-base">
        <FaceFrownIcon className="w-[18px] h-[18px]" />
        <span className="ml-4">No result</span>
      </div>
      <p className="text-gray-500 font-medium text-sm">{errorMessage}</p>
    </div>
  );
};
