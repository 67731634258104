import clsx from 'clsx';
import { Button } from 'components/buttons';
import { CourseItem as Course } from 'types';
import './index.scss';

type Props = {
  className?: string;
  onAdd?: (course: Partial<Course>) => void;
  onRemove?: (course: Partial<Course>) => void;
  onModify?: (course: Partial<Course>) => void;
  course: Partial<Course>;
};

export const CourseItem = ({ className, onAdd, onRemove, onModify, course }: Props) => {
  return (
    <div
      className={clsx(className, 'flex justify-between items-center py-2 px-4 border-b border-b-gray-200 course-item')}
    >
      <div className="flex flex-col mr-4">
        <div className="flex items-center">
          <h5 className="text-gray-900 text-base font-semibold">{course.code}</h5>
          <span
            className={clsx(
              course.usage_recommendation === 'Current' && 'text-green-600',
              course.usage_recommendation === 'Superseded' && 'text-red-600',
              'ml-4 text-xs leading-5 capitalize font-medium'
            )}
          >
            {course.usage_recommendation}
          </span>
          <span className="ml-4 text-xs font-medium text-gray-600 leading-5 capitalize">{course.type}</span>
        </div>
        <p className="text-sm font-normal text-gray-900">{course.title}</p>
      </div>
      <div className="flex justify-end">
        {onAdd && (
          <Button
            className="text-sm text-gray-900 font-medium bg-green-100 py-1.5 px-8 rounded-md"
            onClick={() => onAdd(course)}
          >
            Add
          </Button>
        )}
        {onRemove && (
          <Button
            className={clsx('text-sm text-gray-900 bg-gray-100 py-1.5 px-4 rounded-md font-medium', onModify && 'mr-3')}
            onClick={() => onRemove(course)}
          >
            Remove
          </Button>
        )}
        {onModify && (
          <Button
            className="text-sm text-gray-900 font-medium bg-green-100 py-1.5 px-8 rounded-md"
            onClick={() => onModify(course)}
          >
            Modify
          </Button>
        )}
      </div>
    </div>
  );
};
