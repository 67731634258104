import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
  RowData,
  ColumnSizingState
} from '@tanstack/react-table';
import { Fragment, useMemo } from 'react';
import { AssessmentTask, MappingElement, Content } from 'types';
import { DefaultHeader } from './headers';
import { RowSpanCell, TextAreaCell } from './cells';
import { SkillToolTip } from 'components/unit/SkillToolTip';
import * as ReactDOMServer from 'react-dom/server';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    tableId?: string;
    numbering?: boolean;
    updateData: (rowIndex: number, columnId: string, value: any) => void;
    [key: string]: any;
  }
}

const columnHelper = createColumnHelper<MappingElement>();

const defaultColumns = [
  columnHelper.accessor('content', {
    cell: RowSpanCell,
    footer: (info) => info.column.id,
    header: DefaultHeader,
    meta: {
      pinning: true,
      width: 0,
      headerTitle: 'Element',
      headerClass: '!z-[11]',
      tdClass: 'font-medium'
    }
  }),
  columnHelper.accessor('performanceCriteria', {
    header: DefaultHeader,
    footer: (props) => props.column.id,
    cell({ getValue, column: { columnDef } }) {
      const initialValue = getValue() as Content;
      const meta = columnDef.meta as any;
      const width = meta?.width || 0;

      if (!initialValue.reference.length) {
        return (
          <td className="header-sticky reference" style={{ left: `${width}px` }}>
            <span className="text-primary-500 text-sm font-medium mr-1 cursor-default">{initialValue.level}</span>{' '}
            {initialValue.content}
          </td>
        );
      }

      return (
        <td className="header-sticky reference" style={{ left: `${width}px` }}>
          <span
            data-html={true}
            data-tip={ReactDOMServer.renderToString(
              <SkillToolTip skills={initialValue.reference} className="inline-block" />
            )}
            data-for="mapping-tooltip"
            className="text-primary-500 text-sm font-medium mr-1 cursor-default"
          >
            {initialValue.level}
          </span>
          {initialValue.content}
        </td>
      );
    },
    meta: {
      pinning: true,
      width: 300,
      headerTitle: 'Performance Criteria'
    }
  })
];

// Give our default column cell renderer editing superpowers!
const defaultColumn: Partial<ColumnDef<MappingElement>> = {
  header: DefaultHeader
};

type Props = {
  data: MappingElement[];
  assessmentTasks: AssessmentTask[];
  columnSizing?: ColumnSizingState;
  trafficMode?: boolean;
  onColumnSizingChange: (
    columnSizingState: ColumnSizingState,
    tableId?: 'elements' | 'foundationSkills' | 'section' | 'task'
  ) => void;
};

export const ElementTable = ({ data, columnSizing, assessmentTasks, onColumnSizingChange, trafficMode }: Props) => {
  const columnSizingChange = (updater: any) => {
    onColumnSizingChange(updater(), 'elements');
  };

  const columns = useMemo(() => {
    const extraColumns = assessmentTasks.map((item) => {
      return columnHelper.accessor(`assessmentTask${item.id}`, {
        cell: TextAreaCell,
        header: DefaultHeader,
        footer: (props) => props.column.id,
        size: 200,
        meta: {
          showHeaderActions: true,
          headerClass: ``,
          inputType: 'textarea'
        }
      });
    });

    if (columnSizing) {
      const col1 = defaultColumns[1];
      (col1.meta! as any).width = columnSizing ? columnSizing?.content : (col1.meta! as any).width;
    }
    return [...defaultColumns, ...extraColumns];
  }, [assessmentTasks, columnSizing]);

  const { getHeaderGroups, getRowModel } = useReactTable({
    data: data,
    columns,
    defaultColumn,
    enableColumnResizing: true,
    columnResizeMode: 'onEnd',
    state: {
      columnSizing
    },
    onColumnSizingChange: columnSizingChange,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      tableId: 'elements',
      updateData: (rowIndex: number, columnId: string, value: any) => {
        data[rowIndex][columnId] = value;
      },
      trafficMode
    },
    enablePinning: true
  });

  return (
    <table className="table-fixed table-mapping">
      <thead>
        <tr className="bg-primary-100">
          <th
            className="header-sticky top-0 !z-20 left-0 !bg-primary-100 h-th text-primary-500 !text-xs !font-semibold uppercase"
            colSpan={2}
          >
            Elements and Performance Criteria
          </th>
          <th
            colSpan={assessmentTasks.length}
            className="!bg-primary-100 h-th !border-l-0 header-sticky !z-[12] top-0"
          ></th>
          <th className="min-w-th bg-primary-100 border-b h-th !border-l-0 header-sticky top-0 !z-[12]"></th>
        </tr>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="header-sticky top-th !z-[11]">
            {headerGroup.headers
              .filter((header) => !header.id.includes('assessmentTask'))
              .map((header) => (
                <Fragment key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</Fragment>
              ))}
            {headerGroup.headers
              .filter((header) => header.id.includes('assessmentTask'))
              .map((header) => (
                <Fragment key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</Fragment>
              ))}
            <th className="min-w-th bg-white"></th>
          </tr>
        ))}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <Fragment key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>
            ))}
            <td></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
