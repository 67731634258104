import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addToLibrary } from 'api';
import { Button } from 'components/buttons';
import { Modal, ModalProps } from 'flowbite-react';
import { useStores } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Unit } from 'types';

type Props = {
  unit?: Pick<Unit, 'code' | 'title'>;
  title?: string;
  handleCallback: () => void;
  directToFolder?: boolean;
} & ModalProps;

export const AddUnitModal = ({ title, unit, handleCallback, directToFolder = false, ...modalProps }: Props) => {
  const { mapUIStore, subscriptionStore, sidebarTreeStore } = useStores();
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const { mutate, isLoading: mutationLoading } = useMutation(addToLibrary, {
    onSuccess: async (res: any) => {
      toast('Successfully added unit to your Library. ', {
        type: 'success'
      });
      handleCallback?.();
      mapUIStore.closeModal();
      queryClient.invalidateQueries(['workspaces', 'subscription', 'current']);
      queryClient.invalidateQueries(['unitsOptions']);
      queryClient.invalidateQueries(['unit_folder_list']);
      await sidebarTreeStore.loadUnitFolders();

      if (res?.id && directToFolder) {
        navigate(`/library/folders/${res?.id}`);
      }
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Something went wrong! Please try again. ';

      if (errorMessage === 'INVENTORY_NOT_AVAILABLE') {
        mapUIStore.closeModal();
        subscriptionStore.showPlanOptionsModal(true);
        return;
      }

      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  const modalTitle = title ? title : 'Add unit into your Library now?';

  const handleClose = () => {
    mapUIStore.closeModal();
  };

  const handleConfirmSubmit = () => {
    if (!unit?.code) return;
    if (mutationLoading) return;
    mutate(unit?.code);
  };

  return (
    <Modal {...modalProps}>
      <div className="p-4">
        <h4 className="text-sm font-medium text-gray-900">{modalTitle}</h4>
        <span className="text-xs leading-5 text-gray-700">{`${unit?.code} - ${unit?.title}`}</span>
      </div>

      {/* actions */}
      <div className="p-4 pt-0">
        <div className="flex justify-end">
          <button
            type="button"
            className="hover:bg-gray-200 px-4 h-10 rounded-md mr-4 text-sm font-medium text-gray-900"
            onClick={handleClose}
          >
            No
          </button>
          <Button
            type="button"
            className="bg-primary-500 hover:bg-primary-700 px-4 h-10 rounded-md text-white text-sm font-medium"
            onClick={handleConfirmSubmit}
            isLoading={mutationLoading}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};
