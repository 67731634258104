import { UnitBlank } from 'components/icons';
import { useStores } from 'hooks';

export const UnitAction = ({ isChildFolder }: { isChildFolder: boolean }) => {
  const { uiStore } = useStores();

  const onOpenModalAddNewUnit = () => {
    uiStore.openSearchBar();
  };

  if (isChildFolder) {
    return (
      <>
        <UnitBlank className="mb-8" />
        <p className="text-sm text-gray-900">
          Click
          <span className="relative px-1 inline-block text-sm  text-primary-500 group active:text-orange-500 focus:outline-none focus:ring">
            New Map
          </span>
          on header to start adding a new Map for this Unit.
        </p>
      </>
    );
  }

  return (
    <>
      <UnitBlank className="mb-8" />
      <p className="text-sm text-gray-900">
        Click
        <span
          className="relative px-1 inline-block text-sm  text-primary-500 group active:text-orange-500 focus:outline-none focus:ring cursor-pointer"
          onClick={onOpenModalAddNewUnit}
        >
          Add New Unit
        </span>
        or select
        <span
          className="relative px-1 inline-block text-sm  text-primary-500 group active:text-orange-500 focus:outline-none focus:ring cursor-pointer"
          onClick={onOpenModalAddNewUnit}
        >
          Add New
        </span>
        from sidebar to search for a unit from training.gov.au and add to your Library.
      </p>
    </>
  );
};
