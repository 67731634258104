import { Link } from 'react-router-dom';
import { LogoIcon, LogoSmallIcon } from 'components/icons';
import { NavbarToggleButton } from './ToggleButton';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';

export const NavbarLogo = observer(() => {
  const { uiStore } = useStores();
  const { isNavbarCollapsed } = uiStore;
  return (
    <div className="h-76 flex items-center bg-white sticky top-0 whitespace-nowrap z-10">
      <Link className="pl-8 overflow-hidden" to="/">
        {isNavbarCollapsed ? <LogoSmallIcon /> : <LogoIcon />}
      </Link>
      <NavbarToggleButton />
    </div>
  );
});
