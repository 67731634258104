import { Course, CourseUnit } from 'types';

export const getHeaderBadges = (course: Course) => {
  if (!course?.id) return [];

  const isCurrentUsageRecommendation = course?.usageRecommendation === 'Current';
  const courseTypes: { [key: string]: string } = {
    skill: 'Skillset',
    qualification: 'Qualification'
  };

  return [
    {
      title: course?.usageRecommendation,
      className: isCurrentUsageRecommendation ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
    },
    {
      title: courseTypes[course?.componentType],
      className: 'bg-yellow-100 text-yellow-700'
    },
    {
      title: `Release ${course?.releaseNumber}`,
      className: 'bg-primary-200 text-primary-500'
    }
  ];
};

export const getTabItem = (to: string, title: string, totalItems: number) => {
  return {
    to,
    title: `${title} (${totalItems || 0})`,
    activeExact: true
  };
};

export const searchUnit = (units: CourseUnit[] = [], unitSearchText: string = '') => {
  const searchText = unitSearchText.trim();
  if (!searchText) return units;

  return units.filter((unit: CourseUnit) => {
    return (
      unit.code.toLocaleLowerCase().includes(searchText.toLowerCase()) ||
      unit.title.toLocaleLowerCase().includes(searchText.toLowerCase())
    );
  });
};

export const getUnitsCount = (units: CourseUnit[], unitSearchText: string) => {
  const allUnits = searchUnit(units, unitSearchText);
  const allUnitsCount = allUnits?.length;
  const coreUnits = allUnits?.filter((unit: CourseUnit) => unit.type === 'Core');
  const coreUnitsCount = coreUnits?.length;
  const electiveUnitsCount = allUnitsCount - coreUnitsCount;

  return {
    allUnitsCount,
    coreUnitsCount,
    electiveUnitsCount
  };
};

export const getTabs = (course: Course, unitSearchText: string) => {
  const unitCount = getUnitsCount(course?.units || [], unitSearchText);

  // default tab
  const isQualification = course.componentType === 'qualification';
  const defaultTab = getTabItem(`/courses/${course.code}`, 'All', unitCount.allUnitsCount);

  // type skill set
  if (!isQualification) return [defaultTab];

  // type qualification
  const coreUnitsTab = getTabItem(`/courses/${course.code}/core-units`, 'Core Units', unitCount.coreUnitsCount);
  const electiveUnitsTab = getTabItem(
    `/courses/${course.code}/elective-units`,
    'Elective Units',
    unitCount.electiveUnitsCount
  );

  return [defaultTab, coreUnitsTab, electiveUnitsTab];
};
