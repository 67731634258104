import { ReactNode, useMemo, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { RectangleStackIcon } from '@heroicons/react/24/outline';
import { Header, Loading, Tabs, Pagination } from 'components';
import { CourseSearch, CourseDetailModal } from 'components/rto';
import { ErrorResponse, RTO as IRTO, RTOCoursePaging } from 'types';
import { fetchRtoDetail, fetchRtoCourses } from 'api';
import { getDefaultFilter, updateFilter, updateSearch, getTabs, getActiveTab } from 'domains/rto';

export const RTO = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { code } = useParams();

  // get rto detail
  const { data: rto = {} as IRTO, isLoading } = useQuery<IRTO, ErrorResponse>(
    ['rto', code],
    () => fetchRtoDetail(code as string),
    {
      enabled: !!code
    }
  );

  const [courseCode, setCourseCode] = useState('');
  const [courseCount, setCourseCount] = useState({
    allCoursesCount: 0,
    currentCoursesCount: 0,
    supersededCoursesCount: 0
  });
  const [filter, setFilter] = useState<any>({
    all: getDefaultFilter(),
    current: getDefaultFilter('Current'),
    superseded: getDefaultFilter('Superseded')
  });

  const tabs = useMemo(() => getTabs(rto, courseCount), [rto, courseCount]);
  const activeTab = useMemo(() => getActiveTab(location), [location]);
  const tabFilter = useMemo(() => filter[activeTab], [filter, activeTab]);

  // get rto's courses
  const { data, isLoading: isFetchingCourses } = useQuery<RTOCoursePaging, ErrorResponse>(
    ['rto', 'courses', code, tabFilter],
    () => fetchRtoCourses(code as string, tabFilter),
    {
      enabled: !!code
    }
  );

  const isShowPagination = !isFetchingCourses && Number(data?.totalPages) > 1;
  const isNoSearchResult = !isFetchingCourses && !data?.records?.length;

  // effect to set tab count
  useEffect(() => {
    if (!data) return;
    const { allCoursesCount, currentCoursesCount, supersededCoursesCount } = data;
    setCourseCount({
      allCoursesCount,
      currentCoursesCount,
      supersededCoursesCount
    });
  }, [data]);

  const handleSearch = (value: string) => {
    const nextFilter = updateSearch(filter, value);
    setFilter(nextFilter);
  };

  const handleClearSearch = () => {
    const nextFilter = updateSearch(filter, '');
    setFilter(nextFilter);
  };

  const handleViewCourse = (code: string) => {
    setCourseCode(code);
  };

  const handleClearCourseCode = () => {
    setCourseCode('');
  };

  const handlePageChange = (page: number) => {
    const nextFilter = updateFilter(activeTab, filter, { page });
    setFilter(nextFilter);
  };

  const RTOHeader = ({ children }: { children?: ReactNode }) => {
    const title = `RTO #${code} Scope`;
    const subTitle = rto?.legalName || '...';
    return (
      <>
        <Helmet>
          <title>
            {title} - {subTitle}
          </title>
        </Helmet>
        <Header title={title} subTitle={subTitle}>
          <div className="mt-4 lg:mt-0">{children}</div>
        </Header>
      </>
    );
  };

  const RTOBody = () => {
    if (isLoading || isFetchingCourses) {
      return (
        <div className="min-h-[200px] relative">
          <Loading />
        </div>
      );
    }

    return (
      <Outlet
        context={{
          courses: data?.records || [],
          onViewCourse: handleViewCourse
        }}
      />
    );
  };

  return (
    <>
      <RTOHeader>
        <button
          className="btn-primary text-xs sm:text-sm px-3 sm:px-4"
          onClick={() => navigate({ pathname: `/library/rtos/${code}/new` })}
        >
          <RectangleStackIcon className="w-4 sm:w-5 h-4 sm:h-5 inline-block mr-2" />
          Add & Manage RTO in Library
        </button>
      </RTOHeader>

      {/* content */}
      <div className="p-2 md:p-8">
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between mb-4">
            {/* Tabs */}
            <Tabs
              tabs={tabs}
              classes={{
                root: 'bg-primary-200 border-none rounded-lg p-1 space-x-2',
                tab: 'flex items-center bg-primary-200 hover:bg-primary-400 duration-300 rounded-lg text-primary-500 text-xs md:text-sm h-8 md:h-10 px-2 md:px-4'
              }}
            />

            {/* Search */}
            <CourseSearch
              searchText={tabFilter.q}
              isNoSearchResult={isNoSearchResult}
              onSearch={handleSearch}
              onClearSearch={handleClearSearch}
            />
          </div>

          {/* body */}
          <RTOBody />
        </div>

        {/* pagination */}
        {isShowPagination && (
          <div className="flex items-center justify-end mt-6">
            <Pagination
              currentPage={tabFilter.page}
              onPageChange={handlePageChange}
              totalPages={data?.totalPages || 1}
            />
          </div>
        )}
      </div>

      {/* modals */}
      <CourseDetailModal courseCode={courseCode} onClose={handleClearCourseCode} />
    </>
  );
};
