import axios, { AxiosRequestConfig } from 'axios';
import { onRequest, onRequestError, onResponse, onResponseError } from './interceptor';

export const config: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_URL || window.location.origin,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Expose-Headers': 'Authorization'
  }
};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(onRequest, onRequestError);
axiosInstance.interceptors.response.use(onResponse, onResponseError);

export default axiosInstance;
