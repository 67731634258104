import { PresentationChartBarIcon, FolderIcon, BuildingOfficeIcon, AcademicCapIcon } from '@heroicons/react/24/outline';
import { useStores } from 'hooks';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

export const SimpleAction = observer(() => {
  let navigate = useNavigate();
  const { uiStore } = useStores();

  const onOpenSearBar = () => {
    uiStore.openSearchBar();
  };

  const onOpenFolderPage = () => {
    navigate({
      pathname: '/library/regulars'
    });
  };

  const openHelpScout = () => {
    const Beacon = (window as any).Beacon;
    Beacon?.('open');
    Beacon?.('navigate', '/ask/message');
  };

  return (
    <>
      <div className="bg-white rounded-lg p-6 shadow">
        <h5 className="text-xs leading-5 font-semibold uppercase text-primary-500">Simple steps to get started!</h5>
        <div>
          <div className="rounded-lg px-8 py-4 shadow w-full mt-6">
            <div className="flex flex-col sm:flex-row items-center">
              <div className="w-[60px] h-[60px] flex items-center justify-center rounded-full bg-purple-100 text-primary-500">
                <PresentationChartBarIcon className="w-7 h-7" />
              </div>
              <span className="ml-0 sm:ml-6">
                <p className="text-base font-medium text-gray-900">Sync a unit from training.gov.au</p>
                <p className="text-sm font-normal text-gray-900 ">
                  Select
                  <span
                    className="relative px-1 inline-block  text-primary-500 group active:text-orange-500 focus:outline-none focus:ring cursor-pointer"
                    onClick={onOpenSearBar}
                  >
                    Add New
                  </span>
                  from sidebar to search a unit from training.gov.au and add to your Library.
                </p>
              </span>
            </div>
          </div>
          <div className="rounded-lg px-8 py-4 shadow w-full mt-6">
            <div className="flex flex-col sm:flex-row items-center">
              <div className="w-[60px] h-[60px] flex items-center justify-center rounded-full bg-purple-100 text-primary-500">
                <FolderIcon className="w-7 h-7" />
              </div>
              <span className="ml-6">
                <p className="text-base font-medium text-gray-900">Create your first folder!</p>
                <p className="text-sm font-normal text-gray-900">
                  Select
                  <span
                    className="relative px-1 inline-block  text-primary-500 group active:text-orange-500 focus:outline-none focus:ring cursor-pointer"
                    onClick={onOpenFolderPage}
                  >
                    Folders
                  </span>
                  to create a new folder and organise all your maps.
                </p>
              </span>
            </div>
          </div>
          <div className="rounded-lg px-8 py-4 shadow w-full mt-6">
            <div className="flex flex-col sm:flex-row items-center">
              <div className="w-[60px] h-[60px] flex items-center justify-center rounded-full bg-purple-100 text-primary-500">
                <AcademicCapIcon className="w-7 h-7" />
              </div>
              <span className="ml-0 sm:ml-6">
                <p className="text-base font-medium text-gray-900">Sync a new course from training.gov.au</p>
                <p className="text-sm font-normal text-gray-900">
                  Select
                  <span
                    className="relative px-1 inline-block  text-primary-500 group active:text-orange-500 focus:outline-none focus:ring cursor-pointer"
                    onClick={onOpenSearBar}
                  >
                    Add New
                  </span>
                  from sidebar to search and sync a course and add to your Library.
                </p>
              </span>
            </div>
          </div>
          <div className="rounded-lg px-8 py-4 shadow w-full mt-6">
            <div className="flex flex-col sm:flex-row items-center">
              <div className="w-[60px] h-[60px] flex items-center justify-center rounded-full bg-purple-100 text-primary-500">
                <BuildingOfficeIcon className="w-7 h-7" />
              </div>
              <span className="ml-0 sm:ml-6">
                <p className="text-base font-medium text-gray-900">Sync an RTO and add to your Library</p>
                <p className="text-sm font-normal text-gray-900">
                  Select
                  <span
                    className="relative px-1 inline-block  text-primary-500 group active:text-orange-500 focus:outline-none focus:ring cursor-pointer"
                    onClick={onOpenSearBar}
                  >
                    Add New
                  </span>
                  from sidebar to search and sync an RTO and add to your Library.
                </p>
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mt-8">
          <span className="text-sm font-medium text-gray-900">Need more help to get started?</span>
          <button className="btn bg-purple-100 text-primary-500 text-sm ml-3 uppercase" onClick={() => openHelpScout()}>
            Send us a Message
          </button>
        </div>
      </div>
    </>
  );
});
