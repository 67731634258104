import { Button } from 'components/buttons';
import { Modal, ModalProps } from 'flowbite-react';
import LockOpen from 'assets/svg/lock-open.svg';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

type Props = { mode: 'Enable' | 'Disable'; onConfirm: () => void } & ModalProps;
export const TwoFactorAuthWorkspaceConfirmModal = (props: Props) => {
  const title = props.mode;
  const message =
    props.mode === 'Enable'
      ? 'Are you sure you want to turn on two-factor authentication (2FA) for this workspace? All users will need to complete two-factor authentication to access the workspace.'
      : 'Are you sure you want to turn off two-factor authentication (2FA) for this workspace?';
  const confirmText = props.mode === 'Enable' ? 'Yes, enable 2FA' : 'Yes, disable 2FA';

  return (
    <Modal {...props}>
      <div className="flex items-center p-4 pb-0 justify-between">
        <div className="p-2 rounded-full bg-purple-100">
          <img src={LockOpen} alt="lock" className="mx-auto w-5 h-5" />
        </div>
        <Button className={`form-control !text-sm !text-gray-900`} onClick={() => props.onClose?.()}>
          <XMarkIcon
            className={clsx(
              'hover:rotate-90 duration-300 hover:text-primary-500',
              'text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none'
            )}
          />
        </Button>
      </div>
      <div className="p-4 text-sm">
        <h4 className="text-base mb-4 font-medium text-gray-900">{title} Two Factor Authentication (2FA)</h4>
        <p>{message}</p>
      </div>
      <div className="p-4 pt-0">
        <div className="flex justify-end">
          <button
            type="button"
            className="hover:bg-gray-200 px-4 h-10 rounded-md mr-4 text-sm font-medium text-gray-900"
            onClick={() => props.onClose?.()}
          >
            Cancel
          </button>
          <Button
            type="button"
            className="bg-primary-500 hover:bg-primary-700 px-4 h-10 rounded-md text-white text-sm font-medium"
            onClick={() => props.onConfirm()}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
