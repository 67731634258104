import { removeMember } from 'api';
import { Modal, ModalProps } from 'flowbite-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Button } from 'components/buttons';
import { useStores } from 'hooks';
import { MemberStatus } from 'types/enums';
import { fullName } from 'utils/helpers';

export const DeleteMemberModal = (props: ModalProps) => {
  const { manageUsersStore } = useStores();
  const { currentMember } = manageUsersStore;
  const memberName = currentMember ? fullName(currentMember?.firstName, currentMember?.surname) : null;
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(removeMember, {
    onSuccess: () => {
      queryClient.invalidateQueries(['members']);
      queryClient.invalidateQueries(['workspaces', 'subscription', 'current']);
      const successMessage =
        currentMember?.status === MemberStatus.Pending
          ? `The invitation has been successfully revoked.`
          : `The user ${memberName} has been successfully removed from this workspace.`;

      toast(successMessage, {
        type: 'success'
      });
      manageUsersStore.closeModal();
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  const Title = () => {
    if (currentMember?.status === MemberStatus.Pending) {
      return (
        <p className="text-gray-900 break-words">
          Are you sure you want to revoke invitation that sent to{' '}
          <span className="font-semibold">{currentMember?.email}</span>?
        </p>
      );
    }

    return (
      <p className="text-gray-900 break-words">
        Are you sure you want to remove <span className="font-semibold">{memberName}</span> from your workspace?
      </p>
    );
  };

  return (
    <Modal {...props}>
      <div className="p-4">
        <div className="mt-2 text-sm font-normal text-gray-700">
          <Title />
        </div>
        <div className="mt-4 action flex justify-end space-x-4">
          <Button
            disabled={isLoading}
            className="btn-primary text-sm font-medium bg-white text-gray-900 hover:bg-gray-200 disabled:bg-white disabled:hover:bg-white mr-2"
            onClick={() => manageUsersStore.closeModal()}
          >
            No
          </Button>
          <Button
            isLoading={isLoading}
            className="btn-primary font-medium justify-center outline-0 text-center disabled:hover:bg-purple-100"
            onClick={() => {
              mutate(`${currentMember?.id}`);
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};
