import clsx from 'clsx';
import { TabItem } from './TabItem';

type Props = {
  tabs: {
    to: string;
    title: string;
    activeExact?: boolean;
  }[];
  classes?: {
    root: string;
    tab: string;
  };
  isUpdateUrl?: boolean;
  activeTabIndex?: number;
  onChange?: (tabIndex: number) => void;
};

export const Tabs = ({ tabs, classes, isUpdateUrl = true, activeTabIndex, onChange }: Props) => {
  const handleClickTab = (tabIndex: number) => (event: React.MouseEvent<HTMLElement>) => {
    if (isUpdateUrl) return;
    event.preventDefault();
    onChange?.(tabIndex);
  };

  return (
    <ul className={clsx('flex flex-wrap text-sm text-center text-gray-900 border-b border-primary-200', classes?.root)}>
      {tabs.map((tab, index) => (
        <TabItem
          key={index}
          to={tab.to}
          title={tab.title}
          classes={classes}
          activeExact={tab.activeExact}
          tabIndex={index}
          activeTabIndex={activeTabIndex}
          onClick={handleClickTab(index)}
        />
      ))}
    </ul>
  );
};
