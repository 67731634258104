import { observer } from 'mobx-react-lite';
import useResizeObserver from 'use-resize-observer';
import { SidebarTree } from 'components/sidebar-tree';

export const NavbarListItem = observer(() => {
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

  return (
    <div className="overflow-y-auto overflow-x-hidden flex-1 pl-4 pt-6" ref={ref}>
      <SidebarTree width={width} height={height} />
    </div>
  );
});
