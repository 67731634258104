import { FoundationSkill } from 'types';
import { Tooltip } from 'flowbite-react';
import 'index.scss';

type Props = {
  className?: string;
  foundationSkills: FoundationSkill[];
  levels: any;
};

export const FoundationSkillsTable = ({ foundationSkills, levels, className }: Props) => {
  const hasPerformanceCriteria = foundationSkills.findIndex((f) => f.kind === 'performance_criteria') > -1;
  const hasSkillCol = foundationSkills.findIndex((f) => f.skill) > -1;

  const Row = ({ foundationSkill }: { foundationSkill: FoundationSkill }) => {
    const { foundationContents: rawFoundationContents, performanceCriteria } = foundationSkill;
    const foundationContents = [...rawFoundationContents!];
    const firstPerformanceCriterion = foundationContents?.shift();
    return (
      <>
        <tr>
          {hasSkillCol && (
            <th className="font-medium border-t align-middle" rowSpan={foundationContents.length + 1}>
              {foundationSkill.skill}
            </th>
          )}
          {hasPerformanceCriteria && (
            <th className="font-medium border-t border-l w-[282px] align-top" rowSpan={foundationContents.length + 1}>
              {performanceCriteria.map((item, index) => (
                <div key={index} className="performance-criteria inline-flex justify-center items-center mr-2 mb-2">
                  <Tooltip content={levels[`${item}`]} arrow={false} placement="top-start">
                    <button className="w-[40px] h-[40px] rounded-md bg-primary-100 text-primary-500 text-sm font-medium hover:bg-primary-200">
                      {item}
                    </button>
                  </Tooltip>
                </div>
              ))}
            </th>
          )}
          <td className="border-t border-l">{firstPerformanceCriterion?.content}</td>
        </tr>
        {foundationContents.map((foundation, index) => (
          <tr key={index}>
            <td className="border-t border-l">{foundation.content}</td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className={`foundation-skill-table ${className}`}>
      <h3 className="uppercase mb-4 text-orange-500 text-xs font-semibold leading-5">Foundation Skills</h3>
      <table className="table w-full">
        <thead className="bg-orange-100">
          <tr>
            {hasSkillCol && <th className="text-left font-medium rounded-tl-lg">Skill</th>}
            {hasPerformanceCriteria && <th className="text-left font-medium">Performance Criteria</th>}
            <th className="text-left font-medium rounded-tr-lg">Description</th>
          </tr>
        </thead>
        <tbody>
          {foundationSkills.map((foundationSkill, index) => (
            <Row key={index} foundationSkill={foundationSkill} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
