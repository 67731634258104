import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';

interface Props {
  id: string;
  label: string | JSX.Element;
  inputProps: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}

export const Radio: FC<Props> = ({ id, label, inputProps }) => {
  return (
    <div className="flex items-center mb-4">
      <input
        id={id}
        type="radio"
        className="w-4 h-4 text-primary-500 bg-gray-100 border-gray-300 focus:ring-primary-400 dark:focus:ring-primary-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        {...inputProps}
      />
      <label htmlFor={id} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
        {label}
      </label>
    </div>
  );
};
