import { ReactSVG } from 'react-svg';
import NotAvailableSvg from '../../assets/svg/not-available.svg';

type Props = {
  className?: string;
};

export const NotAvailable = ({ className }: Props) => {
  return <ReactSVG src={NotAvailableSvg} className={className} />;
};
