import { Modal, ModalProps } from 'flowbite-react';
import { Library, LibraryFolder } from 'types';
import { CourseConfigurationContainer } from '../CourseConfigurationContainer';

type Props = {
  code: string;
  folder?: Library;
  folderId: string;
  onCancel?: () => void;
  onSave?: (folder: LibraryFolder) => void;
} & ModalProps;

export const CourseConfigurationModal = ({ code, folder, folderId, onCancel, onSave, ...modalProps }: Props) => {
  return (
    <Modal {...modalProps} size="7xl" position="top-center" id="course-configuration-modal">
      <div className="flex h-[78vh]">
        <CourseConfigurationContainer
          {...{ code, library: folder, folderId, type: 'Modal', onCancel, onSave }}
          className="!h-full !px-0 !py-0"
        />
      </div>
    </Modal>
  );
};
