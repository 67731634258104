import { useState } from 'react';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Library, NodeType } from 'types';
import { useStores } from 'hooks/useStores';
import { CourseConfigurationModal } from 'components/library/course';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { UnitFolderActions } from './HeaderActions/UnitFolderActions';

export const HeaderActions = observer(({ dataDetail }: { dataDetail?: Library }) => {
  const { libraryStore } = useStores();
  const navigate = useNavigate();

  const handleCreateNewFolder = () => {
    libraryStore.openNewModal();
  };

  const handleModifyRTO = () => {
    navigate({ pathname: `/library/rtos/${dataDetail?.source.code}/${dataDetail?.id}` });
  };

  const ButtonCourseAction = () => {
    const [openModal, setOpenModal] = useState(false);
    return (
      <>
        <button
          className="btn-primary text-sm ml-3 hidden md:block"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <PencilIcon className="w-5 h-5 inline-block mr-2" />
          Modify
        </button>
        {openModal && (
          <CourseConfigurationModal
            show={openModal}
            onCancel={() => setOpenModal(false)}
            {...{ code: dataDetail!.source.code, folderId: dataDetail!.id.toString() }}
          />
        )}
      </>
    );
  };

  const ButtonRegularAction = () => {
    return (
      <>
        <button
          className="btn-primary text-sm ml-3 hidden md:block"
          onClick={handleCreateNewFolder}
          data-testid="newFolderBtn"
        >
          <PlusIcon className="w-5 h-5 inline-block mr-2" />
          New Folder
        </button>
      </>
    );
  };

  const ButtonRTOAction = () => {
    return (
      <>
        <button
          className="btn-primary text-sm ml-3 hidden md:block"
          onClick={handleModifyRTO}
          data-testid="editFolderBtn"
        >
          <PencilIcon className="w-5 h-5 inline-block mr-2" />
          Modify
        </button>
      </>
    );
  };

  const ActionButton = () => {
    const type = dataDetail?.type;

    switch (type) {
      case NodeType.REGULAR:
        return <ButtonRegularAction />;
      case NodeType.RTO:
        return <ButtonRTOAction />;
      case NodeType.COURSE:
        return <ButtonCourseAction />;
      case NodeType.UNIT:
        return <UnitFolderActions folder={dataDetail} />;

      default:
        return <></>;
    }
  };

  return <ActionButton />;
});
