import { ReactNode, useEffect } from 'react';
import { createPopper, Placement } from '@popperjs/core';
import './style.scss';
import clsx from 'clsx';

type Props = {
  id: string;
  title: string;
  placement?: Placement;
  children?: ReactNode;
  className?: string;
};

// https://popper.js.org/
export function Tooltip({ id, title, placement = 'right', children, className }: Props) {
  useEffect(() => {
    const target = document.querySelector(`#${id}`) as any;
    const tooltip = document.querySelector(`#${id}-tooltip`) as any;
    if (!target) return;

    createPopper(target, tooltip, {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8]
          }
        }
      ]
    });
  }, [id, placement]);

  return (
    <div className={clsx('tooltip', className)}>
      <div id={id} className="tooltip__target" aria-describedby="tooltip">
        {children}
      </div>
      <div id={`${id}-tooltip`} className="tooltip__title" role="tooltip">
        {title}
        <div id={`${id}-arrow`} className="tooltip__arrow" data-popper-arrow />
      </div>
    </div>
  );
}
