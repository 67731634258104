import {
  CheckCircleIcon,
  ChevronDoubleLeftIcon,
  CloudArrowUpIcon,
  ExclamationCircleIcon,
  FolderIcon
} from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { generateDownloadMapUrl, updateMap } from 'api';
import { DownloadDropdown } from 'components/dropdown';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { formatDistance } from 'date-fns';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { UpdateMapRequest } from 'types';
import { AssignMapModal } from './assign-map';
import { MappingNote } from './Note';

type Props = {
  unitId: number;
  code: string;
  releaseNumber: string;
  name: string;
  comment: string;
  trafficMode?: boolean;
};

export const MappingHeader = observer(({ unitId, code, releaseNumber, name, comment, trafficMode }: Props) => {
  const { id } = useParams();
  const [assignModal, setAssignModal] = useState(false);
  const { isMobile } = useMediaQuery();
  const { register, setValue, getValues } = useForm<{ name: string; trafficMode: boolean }>();
  const {
    mapUIStore: { setSyncStatus },
    uiStore,
    sidebarTreeStore
  } = useStores();
  const { isNavbarCollapsed } = uiStore;

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!getValues('name')) {
      setValue('name', name);
    }
    if (trafficMode) {
      setValue('trafficMode', trafficMode);
    }
  });

  const update = async (update: Partial<UpdateMapRequest>) => {
    if (update.name && update.name === name) {
      return;
    }

    if (update.comment && update.comment === comment) {
      return;
    }

    if (update.trafficMode && update.trafficMode === trafficMode) {
      return;
    }

    update.id = id as string;

    await updateMap({ ...(update as UpdateMapRequest) });
    queryClient.setQueryData(['maps', id], (old: any) => {
      return {
        ...old,
        ...update,
        id: old.id
      };
    });
  };

  const { mutate } = useMutation(update, {
    onSuccess: () => {
      setSyncStatus('saved');
    },
    onMutate: () => {
      setSyncStatus('saving');
    },
    onError: () => {
      setSyncStatus('error');
    }
  });

  return (
    <div className="flex items-center border-b border-gray-200 py-3 md:px-8 px-2 bg-white mapping-note">
      {isNavbarCollapsed && (
        <div
          role="button"
          className="flex items-center justify-center w-6 h-6 rounded-lg bg-primary-200 text-primary-500 cursor-pointer sm:hidden mr-3"
          onClick={() => {
            uiStore.toggleNav();
          }}
        >
          <ChevronDoubleLeftIcon className="duration-300 h-4 w-4 rotate-180" />
        </div>
      )}
      <div className="flex flex-col lg:flex-row flex-1">
        <div className="flex flex-col flex-1">
          <div className="flex form-group mb-4">
            <input
              {...register('name')}
              onBlur={() => mutate({ name: getValues('name') })}
              className="form-control max-w-sm md:mr-6 text-sm"
              type="text"
              placeholder="Untitled map"
            />
            <div
              className="md:flex hidden items-center text-sm font-medium cursor-pointer"
              onClick={() => setAssignModal(true)}
            >
              <FolderIcon className="h-[14px] w-auto mr-2" /> Assign
            </div>
            {!isMobile && <Status />}
          </div>
          <div>
            <ul className="flex text-sm font-medium">
              <li className="flex pr-3 border-r border-gray-200">
                <Link className="flex" to={`/library/folders/${sidebarTreeStore.folderIdByUnitId(unitId)}`}>
                  Unit: <span className="text-primary-500 ml-2 cursor-pointer"> {code}</span>
                </Link>
              </li>
              <li className="flex px-3 border-r border-gray-200">
                Release: <span className="text-primary-500 ml-2"> {releaseNumber}</span>
              </li>
              <li className="flex items-center px-3 border-gray-200 cursor-pointer relative z-50">
                <MappingNote
                  comment={comment}
                  onSave={(comment: string) => {
                    mutate({ comment });
                  }}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="md:inline-flex md:justify-start md:items-center hidden lg:mt-0 mt-4">
          {/* <label htmlFor="trafficMode" className="inline-flex relative items-center cursor-pointer mr-3">
            <input
              type="checkbox"
              id="trafficMode"
              className="sr-only peer"
              {...register('trafficMode')}
              onChange={(event) => {
                update({ trafficMode: event.target.checked });
              }}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-primary-300  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-600"></div>
            <span className="ml-3 text-sm font-normal text-gray-900">Traffic-mode</span>
          </label> */}
          <DownloadDropdown id={id || ''} downloadAPI={generateDownloadMapUrl} btnTitle="Download Map" />
        </div>
      </div>
      {assignModal && (
        <AssignMapModal
          id={id!}
          show={assignModal}
          onClose={() => {
            setAssignModal(false);
          }}
        />
      )}
    </div>
  );
});

const Status = observer(() => {
  const {
    mapUIStore: {
      syncStatus: { status, datetime }
    }
  } = useStores();

  let timeStatus = 'All updates saved';
  if (datetime) {
    timeStatus = `Saved ${formatDistance(datetime, new Date(), { addSuffix: true })}`;
  }

  return (
    <div className="flex items-center ml-2 flex-1 text-sm">
      {status === 'error' && (
        <div className="flex text-xs font-medium mr-2 px-2.5 py-1 rounded-full text-red-700 bg-red-100">
          <ExclamationCircleIcon className="h-4 w-4 mr-2" /> <span>Failed to save</span>
        </div>
      )}
      {status === 'saving' && (
        <div className="flex text-xs font-medium mr-2 px-2.5 py-1 rounded-full text-gray-700 bg-gray-200">
          <CloudArrowUpIcon className="h-4 w-4 mr-2" />
          <span>Saving ...</span>
        </div>
      )}
      {status === 'saved' && (
        <div className="flex text-xs font-medium mr-2 px-2.5 py-1 rounded-full text-green-700 bg-green-100">
          <CheckCircleIcon className="h-4 w-4 mr-2 " />
          {timeStatus}
        </div>
      )}
    </div>
  );
});
