import {
  ColumnDef,
  ColumnSizingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import { Fragment, useMemo } from 'react';
import { AssessmentTask, Content, MappingFoundationSkill, UpdateMapRequest } from 'types';
import { DefaultHeader } from './headers';
import { RowSpanCell, TextAreaCell } from './cells';
import * as ReactDOMServer from 'react-dom/server';

const columnHelper = createColumnHelper<MappingFoundationSkill>();

const defaultColumns = [
  columnHelper.accessor('skill', {
    cell: RowSpanCell,
    footer: (info) => info.column.id,
    meta: {
      pinning: true,
      width: 0,
      headerTitle: 'Skill',
      headerClass: '!z-[12]',
      tdClass: 'font-medium'
    }
  }),
  columnHelper.accessor('performanceCriteria', {
    footer: (props) => props.column.id,
    meta: {
      pinning: true,
      width: 200,
      headerTitle: 'Performance Criteria',
      headerClass: '!z-[11]',
      tdClass: 'font-medium'
    },
    cell: ({ getValue, column: { columnDef }, table: { options } }) => {
      const levels = getValue() as string[];
      const meta = columnDef.meta as any;
      const width = meta?.width || 0;
      const levelData = options.meta?.levels || {};

      return (
        <td className="header-sticky reference" style={{ left: `${width}px` }}>
          {levels.map((level, index) => (
            <div key={index} className="inline-flex mr-2 mb-2">
              <button
                data-html={true}
                data-tip={ReactDOMServer.renderToString(levelData[level])}
                data-for="mapping-tooltip"
                className="w-[40px] h-[40px] rounded-md bg-primary-100 text-primary-500 text-sm font-medium hover:bg-primary-200"
              >
                {level}
              </button>
            </div>
          ))}
        </td>
      );
    }
  }),
  columnHelper.accessor('foundationContent', {
    footer: (props) => props.column.id,
    cell({ getValue, column: { columnDef }, table: { options } }) {
      const meta = columnDef.meta as any;
      const width = meta?.width || 0;
      const numbering = options.meta?.numbering;
      const foundationContent = getValue() as Content;
      return (
        <td className="header-sticky" style={{ left: `${width}px` }}>
          {numbering && <span className="text-orange-500">{foundationContent.level}</span>} {foundationContent.content}
        </td>
      );
    },
    meta: {
      pinning: true,
      width: 350,
      headerTitle: 'Description',
      tdClass: 'font-medium !z-auto'
    }
  })
];

// Give our default column cell renderer editing superpowers!
const defaultColumn: Partial<ColumnDef<any>> = {
  header: DefaultHeader
};

type Props = {
  data: MappingFoundationSkill[];
  assessmentTasks: AssessmentTask[];
  columnSizing?: ColumnSizingState;
  numbering?: boolean;
  trafficMode?: boolean;
  onNumberingChange?: (value: Partial<UpdateMapRequest>) => void;
  onColumnSizingChange: (
    columnSizingState: ColumnSizingState,
    tableId?: 'elements' | 'foundationSkills' | 'section' | 'task'
  ) => void;
  levels: { [key: string]: string };
};

export const FoundationSkillTable = ({
  data,
  assessmentTasks,
  columnSizing,
  numbering,
  trafficMode,
  onNumberingChange,
  onColumnSizingChange,
  levels
}: Props) => {
  const columnSizingChange = (updater: any) => {
    onColumnSizingChange(updater(), 'foundationSkills');
  };

  const columns = useMemo(() => {
    const extraColumns = assessmentTasks.map((item) => {
      return columnHelper.accessor(`assessmentTask${item.id}`, {
        cell: TextAreaCell,
        footer: (props) => props.column.id,
        size: 200,
        meta: {
          headerClass: ''
        }
      });
    });

    if (columnSizing) {
      (defaultColumns[1].meta! as any).width = columnSizing?.skill;
      (defaultColumns[2].meta! as any).width = columnSizing?.skill + columnSizing?.performanceCriteria;
    }
    return [...defaultColumns, ...extraColumns];
  }, [assessmentTasks, columnSizing]);

  const { getHeaderGroups, getRowModel } = useReactTable({
    data,
    columns,
    defaultColumn,
    columnResizeMode: 'onEnd',
    state: {
      columnSizing
    },
    onColumnSizingChange: columnSizingChange,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      tableId: 'foundationSkills',
      numbering: numbering,
      updateData: (rowIndex: number, columnId: string, value: string) => {
        data[rowIndex][columnId] = value;
      },
      trafficMode,
      levels
    }
  });

  return (
    <table>
      <thead>
        <tr className="bg-orange-100">
          <th className="header-sticky top-0 !z-30 left-0 !bg-orange-100 h-th" colSpan={3}>
            <div className="flex items-center">
              <span className="mr-4 text-orange-500 text-xs font-semibold uppercase">Foundation skills</span>
              <label htmlFor="foundationSkillNumbering" className="inline-flex relative items-center cursor-pointer">
                <input
                  type="checkbox"
                  id="foundationSkillNumbering"
                  className="sr-only peer"
                  checked={numbering}
                  onChange={(e) => onNumberingChange && onNumberingChange({ foundationSkillNumbering: !numbering })}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-primary-300  rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-600"></div>
                <span className="ml-3 text-sm font-normal text-gray-900">Numbering</span>
              </label>
            </div>
          </th>
          <th colSpan={assessmentTasks.length} className=" !bg-orange-100 !border-l-0 header-sticky top-0 !z-[12]"></th>
          <th className="min-w-th bg-orange-100 !border-l-0 header-sticky top-0 !z-[12]"></th>
        </tr>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="header-sticky top-th !z-[11]">
            {headerGroup.headers
              .filter((header) => !header.id.includes('assessmentTask'))
              .map((header) => (
                <Fragment key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</Fragment>
              ))}
            {headerGroup.headers
              .filter((header) => header.id.includes('assessmentTask'))
              .map((header) => (
                <Fragment key={header.id}>{flexRender(DefaultHeader, header.getContext())}</Fragment>
              ))}
            <th className="min-w-th bg-white"></th>
          </tr>
        ))}
      </thead>
      <tbody>
        {getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <Fragment key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>
            ))}
            <td></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
