import { PlusIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ColumnDef,
  ColumnSizingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import { createMapColumn } from 'api';
import { useStores } from 'hooks';
import { useMemo, Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AssessmentTask } from 'types';
import { TextAreaCell } from './cells';
import { DefaultHeader, InputAndActionHeader } from './headers';

const columnHelper = createColumnHelper<{ [key: string]: string }>();

const defaultColumn: Partial<ColumnDef<any>> = {
  header: DefaultHeader
};

type Props = {
  assessmentTasks: AssessmentTask[];
  columnSizing?: ColumnSizingState;
  onColumnSizingChange: (
    columnSizingState: ColumnSizingState,
    tableId?: 'elements' | 'foundationSkills' | 'section' | 'task'
  ) => void;
};

export const TaskTable = ({ assessmentTasks, onColumnSizingChange, columnSizing }: Props) => {
  const { id } = useParams();
  const [data] = useState<{ [key: string]: string }[]>([{}]);
  const {
    mapUIStore: { setSyncStatus }
  } = useStores();

  const queryClient = useQueryClient();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('content', {
        footer: (info) => info.column.id,
        header: DefaultHeader,
        cell: ({ getValue }) => {
          <td>{getValue()}</td>;
        },
        size: 600,
        meta: {
          width: 0,
          headerClass: '!bg-gray-100 z-10 header-sticky left-0 !border-b-0'
        },
        enableResizing: false
      }),
      ...assessmentTasks.map((item) => {
        return columnHelper.accessor(`assessmentTask${item.id}`, {
          header: InputAndActionHeader,
          footer: (props) => props.column.id,
          cell: TextAreaCell,
          size: 200,
          meta: {
            headerClass: `!bg-transparent !border-b-0`,
            headerTitle: item.task
          }
        });
      })
    ];
  }, [assessmentTasks]);

  const columnSizingChange = (updater: any) => {
    onColumnSizingChange(updater(), 'task');
  };

  const { mutate: addColumn } = useMutation(
    () => {
      return createMapColumn({ mapId: parseInt(id as string), task: '' });
    },
    {
      onSuccess: () => {
        setSyncStatus('saved');
        queryClient.invalidateQueries(['maps', id]);
      },
      onMutate: () => {
        setSyncStatus('saving');
      },
      onError: () => {
        setSyncStatus('error');
      }
    }
  );

  const { getHeaderGroups, getRowModel } = useReactTable({
    data: data,
    columns,
    defaultColumn,
    columnResizeMode: 'onEnd',
    getCoreRowModel: getCoreRowModel(),
    onColumnSizingChange: columnSizingChange,
    state: {
      columnSizing: columnSizing
    }
  });

  return (
    <table className="table-mapping sticky top-0 z-20 !bg-transparent">
      <thead>
        {getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Fragment key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</Fragment>
            ))}
            <th className="min-w-th h-th !border-b-0 !bg-transparent">
              <div className="flex justify-center items-center cursor-pointer" onClick={() => addColumn()}>
                <PlusIcon className="h-4 w-auto" />
              </div>
            </th>
          </tr>
        ))}
      </thead>
      <tbody className="hidden">
        {getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <Fragment key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Fragment>
            ))}
            <td></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
