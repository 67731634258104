import { UpdateUserRequest, ChangePasswordRequest } from '../types';
import axios from '../lib/axios';
import { ErrorResponse } from '../types/error';

export const fetchUser = async (): Promise<any> => {
  return await axios.get('/me').then((res) => {
    return Promise.resolve(res.data);
  });
};

export const updateUser = async (req: UpdateUserRequest): Promise<any | ErrorResponse> => {
  const bodyFormData = new FormData();
  bodyFormData.append('first_name', req.firstName);
  bodyFormData.append('surname', req.surname);
  if (req.avatar) {
    bodyFormData.append('avatar', req.avatar);
  }

  return await axios({
    method: 'put',
    url: '/me',
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  }).then((res) => {
    return Promise.resolve(res.data);
  });
};

export const changePassword = async (req: ChangePasswordRequest): Promise<any | ErrorResponse> => {
  return await axios.put('/me/password', req).then((res) => {
    return Promise.resolve(res.data);
  });
};
