import { CheckIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateWorkspaceDetails } from 'api';
import { Header } from 'components';
import { BillingCard, RecommendedSubscriptionCard, SubscriptionCard, Title } from 'components/manage-payment';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

export interface FormManagePayment {
  email: string;
  name: string;
  billingName: string;
  billingLine1: string;
  billingLine2?: string | null;
  billingCity: string;
  billingState: string;
  billingPostalCode: string;
  billingCountry: string;
}

export const ManagePayment = observer(() => {
  const {
    uiStore: { currentWorkspaceId }
  } = useStores();

  const queryClient = useQueryClient();

  const save = async (value: FormManagePayment) => {
    await updateWorkspaceDetails({ id: currentWorkspaceId, ...value });
    return { id: currentWorkspaceId, ...value };
  };

  const { mutate: onSave } = useMutation(save, {
    onSuccess: (workspaceBilling) => {
      queryClient.setQueryData(['workspaces', currentWorkspaceId], workspaceBilling);
      toast(`Your information is successfully saved.`, {
        type: 'success'
      });
    },
    onError: (error: any) => {
      toast(error.message || 'Please try again', {
        type: 'error'
      });
    }
  });

  const schema = yup.object().shape({
    name: yup.string().required("Workspace's name can't be blank."),
    email: yup.string().email('Please use a valid email address.').required("Email can't be blank."),
    billingName: yup.string().required("Name can't be blank."),
    billingLine1: yup.string().required("Address line 1 can't be blank."),
    billingCity: yup.string().required("City can't be blank."),
    billingPostalCode: yup.string().required("Postcode can't be blank."),
    billingCountry: yup.string().required("Country can't be blank.")
  });

  const methods = useForm<FormManagePayment>({
    resolver: yupResolver(schema)
  });

  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<FormManagePayment> = (value) => {
    onSave(value);
  };

  const onInvalid: SubmitErrorHandler<FormManagePayment> | undefined = (errors: any) => {
    const messages = Object.keys(errors).map((key) => {
      return errors[key]?.message || 'Please check the form and try again.';
    });

    toast(messages[0], {
      type: 'error'
    });
  };

  return (
    <FormProvider {...methods}>
      <Header titleComponent={<Title />}>
        <div className="mt-4 lg:mt-0">
          <button
            type="submit"
            className="btn-primary flex item-center px-3 sm:px-4"
            onClick={handleSubmit(onSubmit, onInvalid)}
          >
            <CheckIcon className="h-5 w-auto mr-2" />
            Save
          </button>
        </div>
      </Header>
      <div className="md:px-8 py-6 px-2">
        <div className="flex mb-8 min-h-[175px]">
          <SubscriptionCard className="lg:basis-3/4 basis-3/5 mr-2 md:mr-5" />
          <RecommendedSubscriptionCard className="lg:basis-1/4 basis-2/5" />
        </div>
        <div>
          <BillingCard />
        </div>
      </div>
    </FormProvider>
  );
});
