import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { fontClassName, fonts } from 'data';
import { ChangeEvent, FC } from 'react';
import ReactSelect, { components, SingleValue } from 'react-select';

interface Props {
  valueProps: string;
  onChangeProps: (event: string | ChangeEvent<Element>) => void;
}

export const BSFFonts: FC<Props> = ({ valueProps, onChangeProps }) => {
  const fontOptions = fonts.map((font) => {
    return {
      label: font,
      value: font
    };
  });

  const CustomOption = ({ children, ...props }: any) => {
    return (
      <components.Option {...props}>
        <div className="flex items-center justify-between cursor-pointer">
          <div className={fontClassName[props.data.value]}>{props.data.value}</div>
          {props.isSelected && <CheckCircleIcon className="w-5 h-5 stroke-primary-500" />}
        </div>
      </components.Option>
    );
  };

  return (
    <>
      <p className="text-xs text-gray-700 mb-2">Font</p>
      <ReactSelect
        components={{
          IndicatorSeparator: () => <></>,
          Option: CustomOption
        }}
        value={{ label: valueProps, value: valueProps }}
        onChange={(e: SingleValue<{ label: string; value: string }>) => {
          if (e === null) return;

          onChangeProps(e.value);
        }}
        options={fontOptions}
        isMulti={false}
        placeholder=""
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: '#E7EBEF',
            '&:hover, &:focus': {
              borderColor: '#E7EBEF'
            },
            boxShadow: '0px 1px 2px 0px #1018280D'
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            background: '#FCFCFC',
            color: '#282E37',

            '&:hover': {
              background: '#E7EBEF'
            }
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            '& > input:focus': {
              boxShadow: 'none'
            }
          }),
          menu: (baseStyle) => ({
            ...baseStyle,
            position: 'relative'
          })
        }}
      />
    </>
  );
};
