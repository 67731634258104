import { Modal } from 'flowbite-react';
import ReactDiffViewer from 'react-diff-viewer-continued';
type Props = {
  previousRelease: string;
  currentRelease: string;
  oldText: string;
  newText: string;
  open: boolean;
  onClose: () => void;
};
export const MigrationDiff = ({ open, onClose, oldText, newText, previousRelease, currentRelease }: Props) => {
  return (
    <Modal show={open} position="center" size="4xl" onClose={onClose}>
      <Modal.Header>
        <h4 className="text-xl font-bold">What's changes?</h4>
      </Modal.Header>
      <Modal.Body>
        <ReactDiffViewer
          styles={{
            diffContainer: {
              fontSize: 14
            }
          }}
          oldValue={oldText}
          newValue={newText}
          splitView={true}
          hideLineNumbers={true}
          disableWordDiff={true}
        />
      </Modal.Body>
    </Modal>
  );
};
