import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useMediaQuery } from 'components/MediaQueryProvider';

type Props = {
  currentPage: number;
  onPageChange: any;
  totalPages: number;
};

export const Pagination = (props: Props) => {
  const { currentPage, totalPages, onPageChange } = props;
  const { isMobile } = useMediaQuery();
  const pageNum = isMobile ? 4 : 5;
  const allPages = Array.from(Array(totalPages).keys()).map((page) => page + 1);
  const step = Math.floor((currentPage - 1) / pageNum);
  let pages = [...allPages].slice(pageNum * step, pageNum * step + pageNum);
  if (totalPages - currentPage < pageNum) {
    const fromIndex = totalPages - pageNum < 0 ? 0 : totalPages - pageNum;
    pages = [...allPages].slice(fromIndex, totalPages);
  }
  const hasNextPage = currentPage < totalPages;
  const hasPreviousPage = currentPage > 1;

  return (
    <nav aria-label="Page navigation">
      <ul className="inline-flex items-center">
        <li
          className={clsx(
            'flex items-center py-2 px-3 ml-0 leading-tight text-xs cursor-pointer font-medium',
            hasPreviousPage ? 'text-primary-500' : 'text-primary-200'
          )}
          onClick={() => {
            if (!hasPreviousPage) {
              return;
            }
            const previousPage = currentPage - 1;
            onPageChange(previousPage);
          }}
        >
          <span>
            <ChevronLeftIcon className="h-4 w-4" />
          </span>
          <span className="ml-3">Prev</span>
        </li>
        {pages.map((page, index) => (
          <li
            key={index}
            onClick={() => onPageChange(page)}
            className={clsx(
              'w-[40px] h-[40px] flex justify-center items-center leading-tight border hover:bg-primary-100 hover:text-primary-600 rounded-lg cursor-pointer font-medium text-sm',
              currentPage === page
                ? 'bg-primary-200 border-primary-200 text-primary-600'
                : 'bg-white border-gray-200 text-gray-900',
              page === totalPages ? '' : 'mr-3'
            )}
          >
            <span>{page}</span>
          </li>
        ))}
        <li
          className={clsx(
            'flex items-center py-2 px-3 leading-tight text-xs cursor-pointer font-medium',
            hasNextPage ? 'text-primary-500' : 'text-primary-200'
          )}
          onClick={() => {
            if (!hasNextPage) {
              return;
            }
            const nextPage = currentPage + 1;
            onPageChange(nextPage);
          }}
        >
          <span className="mr-3">Next</span>
          <span>
            <ChevronRightIcon className="h-4 w-4" />
          </span>
        </li>
      </ul>
    </nav>
  );
};
