import { StylesConfig } from 'react-select';

export const MinCellWidth: number = 150;

export const reactSelectCustomStyle: StylesConfig = {
  container: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    cursor: 'pointer'
  }),
  control: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    minHeight: 38,
    color: '#282E37',
    border: state.isFocused ? '1px solid #1C64F2' : '1px solid #E7EBEF',
    boxShadow: state.isFocused ? '0 0 0 4px #e1e1fE' : 'none',
    '&:hover': {
      border: '1px solid #1C64F2',
      boxShadow: '0 0 0 4px #e1e1fE'
    },
    cursor: 'pointer'
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    borderBottom: '1px solid #E7EBEF',
    borderBottomColor: '#E7EBEF',
    ':last-child': {
      borderBottomColor: 'white'
    },
    color: '#282E37',
    backgroundColor: state?.isSelected ? '#E7EBEF' : 'white',
    ':hover': {
      backgroundColor: '#E7EBEF'
    },
    padding: 8,
    alignItem: 'center',
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer'
  }),
  singleValue: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    color: '#282E37',
    fontSize: 14,
    fontWeight: 400
  }),
  multiValue: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    color: '#282E37',
    fontSize: 14,
    fontWeight: 400
  }),
  clearIndicator: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    color: '#282E37',
    cursor: 'pointer'
  }),
  dropdownIndicator: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    color: '#282E37',
    cursor: 'pointer'
  })
};
