import {
  AcademicCapIcon,
  BellIcon,
  BuildingOfficeIcon,
  FolderIcon,
  PlusCircleIcon,
  PresentationChartBarIcon,
  RectangleGroupIcon,
  RectangleStackIcon
} from '@heroicons/react/24/outline';
import { makeAutoObservable, runInAction } from 'mobx';
import { NodeType, SidebarTreeNode } from 'types';
import { UIStore } from './uiStore';
import {
  fetchSidebarCourseFolders,
  fetchSidebarRegularFolders,
  fetchSidebarRtoFolders,
  fetchSidebarUnitFolders
} from 'api';

export interface ISidebarTreeStore {
  unitFolders: SidebarTreeNode[];
  regularFolders: SidebarTreeNode[];
  courseFolders: SidebarTreeNode[];
  rtoFolders: SidebarTreeNode[];
  uiStore: UIStore;
}

export class SidebarTreeStore implements ISidebarTreeStore {
  isLoading = false;
  unitFolders: SidebarTreeNode[] = [];
  regularFolders: SidebarTreeNode[] = [];
  courseFolders: SidebarTreeNode[] = [];
  rtoFolders: SidebarTreeNode[] = [];
  uiStore: UIStore;
  folderId?: string;

  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;

    this.unitFolders = [];
    this.regularFolders = [];
    this.courseFolders = [];
    this.rtoFolders = [];

    makeAutoObservable(this);
  }

  loadUnitFolders = async () => {
    const unitFolders = await fetchSidebarUnitFolders();
    runInAction(() => {
      this.unitFolders = unitFolders;
    });
  };

  loadRegularFolders = async () => {
    const regularFolders = await fetchSidebarRegularFolders();
    runInAction(() => {
      this.regularFolders = regularFolders;
    });
  };

  loadCourseFolders = async () => {
    const courseFolders = await fetchSidebarCourseFolders();
    runInAction(() => {
      this.courseFolders = courseFolders;
    });
  };

  loadRtoFolders = async () => {
    const rtoFolders = await fetchSidebarRtoFolders();
    runInAction(() => {
      this.rtoFolders = rtoFolders;
    });
  };

  setCurrentFolderId = (folderId: string) => {
    this.folderId = folderId;
  };

  get inventoryUnitIds(): string[] {
    return this.unitFolders.map((unit) => unit.sourceId?.toString() || '');
  }

  isInLibrary = (unitId: string) => {
    return this.inventoryUnitIds.includes(unitId);
  };

  folderIdByUnitId = (unitId: number) => {
    const unit = this.unitFolders.find((unit) => unit.sourceId === unitId);
    return unit?.id;
  };

  loadSidebar = async () => {
    this.isLoading = true;
    await this.loadUnitFolders();
    await this.loadRegularFolders();
    await this.loadCourseFolders();
    await this.loadRtoFolders();
    runInAction(() => {
      this.isLoading = false;
    });
  };

  clear = () => {
    this.folderId = undefined;
  };

  get treeData(): SidebarTreeNode[] {
    return [
      {
        id: 'notification',
        name: 'Notifications',
        type: NodeType.NOTIFICATION,
        badgeNumber: 30,
        icon: BellIcon,
        onClick: () => {}
      },
      {
        id: 'my_library',
        name: 'My Library',
        type: NodeType.CUSTOM,
        icon: RectangleStackIcon,
        childrenCount: 4,
        linkTo: '/library',
        children: [
          {
            id: 'unit',
            name: 'Units',
            type: NodeType.CUSTOM,
            icon: PresentationChartBarIcon,
            children: this.unitFolders,
            childrenCount: this.unitFolders.length,
            linkTo: '/library/units'
          },
          {
            id: 'folder',
            name: 'Folders',
            type: NodeType.CUSTOM,
            icon: FolderIcon,
            children: this.regularFolders,
            childrenCount: this.regularFolders.length,
            linkTo: '/library/regulars'
          },
          {
            id: 'courses',
            name: 'Courses',
            type: NodeType.CUSTOM,
            icon: AcademicCapIcon,
            children: this.courseFolders,
            childrenCount: this.courseFolders.length,
            linkTo: '/library/courses'
          },
          {
            id: 'rto',
            name: 'RTOs',
            type: NodeType.CUSTOM,
            icon: BuildingOfficeIcon,
            children: this.rtoFolders,
            childrenCount: this.rtoFolders.length,
            linkTo: '/library/rtos'
          }
        ]
      },
      {
        id: 'my_templates',
        name: 'My Templates',
        type: NodeType.CUSTOM,
        icon: RectangleGroupIcon,
        linkTo: '/templates'
      },
      // {
      //   id: 'my_maps',
      //   name: 'My Maps',
      //   type: NodeType.CUSTOM,
      //   icon: MapIcon,
      //   linkTo: '/maps'
      // },
      {
        id: 'add_new',
        name: 'Add New',
        type: NodeType.CUSTOM,
        icon: PlusCircleIcon,
        onClick: () => this.uiStore.openSearchBar()
      }
    ];
  }
}
