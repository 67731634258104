import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

type Props = {
  to: string;
  title: string;
  classes?: {
    tab: string;
  };
  activeExact?: boolean;
  tabIndex?: number;
  activeTabIndex?: number;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export const TabItem = ({ to, title, classes, activeExact, tabIndex, activeTabIndex, onClick }: Props) => {
  const { pathname } = useLocation();
  const isActive = (activeExact ? pathname === to : pathname?.includes(to)) || activeTabIndex === tabIndex;
  return (
    <li className="">
      <Link
        to={to}
        className={clsx(
          'inline-block font-medium',
          classes?.tab ||
            'bg-white hover:bg-primary-200 hover:text-primary-500 text-gray-900 text-sm rounded-t-lg px-4 py-3',
          isActive && '!bg-primary-500 text-white pointer-events-none'
        )}
        onClick={onClick}
      >
        {title}
      </Link>
    </li>
  );
};
