import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/buttons';
import React from 'react';

interface Props {
  onClose: () => void;
}

const SubscriptionCloseButton: React.FC<Props> = ({ onClose }) => {
  return (
    <Button
      className="ml-auto bg-white border-0 text-gray-900 opacity-100 float-right text-3xl leading-none font-semibold outline-none focus:outline-none max-w-[24px] max-h-[24px] rounded"
      onClick={() => onClose()}
    >
      <XMarkIcon className="text-gray-900 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none hover:rotate-90 duration-300 hover:text-primary-500" />
    </Button>
  );
};

export default SubscriptionCloseButton;
