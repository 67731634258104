import { Member } from './../types/workspace';
import { makeAutoObservable } from 'mobx';
import { ShowModal } from 'types/enums';

export interface ManageUsersStore {
  currentMember: Member | undefined;
  showModal: ShowModal;
}

export class ManageUsersStore implements ManageUsersStore {
  currentMember: Member | undefined;
  showModal = ShowModal.None;

  constructor() {
    makeAutoObservable(this);
  }

  openDeleteModal = (member: Member) => {
    this.currentMember = member;
    this.showModal = ShowModal.Delete;
  };

  openNewModal = () => {
    this.showModal = ShowModal.New;
  };

  closeModal = () => {
    this.currentMember = undefined;
    this.showModal = ShowModal.None;
  };
}
