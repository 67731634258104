import clsx from 'clsx';

type Props = {
  className?: string;
  title: string;
};

export const Bagde = ({ className, title }: Props) => {
  return (
    <span className={clsx('text-gray-900 text-xs font-medium mr-2 px-2.5 py-1 rounded-full', className)}>{title}</span>
  );
};
