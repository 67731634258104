import { useStores } from 'hooks';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

export const NextPayment = observer(() => {
  const {
    subscriptionStore: { subscription: currentSubscription }
  } = useStores();

  return (
    <div className="border border-gray-200 p-3 md:p-4 rounded-lg bg-white text-gray-900 text-sm font-medium flex flex-col justify-between h-[134px] w-5/12 lg:w-1/4 ">
      <div>
        <p className="font-medium text-gray-400 text-xs leading-5">Next Payment</p>
        <p className="font-medium text-gray-900 mt-1">{currentSubscription?.nextPaymentDate}</p>
      </div>

      <Link to="/subscriptions" className="btn-outline w-full md:w-max px-1 md:px-4 text-xs md:text-sm leading-5">
        Manage Payment
      </Link>
    </div>
  );
});
