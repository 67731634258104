import BrandSettingUploadLogoImg from 'assets/bg/brand-setting-upload-logo.png';
import { clsx } from 'clsx';
import { useMemo, useRef, useState } from 'react';

type Props = {
  name?: string;
  value?: string;
  onChange?: (event: any) => void;
};

export const BSFUpload = ({ name, onChange, value }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDragActive, setDragActive] = useState<boolean>(false);

  const imagePreview = useMemo(() => {
    if ((value as any) instanceof File) {
      return URL.createObjectURL(value as any);
    }
    return value;
  }, [value]);

  const onReadFileUpload = (file: File) => {
    onChange?.(file);
  };

  const handleChange = () => {
    const files = inputRef.current?.files as File[] | undefined;
    if (files && files[0]) {
      onReadFileUpload(files[0]);
    }
  };

  const handleDrop = (event: any) => {
    setDragActive(false);
    const files = event?.dataTransfer?.files as File[] | undefined;
    if (files && files[0]) {
      onReadFileUpload(files[0]);
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrag = (event: any) => {
    event.preventDefault();

    if (!isDragActive) {
      setDragActive(true);
    }
  };

  const handleDragEnd = (event: any) => {
    event.preventDefault();
    setDragActive(false);
  };

  const UploadImageSection = () => {
    return (
      <>
        <div className="absolute w-full h-full inset-0 z-10 rounded-md">
          <img
            alt="upload-logo"
            src={imagePreview || BrandSettingUploadLogoImg}
            className="w-full h-full object-contain"
          />
        </div>

        <div className="absolute w-full h-full inset-0 z-40 rounded-md group">
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-transparent group-hover:text-white font-semibold text-sm z-50 transition-all duration-300">
            Drop here to upload
          </div>
          <div className="absolute bg-transparent group-hover:bg-stone-900 w-full h-full opacity-60 z-20 rounded-lg transition-all duration-300"></div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="flex justify-center items-center w-full"
        onDragOver={handleDrag}
        onDragLeave={handleDragEnd}
        onDrop={handleDrop}
      >
        <label
          htmlFor="dropzone-file"
          className={clsx(
            'relative flex flex-col justify-center items-center w-full h-52 bg-gray-200 rounded-lg border border-gray-300 border-dotted cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600',
            {
              'bg-neutral-400': isDragActive
            }
          )}
        >
          <UploadImageSection />
          <input
            ref={inputRef}
            id="dropzone-file"
            name={name}
            type="file"
            className="hidden"
            accept="image/*"
            onChange={handleChange}
          />
        </label>
      </div>

      <div className="flex items-center justify-between mt-4 gap-x-4">
        <button className="btn-outline text-sm w-full block" type="button" onClick={() => onChange?.(null)}>
          Remove
        </button>
        <button className="btn-primary text-sm w-full block" type="button" onClick={() => inputRef.current?.click()}>
          Upload
        </button>
      </div>
    </>
  );
};
