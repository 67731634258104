import { isHtmlTag } from './htmlTag';

export const getVirtualHeight = (
  label: string,
  width: number,
  fontSize: number = 14,
  fontFamily: string = 'Inter, sans-serif',
  lineHeight: number = 20,
  padding: number = 12,
  borderWidth: number = 1
): number => {
  if (isHtmlTag(label)) {
    return getVirtualHtmlHeight(label, width, 'unit-element');
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = `${fontSize}px ${fontFamily}`;
    const words = label.split(' ');
    let line = '';
    let lines = [];
    for (let i = 0; i < words.length; i++) {
      const testLine = line + words[i] + ' ';
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth + 2 * padding + 2 * borderWidth > width && i > 0) {
        lines.push(line);
        line = words[i] + ' ';
      } else {
        line = testLine;
      }
    }
    lines.push(line);

    return lines.length * lineHeight + 2 * padding + 2 * borderWidth;
  }
  return 44;
};

export const getVirtualHtmlHeight = (
  label: string,
  width: number,
  className: string,
  padding: number = 12,
  borderWidth: number = 1
): number => {
  const div = document.createElement('div');
  div.style.width = width + 'px';
  div.style.padding = padding + 'px';
  div.style.borderWidth = borderWidth + 'px';
  div.innerHTML = label;
  div.classList.add(className);

  document.body.appendChild(div);

  const virtualHeight = div.offsetHeight;

  div.remove();

  return virtualHeight;
};
