import { ChevronDownIcon, DocumentDuplicateIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { MergeIcon } from 'components/icons';
import { Dropdown } from 'flowbite-react';

export const DropDownCell = ({
  label,
  addRow,
  removeRow,
  duplicateRow
}: {
  label?: string;
  index: number;
  addRow?: any;
  removeRow?: any;
  duplicateRow?: any;
}) => {
  return (
    <div className="flex items-center justify-end w-full dropdown-container absolute right-0 top-0">
      <div className="dropdown">
        <Dropdown
          outline={false}
          color={'bg-white'}
          placement="bottom-end"
          size={14}
          arrowIcon={false}
          trigger={'click'}
          label={
            <div className="flex items-center">
              <ChevronDownIcon className="h-[14px] w-auto" />
            </div>
          }
        >
          <Dropdown.Item icon={PlusIcon} onClick={() => addRow('above')}>
            Insert Row Above
          </Dropdown.Item>
          <Dropdown.Item icon={PlusIcon} onClick={() => addRow('below')}>
            Insert Row Below
          </Dropdown.Item>
          <hr className="border-top border-gray-200 my-1" />
          <Dropdown.Item icon={() => <MergeIcon className="w-4 h-4 mr-2" />}>Merge Row</Dropdown.Item>
          <Dropdown.Item icon={DocumentDuplicateIcon} onClick={() => duplicateRow()}>
            Duplicate Row
          </Dropdown.Item>
          <hr className="border-top border-gray-200 my-1" />
          <Dropdown.Item icon={TrashIcon} onClick={() => removeRow()}>
            Delete Row
          </Dropdown.Item>
        </Dropdown>
      </div>
    </div>
  );
};
