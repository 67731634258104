import clsx from 'clsx';
import { AddressForm } from './AddressForm';
import { EmailAddress } from './EmailAddress';
import { Invoices } from './Invoices';
import { PaymentMethod } from './PaymentMethod';

type Props = {
  className?: string;
};

export const BillingCard = ({ className }: Props) => {
  return (
    <div
      className={clsx(
        'border border-gray-200 p-3 md:p-4 rounded-lg bg-white text-gray-900 text-xs font-normal flex flex-col',
        className
      )}
    >
      <header className="text-primary-500 font-semibold text-xs uppercase mb-8">Billing information</header>
      <div className="flex  flex-col xl:flex-row mb-8">
        <label className="text-xs font-semibold text-gray-400 basis-2/5 mb-4 xl:mb-0">Card Details</label>
        <PaymentMethod className="basis-2/5" />
      </div>
      <div className="flex flex-col xl:flex-row mb-8">
        <label className="text-xs font-semibold text-gray-400 basis-2/5 mb-4 xl:mb-0">
          <span className="leading-5">Email Address</span> <br />
          <span className="font-normal mt-2 leading-5 text-gray-700">Invoices will be sent to this email address.</span>
        </label>
        <div className="basis-2/5 form-group mb-0">
          <EmailAddress readOnly={false} />
        </div>
      </div>
      <div className="flex flex-col xl:flex-row mb-8">
        <label className="text-xs font-semibold text-gray-400 basis-2/5 mb-4 xl:mb-0">
          <span className="leading-5">Billing</span> <br />
          <span className="font-normal mt-2 leading-5 text-gray-700">Don't worry, we won't send anything here.</span>
        </label>
        <div className="basis-2/5">
          <AddressForm />
        </div>
      </div>
      <div className="flex flex-1">
        <div className="xl:basis-4/5 xl:flex-none flex-1">
          <label className="text-xs font-semibold text-gray-400 mb-2">Invoice</label>
          <Invoices />
        </div>
      </div>
    </div>
  );
};
