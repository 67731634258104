import { EllipsisVerticalIcon, UserMinusIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { Bagde } from 'components/bagde';
import { Dropdown } from 'flowbite-react';
import { useStores } from 'hooks';
import { Member } from 'types';
import { MemberStatus, Role } from 'types/enums';
import { fullName } from 'utils/helpers';
import { resendInvitation } from 'api';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

export const MembersTableMobile = ({ members }: { members: Member[] }) => {
  const { manageUsersStore } = useStores();

  const Member = ({ member }: { member: Member }) => {
    const memberName = fullName(member?.firstName, member?.surname);

    const { mutate } = useMutation(resendInvitation, {
      onSuccess: () => {
        toast('Invitation has been resent', {
          type: 'success'
        });
      },
      onError: (error: any) => {
        const errorMessage = error?.message ?? 'Please try again.';
        toast(errorMessage, {
          type: 'error'
        });
      }
    });

    return (
      <div className="p-4 border border-gray-200 mb-4 rounded-md text-sm">
        <div className="flex flex-col space-y-2.5">
          <div className="flex justify-between items-center">
            <div className="flex space-x-2 items-center">
              <div className="font-normal">{member.role}</div>
              <Bagde
                title={member.status}
                className={`text-xs leading-5 mr-2 ${
                  member.status === MemberStatus.Active
                    ? 'bg-green-100 text-green-700'
                    : 'bg-yellow-100 text-yellow-700'
                }`}
              />
            </div>
            <div className="dropdown">
              {member.role !== Role.Owner && (
                <Dropdown
                  outline={false}
                  color={'bg-white'}
                  placement="bottom-end"
                  size={14}
                  arrowIcon={false}
                  label={<EllipsisVerticalIcon className="h-4 w-4" />}
                >
                  <Dropdown.Item
                    icon={UserMinusIcon}
                    onClick={() => {
                      manageUsersStore.openDeleteModal(member);
                    }}
                  >
                    <div className="text-sm font-normal">
                      {member.status === MemberStatus.Pending ? 'Cancel Invitation' : 'Remove User'}
                    </div>
                  </Dropdown.Item>
                  {member.status === MemberStatus.Pending && (
                    <Dropdown.Item
                      icon={ArrowPathIcon}
                      onClick={() => {
                        mutate(member.id);
                      }}
                    >
                      <div className="text-sm font-normal">Resend Invitation</div>
                    </Dropdown.Item>
                  )}
                </Dropdown>
              )}
            </div>
          </div>
          {memberName && <div className="font-medium break-words">{memberName}</div>}
          <div className="font-normal break-words">{member.email}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      {members?.map((member, index) => (
        <Member key={index} member={member} />
      ))}
    </>
  );
};
