import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { PasswordField } from 'components';
import { toast } from 'react-toastify';
import { acceptInvite } from 'api';
import { Button } from 'components/buttons';
import { ErrorResponse } from 'types';
import PasswordLayout from 'components/auth/PasswordLayout';
interface AcceptInvitationRequest {
  password: string;
  passwordConfirmation: string;
  firstName: string;
  surname: string;
}

const schema = yup
  .object({
    firstName: yup.string().required("First name can't be blank."),
    surname: yup.string().required("Last name can't be blank."),
    password: yup.string().required("Password can't be blank.").min(6, 'Password must contain at least 6 characters.'),
    passwordConfirmation: yup
      .string()
      .required("Confirm password can't be blank.")
      .oneOf([yup.ref('password')], "Confirm password doesn't match Password.")
  })
  .required();

export const AcceptInvite = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || '';
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AcceptInvitationRequest>({
    resolver: yupResolver(schema)
  });

  const { mutate, isLoading } = useMutation(acceptInvite, {
    onSuccess: () => {
      toast('The invitation has been accepted. You now can sign in to start using MagicMap.', {
        type: 'success'
      });

      navigate({
        pathname: `/auth/login`
      });
    },
    onError: (error: ErrorResponse) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  if (!token) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <PasswordLayout>
      <form
        className="form-width bg-white px-4 py-6 rounded-mobile md:card md:p-16"
        onSubmit={handleSubmit((value) => {
          mutate({ user: { invitationToken: token, ...value } });
        })}
      >
        <h1 className="text-c1 md:text-c3 text-left md:text-left mb-6 md:mb-8 font-bold text-gray-900 tracking-tight">
          Accept Your Invitation
        </h1>
        <div className="form-group">
          <input
            className={`form-control ${errors.firstName || errors?.firstName ? 'invalid' : ''}`}
            id="firstName"
            type="text"
            placeholder="First name"
            autoFocus={true}
            {...register('firstName')}
          />
          <p className="feedback-invalid text-sm">
            {errors.firstName?.message || (errors?.firstName && `First name ${errors?.firstName}`)}
          </p>
        </div>
        <div className="form-group">
          <input
            className={`form-control ${errors.surname || errors?.surname ? 'invalid' : ''}`}
            id="surname"
            type="text"
            placeholder="Last name"
            {...register('surname')}
          />
          <p className="feedback-invalid text-sm">
            {errors.surname?.message || (errors?.surname && `Surname ${errors?.surname}`)}
          </p>
        </div>
        <div className="form-group">
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState }) => (
              <PasswordField
                className={`form-control ${fieldState.error || errors?.password ? 'invalid' : ''} !pr-10`}
                value={field.value || ''}
                onChange={field.onChange}
                placeholder="Input Password"
                name={field.name}
              />
            )}
          />
          <p className="feedback-invalid text-sm">
            {errors.password?.message || (errors?.password && `Password ${errors?.password}`)}
          </p>
        </div>
        <div className="form-group">
          <Controller
            name="passwordConfirmation"
            control={control}
            render={({ field, fieldState }) => (
              <PasswordField
                className={`form-control ${fieldState.error || errors?.passwordConfirmation ? 'invalid' : ''} !pr-10`}
                value={field.value || ''}
                onChange={field.onChange}
                placeholder="Confirm Password"
                name={field.name}
              />
            )}
          />
          <p className="feedback-invalid text-sm">
            {errors.passwordConfirmation?.message ||
              (errors?.passwordConfirmation && `Confirm password ${errors?.passwordConfirmation}`)}
          </p>
        </div>

        <div className="flex">
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={!token}
            className="btn-primary font-medium disabled:hover:bg-purple-100 w-full"
          >
            Accept
          </Button>
        </div>
      </form>
    </PasswordLayout>
  );
};
