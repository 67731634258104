import { Modal } from 'flowbite-react';
export const MigrationGuide = ({
  unit,
  desc,
  targetUnit,
  targetUnitDesc,
  message,
  open,
  onClose
}: {
  unit: string;
  desc: string;
  targetUnit?: string;
  targetUnitDesc?: string;
  message?: string;
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal show={open} position="center" size="4xl" onClose={onClose}>
      <Modal.Header>
        <h4 className="text-xl font-bold">How to use the new release map duplication tool:</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="text-sm">
          <div>
            <p className="mb-2">
              The two columns below represent the{' '}
              {targetUnit ? (
                <>
                  <strong>
                    {unit} - {desc}
                  </strong>{' '}
                  and{' '}
                  <strong>
                    {targetUnit} - {targetUnitDesc}
                  </strong>
                </>
              ) : (
                <>
                  old and the new release of{' '}
                  <strong>
                    {unit} - {desc}
                  </strong>
                </>
              )}
              .{' '}
              {targetUnit ? (
                <>The team at Magic Map have reviewed and connected elements as a guide for your review.</>
              ) : (
                <>
                  The team at Magic Map have reviewed the release update and have connected the different elements from
                  the old to the new as a guide for your review.
                </>
              )}
            </p>
            <p className="mb-2">
              Please note you can use this tool to add your own connector arrows or you can adjust the existing.
            </p>
            {!targetUnit && (
              <p className="mb-2">
                These arrows illustrate how the duplication of your existing maps and their data will be mapped to the
                new release detail, whilst maintaining your mapping data from your existing maps.
              </p>
            )}
            {targetUnit && (
              <p className="mb-2">
                These arrows illustrate how the duplication of your existing map and its data will be mapped to the new
                unit details, whilst maintaining your mapping data from your existing map.
              </p>
            )}
            {targetUnit && (
              <p className="mb-2">
                Once you have reviewed the connector arrows, simply click ‘Duplicate and create new map’.
              </p>
            )}
            {!targetUnit && (
              <p className="mb-2">
                Once you have reviewed the comments from Magic Map below and the connector arrows, simply select the
                maps you wish to duplicate and click ‘Duplicate and create new maps’.
              </p>
            )}
            <p className="mb-2">
              {!targetUnit && (
                <strong>
                  When you click ‘Duplicate and create new maps’, your existing maps will stay as they are and Magic Map
                  will create a new duplication of the selected maps using the new unit version details.
                </strong>
              )}
              {targetUnit && (
                <strong>
                  When you click ‘Duplicate and create new map’, your existing map will stay as it is and Magic Map will
                  create a new duplication of the map using the new unit details.
                </strong>
              )}
            </p>
            {message && (
              <>
                <p className="mb-2">
                  <strong>Magic Map summary of new release changes:</strong>
                </p>
                <div
                  className="bg-primary-100 p-2 mb-4 text-gray-900 rounded-md"
                  dangerouslySetInnerHTML={{ __html: message }}
                ></div>
              </>
            )}
            <hr className="border-blue-300 mb-4" />
            <p className="mb-2">
              <strong>DISCLAIMER:</strong> Magic Map provides this summary and mapping tool as a template and guide. How
              it is contextualised for use in individual organisations is beyond the control of Magic Map and is the
              sole responsibility of the organisation/individual using the template. Magic Map has used its best
              endeavours to ensure content of this document is correct, comprehensive and current at the time of
              issuing, but does not give any warranty nor accept any liability in relation to the contents or
              information added by the organisation during contextualisation and use. If any law prohibits the exclusion
              of such liability, Magic Map limits its liability to the extent permitted by law.
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
