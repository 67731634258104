import { ReactSVG } from 'react-svg';
import ArrowRightSvg from '../../assets/svg/arrow-right.svg';

type Props = {
  className?: string;
};

export const ArrowRightIcon = ({ className }: Props) => {
  return <ReactSVG src={ArrowRightSvg} className={className} />;
};
