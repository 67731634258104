import { FolderEmpty } from 'components/icons';
import { useStores } from 'hooks';

export const RegularAction = () => {
  const { libraryStore } = useStores();

  const onNewRegularFolder = () => {
    libraryStore.openNewModal();
  };

  return (
    <>
      <FolderEmpty className="mb-8"></FolderEmpty>
      <p className="text-sm text-gray-900">
        Click
        <span
          className="relative px-1 inline-block text-sm  text-primary-500 group active:text-orange-500 focus:outline-none focus:ring cursor-pointer"
          onClick={onNewRegularFolder}
        >
          New Folder
        </span>
        to create a new folder in your Library.
      </p>
    </>
  );
};
