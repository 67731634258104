import clsx from 'clsx';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import { DeleteMemberModal, Header, InviteMemberModal, ManageMembers } from 'components';
import { Subscription, NextPayment } from 'components';
import { useStores } from 'hooks';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ShowModal } from 'types/enums';

export const ManageUsers = observer(() => {
  const {
    manageUsersStore: { showModal, openNewModal },
    uiStore: { currentWorkspace, isNavbarCollapsed },
    subscriptionStore
  } = useStores();

  const navigate = useNavigate();

  if (currentWorkspace && !currentWorkspace.isOwner) {
    navigate({
      pathname: '/library'
    });
  }

  const isDisabledInvite = () => {
    if (!subscriptionStore.subscription) return true;

    return subscriptionStore.subscription.usedMembers >= subscriptionStore.subscription.plan.users;
  };

  return (
    <>
      <Header title="Manage User" className="flex justify-between text-gray-900">
        <button className="btn-primary flex item-center" onClick={() => openNewModal()} disabled={isDisabledInvite()}>
          <UserPlusIcon className="h-5 w-5 inline-block mr-2 content-center" />
          Invite Users
        </button>
      </Header>
      <div className="flex flex-col pt-4 px-2 md:px-8 justify-between relative">
        <div className={clsx(!isNavbarCollapsed && 'w-screen md:w-auto absolute md:relative top-4 left-2')}>
          <div className={clsx('flex md:space-x-5 space-x-2 mb-8')}>
            <Subscription />
            <NextPayment />
          </div>
          <div>
            <ManageMembers />
          </div>
        </div>
      </div>
      <DeleteMemberModal show={showModal === ShowModal.Delete} size="sm" position={'center'} />
      <InviteMemberModal show={showModal === ShowModal.New} position={'center'} />
    </>
  );
});
