import { ReactSVG } from 'react-svg';
import LogoSvg from '../../assets/svg/logo.svg';
import clsx from 'clsx';

type Props = {
  className?: string;
};

export const LogoIcon = ({ className }: Props) => {
  const logoClasses = clsx('[&_svg]:w-192', className);
  return <ReactSVG src={LogoSvg} className={logoClasses} />;
};
