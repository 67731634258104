import { useMutation, useQuery } from '@tanstack/react-query';
import { migrateUnitMap, fetchUnitMigration } from 'api';
import { Header, Loading } from 'components';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Unit, ErrorResponse, UnitMigrationPlan, MigrationPlan, MigrateUnitRequest } from 'types';
import 'reactflow/dist/style.css';
import { MigrationFooter, MigrationGuide, MigrationSection } from 'components/migration';
import { ReactFlowProvider } from 'reactflow';
import { toast } from 'react-toastify';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

export const UnitMigration = () => {
  const [guideOpened, setGuideOpened] = useState<boolean>(true);
  let migrationPlans: MigrationPlan[] = [];
  const navigate = useNavigate();
  const elementRef = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const planId = searchParams.get('planId');
  const { data: unitMigrationData, isLoading } = useQuery<UnitMigrationPlan, ErrorResponse>(
    ['unit', id, 'plan', planId],
    () => fetchUnitMigration(id as string, planId as string),
    {
      enabled: !!id
    }
  );
  const unit: Unit = (unitMigrationData?.unit as Unit) || {};
  unit.code = id as string;
  unit.title = unit.title || '...';

  const [clientWidth, setClientWidth] = useState<number>(0);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setClientWidth(entries[0].contentRect.width);
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const mutation = useMutation({
    mutationFn: ({ payload, next }: { payload: MigrateUnitRequest; next?: () => void }) => migrateUnitMap(payload),
    onSuccess: () => {
      navigate(`/units/${id}/maps`);
      const msgSuccess = `Your maps have been duplicated with new release details.`;
      toast(msgSuccess, {
        type: 'success'
      });
    },
    onSettled(_data, _error, variables) {
      const { next } = variables;
      next && next();
    },
    onError: () => {
      const msgSuccess = 'Something went wrong! Please try again.';
      toast(msgSuccess, {
        type: 'error'
      });
    }
  });

  const onSubmit = ({ mapIds, next }: { mapIds: string[]; next?: () => void }) => {
    mutation.mutate({
      payload: {
        unitCode: id as string,
        planId: planId as string,
        plans: migrationPlans,
        mapIds: mapIds
      },
      next
    });
  };

  return (
    <div className="h-full flex flex-col">
      <Helmet>
        <title>
          {unit?.code} - {unit?.title}
        </title>
      </Helmet>
      <div ref={elementRef}>
        <Header title={unit?.code} subTitle={unit?.title}>
          <button
            className="border-primary-500 bg-primary-200 p-2 rounded-md border"
            onClick={() => setGuideOpened(!guideOpened)}
          >
            <QuestionMarkCircleIcon className="w-5 h-5" />
          </button>
        </Header>
      </div>
      {!isLoading && (
        <MigrationGuide
          unit={unit.code}
          desc={unit.title}
          targetUnit={unitMigrationData?.currentUnit?.code}
          targetUnitDesc={unitMigrationData?.currentUnit?.title}
          message={unitMigrationData?.planSummary}
          open={guideOpened}
          onClose={() => setGuideOpened(false)}
        />
      )}
      {isLoading && (
        <div className="min-h-[200px] relative">
          <Loading />
        </div>
      )}
      {!isLoading && (
        <>
          <ReactFlowProvider>
            <MigrationSection
              clientWidth={clientWidth || elementRef.current?.clientWidth || 0}
              currentRelease={unitMigrationData?.currentRelease}
              previousRelease={unitMigrationData?.previousRelease}
              plans={unitMigrationData?.plan}
              onPlanChange={(plans) => (migrationPlans = plans)}
            />
          </ReactFlowProvider>
          <MigrationFooter unitCode={id} onSubmit={onSubmit} maps={unitMigrationData?.previousMaps || []} />
        </>
      )}
    </div>
  );
};
