import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Modal, ModalProps } from 'flowbite-react';
import { Button } from 'components/buttons';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { focusManager, useMutation, useQueryClient } from '@tanstack/react-query';
import { SetupIntent } from 'types';
import { createPaymentMethod } from 'api';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';
import { useEffect } from 'react';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

interface Props extends ModalProps {
  onClose: () => void;
  paymentIntent?: SetupIntent;
}
export const PaymentMethodModal = (props: Props) => {
  useEffect(() => {
    focusManager.setFocused(false);
    return () => focusManager.setFocused(undefined);
  }, []);

  const options: StripeElementsOptions = {
    clientSecret: props.paymentIntent?.clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        fontSizeBase: '14px'
      }
    }
  };

  return (
    <Modal size={props.size} show={props.show}>
      <div className="p-4 flex justify-between w-full items-center">
        <h4 className="text-sm font-medium text-gray-900">New Payment Method</h4>
        <Button
          className="ml-auto bg-white border-0 text-gray-700 opacity-100 float-right text-3xl leading-none font-semibold outline-none focus:outline-none max-w-[24px] max-h-[24px] rounded"
          onClick={() => props.onClose()}
        >
          <XMarkIcon className="text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none hover:rotate-90 duration-300 hover:text-primary-500" />
        </Button>
      </div>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm {...props} />
      </Elements>
    </Modal>
  );
};

const CheckoutForm = observer((props: Props) => {
  const {
    uiStore: { currentWorkspaceId }
  } = useStores();
  const stripe = useStripe();
  const elements = useElements();

  const queryClient = useQueryClient();

  const submit = async (next?: () => void) => {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required'
    });

    if (result.error) {
      next && next();
      throw result.error;
    }

    await createPaymentMethod(result.setupIntent?.payment_method as string);
    next && next();
  };

  const { mutate: handleSubmit, isLoading } = useMutation(submit, {
    onSuccess: () => {
      props.onClose();
      queryClient.invalidateQueries(['workspaces', currentWorkspaceId, 'payments']);
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  return (
    <form>
      <div className="p-4">
        <PaymentElement />
      </div>

      <div className="flex justify-end p-4">
        <Button className="btn mr-2 hover:bg-gray-200 rounded-md" onClick={() => props.onClose()} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          progress={true}
          disabled={!stripe || isLoading}
          className="btn-primary font-normal disabled:hover:bg-purple-100"
        >
          Add Card
        </Button>
      </div>
    </form>
  );
});
