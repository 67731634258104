import { MapIcon } from '@heroicons/react/24/outline';
import { AddUnitModal } from 'components/course/modals';
import { NewMapModal } from 'components/modals/NewMapModal';
import { useStores } from 'hooks';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Library, NodeType } from 'types';
import { ShowModal } from 'types/enums';

export const UnitFolderActions = observer(({ folder }: { folder: Library | undefined }) => {
  const {
    sidebarTreeStore,
    mapUIStore: { showModal: showModalAddUnit, openNewModal: openNewModalAddUnit }
  } = useStores();

  const [showNewMapModal, setShowNewMapModal] = useState(false);

  const courseParentId = folder?.ancestors?.find((ancestor) => ancestor.type === NodeType.COURSE)?.id;
  const folderIds = courseParentId ? [courseParentId] : [];

  if (!folder) return <></>;

  const unitCode: string = folder.source?.code || '';
  const unitId: string = folder.source?.id?.toString() || '';
  const unitTitle: string = folder.source?.title || '';

  const handleCreateNewMap = () => {
    // In Library -> create map
    // Not in Lib -> Ask to add.
    if (sidebarTreeStore.isInLibrary(unitId)) {
      setShowNewMapModal(true);
    } else {
      openNewModalAddUnit();
    }
  };

  const onAddedUnitToLibrary = () => {
    setShowNewMapModal(true);
  };

  return (
    <>
      <button className="btn-primary text-sm ml-3 hidden md:block" onClick={handleCreateNewMap}>
        <MapIcon className="w-5 h-5 inline-block mr-2" />
        New Map
      </button>
      <NewMapModal
        unitId={unitCode}
        show={showNewMapModal}
        folderIds={folderIds}
        hide={() => setShowNewMapModal(false)}
      />
      <AddUnitModal
        title="You cannot create map for unit that is not in your Library yet. Add unit into your Library now?"
        unit={{ code: unitCode, title: unitTitle }}
        show={showModalAddUnit === ShowModal.New}
        handleCallback={onAddedUnitToLibrary}
      />
    </>
  );
});
