import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import { OTPInput, SlotProps } from 'input-otp';
import { LoginFormInputs } from 'pages';
import { Helmet } from 'react-helmet-async';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  onSubmit: (data: LoginFormInputs) => any;
};
export const TwoFactorVerificationForm = ({ onSubmit }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useFormContext<LoginFormInputs>();

  const { mutate, error, isLoading } = useMutation<LoginFormInputs, string, LoginFormInputs, unknown>(onSubmit);

  const Slot = (props: SlotProps) => {
    return (
      <div
        className={clsx(
          'relative w-10 h-10 text-lg',
          'flex items-center justify-center mr-2',
          'transition-all duration-300',
          'border border-accent-foreground/10 rounded-lg',
          'group-hover:border-primary-500 group-focus-within:border-primary-500',
          'outline outline-0 outline-primary-500',
          { 'outline-1 outline-accent-foreground': props.isActive }
        )}
      >
        {props.char !== null && <div>{props.char}</div>}
      </div>
    );
  };

  return (
    <form
      className="form-width px-4 py-6 md:p-16 bg-white md:bg-transparent rounded-mobile"
      onSubmit={handleSubmit((values) => mutate(values))}
    >
      <Helmet>
        <title>Enter OTP </title>
      </Helmet>
      <h1 className="text-c1 md:text-c3 text-left md:mb-8 mb-6 font-bold text-gray-900">Enter OTP</h1>
      <p className="mb-6 md:mb-8">
        To continue, please enter the 6-digit verification code generated by your authenticator app.
      </p>
      <div className="form-group">
        <Controller
          name="otpAttempt"
          control={control}
          render={({ field }) => (
            <OTPInput
              autoFocus={true}
              maxLength={6}
              containerClassName="group flex items-center has-[:disabled]:opacity-30"
              render={({ slots }) => slots.map((slot, index) => <Slot key={index} {...slot} />)}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />

        <p className="feedback-invalid text-sm">{errors.otpAttempt?.message}</p>
      </div>
      <div className="flex">
        <button disabled={isLoading} className="btn-primary w-full" type="submit">
          Confirm
        </button>
      </div>
      {error && (
        <div className="form-group mb-0 mt-5">
          <p className="feedback-invalid text-sm">{error === 'otp_required' ? 'OTP is required' : error}</p>
        </div>
      )}
    </form>
  );
};
