import clsx from 'clsx';
import { PlusIcon, CheckIcon } from '@heroicons/react/24/outline';

type Props = {
  added: boolean | null;
  onClick?: () => void;
};

export const UnitAction = ({ added, onClick }: Props) => {
  const ActionIcon = added ? CheckIcon : PlusIcon;
  const handleClick = added ? undefined : onClick;
  const actionText = added ? 'Added' : 'Add unit';

  // TODO add tooltip for add button
  return (
    <div
      role="button"
      className={clsx(
        'flex items-center justify-center rounded duration-300 py-1.5 px-2.5 font-medium text-xs w-[100px]',
        added ? 'bg-green-100' : 'bg-gray-100 hover:bg-gray-200 cursor-pointer'
      )}
      onClick={handleClick}
    >
      <div>
        <ActionIcon strokeWidth={2.5} className={clsx('w-4 h-4 mr-2', added ? 'text-green-700' : 'text-gray-700')} />
      </div>
      <span>{actionText}</span>
    </div>
  );
};
