import { TemplateOption, UnitOption } from 'types';
import axios from 'lib/axios';

export const fetchTemplateOptions = async (query: string): Promise<TemplateOption[]> => {
  return await axios.get(`/options/templates?q=${query}`).then((res) => {
    return res.data;
  });
};

export const fetchUnitOptions = async (query: string): Promise<UnitOption[]> => {
  return await axios.get(`/options/units?q=${query}`).then((res) => {
    return res.data;
  });
};
