import { HomeIcon } from '@heroicons/react/24/outline';
import { Breadcrumb, BreadcrumbItem } from 'components';
import { Link } from 'react-router-dom';
import { Library, NodeType } from 'types';

const MAX_ITEM_BREADCRUMB = 2;

export const BreadCrumbByFolderType = ({ type }: { type?: string }) => {
  const DefaultItem = () => (
    <BreadcrumbItem>
      <Link to="/library/regulars">Folders</Link>
    </BreadcrumbItem>
  );

  switch (type) {
    case NodeType.REGULAR:
      return <DefaultItem />;
    case NodeType.COURSE:
      return (
        <BreadcrumbItem>
          <Link to="/library/courses">Courses</Link>
        </BreadcrumbItem>
      );
    case NodeType.UNIT:
      return (
        <BreadcrumbItem>
          <Link to="/library/units">Units</Link>
        </BreadcrumbItem>
      );
    case NodeType.RTO:
      return (
        <BreadcrumbItem>
          <Link to="/library/rtos">RTOs</Link>
        </BreadcrumbItem>
      );
    default:
      return <DefaultItem />;
  }
};

export const HeaderBreadcrumb = ({ dataDetail }: { dataDetail?: Library }) => {
  const TruncateItemBreadcrumb = () => {
    if (dataDetail?.ancestors && dataDetail?.ancestors?.length > MAX_ITEM_BREADCRUMB) {
      const totalItem = dataDetail?.ancestors?.length;
      const prevItem = dataDetail.ancestors[totalItem - MAX_ITEM_BREADCRUMB - 1];
      return (
        <BreadcrumbItem>
          <Link to={`/library/folders/${prevItem?.id}`}>...</Link>
        </BreadcrumbItem>
      );
    }

    return <></>;
  };

  const FolderTypeBreadCrumb = () => {
    if (dataDetail?.ancestors && dataDetail?.ancestors?.length > 0) {
      const lastItem = dataDetail?.ancestors[0];

      return <BreadCrumbByFolderType type={lastItem?.type} />;
    } else if (dataDetail?.type) {
      return <BreadCrumbByFolderType type={dataDetail?.type} />;
    }

    return <></>;
  };

  const renderAncestorsBreadcrumb = () => {
    if (dataDetail?.ancestors && dataDetail?.ancestors?.length > 0) {
      const totalItem = dataDetail?.ancestors?.length;

      return dataDetail.ancestors.slice(totalItem - MAX_ITEM_BREADCRUMB, totalItem).map((item, index) => (
        <BreadcrumbItem key={`item-${index.toString()}`}>
          <Link to={`/library/folders/${item?.id}`}>{item?.shortName || item.name}</Link>
        </BreadcrumbItem>
      ));
    }

    return <></>;
  };

  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <Link to="/">
          <HomeIcon className="w-5 text-gray-600" />
        </Link>
      </BreadcrumbItem>
      <FolderTypeBreadCrumb />
      <TruncateItemBreadcrumb />
      {renderAncestorsBreadcrumb()}
      {dataDetail?.name && <BreadcrumbItem>{dataDetail?.shortName || dataDetail?.name}</BreadcrumbItem>}
    </Breadcrumb>
  );
};
