import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import { EmailAddress } from 'components/manage-payment/EmailAddress';
import { UploadFile } from 'components';
import { useStores } from 'hooks';
import { useState } from 'react';
import { Avatar } from 'components/avatar';
import { TwoFactorAuthToggle } from './TwoFactorAuthToggle';
import { User } from 'types';
import { observer } from 'mobx-react-lite';

type Props = {
  user: User;
  className?: string;
  onOpenModalChangePassword: () => void;
};

export interface IFormInputs {
  avatar: File;
  firstName: string;
  surname: string;
}

export const EditProfileForm = observer(({ className, onOpenModalChangePassword, user }: Props) => {
  const { authStore } = useStores();
  const {
    control,
    register,
    formState: { errors }
  } = useFormContext<IFormInputs>();

  const [avatar, setAvatar] = useState<string | undefined>(authStore.user?.avatarUrl);

  const handleChangeAvatar = (image: string) => {
    setAvatar(image);
  };

  return (
    <>
      <div
        className={clsx(
          'border border-gray-200 p-3 md:p-4 rounded-lg bg-white text-gray-900 text-xs font-normal flex flex-col',
          className
        )}
      >
        <header className="text-primary-500 font-semibold text-xs uppercase mb-8">Edit profile</header>
        <div className="flex flex-col xl:flex-row mb-8">
          <TwoFactorAuthToggle checked={user?.otpRequiredForLogin} />
        </div>
        <div className="flex flex-col xl:flex-row mb-8">
          <label className="text-xs font-semibold text-gray-400 basis-2/5 mb-4 xl:mb-0">
            <span className="leading-5">Profile Picture</span>
          </label>
          <div className="basis-2/5 form-group mb-0">
            <div className="flex justify-center items-center gap-4">
              <Avatar
                src={avatar}
                label={authStore.user?.fullName}
                className="w-[64px]"
                size={64}
                bgColor="bg-orange-100"
                txtColor="text-orange-6"
                txtSize="text-2xl"
              />
              <Controller
                name="avatar"
                control={control}
                render={({ field, fieldState }) => (
                  <UploadFile
                    className={`w-full flex-1 ${fieldState.error ? 'invalid' : ''}`}
                    onPreview={handleChangeAvatar}
                    onChange={field.onChange}
                    placeholder="SVG, PNG, JPG or GIF (max. 400x400px)"
                    accept="image/svg, image/jpeg, image/jpg , image/png, image/gif"
                    name={field.name}
                    showPreview={false}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col xl:flex-row mb-8">
          <label className="text-xs font-semibold text-gray-400 basis-2/5 mb-4 xl:mb-0">
            <span className="leading-5">Name</span>
          </label>
          <div className="basis-2/5 form-group mb-0">
            <div className="form-group">
              <input
                className={`form-control ${errors.firstName ? 'invalid' : ''}`}
                id="firstName"
                type="text"
                placeholder="First name"
                {...register('firstName')}
              />
              <p className="feedback-invalid">{errors.firstName?.message}</p>
            </div>
            <div className="form-group">
              <input
                className={`form-control ${errors.surname ? 'invalid' : ''}`}
                id="surname"
                type="text"
                placeholder="Last name"
                {...register('surname')}
              />
              <p className="feedback-invalid">{errors.surname?.message}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col xl:flex-row mb-8">
          <label className="text-xs font-semibold text-gray-400 basis-2/5 mb-4 xl:mb-0">
            <span className="leading-5">Email</span>
          </label>
          <div className="basis-2/5 form-group mb-0">
            <EmailAddress readOnly={true} email={authStore.user?.email} />
            <button type="button" onClick={onOpenModalChangePassword} className="btn btn-secondary mt-2">
              Change Password
            </button>
          </div>
        </div>
      </div>
    </>
  );
});
