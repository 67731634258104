import { Navigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { fetchUnitDetail } from 'api';
import { useQuery } from '@tanstack/react-query';
import { Unit, ErrorResponse } from 'types';

export const DefaultUnitRoute = observer(() => {
  const { id } = useParams();
  const { data } = useQuery<Unit, ErrorResponse>(['unit', id], () => fetchUnitDetail(id as string), {
    enabled: !!id
  });

  if (data?.inLibrary) {
    return <Navigate to={`/units/${id}/maps`} replace={true} />;
  }
  return <Navigate to={`/units/${id}/details`} replace={true} />;
});
