import { useStores } from 'hooks';
import React from 'react';

interface Props {
  customHeading?: string;
}

const SubscriptionExpiredHeading: React.FC<Props> = ({ customHeading }) => {
  const { subscriptionStore } = useStores();
  const { subscription } = subscriptionStore;

  const renderHeading = () => {
    if (customHeading) {
      return <p className="text-primary-600 text-sm md:text-base">{customHeading}</p>;
    }

    if (subscription?.onTrial && subscriptionStore.planOptionsModalPaymentPrompt) {
      return (
        <p className="text-primary-600 text-sm md:text-base">
          You are allowed one unit in your library as a part of your free trial. Ready to add more? Pay as you go or
          save when you buy units in bulk.
        </p>
      );
    }

    if (!subscription?.onTrial && subscriptionStore.planOptionsModalPaymentPrompt) {
      return (
        <p className="text-primary-600 text-sm md:text-base">
          You are all out of available units. You can purchase single units or save when you buy units in bulk.
        </p>
      );
    }

    return (
      <p className=" text-gray-600 text-sm md:text-base">
        Select your plan based on the number of units you plan to map.
      </p>
    );
  };
  return <div className="xs:order-2 xs:mt-4 xs:text-center md:!text-left">{renderHeading()}</div>;
};

export default SubscriptionExpiredHeading;
