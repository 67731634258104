import { ReactSVG } from 'react-svg';
import NodataSVG from '../../assets/svg/no-data.svg';

export const NoTemplates = () => {
  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center mt-[100px]">
        <ReactSVG src={NodataSVG} className="mb-4" />
        <div className="text-gray-900 flex flex-col m-w">
          <p className="mb-2 font-medium text-sm text-center">Click New Template to create your first template.</p>
          <p className="mb-8 text-sm max-w-md text-center">
            Templates can be applied to any new map when you first create your map. Templates are a great tool when you
            are consistently using the same or similar header row in your mapping templates. You can quickly select and
            apply from your saved templates in one click as you create a new map.
          </p>
        </div>
      </div>
    </div>
  );
};
