import { PresentationChartBarIcon, AcademicCapIcon, BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { Bagde } from 'components/bagde';
import { useStores } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { AutocompleteItem } from 'types';

type Props = {
  item: AutocompleteItem;
  close?: any;
};

export const SearchBarItem = ({ item, close }: Props) => {
  const { sidebarTreeStore } = useStores();
  const navigate = useNavigate();
  const navigateItem = () => {
    let link = '';
    switch (item.type) {
      case 'unit':
        link = `units/${item.code}`;
        break;
      case 'course':
        link = `courses/${item.code}`;
        break;
      case 'rto':
        link = `rtos/${item.code}`;
        break;
      default:
        link = `units/${item.code}`;
    }
    close();
    navigate(link);
  };

  const checkUnitAddToLibrary = () => {
    return item.type === 'unit' && sidebarTreeStore.isInLibrary(item.id);
  };

  return (
    <div className="autocomplete-item flex p-4 bg-white mb-[1px] hover:bg-primary-100 cursor-pointer">
      <div className="w-[18px] h-[18px] block mt-[3px] mr-3">
        {item.type === 'unit' && <PresentationChartBarIcon />}
        {item.type === 'course' && <AcademicCapIcon />}
        {item.type === 'rto' && <BuildingOfficeIcon />}
      </div>

      <div className="block flex-1 text-sm mr-3" onClick={navigateItem}>
        <div className="flex items-center">
          <h5 className="text-gray-900 font-medium" dangerouslySetInnerHTML={{ __html: item.title }} />
          {item.usageRecommendation === 'Current' && (
            <Bagde title="Current" className="ml-1 bg-green-100 text-green-700" />
          )}
          {item.usageRecommendation === 'Superseded' && (
            <Bagde title="Superseded" className="ml-1 bg-red-100 text-red-600" />
          )}
        </div>

        <p className="text-gray-700 font-normal" dangerouslySetInnerHTML={{ __html: item.desc }} />
      </div>
      {(item.added || checkUnitAddToLibrary()) && (
        <div className="flex items-center">
          <button className="bg-primary-100 rounded-3xl px-2 font-medium text-xs text-primary-500 h-[20px]">
            Added
          </button>
        </div>
      )}
    </div>
  );
};
