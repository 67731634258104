import clsx from 'clsx';
import { ChevronDoubleLeftIcon } from '@heroicons/react/24/outline';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';

export const NavbarToggleButton = observer(() => {
  const { uiStore } = useStores();
  const { isNavbarCollapsed } = uiStore;

  const handleCollapse = () => {
    uiStore.toggleNav();
  };

  return (
    <div
      role="button"
      className="flex items-center justify-center absolute right-0 w-6 h-6 -mr-3 rounded-lg bg-primary-200 text-primary-500 cursor-pointer"
      onClick={handleCollapse}
    >
      <ChevronDoubleLeftIcon className={clsx('duration-300 h-4 w-4', isNavbarCollapsed && 'rotate-180')} />
    </div>
  );
});
