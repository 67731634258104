import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';

interface Props {
  label: string;
  inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}

export const SmallToggle: FC<Props> = ({ label = '', inputProps }) => {
  return (
    <label className="flex items-center cursor-pointer">
      <input {...inputProps} type="checkbox" className="peer w-0 opacity-0 -z-10" />
      <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-primary-500"></div>
      <span className="ms-3 text-sm font-semibold text-gray-700 dark:text-gray-300">{label}</span>
    </label>
  );
};
