import { Map } from 'types';
import { RecentTable } from './RecentTable';

type props = {
  data: Map[];
  isLoading: boolean;
};

export const RecentMap = ({ data, isLoading }: props) => {
  return (
    <div className="bg-white rounded-lg p-6 shadow">
      <h5 className="text-xs leading-5 font-semibold uppercase text-primary-500">Recent MAPS</h5>
      <div className="mt-6">
        <RecentTable data={data} />
      </div>
    </div>
  );
};
