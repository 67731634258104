import { createAutocomplete } from '@algolia/autocomplete-core';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useStores } from 'hooks';
import { SearchParams } from 'meilisearch';
import { useRef, useEffect, useState, useMemo, BaseSyntheticEvent, MouseEvent, KeyboardEvent } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AutocompleteItem, RtoItem, UnitItem } from 'types';
import { SearchBarItem } from './Item';
import './SearchBar.scss';
import { NoResult } from './NoResult';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

type State = {
  isOpen: boolean;
  collections: any[];
  status?: string;
  query?: string;
  includeSuperseded?: boolean;
};

export const SearchBar = observer((props: any) => {
  const { uiStore } = useStores();
  const { search } = uiStore;
  const [autocompleteState, setAutocompleteState] = useState<State>({
    isOpen: false,
    collections: [],
    includeSuperseded: false
  });

  const autocomplete = useMemo(
    () =>
      createAutocomplete<AutocompleteItem, BaseSyntheticEvent, MouseEvent, KeyboardEvent>({
        onStateChange({ state }: { state: any }) {
          setAutocompleteState({ ...state, includeSuperseded: state?.context.includeSuperseded });
        },
        getSources(data: any) {
          const { context } = data.state;

          let params: SearchParams = {
            q: data.query,
            limit: 5,
            attributesToHighlight: ['*'],
            filter: 'usage_recommendation = "Current"'
          };

          if (context.includeSuperseded) {
            delete params.filter;
          }

          return [
            {
              sourceId: 'UNIT',
              getItems() {
                return search('Unit', params).then((hits: UnitItem[]) => {
                  if (!hits) {
                    return [];
                  }
                  return hits.map((item) => {
                    return {
                      id: item.id,
                      title: item._formatted?.code,
                      desc: item._formatted?.title,
                      type: 'unit',
                      code: item.code,
                      usageRecommendation: item.usage_recommendation
                    } as AutocompleteItem;
                  });
                });
              }
            },
            {
              sourceId: 'COURSE',
              getItems() {
                return search('Course', params).then((hits: UnitItem[]) => {
                  if (!hits) {
                    return [];
                  }
                  return hits.map((item) => {
                    return {
                      id: item.id,
                      title: item._formatted?.code,
                      desc: item._formatted?.title,
                      type: 'course',
                      code: item.code,
                      usageRecommendation: item.usage_recommendation
                    } as AutocompleteItem;
                  });
                });
              }
            },
            {
              sourceId: 'RTO',
              getItems() {
                delete params.filter;
                return search('Rto', params).then((hits: RtoItem[]) => {
                  if (!hits) {
                    return [];
                  }
                  return hits.map((item) => {
                    return {
                      id: item.id,
                      title: `${item._formatted?.code} - ${item._formatted?.legal_name}`,
                      desc: item._formatted?.rto_type,
                      type: 'rto',
                      code: item.code
                    } as AutocompleteItem;
                  });
                });
              }
            }
          ];
        },
        ...props
      }),
    [props, search]
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const { getEnvironmentProps } = autocomplete;

  useEffect(() => {
    if (!inputRef) return;
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (!formRef.current || !panelRef.current || !inputRef.current) {
      return undefined;
    }

    const { onTouchStart, onTouchMove } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current
    });

    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);

    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
    };
  }, [getEnvironmentProps, formRef, inputRef, panelRef]);

  const shouldOpenNoResultPanel =
    autocompleteState.status === 'idle' &&
    autocompleteState.collections.filter((collection) => collection.items.length).length === 0 &&
    autocompleteState.query;

  const shouldOpenResultPanel =
    autocompleteState.collections.filter((collection) => collection.items.length).length !== 0 &&
    autocompleteState.query;

  return (
    <div
      className="aa-Autocomplete z-40 fixed top-0 left-0 right-0 bottom-0 bg-gray-900/[0.25] block md:pt-28 pt-24 md:pb-20 px-4 drop-shadow-md"
      {...autocomplete.getRootProps({})}
    >
      <div className="modal md:w-[650px] w-full bg-gray-200 p-3 md:p-5 m-auto block rounded-lg">
        <form ref={formRef} className="aa-Form" {...autocomplete.getFormProps({ inputElement: inputRef.current })}>
          <div className="input-group relative mb-4 drop-shadow">
            <div className="aa-InputWrapperPrefix absolute left-4 top-0 bottom-0 flex items-center">
              <label className="aa-Label flex" {...autocomplete.getLabelProps({})}>
                <button className="aa-SubmitButton w-[18px] h-[18px]" type="button">
                  <MagnifyingGlassIcon />
                </button>
              </label>
            </div>
            <div className="aa-InputWrapper">
              <input
                className={clsx(
                  'aa-Input outline-none focus:ring-0 border-0 rounded-lg bg-white w-full text-sm p-4 pl-10',
                  autocompleteState.query ? 'pr-[110px]' : 'pr-10'
                )}
                ref={inputRef}
                {...autocomplete.getInputProps({ inputElement: inputRef.current })}
                autoFocus={true}
                placeholder="Search for Unit, Course, or RTO to add to Library"
                type={'text'}
              />
            </div>
            <div className="aa-InputWrapperSuffix absolute right-4 top-0 bottom-0 flex items-center py-4">
              {autocompleteState.query && (
                <button className="aa-ClearButton text-xs text-gray-700 mr-5" title="Clear" type="reset">
                  Clear
                </button>
              )}
              <div className="border-[1px] w-0 h-full border-gray-200"></div>
              <button
                onClick={() => uiStore.closeSearchBar()}
                className="ml-2 bg-white text-gray-700 opacity-100 text-3xl leading-none font-semibold outline-none focus:outline-none max-w-[24px] max-h-[24px] rounded-md justify-center items-center flex"
                type="button"
              >
                <XMarkIcon className="hover:rotate-90 duration-300 text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none hover:text-primary-500" />
              </button>
            </div>
          </div>
          <div className="flex items-center">
            <input
              id="include-superseded-items"
              type="checkbox"
              value="true"
              ref={checkboxRef}
              onChange={(event) => {
                autocomplete.setContext({ includeSuperseded: event.target.checked });
                autocomplete.refresh();
              }}
              className="w-4 h-4 text-primary-500 bg-white rounded border-gray-200 focus:ring-0 ring-0 "
            />
            <label htmlFor="include-superseded-items" className="ml-2 text-sm font-normal text-gray-500 cursor-pointer">
              Include superseded items
            </label>
          </div>
        </form>
        {shouldOpenNoResultPanel && <NoResult includeSuperseded={autocompleteState.includeSuperseded} />}
        {shouldOpenResultPanel && (
          <div
            ref={panelRef}
            className={['aa-Panel', 'aa-Panel--desktop', autocompleteState.status === 'stalled' && 'aa-Panel--stalled']
              .filter(Boolean)
              .join(' ')}
            {...autocomplete.getPanelProps({})}
          >
            <div className="aa-PanelLayout mt-4">
              <PerfectScrollbar options={{ suppressScrollX: true }} className="md:h-[50vh] h-[65vh]">
                {autocompleteState.collections
                  .filter((collection: any) => collection.items.length)
                  .map((collection, index) => {
                    const { source, items } = collection;

                    return (
                      <section key={`source-${index}`} className="aa-Source mb-4">
                        <label className="block mb-2 text-gray-500 text-xs font-semibold" htmlFor="">
                          {source.sourceId}
                        </label>
                        {items.length > 0 && (
                          <ul className="aa-List" {...autocomplete.getListProps()}>
                            {items.map((item: AutocompleteItem, index: number) => {
                              return (
                                <SearchBarItem
                                  key={index}
                                  item={item}
                                  close={() => uiStore.closeSearchBar()}
                                ></SearchBarItem>
                              );
                            })}
                          </ul>
                        )}
                      </section>
                    );
                  })}
              </PerfectScrollbar>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
