import { useStores } from 'hooks';
import { observer } from 'mobx-react';
import { useJwt } from 'react-jwt';

export const Impersonation = observer(() => {
  const { authStore } = useStores();

  if (!authStore.token) {
    return null;
  }

  const { decodedToken } = useJwt(authStore.token) as any;

  if (!decodedToken || !decodedToken.impersonated_by_id) {
    return null;
  }

  return (
    <div className="text-sm w-full py-2.5 pr-4 md:pr-8 border-b border-gray-200 sticky top-0 bg-primary-100 z-30 flex justify-center pl-10 sm:pl-8">
      You are impersonating as&nbsp;<strong>{authStore.user?.fullName}</strong>. Simply logout to stop impersonating.
    </div>
  );
});
