import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchPlanDuplicateToUnit, duplicatePlanToUnit } from 'api';
import { Header, Loading } from 'components';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Unit, ErrorResponse, UnitMigrationPlan, MigrationPlan, MigrateMapUnitRequest } from 'types';
import 'reactflow/dist/style.css';
import { MapMigrationFooter, MigrationGuide, MigrationSection } from 'components/migration';
import { ReactFlowProvider } from 'reactflow';
import { toast } from 'react-toastify';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

export const MapMigration = () => {
  const [guideOpened, setGuideOpened] = useState<boolean>(true);
  let migrationPlans: MigrationPlan[] = [];
  const navigate = useNavigate();
  const elementRef = useRef<HTMLDivElement>(null);
  const { id = '' } = useParams();
  const [searchParams] = useSearchParams();
  const unitId = searchParams.get('unitId') || '';
  const name = searchParams.get('name');
  const { data: unitMigrationData, isLoading } = useQuery<UnitMigrationPlan, ErrorResponse>(
    ['map', id, 'migrate', 'unitId', unitId],
    () => fetchPlanDuplicateToUnit(id, unitId),
    {
      enabled: !!id
    }
  );

  const unit: Unit = (unitMigrationData?.unit as Unit) || {};
  unit.code = unitId as string;
  unit.title = unit.title || '...';

  const [clientWidth, setClientWidth] = useState<number>(0);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setClientWidth(entries[0].contentRect.width);
    });

    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const mutation = useMutation({
    mutationFn: ({ payload, next }: { payload: MigrateMapUnitRequest; next?: () => void }) =>
      duplicatePlanToUnit(payload),
    onSuccess: (data: any) => {
      navigate(`/maps/${data.mapId}`);
      const msgSuccess = `Your map has been duplicated with new unit details.`;
      toast(msgSuccess, {
        type: 'success'
      });
    },
    onSettled(_data, _error, variables) {
      const { next } = variables;
      next && next();
    },
    onError: () => {
      const msgSuccess = 'Something went wrong! Please try again.';
      toast(msgSuccess, {
        type: 'error'
      });
    }
  });

  const onSubmit = (next?: () => void) => {
    mutation.mutate({
      payload: {
        name: name as string,
        mapId: id as string,
        unitId: unitId as string,
        plans: migrationPlans
      } as MigrateMapUnitRequest,
      next
    });
  };

  return (
    <div className="h-full flex flex-col">
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <div ref={elementRef}>
        <Header title={name || ''} subTitle={''}>
          <button
            className="border-primary-500 bg-primary-200 p-2 rounded-md border"
            onClick={() => setGuideOpened(!guideOpened)}
          >
            <QuestionMarkCircleIcon className="w-5 h-5" />
          </button>
        </Header>
      </div>
      {isLoading && (
        <div className="min-h-[200px] relative">
          <Loading />
        </div>
      )}
      {!isLoading && (
        <>
          <MigrationGuide
            unit={unitMigrationData!.previousUnit!.code!}
            desc={unitMigrationData!.previousUnit!.title!}
            targetUnit={unitMigrationData?.currentUnit?.code}
            targetUnitDesc={unitMigrationData?.currentUnit?.title}
            message={unitMigrationData?.planSummary}
            open={guideOpened}
            onClose={() => setGuideOpened(false)}
          />
          <ReactFlowProvider>
            <MigrationSection
              clientWidth={clientWidth || elementRef.current?.clientWidth || 0}
              currentRelease={unitMigrationData?.currentRelease}
              previousRelease={unitMigrationData?.previousRelease}
              plans={unitMigrationData?.plan}
              onPlanChange={(plans) => (migrationPlans = plans)}
              currentUnit={unitMigrationData?.currentUnit}
              previousUnit={unitMigrationData?.previousUnit}
            />
          </ReactFlowProvider>
          <MapMigrationFooter onSubmit={onSubmit} />
        </>
      )}
    </div>
  );
};
