import { observer } from 'mobx-react-lite';
import { DocumentTextIcon, ArrowDownTrayIcon, TableCellsIcon } from '@heroicons/react/24/outline';
import { FileType } from 'types/enums';
import { PdfIcon } from 'components/icons/PdfIcon';
import { Dropdown } from 'flowbite-react';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { ErrorResponse } from 'types';

type Props = {
  id: string;
  btnTitle?: string;
  downloadAPI: MutationFunction<string | ErrorResponse, { id: string; fileType: FileType }>;
};

export const DownloadDropdown = observer(({ id, btnTitle = 'Download', downloadAPI }: Props) => {
  const { mutate } = useMutation(downloadAPI, {
    onSuccess: (res: any) => {
      if (!res) {
        return;
      }

      toast('Please wait, your file download will begin shortly', {
        type: 'success'
      });

      window.location.assign(res?.url);
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  return (
    <div className="dropdown download relative">
      <Dropdown
        inline={true}
        outline={false}
        placement="bottom-start"
        color={'bg-white'}
        size={14}
        arrowIcon={false}
        label={
          <div className="btn-outline flex">
            <ArrowDownTrayIcon className="h-5 w-auto mr-2" />
            <span>{btnTitle}</span>
          </div>
        }
      >
        <Dropdown.Item
          onClick={() => {
            mutate({
              id: id,
              fileType: FileType.Xlsx
            });
          }}
        >
          <div className="text-sm font-normal flex whitespace-nowrap">
            <TableCellsIcon className="h-5 w-5 mr-2 " /> Export to Excel
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            mutate({
              id: id,
              fileType: FileType.Docx
            });
          }}
        >
          <div className="text-sm font-normal flex whitespace-nowrap">
            <DocumentTextIcon className="h-5 w-5 mr-2" /> Export to Word
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            mutate({
              id: id,
              fileType: FileType.Pdf
            });
          }}
        >
          <div className="text-sm font-normal flex whitespace-nowrap">
            <PdfIcon className="h-5 w-5 mr-2" /> Export to PDF
          </div>
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
});
