import { CheckIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { NotAvailable } from 'components/icons';
import { Header } from 'components/layout';
import { Loading } from 'components/loading';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ErrorResponse, Template } from 'types';
import * as yup from 'yup';
import { createTemplate, details, updateTemplate } from '../../api';
import { Button } from '../buttons/Button';
import { TemplateTable } from './TemplateTable';

export type TemplateInputsType = {
  name: string;
  columns: { value: string }[];
};

const schema = yup
  .object({
    name: yup.string().required("Template name can't be blank."),
    columns: yup.array()
  })
  .required();

export const TemplateDetails = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { id } = useParams();
  const isNewTemplatePage = !id;
  let defaultValues: TemplateInputsType = { name: '', columns: [{ value: '' }, { value: '' }, { value: '' }] };

  const [template, setTemplate] = useState<Template>();

  const { data: templateDetails, isLoading: templateDetailsLoading } = useQuery<Template, ErrorResponse>(
    ['template', id],
    () => details(id as string),
    {
      enabled: !isNewTemplatePage,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setTemplate(data);
        defaultValues = {
          name: data.name,
          columns: data.columns.map((column) => ({ value: column }))
        };
        formMethods.reset(defaultValues);
      }
    }
  );

  const formMethods = useForm<TemplateInputsType>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
    reValidateMode: 'onBlur'
  });

  const action = isNewTemplatePage ? createTemplate : updateTemplate;
  const { mutate, isLoading } = useMutation(action, {
    onSuccess: () => {
      const message = !isNewTemplatePage ? 'Your changes have been saved.' : 'Template has been created successfully.';
      toast(message, {
        type: 'success'
      });
      formMethods.clearErrors(['name']);
      formMethods.reset();
      queryClient.invalidateQueries(['templates']);
      queryClient.invalidateQueries(['templatesOptions']);
      navigate({
        pathname: `/templates`,
        search: `?page=${1}`
      });
    },
    onError: (error: any) => {
      const errorMessage = error?.message ?? 'Please try again.';
      toast(errorMessage, {
        type: 'error'
      });
    }
  });

  if (!isNewTemplatePage && (templateDetailsLoading || !templateDetails)) {
    return <Loading />;
  }

  const TemplateDetailHeader = () => {
    return (
      <>
        <Helmet>
          <title>{template ? template.name : 'New template'}</title>
        </Helmet>
        <Header
          titleComponent={
            <div className="flex content-center text-center">
              <div className="flex form-group mb-0 mr-4">
                <input
                  {...formMethods.register('name')}
                  type="text"
                  placeholder="Untitled Template"
                  className={`form-control !text-sm !text-gray-900 ${
                    formMethods.formState.errors.name ? 'invalid mr-4' : ''
                  }`}
                  data-testid="templateNameInput"
                />
              </div>
            </div>
          }
        >
          <div className="mt-4 lg:mt-0 hidden sm:flex">
            <Button
              type="submit"
              isLoading={isLoading}
              disabled={!!id && templateDetails?.predefined}
              className="btn-primary font-normal disabled:hover:bg-purple-100"
              data-testid="saveTemplate"
            >
              <CheckIcon className="w-5 h-5 inline-block mr-2" />
              {isNewTemplatePage ? 'Create' : 'Update'}
            </Button>
          </div>
        </Header>
      </>
    );
  };

  if (isMobile) {
    return (
      <div className="mapping flex flex-col h-full">
        <TemplateDetailHeader />
        <div className="flex flex-col items-center pt-24">
          <NotAvailable className="mb-8" />
          <div className="font-medium text-sm text-gray-900">Template is not available on mobile devices.</div>
        </div>
      </div>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form
        className="text-left"
        onSubmit={formMethods.handleSubmit(
          (value) => {
            mutate({
              id: id || '',
              name: value.name,
              columns: value.columns.map((column) => column.value)
            });
          },
          (errors) => {
            toast(errors.name?.message, {
              type: 'error'
            });
          }
        )}
      >
        <TemplateDetailHeader />
        <TemplateTable />
      </form>
    </FormProvider>
  );
});
