import { HeaderContext } from '@tanstack/react-table';
import clsx from 'clsx';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { useEffect, useRef, useState } from 'react';
import { HeaderDropDown } from './HeaderDropDown';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateMapColumn } from 'api';
import { useParams } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useStores } from 'hooks';
import { AssessmentTask } from 'types';

export const InputAndActionHeader = ({
  table: { getState, getFlatHeaders },
  header,
  column: { columnDef, id }
}: HeaderContext<any, any>) => {
  const { id: mapId } = useParams();
  const {
    mapUIStore: { setSyncStatus }
  } = useStores();
  const { isTabletAndDown } = useMediaQuery();
  const meta = (columnDef.meta as any) || {};
  const { width = 0, pinning, headerTitle, headerClass } = meta;
  const [value, setValue] = useState(headerTitle || '');
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const queryClient = useQueryClient();

  const index = getFlatHeaders()
    .filter((item) => item.id.includes('assessmentTask'))
    .findIndex((item) => item.id === header.id);

  const update = async () => {
    if (headerTitle === value) {
      // Don't call API if no changes
      return;
    }
    const assessmentTaskId = parseInt(id.split('assessmentTask')[1]);
    return await updateMapColumn({ mapId: parseInt(mapId as string), assessmentTaskId, task: value });
  };

  const { mutate: updateColumn, isError } = useMutation(update, {
    onSuccess: (res) => {
      setSyncStatus('saved');
      if (!res) {
        return;
      }
      queryClient.setQueryData(['maps', mapId], (oldData?: any) => {
        const assessmentTasks = [...(oldData?.assessmentTasks as AssessmentTask[])];
        const assessmentTaskIndex = assessmentTasks?.findIndex((x) => x.id === res.assessmentTaskId);
        if (assessmentTaskIndex >= 0) {
          assessmentTasks[assessmentTaskIndex] = {
            ...assessmentTasks[assessmentTaskIndex],
            task: res.task
          };
        }
        return {
          ...oldData,
          assessmentTasks
        };
      });
    },
    onMutate: () => {
      setSyncStatus('saving');
    },
    onError: () => {
      setSyncStatus('error');
    }
  });

  const onBlur = () => {
    updateColumn();
    setFocus(false);
  };

  const onFocus = () => {
    setFocus(true);
    inputRef.current?.focus();
  };

  useEffect(() => {
    setValue(headerTitle);
  }, [headerTitle]);

  return (
    <th
      style={{
        left: `${width}px`,
        minWidth: `${header.getSize()}px`,
        maxWidth: `${header.getSize()}px`,
        width: `${header.getSize()}px`
      }}
      className={clsx('h-th !p-0', pinning ? 'header-sticky' : '', headerClass, isTabletAndDown && focus && 'focus')}
      key={header.id}
    >
      <div
        className={clsx('min-h-full p-2', focus ? 'outline outline-primary-200 outline-offset-[-3px]' : '')}
        onClick={onFocus}
      >
        <input
          ref={inputRef}
          onClick={onFocus}
          className="border-0 !bg-transparent outline-none focus:outline-none focus:ring-0 bg-white w-full p-0 overflow-hidden text-sm font-normal"
          style={{ resize: 'none' }}
          value={(value as string) || ''}
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
        />
      </div>
      <HeaderDropDown index={index} columnId={id} value={value} />
      {isError && (
        <button
          className="absolute right-1 bottom-1 text-red-500 text-xs flex flex-col items-center cursor-pointer"
          title="Retry"
        >
          <ExclamationTriangleIcon className="h-4 w-auto" />
        </button>
      )}
      <div
        {...{
          onMouseDown: header.getResizeHandler(),
          onTouchStart: header.getResizeHandler(),
          className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
          style: {
            transform: header.column.getIsResizing() ? `translateX(${getState().columnSizingInfo.deltaOffset}px)` : ''
          }
        }}
      >
        <div className="absolute bottom-[-10px] left-[-8px] bg-gray-600 border border-white rounded-full h-[20px] w-[20px] flex">
          <ChevronLeftIcon className="text-white" />
          <ChevronRightIcon className="text-white" />
        </div>
      </div>
    </th>
  );
};
