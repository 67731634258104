import { ReactSVG } from 'react-svg';
import { FaceFrownIcon } from '@heroicons/react/24/outline';
import NodataSVG from '../../assets/svg/no-data.svg';

export const CourseNoUnit = () => {
  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center mt-[100px] w-full max-w-[404px]">
        <ReactSVG src={NodataSVG} className="mb-4" />
        <div className="flex text-gray-900 mb-8">
          <FaceFrownIcon strokeWidth={2} className="mr-3 w-5 h-5" />
          <p className="font-medium text-sm text-center">Unit can't be found. Please try again or remove search.</p>
        </div>
      </div>
    </div>
  );
};
