import {
  EllipsisVerticalIcon,
  TrashIcon,
  PencilSquareIcon,
  PresentationChartBarIcon,
  DocumentDuplicateIcon,
  MapIcon
} from '@heroicons/react/24/outline';
import { Dropdown } from 'flowbite-react';
import { useStores } from 'hooks';
import { Link } from 'react-router-dom';
import { Library, NodeType } from 'types';

type Props = {
  onRename?: () => void;
  onDelete?: () => void;
  libraryNode: Library;
};

export const LibraryAction = ({ libraryNode, onRename, onDelete }: Props) => {
  const { mapUIStore } = useStores();
  const inventoryTracking = libraryNode.inventoryTracking;
  const type = libraryNode.type;
  const isMap = type === NodeType.MAP;
  const isUnitFolder = type === NodeType.UNIT;

  if (isMap) {
    return (
      <div className="dropdown float-right">
        <Dropdown
          outline={false}
          color={'bg-white'}
          placement="bottom-end"
          size={14}
          arrowIcon={false}
          label={<EllipsisVerticalIcon className="h-5 w-auto" />}
        >
          <Dropdown.Item
            icon={DocumentDuplicateIcon}
            onClick={() => {
              mapUIStore.openDuplicateModal(libraryNode as any);
            }}
          >
            <div className="text-sm font-normal">Duplicate Map</div>
          </Dropdown.Item>
          <Dropdown.Item
            icon={MapIcon}
            onClick={() => {
              mapUIStore.openDuplicateMapUnitModal(libraryNode as any);
            }}
          >
            <div className="text-sm font-normal">Duplicate Map to New Unit</div>
          </Dropdown.Item>
          <div className="border-t pt-1 mt-1">
            <Dropdown.Item
              icon={TrashIcon}
              onClick={() => {
                mapUIStore.openDeleteModal(libraryNode as any);
              }}
            >
              <div className="text-sm font-normal">Delete</div>
            </Dropdown.Item>
          </div>
        </Dropdown>
      </div>
    );
  }

  return (
    <div className="dropdown float-right">
      <Dropdown
        outline={false}
        color={'bg-white'}
        placement="bottom-end"
        size={14}
        arrowIcon={false}
        label={<EllipsisVerticalIcon className="h-5 w-auto" />}
      >
        {isUnitFolder && (
          <Link to={`/units/${libraryNode.source.code}/details`}>
            <Dropdown.Item icon={PresentationChartBarIcon}>
              <div className="text-sm font-normal">View Unit Details</div>
            </Dropdown.Item>
          </Link>
        )}
        {!inventoryTracking && (
          <>
            <Dropdown.Item icon={PencilSquareIcon} onClick={onRename}>
              <div className="text-sm font-normal">Rename</div>
            </Dropdown.Item>
            <Dropdown.Item icon={TrashIcon} onClick={onDelete}>
              <div className="text-sm font-normal">Delete</div>
            </Dropdown.Item>
          </>
        )}
      </Dropdown>
    </div>
  );
};
