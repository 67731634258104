import clsx from 'clsx';
import { Spinner } from 'flowbite-react';
import { ReactNode, useEffect, useState } from 'react';

interface Props {
  children?: ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  progress?: boolean;
  className?: string;
  onClick?: (next?: () => void) => void;
  [key: string]: any;
}

export const Button = (props: Props) => {
  const { isLoading, disabled: isDisabled, className, children, progress, onClick } = props;
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(!!isLoading);
  }, [isLoading]);

  const next = () => {
    setLoading(false);
  };

  const press = () => {
    if (!onClick) return;
    if (progress) {
      setLoading(true);
    }
    onClick(next);
  };

  return (
    <button
      type={props.type || 'button'}
      className={clsx('flex items-center justify-center outline-0 text-center relative disabled:opacity-50', className)}
      disabled={loading || isDisabled}
      onClick={press}
      data-testid={props['data-testid']}
    >
      <div className={clsx('flex', loading && 'invisible')}>{children}</div>
      {loading && (
        <div className="absolute">
          <Spinner color="pink" size="sm" light={true} />
        </div>
      )}
    </button>
  );
};
