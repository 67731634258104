import { EllipsisVerticalIcon, UserMinusIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { Bagde } from 'components/bagde';
import { Dropdown, Table } from 'flowbite-react';
import { useStores } from 'hooks';
import { Member } from 'types';
import { MemberStatus, Role } from 'types/enums';
import { resendInvitation } from 'api';
import './index.scss';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

export const MembersTable = ({ members }: { members: Member[] }) => {
  const { manageUsersStore } = useStores();

  const Member = ({ member }: { member: Member }) => {
    const { mutate } = useMutation(resendInvitation, {
      onSuccess: () => {
        toast('Invitation has been resent', {
          type: 'success'
        });
      },
      onError: (error: any) => {
        const errorMessage = error?.message ?? 'Please try again.';
        toast(errorMessage, {
          type: 'error'
        });
      }
    });

    return (
      <Table.Row
        className="
      font-medium text-gray-900"
      >
        <Table.Cell className="break-words max-w-xs">
          {member.firstName} {member.surname}
        </Table.Cell>
        <Table.Cell className="font-normal break-words max-w-xs">{member.email}</Table.Cell>
        <Table.Cell>
          <Bagde
            title={member.status}
            className={`text-xs leading-5 mr-2 ${
              member.status === MemberStatus.Active ? 'bg-green-100 text-green-700' : 'bg-yellow-100 text-yellow-700'
            }`}
          />
        </Table.Cell>
        <Table.Cell>
          <div className="font-normal">{member.role}</div>
        </Table.Cell>
        <Table.Cell>
          <div className="font-normal text-center">
            {member.otpRequiredForLogin ? <CheckCircleIcon className="m-auto w-5 h-5 text-purple-500" /> : '-'}
          </div>
        </Table.Cell>
        <Table.Cell>
          {member.role !== Role.Owner && (
            <div className="dropdown flex justify-end">
              <Dropdown
                outline={false}
                color={'bg-white'}
                placement="bottom-end"
                size={14}
                arrowIcon={false}
                label={<EllipsisVerticalIcon className="h-4 w-4" />}
              >
                <Dropdown.Item
                  icon={UserMinusIcon}
                  onClick={() => {
                    manageUsersStore.openDeleteModal(member);
                  }}
                >
                  <div className="text-sm font-normal">
                    {member.status === MemberStatus.Pending ? 'Cancel Invitation' : 'Remove User'}
                  </div>
                </Dropdown.Item>
                {member.status === MemberStatus.Pending && (
                  <Dropdown.Item
                    icon={ArrowPathIcon}
                    onClick={() => {
                      mutate(member.id);
                    }}
                  >
                    <div className="text-sm font-normal">Resend Invitation</div>
                  </Dropdown.Item>
                )}
              </Dropdown>
            </div>
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <Table>
      <Table.Head>
        <Table.HeadCell>
          <div className="font-medium capitalize">Name</div>
        </Table.HeadCell>
        <Table.HeadCell>
          <div className="font-medium capitalize">Email</div>
        </Table.HeadCell>
        <Table.HeadCell>
          <div className="font-medium capitalize">Status</div>
        </Table.HeadCell>
        <Table.HeadCell>
          <div className="font-medium capitalize">Role</div>
        </Table.HeadCell>
        <Table.HeadCell>
          <div className="font-medium capitalize text-center">2FA</div>
        </Table.HeadCell>
        <Table.HeadCell>
          <span className="sr-only">Edit</span>
        </Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y">
        {members?.map((member, index) => (
          <Member key={index} member={member} />
        ))}
      </Table.Body>
    </Table>
  );
};
