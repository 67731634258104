import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { MapStatus } from 'types';
import { Link } from 'react-router-dom';

type AlertProps = {
  children: React.ReactNode;
  id?: string;
};

type StatusAlertProps = {
  dataMapStatus: MapStatus | undefined;
};

export const Alert = (props: AlertProps) => {
  const { children, id } = props;

  const closeAlert = (e: React.MouseEvent) => {
    const element = e.target as Element;
    if (element.tagName === 'svg') {
      const alertElement = (element as SVGElement).parentElement?.parentElement;
      if (alertElement) alertElement.remove();
    }
  };

  return (
    <div
      className={`flex gap-2 p-4 text-sm text-green-700 bg-green-100 border-green-500 mapping-alert`}
      role="alert"
      id={id}
    >
      {children}
      <button
        type="button"
        className="ml-auto -mx-1.5 -my-1.5 text-black p-1.5 inline-flex h-8 w-8"
        aria-label="Close"
        onClick={closeAlert}
      >
        <span className="sr-only">Close</span>
        <XMarkIcon width={20} height={20} />
      </button>
    </div>
  );
};

export const StatusAlert = (props: StatusAlertProps) => {
  const { dataMapStatus } = props;

  if (!dataMapStatus) {
    return <></>;
  }

  return (
    <>
      {dataMapStatus.reverseMappings.map((element) => (
        <Alert key={element.reverseMappingCode}>
          <span className="text-black">
            Unit <span className="font-bold !text-black">{dataMapStatus.unitCode}</span> is superseded
            {element.isEquivalent ? ' and equivalent ' : ''} to
            <Link className="font-bold text-primary-500" to={`/units/${element.reverseMappingCode}/details`}>
              &nbsp;{element.reverseMappingCode} - {element.reverseMappingTitle}.
            </Link>
            <br />
            <a
              href={element.linkCheckoutSuperseded}
              target="_blank"
              className="text-primary-500 font-bold"
              rel="noreferrer"
            >
              Check out the updates.
            </a>
          </span>
        </Alert>
      ))}
      {dataMapStatus.newRelease && dataMapStatus.newRelease.planId && (
        <Alert>
          <span className="text-black">
            This map is mapped to release <span className="font-bold">{dataMapStatus.currentReleaseNumber}</span> of{' '}
            <span className="font-bold">
              {dataMapStatus.unitCode} - {dataMapStatus.unitTitle}
            </span>{' '}
            which has been replaced by release{' '}
            <span className="font-bold">{dataMapStatus.newRelease.latestReleaseNumber}</span>.{' '}
            <Link
              className="text-primary-500 font-bold"
              to={`/units/${dataMapStatus.unitCode}/migrate?planId=${dataMapStatus.newRelease.planId}`}
            >
              Click here to preview changes and duplicate your map.
            </Link>
            <br />
            <a
              href={dataMapStatus.newRelease.linkPreviewNewRelease}
              className="font-normal underline text-xs text-gray-900"
              target="_blank"
              rel="noreferrer"
            >
              Go to training.gov.au
            </a>
          </span>
        </Alert>
      )}
    </>
  );
};
