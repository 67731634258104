import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import logo from 'assets/svg/logo.svg';
import checkcircle from 'assets/svg/check-circle.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ErrorResponse } from 'types';
import { useStores } from 'hooks';
import { SigninForm } from 'components/auth';
import { TwoFactorVerificationForm } from 'components/auth/TwoFactorVerificationForm';
import { useState } from 'react';

export interface LoginFormInputs {
  email: string;
  password: string;
  otpAttempt?: string;
}

const schema = yup
  .object({
    email: yup.string().required("Email can't be blank.").email('Please use a valid email address.'),
    password: yup.string().required("Password can't be blank.")
  })
  .required();

export const Signin = observer(() => {
  const queryClient = useQueryClient();
  const methods = useForm<LoginFormInputs>({
    resolver: yupResolver(schema)
  });
  const [isTwoFactor, setIsTwoFactor] = useState(false);

  const store = useStores();
  let navigate = useNavigate();
  let location = useLocation();

  const websiteUrl = process.env.REACT_APP_LANDING_URL || '';

  let from = (location as any).state?.from?.pathname || '/';

  const onSubmit = async ({ email, password, otpAttempt }: LoginFormInputs) => {
    try {
      await store.login({
        user: {
          email,
          password,
          otpAttempt
        }
      });

      // When user sign in/sign up, invalidate all queries to refresh the cache.
      queryClient.invalidateQueries();

      navigate(from, { replace: true });
    } catch (error: any) {
      if (error?.error === 'otp_required') {
        setIsTwoFactor(true);
      }
      throw (error as ErrorResponse).error || '';
    }
  };

  const onNavigateToWebsite = () => {
    window.location.href = websiteUrl;
  };

  return (
    <PerfectScrollbar>
      <div className="flex flex-col h-full">
        <div className="md:hidden flex justify-center items-center p-4 bg-white">
          <img className="cursor-pointer" onClick={() => onNavigateToWebsite()} src={logo} alt="Logo" />
        </div>
        <div className="flex flex-1 md:flex-row flex-col md:p-0 px-4 py-8 bg-sign-in-mobile-bg bg-cover bg-no-repeat bg-center">
          <div className="md:basis-1/2 flex-col md:flex justify-center items-center md:bg-sign-in-left-bg bg-cover bg-no-repeat">
            <div
              onClick={() => onNavigateToWebsite()}
              className="md:absolute top-16 left-16 hidden md:flex cursor-pointer"
            >
              <img src={logo} alt="" className="md:mb-0" />
            </div>
            <FormProvider {...methods}>
              {!isTwoFactor ? <SigninForm onSubmit={onSubmit} /> : <TwoFactorVerificationForm onSubmit={onSubmit} />}
            </FormProvider>
          </div>
          <div className="md:basis-1/2 flex  md:bg-sign-in-right-bg bg-cover bg-no-repeat bg-center">
            <div className="flex justify-center items-center text-white min-h-full">
              <div className="md:p-20 p-0 pt-10">
                <h1 className="text-c4 font-bold">
                  Mapping?<br></br> Say 'Goodbye' to Copy-and-Paste.
                </h1>
                <div className="text-base mt-4 font-normal">
                  Magic Map instantly generates mapping templates with unit details from training.gov.au, making your
                  road to compliance that much easier.
                </div>
                <div className="flex items-start mt-10">
                  <img src={checkcircle} alt="" className="mr-2.5" />
                  <div className="flex-1">
                    <div className="text-lg font-medium mb-2">Sync directly from Training.Gov.Au</div>
                    <div className="text-base font-normal">
                      We sync data the moment you select your unit, so you are always using the most up to date unit
                      information.
                    </div>
                  </div>
                </div>
                <div className="flex items-start mt-4">
                  <img src={checkcircle} alt="" className="mr-2.5" />
                  <div className="flex-1">
                    <div className="text-lg font-medium mb-2">Instant VET Mapping Templates</div>
                    <div className="text-base font-normal">
                      Start by creating a new template or apply the Traning.Gov updates to an existing map. No copy and
                      paste required!
                    </div>
                  </div>
                </div>
                <div className="flex items-start mt-4">
                  <img src={checkcircle} alt="" className="mr-2.5" />
                  <div className="flex-1">
                    <div className="text-lg font-medium mb-2">Updates from Training.Gov.Au</div>
                    <div className="text-base font-normal">
                      Be alerted when training.gov.au updates a unit you have previously created mapping templates for.
                    </div>
                  </div>
                </div>
                <div className="flex items-start mt-4">
                  <img src={checkcircle} alt="" className="mr-2.5" />
                  <div className="flex-1">
                    <div className="text-lg font-medium mb-2">Magic Map's Team Collaboration</div>
                    <div className="text-base font-normal">
                      Online source storage allows users to work and manage all mapping templates in one truthful
                      place. 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PerfectScrollbar>
  );
});
