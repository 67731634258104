import { BrandSettingsForm, BrandSettingsPayload } from 'types/brand-settings';
import axios from 'lib/axios';

export const getBrandSettings = async (): Promise<BrandSettingsForm> => {
  return await axios.get('/brand_settings').then((res) => {
    return res.data;
  });
};

export const updateBrandSettings = async (payload: BrandSettingsPayload) => {
  const bodyFormData = new FormData();
  // Update logo
  if (payload.logo instanceof File || payload.logo === null) {
    bodyFormData.append('logo', payload.logo);
    await axios({
      method: 'post',
      url: '/brand_settings',
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  delete payload.logo;

  return await axios({
    method: 'post',
    url: '/brand_settings',
    data: payload
  }).then((res) => {
    return res.data;
  });
};
