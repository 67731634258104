import axios from './../lib/axios';
export const setup2FA = async (): Promise<any> => {
  return await axios.get('2fa').then((res) => {
    return Promise.resolve(res.data);
  });
};

export const verify2FA = async (req: { otpAttempt: string }): Promise<any> => {
  return await axios.put('2fa', req).then((res) => {
    return Promise.resolve(res.data);
  });
};
