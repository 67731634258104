import { NotAvailable } from 'components/icons';
import { useMediaQuery } from 'components/MediaQueryProvider';
import { CourseConfigurationContainer } from 'components/library/course';
import { useParams } from 'react-router-dom';

export const CourseConfiguration = () => {
  const { isMobile } = useMediaQuery();
  let { code = '', id: folderId = '' } = useParams();

  if (folderId === 'new') {
    folderId = '0';
  }
  if (isMobile) {
    return (
      <div className="mapping flex flex-col h-full">
        <div className="flex flex-col items-center pt-24">
          <NotAvailable className="mb-8" />
          <div className="font-medium text-sm text-gray-900">
            Course Configuration is not available on mobile devices.
          </div>
        </div>
      </div>
    );
  }

  return <CourseConfigurationContainer {...{ code, folderId }} />;
};
