import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Button } from 'components/buttons';
import { Modal, ModalProps } from 'flowbite-react';

type Props = { onConfirm: () => void } & ModalProps;
export const BSFConfirmModal = (props: Props) => {
  return (
    <Modal {...props}>
      <div className="p-4 pb-0 flex justify-between items-start">
        <div className="h-10 w-10 p-2 text-primary-500 bg-primary-100 rounded-full">
          <InformationCircleIcon className="w-full" />
        </div>
        <Button className={`form-control !text-sm !text-gray-900`} onClick={() => props.onClose?.()}>
          <XMarkIcon
            className={clsx(
              'hover:rotate-90 duration-300 hover:text-primary-500',
              'text-gray-700 opacity-100 h-6 w-6 text-2xl outline-none focus:outline-none'
            )}
          />
        </Button>
      </div>
      <div className="p-4 text-sm">
        <p className="mb-3 font-bold text-gray-900">Unsaved changes!</p>
        <p className="text-gray-700">
          You have <b>unsaved changes</b> that will be lost if you continue. Please remember to click <b>'Update'</b> to
          apply changes in each section. Do you still want to proceed?
        </p>
      </div>
      <div className="p-4 pt-0">
        <div className="flex justify-end">
          <button
            type="button"
            className="hover:bg-gray-200 px-4 h-10 rounded-md mr-4 text-sm font-medium text-gray-900"
            onClick={() => props.onClose?.()}
          >
            Cancel
          </button>
          <Button
            type="button"
            className="bg-primary-500 hover:bg-primary-700 px-4 h-10 rounded-md text-white text-sm font-medium"
            onClick={() => props.onConfirm()}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};
