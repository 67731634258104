import {
  CreateCellRequest,
  CreateColumnRequest,
  DeleteColumnRequest,
  ErrorResponse,
  Mapping,
  Map,
  PagedList,
  UpdateCellRequest,
  UpdateColumnRequest,
  UpdateMapRequest,
  AssignmentTreeNote,
  MapStatus
} from 'types';
import axios from '../lib/axios';
import { NewMapRequest } from 'types';
import { FileType } from 'types/enums';

export const fetchMapDetail = async (id: number): Promise<Mapping> => {
  return await axios.get(`/maps/${id}`).then((res) => {
    return res.data;
  });
};

export const fetchListMaps = async (page: number = 1, perPage: number = 10): Promise<PagedList<Map>> => {
  return await axios.get(`/maps?page=${page}&per_page=${perPage}`).then((res) => {
    return res.data;
  });
};

export const fetchMaps = async (code: string, page: number = 1, perPage: number = 10): Promise<PagedList<Map>> => {
  return await axios.get(`units/${code}/maps?page=${page}&per_page=${perPage}`).then((res) => {
    return res.data;
  });
};

export const fetchStatus = async (id: number): Promise<MapStatus> => {
  return await axios.get(`/maps/${id}/status`).then((res) => {
    return res.data;
  });
};

export const newMap = async (req: NewMapRequest) => {
  return await axios.post('maps', { map: req }).then((res: any) => {
    return { id: res.data.id, name: req.name, unit: req.unit };
  });
};

export const deleteMap = async (id: number) => {
  return await axios.delete(`maps/${id}`);
};

export const duplicateMap = async (request: { id: number; name: string }) => {
  return await axios
    .post(`maps/${request.id}/duplicate`, {
      map: {
        name: request.name
      }
    })
    .then((res: any) => {
      return res.data;
    });
};

export const updateMap = async (req: UpdateMapRequest) => {
  return await axios.put(`maps/${req.id}`, { map: req }).then((res: any) => {
    return { ...req, id: res.id };
  });
};

export const updateMapCell = async (req: UpdateCellRequest) => {
  return await axios
    .put(`maps/${req.mapId}/cells/${req.cellId}`, { cell: { references: req.references } })
    .then((res: any) => {
      return { ...req };
    });
};

export const createMapCell = async (req: CreateCellRequest) => {
  return await axios.post(`maps/${req.mapId}/cells`, { cell: req }).then((res: any) => {
    return { ...req };
  });
};

export const createMapColumn = async (req: CreateColumnRequest) => {
  return await axios
    .post(`maps/${req.mapId}/columns`, { column: { task: req.task } })
    .then((res: { data: { id: number } }) => {
      const id = res.data.id;
      return { ...req, id };
    });
};

export const updateMapColumn = async (req: UpdateColumnRequest) => {
  return await axios
    .put(`maps/${req.mapId}/columns/${req.assessmentTaskId}`, { column: { task: req.task } })
    .then((_) => {
      return { ...req };
    });
};

export const deleteMapColumn = async (req: DeleteColumnRequest) => {
  return await axios.delete(`maps/${req.mapId}/columns/${req.assessmentTaskId}`).then((_) => {
    return { ...req };
  });
};

export const generateDownloadMapUrl = async (request: {
  id: string;
  fileType: FileType;
}): Promise<string | ErrorResponse> => {
  return await axios
    .post(`maps/${request.id}/generate_download_url`, {
      file_type: request.fileType
    })
    .then((res) => {
      return Promise.resolve(res.data);
    });
};

export const fetchMapAssignmentTree = async (id: string): Promise<AssignmentTreeNote[]> => {
  return await axios.get(`/maps/${id}/assignments`).then((res) => {
    return res.data;
  });
};
